<template>
  <f7-page>
    <div class="spacer-top"></div>
    <f7-card v-for="ad in ads" v-bind:key="ad.widget_id">
      <div class="card-header">
        <f7-link @click="openPhotoBrowser(ad, 0)">
          {{ decodeHTMLEntities(ad.widget_json.ad_name) }}
        </f7-link>
      </div>
      <div
        class="card-content card-content-padding"
        v-lazy-container="{ selector: 'img', loading: 'images/ajax-loader.gif' }"
        v-for="(image_number,index) in ad.widget_json.images.length"
      >
        <f7-link @click="openPhotoBrowser(ad, index)">
          <div class="ad-image-wrapper">
            <img v-bind:data-src="getWeeklyAdImageUrl(ad.widget_id, index)" class="ad-image" />
          </div>
        </f7-link>
      </div>
    </f7-card>
    <f7-card v-show="ads.length == 0">
      <div class="card-header">Weekly Ad Unavailable</div>
      <div class="card-content card-content-padding">
        <p>
          Unfortunately our current weekly ad is not yet available on our mobile app.
          <br />
          <br />
        </p>
        <p>
          Please try back again soon.
          <br />
          <br />
        </p>
      </div>
    </f7-card>
    <div class="spacer-bottom"></div>
  </f7-page>
</template>

<script>
import ShopHeroApi from "../api/ShopHeroApi";

import $$ from "dom7";

import ajaxLoader from '../images/ajax-loader.gif'

export default {
  data() {
    return {
      ads: []
    };
  },

  props: [],

  computed: {
    selectedLocation: ({ $store }) => $store.getters.selectedLocation
  },

  methods: {
    getWeeklyAdImageUrl(widgetId, page) {
      return ShopHeroApi.getWeeklyAdImageUrl(
        this.selectedLocation.physical_location_id,
        widgetId,
        page
      );
    },
    openPhotoBrowser(ad, active_index) {
      const adImages = [];

      for (let i = 0; i < ad.widget_json.images.length; i++) {
        const adImage = ad.widget_json.images[i];
        adImages.push(this.getWeeklyAdImageUrl(ad.widget_id, i));
      }

      const myPhotoBrowserDark = this.$root.$f7.photoBrowser.create({
        photos: adImages,
        theme: "light"
      });

      myPhotoBrowserDark.activeIndex = active_index;

      myPhotoBrowserDark.open();
    },
    todayFormatted() {
      const today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1; // January is 0!
      const yyyy = today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      return mm + "/" + dd + "/" + yyyy;
    },
    decodeHTMLEntities(str) {
      const element = document.createElement("div");
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }

      return str;
    }
  },

  created() {
    ShopHeroApi.getWeeklyAds(
      this.selectedLocation.physical_location_id,
      ads => {
        this.ads = ads.ads;
      },
      error => {}
    );
  },

  mounted() {
    this.$root.setActiveToolbar("weeklyad");
    this.$root.setNavbarTitle("Weekly Ads and Circulars");
    this.$root.setNavbarSubtitle(this.todayFormatted());

    this.$root.$f7.lazy.create(".ad-image");
  }
};
</script>

<style lang="scss" scoped="true">
.spacer-top {
  height: var(--f7-page-spacer-top);
}

.card-header {
  a {
    line-height: 1 !important;
    color: black;
    font-weight: $bold;
  }
}

.card-content {
  background-color: #fafafa;
}

.ad-image-wrapper {
  min-height: 280px;
  width: 100%;
  height: 100%;

  img[lazy="loading"] {
    width: 100px;
    height: 100px;
    margin: 100px;
  }
}

.ad-image {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.spacer-bottom {
  margin-top: 25px;
}
</style>
