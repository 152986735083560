/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: "warn" */

import * as types from '../mutation-types' // eslint-disable-line no-unused-vars
import storeConfig from "../../config";

// import ShopHeroApi from '../../api/ShopHeroApi' // eslint-disable-line no-unused-vars
import LocalStorage from '../../local-storage/LocalStorageInterface' // eslint-disable-line no-unused-vars

// import cordova from 'cordova'

// initial state
const state = {
  apiInitialized: false,
  appPackage: null,
  appVersion: 'dev',
  storeConfig
}

// getters
const getters = {
  apiIsInitialized: state => state.apiInitialized,
  appPackage: state => state.appPackage,
  appVersion: state => state.appVersion,
  options: state => state.storeConfig.options,
}

// actions
const actions = {
  setApiInitialized({ commit }) {
    return new Promise((resolve, reject) => {
      commit(types.APP_SET_API_INITIALIZED)
      resolve()
    })
  },
  setAppVersion({ commit }, { appVersion }) {
    commit(types.APP_SET_APP_VERSION, { appVersion })
  },
  setAppPackage({ commit }, { appPackage }) {
    commit(types.APP_SET_APP_PACKAGE, { appPackage })
  },
}

// mutations
const mutations = {
  [types.APP_SET_API_INITIALIZED](state) {
    state.apiInitialized = true
  },
  [types.APP_SET_APP_VERSION](state, { appVersion }) {
    state.appVersion = appVersion
  },
  [types.APP_SET_APP_PACKAGE](state, { appPackage }) {
    state.appPackage = appPackage
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}
