<template>
  <f7-page class="page-product-browse">
    <div class="spacer"></div>
    <div class="search-loading" v-show="!initialized">
      <h2>Please wait while we cook up some tasty recipes...</h2>
      <div class="preloader"></div>
    </div>
    <template v-if="initialized">
      <template v-if="Object.size(searchResults.hits) > 0">
        <f7-card>
          <f7-card-header class="search-results-header">Recipe Search Results</f7-card-header>
          <f7-card-content>
            <div class="recipe-container">
              <div v-for="recipe in searchResults.hits" class="recipe-container-individual">
                <sh-recipe-box v-bind:recipe="recipe" v-bind:key="recipe.key" />
              </div>
            </div>
          </f7-card-content>
        </f7-card>
      </template>
    </template>

    <div class="spacer-bottom" />
  </f7-page>
</template>

<script>
import ShopHeroApi from "../api/ShopHeroApi";

import ShRecipeBox from "../components/ShRecipeBox.vue";

export default {
  data() {
    return {
      searchResults: {},
      initialized: false
    };
  },

  computed: {},

  props: ["searchQuery"],

  components: {
    ShRecipeBox
  },

  methods: {},

  created() {
    // this.setNavbarTitle(this.searchQuery)
    // this.setNavbarSubtitle('Searching in Products')
    this.$root.setNavbarSearchPlaceholder("");
    this.$root.setNavbarSearchText(this.searchQuery);
  },

  mounted() {
    this.$root.setActiveToolbar("recipes");

    this.$root.setNavbarTitle("Search Recipes");
    this.$root.setNavbarSubtitle("");

    if (this.initialized === false) {
      ShopHeroApi.getRecipeSearch(
        this.$store.state.locations.selectedLocation.physical_location_id,
        this.searchQuery,
        0,
        48,
        response => {
          this.searchResults = response;
          this.$root.setNavbarSubtitle(`Found ${response.hits.length} Recipes`);
          this.initialized = true;
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped="true">
.spacer {
  width: 100%;
  height: var(--f7-page-spacer-top);
}

.search-loading {
  margin-top: 40px;
  text-align: center;
  height: 200px;
  width: 100%;
  position: relative;

  h2 {
    font-size: 2em !important;
    padding: 20px;
  }

  .preloader {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }
}

.recipe-container {
  //display: flex;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.recipe-container-individual {
  width: 33.33%;
  @include breakpoint(tablet) {
    width: 25%;
  }
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.spacer-bottom {
  width: 100%;
  height: 70px;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.search-results-header {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: 5px;
}
</style>
