<template>
  <div class="product-box">
    <!-- increment widget -->
    <div class="increment-wrapper">
      <sh-product-qty-increment-widget
        v-bind:product="product"
        v-bind:isEA="true"
        v-bind:id="`product-increment-widget-${product.productKey}`"
      >
      </sh-product-qty-increment-widget>
    </div>

    <div class="qtyBox" v-show="product.endlessAisle.caseQty > 1">
      <CaseCount :count="product.endlessAisle.caseQty" />
    </div>

    <div class="product-box-image" @click="navigateToProduct">
      <img
        class="sale-tag"
        src="../images/sale-tag.svg"
        alt="Sale tag"
        v-show="isOnSale"
      />
      <template v-if="swiper">
        <div class="product-image-container">
          <img
            class="product-image swiper-lazy"
            v-bind:data-src="productImageUrl"
          />
        </div>
      </template>
      <template v-else>
        <div
          class="product-image-container"
          v-lazy-container="{ selector: 'img' }"
        >
          <img
            class="product-image"
            v-bind:data-src="productImageUrl"
            data-loading="images/photo_loading_lt_100.png"
          />
        </div>
      </template>
    </div>

    <!-- To much info, matt wanted removed since fedex and shipping info is on the CD popup before adding to cart -->
    <!-- <FedExButton class="fedexLabel" /> -->

    <div class="product-price" v-if="parseFloat(this.product.pricePrice) > 0">
      <span class="itemPrice">${{ product.pricePrice }}</span>
      <span>
        <span>{{ product.endlessAisle.caseQty > 1 ? 'per case' : 'each' }}</span>
      </span>
    </div>
    <div class="product-price" v-else>
      <strong>
        <i>In-store Price</i>
      </strong>
    </div>
    <div class="case-price" v-if="product.endlessAisle.caseQty > 1">
      {{ product.endlessAisle.caseQty }} per case (${{
        product.endlessAisle.unitPrice().toFixed(2)
      }}
      each)
    </div>
    <div class="product-name" @click="navigateToProduct">
      <span
        v-html="filteredSizeFromName(this.product.productName).trunc(30, true)"
      ></span>
    </div>
    <div class="product-per-unit">
      <span v-html="productLabelUnitPrice"></span>
    </div>
  </div>
</template>

<script>
import $$ from "dom7";

import ShProductQtyIncrementWidget from "../components/ShProductQtyIncrementWidget.vue";
import FedExButton from "../components/FedExButton.vue";
// import CustomBox from '../images/icons/CustomBox.vue'
import CaseCount from "./CaseCount.vue";
import plus from "../images/icons/plus.svg"; // eslint-disable-line no-unused-vars
import minus from "../images/icons/minus.svg"; // eslint-disable-line no-unused-vars

import imgLoading100 from "../images/photo_loading_lt_100.png"; // eslint-disable-line no-unused-vars
import imgLoading56 from "../images/photo_loading_lt_56.png"; // eslint-disable-line no-unused-vars

import saleTag from "../images/sale-tag.svg"; // eslint-disable-line no-unused-vars

export default {
  data() {
    return {};
  },

  components: {
    ShProductQtyIncrementWidget,
    FedExButton,
    CaseCount,
  },

  props: ["product", "swiper"],

  methods: {
    filteredSizeFromName: (name) =>
      name.replace(/,\s+[0-9]+.?[0-9]*\s+[a-z]{2,}/g, ""),
    // truncatedName: filteredSizeFromName => (filteredSizeFromName.length() > 60)? filteredSizeFromName.substring(0, 60) + '...' : name,
    navigateToProduct() {
      const path = `/product/${this.product.productKey}/`;
      this.$f7.views.main.router.navigate(path, { props: { isEA: true } });
    },
  },

  computed: {
    isOnSale: ({ product }) => product.priceRank === 3,
    productUnit() {
      if (this.product.productUnit === undefined) {
        return undefined;
      }
      return this.product.productUnit;
    },
    productImageUrl() {
      // const w = window.screen.width
      const w = window.innerWidth;
      if (w > 740) {
        if (this.product.productImageUrl215) {
          return this.product.productImageUrl215;
        } else {
          // console.log('product image downgrade')
          return this.product.productImageUrl150;
        }
      } else {
        return this.product.productImageUrl150;
      }
    },
    productLabelUnitPrice() {
      return this.product.productUnitLabel();
    },
  },

  watch: {},

  created() {},

  mounted() {
    //this.$nextTick(() => {
    // this.$root.$f7.lazy.create('.product-image')
    //})
    // $$('img.lazy').trigger('lazy')
    // console.log(this.product.productName + ": " + parseFloat(this.product.pricePrice) + ": " + this.product.pricePrice)
  },

  destroyed() {
    // this is necessary because sometimes our widget is detached as a child
    // and needs to be manually removed when this component is destroyed
    // $$(`#product-increment-widget-${this.product.productKey}`).remove()
  },
};
</script>

<style lang="scss" scoped="true">
//Main Styles for the page
*,
*::before,
*::after {
  box-sizing: border-box;
}

.product-box {
  padding: 2%; // overflow: hidden;
  height: auto;
  position: relative;
  max-width: 200px;
  overflow: visible;
  .increment-wrapper {
    position: absolute;
    right: 0px;
    top: 5px;
    display: inline;
    @include breakpoint(tablet) {
      right: -3px;
    }
  }
  .product-image-container {
    max-height: 120px;
    max-width: 120px;
    overflow: hidden;
  }
  .product-box-image {
    padding: 5px;
    height: auto;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 3px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img.product-image {
      width: 100% !important;
      height: auto !important;
      // max-height: 111px;
    }
    img.sale-tag {
      position: absolute;
      left: 3px;
      top: 3px;
    }
    .swiper-lazy-preloader {
      position: absolute;
      top: 0px;
      left: -12px;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px;
      width: 100%;
      height: 100%;
      .swiper-lazy-preloader-container {
        width: 50%;
        margin: 0 auto;
        img {
          width: 100px;
          height: 100px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
    img.swiper-lazy-loaded {
      width: 70%;
      height: auto;
    }
    img.lazy-loaded {
      width: 70%;
      height: auto;
    }
  }
  .product-price {
    font-size: 14px;
    line-height: 150%;
    @include breakpoint(tablet) {
      font-size: 16px;
    }
  }
  .product-name {
    font-size: 10px;
    font-weight: $regular;
    line-height: 130%;
    max-height: 50px;
    overflow-y: hidden;
    @include breakpoint(tablet) {
      font-size: 14px;
    }
    a {
      color: #111;
    }
  }
  .product-per-unit {
    margin-top: 4px;
    font-size: 9px;
    font-weight: $regular;
    color: #ccc;
    line-height: 150%;
    @include breakpoint(tablet) {
      font-size: 12px;
    }
  }
}
svg {
  width: 9px;
  height: 9px;
}

.iconWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div.icon-wrap {
    width: auto;
    position: relative;
    line-height: 0;
  }
  div.circleNotif {
    // background: $colorThemeC;
    background: #8312c5;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    right: -5px;
    top: -7px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: white !important;
      font-weight: 700;
    }
  }
}
.chip {
  background-color: white;
  border-radius: 0%;
  box-shadow: -2px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

.qtyBox {
  width: 30px;
  height: 30px;
  z-index: 1;
  position: absolute;
  left: -3px;
  top: 5px;
}

.fedexLabel {
  margin-bottom: 10px;
}

.itemPrice {
  font-weight: 800;
}

.case-price {
  font-size: 0.5rem;
  margin-bottom: 10px;
}
</style>
