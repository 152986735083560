<template>
  <f7-app :params="f7params">
    <!-- <div id="app"> -->
    <!-- <f7-statusbar/> -->
    <f7-view
      main
      url="/splash/"
      :iosDynamicNavbar="false"
      :iosSeparateDynamicNavbar="false"
      :allowDuplicateUrls="true"
      :uniqueHistory="true"
      :restoreScrollTopOnBack="false"
      :iosPageLoadDelay="20"
    >
      <!-- :stackPages="true" -->

      <sh-nav-bar></sh-nav-bar>
      <sh-tool-bar></sh-tool-bar>
      <sh-master-menu></sh-master-menu>
      <sh-shopping-cart-side></sh-shopping-cart-side>
      <sh-cd-modal></sh-cd-modal>
      
      <div class="random"></div>
    </f7-view>
    <!-- </div> -->
  </f7-app>
</template>

<script>
import $$ from "dom7";

import { Device } from "framework7/framework7-lite.esm.bundle.js";
import cordovaApp from "../js/cordova-app.js";
import routes from "../js/routes.js";

import storeConfig from "../config";

import ShNavBar from "../components/ShNavBar.vue";
import ShToolBar from "../components/ShToolBar.vue";
import ShMasterMenu from "../components/ShMasterMenu.vue";
import ShShoppingCartSide from "../components/ShShoppingCartSide.vue";
import ShCdModal from '../components/ShCdModal.vue';

export default {
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        id: "com.shophero.spacemonkey", // App bundle ID
        name: "ShopHero Mobile Grocery", // App name
        theme: "ios", // Automatic theme detection

        // App routes
        routes: routes,

        // Input settings
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false
        },

        navbar: {
          hideOnPageScroll: true
        }
      },
      resizeTimer: null
    };
  },

  components: {
    ShNavBar,
    ShToolBar,
    ShMasterMenu,
    ShShoppingCartSide,
    ShCdModal,
  },

  methods: {
    reloadPageThrottle(e) {
      if (this.resizeTimer) return;
      this.resizeTimer = setTimeout(this.clearResizeTimer, 100);
      this.reloadPage(e);
    },
    reloadPage(e) {
      console.log("reloadPage");
      // this.$f7.views.main.router.refreshPage();
    },
    clearResizeTimer() {
      this.resizeTimer = null;
    }
  },

  on: {
    pageInit: function() {
      console.log("view page init");
    },
    routeChange: function(newRoute, previousRoute, router) {
      console.log("routeChange", newRoute);
    },
    routeUrlUpdate: function(newRoute, router) {
      console.log("routeUrlUpdate", routeUrlUpdate);
    }
  },

  created() {
    console.log("initializeAppDataViaApi start");

    this.$store.dispatch("initializeAppDataViaApi").then(
      success => {
        console.log("initializeAppDataViaApi success");
      },
      error => {
        console.log("initializeAppDataViaApi error", error);

        this.$f7.views.main.router.navigate("/uhoh/");
      }
    );

    console.log("appCreated");
    $$(window).on("resize", this.reloadPageThrottle);
  },

  mounted() {
    this.$f7ready(f7 => {
      f7.navbar.hide(".navbar");
    });

    if (false) {
      window._chatlio = window._chatlio || [];
      !(function() {
        var t = document.getElementById("chatlio-widget-embed");
        if (t && window.ChatlioReact && _chatlio.init)
          return void _chatlio.init(t, ChatlioReact);
        for (
          var e = function(t) {
              return function() {
                _chatlio.push([t].concat(arguments));
              };
            },
            i = [
              "configure",
              "identify",
              "track",
              "show",
              "hide",
              "isShown",
              "isOnline",
              "page"
            ],
            a = 0;
          a < i.length;
          a++
        )
          _chatlio[i[a]] || (_chatlio[i[a]] = e(i[a]));
        var n = document.createElement("script"),
          c = document.getElementsByTagName("script")[0];
        (n.id = "chatlio-widget-embed"),
          (n.src = "https://w.chatlio.com/w.chatlio-widget.js"),
          (n.async = !0),
          n.setAttribute("data-embed-version", "2.3");
        n.setAttribute("data-widget-options", '{"embedSidebar": true}');
        n.setAttribute(
          "data-widget-id",
          "e7aead05-f7d0-42b7-4a6b-dc419bdfdd62"
        );
        c.parentNode.insertBefore(n, c);
      })();
    }
  }
};
</script>

<style lang="scss">
@import "../scss/General/_fonts.scss";
@import "../scss/General/_generalStyling.scss";
.statusbar {
  background: black;
}

.ios .navbar-inner {
  padding: 0px !important;
}

.chatlio-widget.chatlio-sidebar .chatlio-title-bar {
  bottom: 50px !important;
}

.panel {
  height: 100% !important;
  top: 0px !important;
}

::-webkit-scrollbar {
  display: none;
}
</style>
