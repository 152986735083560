<template>
  <f7-page no-swipeback no-navbar no-toolbar>
    <div class="splash-loading">
      <div class="spash-logo">
        <img src="../images/store-assets/store-logo.png" alt="logo" />
      </div>
      <div class="preloader"></div>
    </div>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      appVersionNumber: ""
    };
  },
  computed: {
    allLocations: ({ $store }) => $store.getters.allLocations,
    selectedLocation: ({ $store }) => $store.getters.selectedLocation
  },
  watch: {
    allLocations: function(v) {
      console.log("location count: " + v.length);

      if (
        v.length > 1 &&
        this.selectedLocation.location_preference_stored === false
      ) {
        this.$f7.views.main.router.navigate(`/location-select/`);
      } else {
        this.$store.dispatch("initializeLocation").then(
          () => {
            this.$f7.views.main.router.navigate("/home/");
          },
          error => {
            this.$f7.views.main.router.navigate("/uhoh/");
          }
        );
      }
    }
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped="true">
.page {
  background-image: url(../images/search/search-background2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% -0px;
}

.spash-logo {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  max-width: 90%;
}

.splash-loading {
  margin-top: calc(80px + var(--sat));
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 200px;
  max-width: 300px;
  padding: 40px;
  position: relative;
  .preloader {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 200px;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }
}

.version-bar {
  margin: 30px;
  font-size: 1.5em;
}
</style>
