/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: "warn" */

import ShopHeroApi from '../../api/ShopHeroApi' // eslint-disable-line no-unused-vars

import * as types from '../mutation-types' // eslint-disable-line no-unused-vars

// initial state
const state = {
    initialized: false,
    offers: [],
    offersByProductId: [],
    productsByOfferId: [],
    availablePoints: null
}

// getters
const getters = {
    loyaltyIsInitialized: state => state.initialized,
    loyaltyAvailablePoints: state => state.availablePoints,
    loyaltyAvailableOffers: state => state.offers,
    getProductsByOfferId: state => offerId => state.productsByOfferId[offerId],

}

// actions
const actions = {
    populateLoyaltyMembership({ commit }, { physicalLocationId }) {
        return new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
            ShopHeroApi.getLoyaltyMembership(physicalLocationId, (response) => {
                if (response.success) {
                    commit(types.RECEIVE_LOYALTY_MEMBERSHIP, { member: response.member })
                    resolve()
                } else {
                    reject()
                }
            })
        })
    },
    populateLoyaltyOffers({ commit }, { physicalLocationId }) {
        return new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
            ShopHeroApi.getLoyaltyClippableCoupons(physicalLocationId, (response) => {
                if (response.success) {
                    commit(types.RECEIVE_LOYALTY_OFFERS, { offers: response.offers })
                    resolve()
                } else {
                    reject()
                }
            })
        })
    },
    resetLoyaltyState({ commit }) {
        return new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
            commit(types.RESET_LOYALTY_STATE)
            resolve()
        })
    }
}

// mutations
const mutations = {
    [types.RECEIVE_LOYALTY_MEMBERSHIP](state, { member }) {
        state.availablePoints = member.memberPointsAvailable
        state.initialized = true
    },
    [types.RECEIVE_LOYALTY_OFFERS](state, { offers }) {

        // reset state
        state.offers = []
        state.offersByProductId = []
        state.productsByOfferId = []

        // loop through offers
        offers.forEach(function (v) {

            // build indexes for quick lookups
            if (v.offerProductIDs) {
                v.offerProductIDs.forEach(function (i) {
                    state.offersByProductId[i] = v;
                    if (!state.productsByOfferId[v.offerId])
                        state.productsByOfferId[v.offerId] = []
                    state.productsByOfferId[v.offerId].push(i)
                });
            }

            state.offers.push(v)
        });

    },
    [types.RESET_LOYALTY_STATE](state) {
        state.initialized = false
        state.offers = []
        state.offersByProductId = []
        state.productsByOfferId = []
        state.availablePoints = null

    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
