<template>
<f7-page class="page-product-browse">
  <div class="spacer"></div>
  <ShCategoryQuickSlider v-bind:categoryKey="categoryKey"></ShCategoryQuickSlider>
  <div v-for="child in children"
       :is="child.type"
       v-bind:headerText="child.headerText"
       v-bind:categoryKey="child.categoryKey"
       v-bind:isLeaf="child.isLeaf"
       v-bind:noLink="child.noLink"
       v-bind:autoLoad="child.autoLoad"
       v-bind:limit="child.limit"
       v-bind:key="child.categoryKey"
       :loadCategoryFromApi="true"
       class="clearfix"></div>
  <!--   <ShProductsCard v-bind:categoryKey="categoryKey"></ShProductsCard>
 -->
  <div class="spacer-bottom" />
</f7-page>

</template>

<script>
import ShCategoryQuickSlider from '../components/ShCategoryQuickSlider.vue'
import ShProductsCard from '../components/ShProductsCard.vue'

export default {

  data() {
    return {
      children: [],
    }
  },

  props: [
    'categoryKey',
  ],

  components: {
    ShCategoryQuickSlider,
    ShProductsCard,
  },

  computed: {
    categoryChildren: ({ categoryKey, $store }) => $store.getters.getCategoryByKey(categoryKey).child_categories,
  },

  methods: {

  },

  mounted() {
    this.$root.setActiveToolbar('aisles')

    // dynamically add the children components
    if (this.$store.getters.getCategoryByKey(this.categoryKey)) {
      const category = this.$store.getters.getCategoryByKey(this.categoryKey)
      this.$root.setNavbarTitle(category.category_name)

      if (category.parent_category.shophero_category_key !== '000000') {
        this.$root.setNavbarSubtitle(`in ${category.parent_category.category_name}`)
      } else {
        this.$root.setNavbarSubtitle('')
      }

      if (category.child_categories) {
        let childIndex = 0
        const childCategoryKeys = Object.keys(category.child_categories)
        for (const key of childCategoryKeys) { // eslint-disable-line no-restricted-syntax
          if (this.$store.getters.getCategoryByKey(key).child_product_count > 0) {
            this.children.push({
              type: ShProductsCard,
              categoryKey: key,
              loadCategoryFromApi: true,
              autoLoad: (childIndex++ < 3),
            })
          }
        }
      }
    }

    // else add self because this must be a leaf category
    if (this.children.length === 0) {
      this.children.push({
        type: ShProductsCard,
        categoryKey: this.categoryKey,
        isLeaf: true,
        autoLoad: true,
        loadCategoryFromApi: true,
        //limit: 256,
      })
    } else {
      // push the "popular in" to top
      this.children.unshift({
        type: ShProductsCard,
        headerText: 'Popular in',
        categoryKey: this.categoryKey,
        noLink: true,
        loadCategoryFromApi: true,
        autoLoad: true,
      })
    }

    this.$store.dispatch('setSearchModeProducts')

  },

}

</script>

<style lang="scss" scoped="true">
.spacer {
  width: 100%;
  height: var(--f7-page-spacer-top);
}

.spacer-bottom {
  width: 100%;
  height: 70px;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
</style>
