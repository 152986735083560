<template>
  <div class="add-to-cart-widget">
    <f7-col class='checkoutItemColumn'>
      <div style="display: none;" class="plusCart" @click="incrementCart">

      </div>
      <div v-clickOut="closeQtyExpander" @click="openQtyExpander" class="itemCounter">
        <div class="plusWidget" @click="incrementCart">
          <svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>plusAddWidget</title>
              <g id="Canvas" transform="translate(-6567 -258)">
              <g id="plusAddWidget">
              <use xlink:href="#plusWidget" transform="translate(6567 258)"/>
              </g>
              </g>
            <defs>
            <path id="plusWidget" d="M 6.75 0C 6.29883 0 6 0.298828 6 0.75L 6 6L 0.75 6C 0.298828 6 0 6.29883 0 6.75L 0 8.25C 0 8.70117 0.298828 9 0.75 9L 6 9L 6 14.25C 6 14.7012 6.29883 15 6.75 15L 8.25 15C 8.70117 15 9 14.7012 9 14.25L 9 9L 14.25 9C 14.7012 9 15 8.70117 15 8.25L 15 6.75C 15 6.29883 14.7012 6 14.25 6L 9 6L 9 0.75C 9 0.298828 8.70117 0 8.25 0L 6.75 0Z"/>
            </defs>
          </svg>
        </div>
        <div class="itemQty" @click="openQtyExpander">
          {{qtyInCart}}
        </div>
        <div class='minusWidget' @click="decrementCart">
          <svg width="15" height="2" viewBox="0 0 15 2" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>minusWidget</title>
            <g id="Canvas" transform="translate(-6525 -266)">
            <g id="minusWidget">
            <use xlink:href="#something" transform="translate(6525 266)"/>
            </g>
            </g>
            <defs>
            <path id="something" fill-rule="evenodd" d="M 0 0L 0 2L 15 2L 15 0L 0 0Z"/>
            </defs>
          </svg>
        </div>
      </div>
    </f7-col>
  </div>
</template>

<script>

import $$ from 'dom7'

import { TweenMax } from 'gsap' // eslint-disable-line

export default {
  data() {
    return {
      isExpanded: false,
      qtyExpanderRadius: null,
      domOriginalParent: null,
      domOriginalTop: null,
      domOriginalLeft: null,
      domOriginalRight: null,
      domOriginalBottom: null,
      debug: false,
    }
  },

  props: [
    'product',
    'isEA'
  ],

  directives: {
      clickOut: {
        inserted: (el, binding) => {
          window.addEventListener('click', (e) => {
            if (!el.contains(e.target)) {
              binding.value()
            }
          })
        },
      },
    },

  computed: {
    qtyInCart: ({ product, $store }) => {
      const productInCart = $store.getters.getProductInCartByProductKey(product.productKey)
      if(productInCart) {
        if(productInCart.qtyInCart == parseInt(productInCart.qtyInCart)) {
          return parseInt(productInCart.qtyInCart)
        } else {
          return productInCart.qtyInCart
        }
      } else {
        return 0
      }
    },
  },

  watch: {
    isExpanded: function (val) { // eslint-disable-line

        const itemCounter = this.$el.querySelector('.itemCounter')
        const itemQty = this.$el.querySelector('.itemQty')
        const plusWidget = this.$el.querySelector('.plusWidget')
        const minusWidget = this.$el.querySelector('.minusWidget')
        // console.log(plusWidget.childNodes[0]);

        // console.log(this.qtyExpanderRadius)

        if (val) {
          if(this.debug) console.log('expanding')

          this.moveComponentToCard()

          plusWidget.childNodes[0].style.fill = '#fff';
          minusWidget.childNodes[0].style.fill = '#fff';
          // expand
          TweenMax.to(itemCounter, 0.30, {
            height: '80px',
            background: 'rgba(18, 197, 34, 0.8)',
            color: '#fff',
            top: '-20px',
          })
          TweenMax.to(itemQty, 0.30, {
            color: 'white',
          })
          TweenMax.to(plusWidget, 0.30, {
            position: 'relative',
            margin: '10px 0 0 0',
            top: '-8px',
            display: 'block',
          })
          TweenMax.to(minusWidget, 0.30, {
            position: 'relative',
            margin: '0 0 10px 0',
            bottom: '-8px',
            display: 'block',
          })
        } else {

          if(this.debug) console.log('contracting')

          if (this.qtyInCart > 0) {
            // display qty in cart
            TweenMax.to(itemCounter, 0.30, {
              height: '30px', //this.qtyExpanderRadius - 10 + 'px',
              background: 'rgba(18, 197, 34, 0.8)',
              top: '0px',
              onComplete: this.moveComponentBackToParent,
            })
            TweenMax.to(plusWidget, 0.05, {
              display: 'none',
            })
            TweenMax.to(minusWidget, 0.05, {
              display: 'none',
            })
          } else {
            // display '+'
            plusWidget.childNodes[0].style.fill = '#12C522';
            TweenMax.to(itemCounter, 0.30, {
              top: '0px',
              height: '30px',
              width: '30px',
              background: '#fff',
              onComplete: this.moveComponentBackToParent,
            })
            TweenMax.to(itemQty, 0.1, {
              display: 'none',
            })
            TweenMax.to(plusWidget, 0.1, {
              margin: 0,
              top: '',
              display: 'block',
            })
            TweenMax.to(minusWidget, 0.1, {
              display: 'none',
            })
          }
        }
      },
      qtyInCart: function(val) {
        this.applyQtyInCart(val)
      },
  },

  methods: {
     toggleQtyExpander(e) {
      if(this.debug) console.log('toggleQtyExpander')
        if (!this.isExpanded) {
          this.openQtyExpander()
        } else {
          this.closeQtyExpander()
        }
        if(e) e.stopPropagation()
      },

      openQtyExpander() {
        this.isExpanded = true
        if(this.qtyInCart == 0) {
            if (!this.isEA) {
              this.incrementCart();
            } else {
              if (this.isEA && this.qtyInCart == 0 && this.product.endlessAisle.caseQty > 1 && !this.$store.getters.userHasPlacedCDOrder) {
                // Open the EA modal
                this.$root.showChoiceDoorstepConfirmationModal(this.product, 1);
              } else {
                this.incrementCart();
              }
            }
        }
      },
      closeQtyExpander() {
        this.isExpanded = false
      },
      incrementCart(e) {
        if(!this.isExpanded) {
          this.openQtyExpander();
          return;
        }

        if(this.qtyInCart == 0) {
          this.$root.addedItemToCartToast()
        }
        this.$store.dispatch('incrementProductInCart', {
          product: this.product,
        })
        if(e) e.stopPropagation()
      },
      decrementCart(e) {
        this.$store.dispatch('decrementProductInCart', {
          product: this.product,
        })
        if(e) e.stopPropagation()
      },
      moveComponentToCard() {

          const swiperParent = this.domOriginalParent.closest('.swiper-container')
          if(swiperParent.length == 0) return

          const swiper = swiperParent[0].swiper
          swiper.allowTouchMove = false

          const dom = $$(this.$el)
          const appDom = dom.closest('.card')

          if(this.domOriginalParent.eq(dom.parent()) == false)
            return

          if(this.debug) console.log('moving to card')

          const domOffset = dom.offset()
          dom.remove()
          const appDomOffset = appDom.offset()
          appDom.append(dom)
          dom.css('position', 'absolute')
          dom.css('right', 'initial')
          //dom.css('bottom', '0px')
          dom.css('top', (domOffset.top - appDomOffset.top) + 'px')
          dom.css('left', (domOffset.left - appDomOffset.left) + 'px')




      },
      moveComponentBackToParent() {

          const swiperParent = this.domOriginalParent.closest('.swiper-container')
          if(swiperParent.length == 0) return

          const swiper = swiperParent[0].swiper
          swiper.allowTouchMove = true

          const dom = $$(this.$el)
          const appDom = this.domOriginalParent

          // if(this.domOriginalParent.eq(dom.parent()))
          //   return

          if(this.debug) console.log('moving to parent')

          dom.remove()
          appDom.append(dom)
          dom.css('position', 'static')
          dom.css('top', this.domOriginalTop)
          dom.css('left', this.domOriginalLeft)
          dom.css('right', this.domOriginalRight)
          //dom.css('bottom', this.domOriginalBottom)

      },
      applyQtyInCart(val) {
        if(this.debug)  console.log('apply qtyInCart:' + val)
        const itemCounter = this.$el.querySelector('.itemCounter')
        const itemQty = this.$el.querySelector('.itemQty')
        const plusWidget = this.$el.querySelector('.plusWidget')
        const minusWidget = this.$el.querySelector('.minusWidget')

        // fade in 'in-cart' color
        if(val > 0) {
          TweenMax.to(itemCounter, 0.30, {
            background: 'rgba(18, 197, 34, 0.8)',
          })
          TweenMax.to(itemQty, 0.30, {
            display: 'block',
            color: '#fff',
          })

          if(!this.isExpanded) {
            $$(plusWidget).css('display', 'none')
            $$(minusWidget).css('display', 'none')
          }
        }
      }
  },


  mounted() {
    this.domOriginalParent = $$(this.$el).parent()
    this.domOriginalTop = $$(this.$el).css('top')
    this.domOriginalLeft = $$(this.$el).css('left')
    this.domOriginalRight = $$(this.$el).css('right')
    this.domOriginalBottom = $$(this.$el).css('bottom')

    this.qtyExpanderRadius = $$('.itemCounter').height() + 10

    this.applyQtyInCart(this.qtyInCart)
  },

}

</script>


<style lang="scss" scoped="true">

.add-to-cart-widget {
  z-index: 500;
  .checkoutItemColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    .itemCounter {
      @extend .checkoutItemColumn;
      height: 30px;
      width: 30px;
      @include breakpoint(tablet) {
        height: 40px;
        width: 40px;
      }
      color: white;
      background-color: white;
      border: solid 2px #12C522; //#474646
      border-radius: 23px;
      font-weight: 600;
      font-size: 12px;
      .itemQty {
        display: none;
        color: white;
      }
      .plusWidget {
        padding: 0 10px;
        svg {
          fill: #12C522;
        }
      }
      .minusWidget {
        display: none;
        padding: 0 10px;
        svg {
          fill: #12C522;
        }
      }
    }
  }
}

svg {
  width: 9px;
  height: 9px;
}

</style>
