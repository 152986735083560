/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: "warn" */

import * as types from '../mutation-types' // eslint-disable-line no-unused-vars

// initial state
const state = {
  selectedIcon: 'home',
  routeStateMap: [],
}

// getters
const getters = {
  activeToolbarIcon: state => state.selectedIcon,
}

// actions
const actions = {
  setActiveToolbarIcon({ commit }, { icon }) {
    commit(types.RECEIVE_TOOLBAR_ACTIVE_ICON, { activeIconKey: icon })
  },
  pushToolBarState({ commit }, { route }) {
    commit(types.RECEIVE_TOOLBAR_STATE, { route })
  },
  refreshToolBar({ commit }, { route }) {
    commit(types.RECEIVE_TOOLBAR_REFRESH, { route })
  },
}

// mutations
const mutations = {
  [types.RECEIVE_TOOLBAR_ACTIVE_ICON](state, { activeIconKey }) {
    state.selectedIcon = activeIconKey
  },
  [types.RECEIVE_TOOLBAR_STATE](state, { route }) {
    state.routeStateMap[route] = {
      selectedIcon: state.selectedIcon,
    }
  },
  [types.RECEIVE_TOOLBAR_REFRESH](state, { route }) {
    if (state.routeStateMap[route]) {
      const map = state.routeStateMap[route]
      state.selectedIcon = map.selectedIcon
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
