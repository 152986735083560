<template>
  <f7-button
    large
    class="button"
    :disabled="!disabledConditional"
    @click="$emit('onButtonClick', $event)"
  >
    <slot></slot>
    <f7-icon class="icon" size="20px" f7="chevron_right"></f7-icon>
  </f7-button>
</template>

<script>
export default {
  props: ["disabledConditional"],
};
</script>

<style lang="scss" scoped>
.button {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 30px;
  background: $storePrimaryColor;
  color: white;
}

.icon {
  margin-right: 16px;
}
</style>
