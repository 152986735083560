import ShopHeroApi from '../api/ShopHeroApi' // eslint-disable-line no-unused-vars

import * as types from './mutation-types' // eslint-disable-line no-unused-vars


// chain load events here
export const initializeAppDataViaApi = ({ dispatch, state }) => new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
  console.log('initializeAppDataViaApi')
  // if(Object.size(state.categories.categoryTree) === 0) {
  if (!state.app.apiInitialized) {
    dispatch('authenticateUserViaStoredToken').then(() => {
        dispatch('populateLocations').then(() => {
          // dispatch('initializeLocation').then(() => {
            resolve()
          // },
          // (error) => {
          //   reject(error)
          // })
      },
      (error) => {
        reject(error)
      })
    },
    (error) => {
        reject(error)
    })
  }
})

export const initializeLocation = ({ dispatch, state }) => new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
  dispatch('populateRootCategoryTree', { physicalLocationId: state.locations.selectedLocation.physical_location_id }).then(() => {
    dispatch('populateCart', { physicalLocationId: state.locations.selectedLocation.physical_location_id }).then(() => {
      dispatch('setApiInitialized').then(() => {
        resolve()
      }, (error) => {
        reject(error)
      })
    }, (error) => {
      reject(error)
    })
  }, (error) => {
    reject(error)
  })
})

export const scanBarcode = ({ state }) => new Promise((resolve, reject) => {

    if(typeof(cordova) == 'undefined') reject('No barcode scanner available')
    if(typeof(cordova.plugins.barcodeScanner) == 'undefined') reject('Barcode scanning is not available on your device.')

     cordova.plugins.barcodeScanner.scan(
        function (result) {
            // alert("We got a barcode\n" +
            //       "Result: " + result.text + "\n" +
            //       "Format: " + result.format + "\n" +
            //       "Cancelled: " + result.cancelled);

            if(result.cancelled == 0) {
              ShopHeroApi.getProductByUpc(state.locations.selectedLocation.physical_location_id, result.text, (response) => {
                resolve(response.product_key)
              }, (error) => {
                reject('The scanned barcode did not match.')
              })

            }

        },
        function (error) {
            reject("Barcode scanning failed: " + error);
        },
        {
            preferFrontCamera : false, // iOS and Android
            showFlipCameraButton : false, // iOS and Android
            showTorchButton : true, // iOS and Android
            torchOn: true, // Android, launch with the torch switched on (if available)
            saveHistory: false, // Android, save scan history (default false)
            prompt : "Place a barcode inside the scan area", // Android
            resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
            formats : "UPC_A,UPC_E,EAN_8,EAN_13", // default: all but PDF_417 and RSS_EXPANDED
            //orientation : "landscape", // Android only (portrait|landscape), default unset so it rotates with the device
            //disableAnimations : true, // iOS
            disableSuccessBeep: false // iOS and Android
        }
     )
})
