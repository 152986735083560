

import HomePage from '../pages/home.vue'

import SplashPage from '../pages/splash.vue'
import UhOhPage from '../pages/uhoh.vue'

import LocationSelectPage from '../pages/location-select.vue'

import CategoryBrowse from "../pages/category-browse.vue"

import ProductBrowsePage from '../pages/product-browse.vue'
import ProductPage from '../pages/product.vue'

import SearchProductsLandingPage from '../pages/search-products-landing.vue'
import SearchProductsPage from '../pages/search-products.vue'

import SearchRecipesLandingPage from '../pages/search-recipes-landing.vue'
import SearchRecipesPage from '../pages/search-recipes.vue'

import RecipePage from '../pages/recipe.vue'

import WeeklyAdsPage from '../pages/weekly-ads.vue'

import StoreInfoPage from '../pages/store-info.vue'

import BuyItAgainPage from '../pages/buy-it-again.vue'

import FavoritesPage from '../pages/favorites.vue'

import CouponsPage from '../pages/coupons.vue'


import AuthSignInPage from '../pages/auth-sign-in.vue'
import AuthSignUpPage from '../pages/auth-sign-up.vue'
import AuthResetPasswordPage from '../pages/auth-reset-password.vue'

import CheckoutServicePage from '../pages/checkout-service/checkout-service.vue'
import CheckoutSchedulePage from '../pages/checkout-schedule.vue'
import CheckoutPaymentPage from '../pages/checkout-payment.vue'
import CheckoutReviewPage from '../pages/checkout-review.vue'

import OrderHistoryPage from '../pages/order-history.vue'
import OrderPage from '../pages/order.vue'
import OrderPlacedPage from '../pages/order-placed.vue'

import LoyaltyLinkAccountAppCard from '../pages/loyalty-link-account-app-card.vue'


export default [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/home/',
    component: HomePage,
  },
  {
    path: '/splash/',
    component: SplashPage,
  },
  {
    path: '/uhoh/',
    component: UhOhPage,
  },
  {
    path: "/location-select/",
    component: LocationSelectPage,
  },
  {
    path: "/category-browse/",
    component: CategoryBrowse,
  },
  {
    path: '/product-browse/:categoryKey/',
    component: ProductBrowsePage,
  },
  {
    path: '/product/:productKey/',
    component: ProductPage,
    options: {
      animate: false,
    },
  },
  {
    path: '/search/products-query/:searchQuery/',
    component: SearchProductsPage,
  },
  {
    path: '/search/products-query/:searchQuery/:categoryFilter/',
    component: SearchProductsPage,
  },
  {
    path: '/search/products/',
    component: SearchProductsLandingPage,
  },
  {
    path: '/search/recipes/',
    component: SearchRecipesLandingPage,
  },
  {
    path: '/search/recipes-query/:searchQuery/',
    component: SearchRecipesPage,
  },
  {
    path: '/recipe/:recipeKey/',
    component: RecipePage,
  },
  {
    path: '/store-info/',
    component: StoreInfoPage,
  },
  {
    path: '/weekly-ads/',
    component: WeeklyAdsPage,
  },
  {
    path: '/favorites/',
    component: FavoritesPage,
  },
  {
    path: '/coupons/',
    component: CouponsPage,
  },
  {
    path: '/buy-it-again/',
    component: BuyItAgainPage,
  },
  {
    path: '/buy-it-again/:categoryKey/',
    component: BuyItAgainPage,
  },
  {
    path: '/auth-sign-in/:nextPage/',
    component: AuthSignInPage,
    options: {
      history: false,
      pushState: false,
    },
  },
  {
    path: '/auth-sign-up/:nextPage/',
    component: AuthSignUpPage,
    options: {
      history: false,
      pushState: false,
    },
  },
  {
    path: '/auth-reset-password/:nextPage/',
    component: AuthResetPasswordPage,
    options: {
      history: false,
      pushState: false,
    },
  },
  {
    path: '/checkout-service/',
    component: CheckoutServicePage,
  },
  {
    path: '/checkout-schedule/',
    component: CheckoutSchedulePage,
  },
  {
    path: '/checkout-payment/',
    component: CheckoutPaymentPage,
  },
  {
    path: '/checkout-review/',
    component: CheckoutReviewPage,
  },
  {
    path: '/orders/',
    component: OrderHistoryPage,
  },
  {
    path: '/orders/:orderKey/:itemsAddedCount?',
    component: OrderPage,
  },
  {
    path: '/order-placed/:eaOrderKey/:orderKey/',
    component: OrderPlacedPage,
  },
  {
    path: '/loyalty-app-card-link-account/:nextPage/',
    component: LoyaltyLinkAccountAppCard,
    options: {
      history: false,
      pushState: false,
    },
  },
  {
    path: '(.*)',
    component: UhOhPage,
  },
];
