<template>
  <f7-page>

  <div class="spacer"></div>

    <div class="products-loading" v-show="!productsLoaded">
      <div class="preloader"></div>
    </div>

    <template v-if="productsLoaded && !categoryKey">
      <sh-products-card v-for="categoryResults in productCategories" v-bind:key="categoryResults.categoryKey" v-bind:sourceProducts="categoryResults.products" v-bind:headerText="`Previously Purchased in ${categoryResults.categoryName}`" v-bind:categoryKey="categoryResults.key" :customLink="`/buy-it-again/${categoryResults.categoryKey}/`" :customLinkText="linkText(categoryResults)" :isLeaf="false" :noLink="false" :loadCategoryFromApi="false" :searchResultMode="false"/>
    </template>


    <template v-if="productsLoaded && categoryKey">
      <sh-products-card v-bind:sourceProducts="productCategories[categoryKey].products" v-bind:headerText="`Previously Purchased in ${productCategories[categoryKey].categoryName}`" :autoLoad="true" :isLeaf="true" :noLink="true" />
    </template>

  <div class="spacer-bottom"></div>


  </f7-page>
</template>

<script>

import ShopHeroApi from '../api/ShopHeroApi'

import ShProductsCard from '../components/ShProductsCard.vue'

export default {

  data() {
    return {
      productCategories: {},
      productsLoaded: false,
    }
  },

  props: [
    'categoryKey',
  ],

  components: {
    ShProductsCard,
  },

  methods: {
    linkText(categoryResult) {
      const length = categoryResult.products.length
      if(length <= 3) return ''

      const furtherResults = (length-3 <= 100) ? length-3 : `100+`
      return `View ${furtherResults} more &gt;`
    }
  },

  mounted() {

    const self = this

    ShopHeroApi.getPreviouslyPurchasedProductsByQuantity(this.$store.state.locations.selectedLocation.physical_location_id, 0, 200, (response) => {

        const productCategoryMap = {}

        response.products_by_quantity.forEach(function (e) {

          const product = ShopHeroApi.convertApiProductToProductModel(e.product, e.product.product_key);

          // sort items into root category groups
          if (e.product.product_categories.length > 0) {
            const categoryKey = e.product.product_categories[0].shophero_category_key
            const category = self.$store.getters.getCategoryByKey(categoryKey)

            if(category) {
              let topLevelCategory = category
              for (let level = 5; level >= 0; level--) { // eslint-disable-line
                if ((topLevelCategory.parent_category) && (topLevelCategory.parent_category.shophero_category_key !== '000000')) {
                  topLevelCategory = topLevelCategory.parent_category
                } else {
                  break
                }
              }

              if (productCategoryMap[topLevelCategory.shophero_category_key]) {
                productCategoryMap[topLevelCategory.shophero_category_key].products.push(product)
              } else {
                productCategoryMap[topLevelCategory.shophero_category_key] = {
                  categoryName: topLevelCategory.category_name,
                  categoryKey: topLevelCategory.shophero_category_key,
                  products: [product],
                }
              }
            }
          }

        })

        self.productCategories = productCategoryMap
        self.productsLoaded = true

      })

    this.$root.setNavbarTitle('Buy It Again')

    if(this.categoryKey) {
      const category = this.$store.getters.getCategoryByKey(this.categoryKey)
      this.$root.setNavbarSubtitle(category.category_name)
    } else {
      this.$root.setNavbarSubtitle('')
    }


  },

}

</script>

<style lang="scss" scoped="true">

.spacer {
  width: 100%;
  height: var(--f7-page-spacer-top);
}

.spacer-bottom {
  width: 100%;
  height: 40px;
}

.products-loading {
  margin-top: 40px;
  text-align: center;
  height: 200px;
  width: 100%;
  position: relative;
  .preloader {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }
}

</style>
