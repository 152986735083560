<template>
  <li class="swipeout">
    <div class="swipeout-content">
      <div class="item-content">
        <div class="item-inner">
          <f7-row class="checkoutItem">
            <!-- Instructions and more and less -->
            <div class="checkoutItemColumn">
              <div
                v-clickOut="closeQtyExpander"
                @click="qtyExpander = !qtyExpander"
                class="itemCounter"
              >
                <!-- Plus sign -->
                <div
                  style="display: none"
                  class="plusCart"
                  @click="incrementCart"
                >
                  <svg
                    class="plusCart"
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>plus</title>
                    <g id="Canvas" transform="translate(-4267 -464)">
                      <g id="plus">
                        <use
                          xlink:href="#plusCart"
                          transform="translate(4267 464)"
                          fill="#474646"
                        />
                      </g>
                    </g>
                    <defs>
                      <path
                        id="plusCart"
                        d="M 6.3 0C 5.87891 0 5.6 0.298828 5.6 0.75L 5.6 6L 0.7 6C 0.278907 6 0 6.29883 0 6.75L 0 8.25C 0 8.70117 0.278907 9 0.7 9L 5.6 9L 5.6 14.25C 5.6 14.7012 5.87891 15 6.3 15L 7.7 15C 8.12109 15 8.4 14.7012 8.4 14.25L 8.4 9L 13.3 9C 13.7211 9 14 8.70117 14 8.25L 14 6.75C 14 6.29883 13.7211 6 13.3 6L 8.4 6L 8.4 0.75C 8.4 0.298828 8.12109 0 7.7 0L 6.3 0Z"
                      />
                    </defs>
                  </svg>
                </div>
                <!-- Number -->
                <div class="qty">
                  {{ formattedQtyInCart }} {{ productUnit }}
                </div>
                <!-- Minus sign -->
                <div
                  style="display: none"
                  class="minusCart"
                  @click="decrementCart"
                >
                  <svg
                    width="14"
                    height="3"
                    viewBox="0 0 14 3"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>minus</title>
                    <g id="Canvas" transform="translate(-4267 -448)">
                      <g id="minus">
                        <use
                          xlink:href="#minusCart"
                          transform="translate(4267 448)"
                          fill="#474646"
                        />
                      </g>
                    </g>
                    <defs>
                      <path
                        id="minusCart"
                        d="M 13.3 2.8L 0.7 2.8C 0.278907 2.8 0 2.52109 0 2.1L 0 0.7C 0 0.278907 0.278907 0 0.7 0L 13.3 0C 13.7211 0 14 0.278907 14 0.7L 14 2.1C 14 2.52109 13.7211 2.8 13.3 2.8Z"
                      />
                    </defs>
                  </svg>
                </div>
              </div>
              <div
                @click="instructionsExpander = !instructionsExpander"
                class="specialInstructions"
                v-bind:class="{ specialactive: hasSpecialInstructions }"
              >
                Instructions
              </div>
            </div>

            <!-- product image -->
            <div class="productImage">
              <f7-link @click="navigateToProduct" panel-close>
                <img
                  v-bind:src="cartItem.product.productImageUrl150"
                  v-bind:alt="cartItem.product.productName"
                />
              </f7-link>
            </div>

            <!-- description -->
            <div class="descriptionWrapper">
              <div class="description">
                <h2 v-html="cartItem.product.productName.trunc(100, true)"></h2>
              </div>
            </div>

            <!-- price -->
            <div class="priceWrapper">
              <div class="vertColumn priceUnit">
                <p>
                  ${{ cartItem.pricePrice }}/{{ productUnitAbbreviatedPerItem }}
                </p>
              </div>
              <div class="vertColumn totalPrice">
                <p>
                  ${{ (cartItem.pricePrice * cartItem.qtyInCart).toFixed(2) }}
                </p>
              </div>
            </div>

            <!-- instructions  -->
            <div class="instructionsTextWrapper">
              <div class="instructionsTextContainer">
                <textarea
                  v-bind:id="`cart-input-special-instructions-${cartItem.productKey}`"
                  :placeholder="specialInstructionsPlaceholder"
                  >{{ specialInstructions }}</textarea
                >
                <div class="buttonWrapper" @click="saveSpecialInstructions">
                  <button class="button-small">Save</button>
                </div>
              </div>
            </div>
          </f7-row>
        </div>
      </div>
    </div>
    <div class="swipeout-actions-right">
      <a @click="removeFromCart" href="#" class="swipeout-delete">Delete</a>
    </div>
  </li>
</template>

<script>
import $$ from "dom7";

import { TweenMax, Power1 } from "gsap"; // eslint-disable-line
export default {
  data() {
    return {
      qtyExpander: false,
      instructionsExpander: true,
    };
  },

  directives: {
    clickOut: {
      inserted: (el, binding) => {
        window.addEventListener("click", (e) => {
          if (!el.contains(e.target)) {
            binding.value();
          }
        });
      },
    },
  },

  computed: {
    productUnitAbbreviatedPerItem({ cartItem }) {
      if (cartItem.productUnit === "each") {
        return "ea";
      }
      return cartItem.productUnit;
    },
    productUnit: ({ cartItem }) => {
      if (cartItem.productUnit === "each") return "";
      if (cartItem.qtyInCart !== 1) {
        return cartItem.productUnitPlural;
      }
      return cartItem.productUnit;
    },
    hasSpecialInstructions({ cartItem }) {
      if (cartItem === undefined) return false;
      if (cartItem.permitSubstitution == false) return true;
      if (cartItem.instructions === undefined) return false;
      if (cartItem.instructions === null) return false;
      return (
        cartItem.instructions.length > 0 || cartItem.permitSubstitution == false
      );
      // return (cartItem.instructions.length > 0)
    },
    specialInstructions({ cartItem }) {
      if (cartItem === undefined) return "";
      if (cartItem.instructions === undefined) return "";
      return cartItem.instructions;
    },
    substitutionPermitted({ cartItem }) {
      if (cartItem === undefined) return "";
      if (cartItem.permitSubstitution === undefined) return "";
      return cartItem.permitSubstitution;
    },
    formattedQtyInCart({ cartItem }) {
      if (parseInt(cartItem.qtyInCart) == parseFloat(cartItem.qtyInCart)) {
        return parseInt(cartItem.qtyInCart);
      } else {
        return parseFloat(cartItem.qtyInCart);
      }
    },
    specialInstructionsPlaceholder: ({ $store }) => {
      return $store.getters.options.hideGrocery
        ? "Enter special instructions for this product"
        : "Enter special instructions for this product: Pick three apples only, or pick the yellowest grapes.";
    },
  },

  methods: {
    closeQtyExpander() {
      this.qtyExpander = false;
    },
    incrementCart(e) {
      this.$store.dispatch("incrementProductInCart", {
        product: this.cartItem.product,
      });
      e.stopPropagation();
    },
    decrementCart(e) {
      this.$store.dispatch("decrementProductInCart", {
        product: this.cartItem.product,
      });
      e.stopPropagation();
    },
    removeFromCart(e) {
      this.$store.dispatch("setQuantityProductInCart", {
        product: this.cartItem.product,
        qty: 0,
      });
      e.stopPropagation();
    },
    navigateToProduct() {
      const path = `/product/${this.cartItem.productKey}/`;

      console.log(`navigateToProduct: ${this.cartItem.productKey}`);

      const router = this.$f7.views.main.router;

      // Go to regular item or EA item
      if (router.currentRoute.url !== path) {
        if (!this.cartItem.isEndlessAisle) {
          router.navigate(path);
        } else {
          router.navigate(path, { props: { isEA: true } });
        }
      }

      // this.$f7.closePanel()

      // event.stopPropagation()
    },
    saveSpecialInstructions() {
      if (this.cartItem !== undefined) {
        const v = $$(
          `#cart-input-special-instructions-${this.cartItem.productKey}`
        ).val();

        // also update on the cart if already added
        this.$store.dispatch("setSpecialInstructionsOnProductInCart", {
          product: this.cartItem,
          specialInstructions: v,
        });

        console.log("saveSpecialIntructions: " + v);

        this.instructionsExpander = true;
      }
    },
  },

  props: ["cartItem"],

  watch: {
    instructionsExpander: function (val) {
      const instrucCont = this.$el.querySelector(".instructionsTextContainer");
      const instrucWrapper = this.$el.querySelector(".instructionsTextWrapper");
      if (val) {
        TweenMax.to(instrucCont, 0.8, {
          display: "none",
        });
        TweenMax.to(instrucWrapper, 0.5, {
          ease: Power1.easeOut,
          height: "0px",
        });
      } else {
        TweenMax.to(instrucWrapper, 0.5, {
          ease: Power1.easeOut,
          height: "110px",
        }),
          TweenMax.to(instrucCont, 0.6, {
            display: "block",
          });
      }
    },
    qtyExpander: function (val) {
      // eslint-disable-line
      const itemCounter = this.$el.querySelector(".itemCounter");
      const plusCart = this.$el.querySelector(".plusCart");
      const minusCart = this.$el.querySelector(".minusCart");
      if (val) {
        TweenMax.to(itemCounter, 0.3, {
          // position: 'absolute',
          height: "100px",
          background: "white",
        });
        TweenMax.to(plusCart, 0.1, {
          display: "flex",
          opacity: "100%",
          delay: 0.05,
        });
        TweenMax.to(minusCart, 0.01, {
          display: "flex",
          opacity: "100%",
          delay: 0.05,
        });
      } else {
        TweenMax.to(itemCounter, 0.3, {
          height: "35px",
          background: "white",
        });
        TweenMax.to(plusCart, 0.01, {
          display: "none",
        });
        TweenMax.to(minusCart, 0.01, {
          display: "none",
        });
      }
    },
  },

  created() {},

  mounted() {},
};
</script>

<style lang="scss" scoped="true">
.instructionsTextWrapper {
  grid-column: 1 / 5;
  height: 0px;
  padding: 0px 10px;
  overflow: hidden;
  /deep/ textarea {
    width: 100%;
    height: 100px;
    border: 0.5px solid #e0dfdf;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 0px;
    margin-top: 10px;
    font-size: 14px;
    color: $colorThemeB;
  }
  /deep/ .buttonWrapper {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
    top: -32px;
    right: 6px;
    button {
      // position: absolute;
      width: auto;
      top: 0px;
      font-size: 10px;
    }
  }
}

// /deep/ .swipeout-content {
//  display: block;
// }

.elementsWrapper {
  .priceWrapper {
    height: 100%;
    text-align: right;
    p {
      margin: 0;
    }
    .totalPrice {
      color: $fontColor;
      font-size: 18px;
      font-weight: $bold;
      letter-spacing: 0.7px;
    }
    .priceUnit {
      color: #898989;
      font-size: 12px;
      letter-spacing: 0.7px;
    }
  }
  .checkoutItem {
    border-bottom: solid 0px #fafafa;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    gap: 0 1rem;
    align-items: center;
    margin: 0.25rem 0px;
    .productImage {
      width: 50px;
      img {
        width: 100%;
      }
    }
    .specialInstructionsButtonWrapper {
      height: 35%;
    }
    .description {
      h2 {
        font-size: 10px;
        font-weight: $medium;
        margin-bottom: 5px;
        @include breakpoint(tablet) {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
    }
    .checkoutItemColumn {
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .qty {
        height: 33.33%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .plusCart {
        height: 33.33%;
        z-index: 50001;
        justify-content: center;
        align-items: center;
        svg {
          width: 12px;
          path {
            fill: lightgray;
          }
        }
      }
      .minusCart {
        width: 100%;
        height: 33.33%;
        z-index: 50001;
        justify-content: center;
        align-items: center;
        svg {
          width: 12px;
          path {
            fill: lightgray;
          }
        }
      }
      .itemCounter {
        @extend .checkoutItemColumn;
        height: 35px;
        width: 60px;
        @include breakpoint(tablet) {
          width: 70px;
        }
        border: solid 1px #ccc; //#474646
        border-radius: 5px;
        font-weight: $bold;
        font-size: 16px;
        position: relative;
        margin-left: 2px;
        margin-right: 2px;
        margin-bottom: 8px;
      }
      div.specialInstructions {
        color: $fontColor;
        border-radius: 5px;
        display: inline;
        background: #f0f0f0;
        padding: 3px 8px;
        font-size: 8px;
        border: 1px solid #e8e8e8;
        @include breakpoint(tablet) {
          font-size: 10px;
        }
      }
      .specialactive {
        background: $colorThemeB !important;
        color: #fff !important;
      }
    }
  }
  h1 {
    margin-bottom: 10px;
  }
  padding: 10px;
  .column {
    height: 25px;
  }
  .columnWrapper {
    a {
      height: 100%;
      width: 100%;
    }
  }
  .exit {
    width: 25px;
    path {
      fill: $fontColor;
    }
  }
}
</style>
