<template>
    <div>
        <div><strong>Tracking Numbers:</strong></div>
        <div v-if="eaVendorOrders">
            <div v-for="vendorOrder in eaVendorOrders">
                
                <div v-if="hasTrackingNumber(vendorOrder.shipments)" v-for="shipment in vendorOrder.shipments">
                    
                    <div v-if="shipment.shipping_provider.name == 'FedEx'">
                        <f7-link
                            :href="`https://www.fedex.com/fedextrack/?trknbr=${shipment.tracking_number}`"
                            target="_blank"
                            external
                        >{{ shipment.tracking_number }}</f7-link> via {{ shipment.shipping_provider.name }}
                    </div>
                    <div v-else>
                        {{ shipment.tracking_number }} via {{ shipment.shipping_provider.name }}
                    </div>
                </div>
            
            </div>
        </div>
        <div v-if="!trackingNumberFound">NA</div>
  </div>
</template>

<script>
    export default {
        props: [
            'eaVendorOrders'
        ],

        data() {
            return {
                trackingNumberFound: false,
            }
        },

        methods: {
            hasTrackingNumber(shipmentsArray) {
                if(shipmentsArray.length) {
                    this.trackingNumberFound = true;
                    return true;
                } else {
                    return false;
                }
            }
        },
    }
</script>
<style scoped lang="scss">

</style>
