<template>
  <div class="wrapper hero-wrapper herobar">
    <div class="hero-background">
    <!-- Topbar -->
    <f7-row no-gutter class="topBar">
      <!-- Hambuger Menu -->
      <f7-col width="20" class="column">
        <!-- To-do: Make a sliding menu on click of this icon -->
        <f7-link panel-open="left">
          <svg class='menu-icon' width="28" height="28" viewBox="0 0 6 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>menu</title>
            <g id="Canvas" transform="translate(-24 -1192)">
              <g id="menu">
                <use xlink:href="#menu_path" transform="translate(24 1192)" fill="#FFFFFF" />
              </g>
            </g>
            <defs>
              <path id="menu_path" d="M 3 0C 1.34961 0 0 1.22692 0 2.72727C 0 4.22763 1.34961 5.45455 3 5.45455C 4.65039 5.45455 6 4.22763 6 2.72727C 6 1.22692 4.65039 0 3 0ZM 3 7.27273C 1.34961 7.27273 0 8.49964 0 10C 0 11.5004 1.34961 12.7273 3 12.7273C 4.65039 12.7273 6 11.5004 6 10C 6 8.49964 4.65039 7.27273 3 7.27273ZM 3 14.5455C 1.34961 14.5455 0 15.7724 0 17.2727C 0 18.7731 1.34961 20 3 20C 4.65039 20 6 18.7731 6 17.2727C 6 15.7724 4.65039 14.5455 3 14.5455Z"
              />
            </defs>
          </svg>
        </f7-link>
      </f7-col>
      <!-- Title -->
      <f7-col width="60" class="column title" v-bind:class="{ 'title-small': shrinkStoreTitle }">
        <h3 class="storesName"><f7-link href="#" @click="redirectToLocationSelect">{{storeName}}</f7-link></h3>
        <p><f7-link href="#" @click="redirectToLocationSelect">{{storeCity}} <i class="f7-icons" v-show="allLocations.length > 1"></i></f7-link></p>
      </f7-col>
      <!-- Shoping Cart icon -->
      <f7-col width="20" class="column">
        <sh-cart-icon></sh-cart-icon>
      </f7-col>
    </f7-row>
    <!-- end of topBar -->
    <!-- logo -->
    <f7-row no-gutter class="storeLogoWrapper">
      <f7-col class="storeLogo">
        <div class="circleLogo">
          <img src="../images/store-assets/store-logo.png" alt="logo" v-if="allLocations.length == 1">
          <img v-bind:src="selectedLocation.physical_location_logo_url" alt="logo" v-if="allLocations.length > 1">
        </div>
      </f7-col>
    </f7-row>
    <!-- end of logo -->
    <!-- Social -->
    <div class="socialMasterWrapper" v-if="selectedLocation">
      <f7-row no-gutter class="socialWrapper">
        <!-- social1 -->
          <transition name="fade-in">
          <f7-col class="socialContainer" v-show="selectedLocation.physical_location_facebook_link">
            <div class="socialCircle">
              <f7-link @click="openExternalLink(selectedLocation.physical_location_facebook_link)">
                <img src="../images/FacebookLogo.svg" alt="Facebook logo">
              </f7-link>
            </div>
          </f7-col>
          </transition>

        <!-- social 2 -->
        <f7-col class="socialContainer" v-show="selectedLocation.physical_location_instagram_link">
          <div class="socialCircle">
            <f7-link @click="openExternalLink(selectedLocation.physical_location_instagram_link)">
              <img src="../images/InstagramLogo.svg" alt="Instagram logo">
            </f7-link>
          </div>
        </f7-col>
      </f7-row>
    </div>
  </div>
    <!-- End of social -->
    <!-- hero-search bar -->
    <div class="hero-search-bar">
      <f7-row no-gutter>
        <f7-col width='5' class="column"></f7-col>
        <f7-col width='90' class="column search-wrapper">
          <div class="search-container">
            <f7-row class='search-elements-container' no-gutter>
              <f7-col width='30' class="column category-search-section">
                <div @click="products = !products" class="categorySearchWrapper">
                  <p>PRODUCTS</p>
                </div>
                <f7-icon fa='chevron-down' color='white' size='8px'></f7-icon>
              </f7-col>
              <!-- search -->
              <f7-col width='10' class="column search-icon-wrapper">
                <f7-link class="iconSearchLink" @click="redirectToSearch($event)">
                  <svg width="17" height="17" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>search</title>
                    <g id="Canvas" transform="translate(-469 -1284)">
                      <g id="search">
                        <use xlink:href="#search_path" transform="translate(469 1284)" fill="#D9D9D9" />
                      </g>
                    </g>
                    <defs>
                      <path id="search_path" d="M 9.39381 10.531L 11.1508 12.288C 10.8824 12.7999 10.8548 13.309 11.137 13.5913L 14.3439 16.7982C 14.7479 17.2021 15.6278 16.9808 16.3029 16.3029C 16.9808 15.625 17.2021 14.7479 16.7982 14.3439L 13.594 11.137C 13.309 10.8548 12.7999 10.8824 12.288 11.1481L 10.531 9.39381L 9.39381 10.531ZM 6.02089 0C 2.69501 0 0 2.69501 0 6.02089C 0 9.34677 2.69501 12.0418 6.02089 12.0418C 9.34677 12.0418 12.0418 9.34677 12.0418 6.02089C 12.0418 2.69501 9.34677 0 6.02089 0ZM 6.02089 10.6251C 3.47806 10.6251 1.41668 8.56372 1.41668 6.02089C 1.41668 3.47806 3.47806 1.41668 6.02089 1.41668C 8.56372 1.41668 10.6251 3.47806 10.6251 6.02089C 10.6251 8.56372 8.56372 10.6251 6.02089 10.6251Z"
                      />
                    </defs>
                  </svg>
                </f7-link>
              </f7-col>
              <f7-col width='30' class="column input-wrapper">
                <div class="inputContainer">
                  <input @click="redirectToSearch($event)" readonly="readonly" type="text" name="" id="" :placeholder="searchPlaceholder"  class="input-search-box">
                </div>
              </f7-col>
              <f7-col width='10' class="column search-icon-wrapper">
                <svg style="display:none;" width="12" height="17" viewBox="0 0 12 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>mic</title>
                  <desc>Created using Figma</desc>
                  <g id="Canvas" transform="translate(-420 -1255)">
                    <g id="mic">
                      <use xlink:href="#mic_path" transform="translate(420 1255)" fill="#474646" />
                    </g>
                  </g>
                  <defs>
                    <path id="mic_path" d="M 5.88462 0C 4.07888 0 2.61538 1.46349 2.61538 3.26923L 2.61538 8.5C 2.61538 10.3057 4.07888 11.7692 5.88462 11.7692C 5.997 11.7692 6.10171 11.759 6.21154 11.7488C 7.19997 11.6492 8.07347 11.1001 8.60216 10.3185C 8.95463 9.79748 9.15385 9.17683 9.15385 8.5L 9.15385 3.26923C 9.15385 1.46349 7.69035 0 5.88462 0ZM 5.88462 1.30769C 6.73513 1.30769 7.45027 1.85938 7.72356 2.61538L 7.19231 2.61538C 6.83218 2.61538 6.53846 2.9091 6.53846 3.26923C 6.53846 3.90009 7.19231 3.92308 7.19231 3.92308L 7.84615 3.92308L 7.84615 4.57692L 7.19231 4.57692C 6.83218 4.57692 6.53846 4.87064 6.53846 5.23077C 6.53846 5.86163 7.19231 5.88462 7.19231 5.88462L 7.84615 5.88462L 7.84615 6.53846L 3.92308 6.53846L 3.92308 5.88462L 4.57692 5.88462C 4.57692 5.88462 5.23077 5.86163 5.23077 5.23077C 5.23077 4.87064 4.93705 4.57692 4.57692 4.57692L 3.92308 4.57692L 3.92308 3.92308L 4.57692 3.92308C 4.57692 3.92308 5.23077 3.90009 5.23077 3.26923C 5.23077 2.9091 4.93705 2.61538 4.57692 2.61538L 4.04567 2.61538C 4.31896 1.85938 5.0341 1.30769 5.88462 1.30769ZM 0.653846 7.19231C 0.0715144 7.19231 0 7.84615 0 7.84615L 0 8.5C 0 11.2942 1.95898 13.6235 4.57692 14.2212L 4.57692 15.6923L 2.61538 15.6923C 1.98708 15.6923 1.96154 16.3462 1.96154 16.3462L 1.96154 17L 9.80769 17L 9.80769 16.3462C 9.80769 15.986 9.51397 15.6923 9.15385 15.6923L 7.19231 15.6923L 7.19231 14.2212C 9.81025 13.6235 11.7692 11.2942 11.7692 8.5L 11.7692 7.84615C 11.7692 7.84615 11.7079 7.19231 11.1154 7.19231C 10.5228 7.19231 10.4615 7.84615 10.4615 7.84615L 10.4615 8.5C 10.4615 11.0234 8.40805 13.0769 5.88462 13.0769C 3.36118 13.0769 1.30769 11.0234 1.30769 8.5L 1.30769 7.84615C 1.30769 7.84615 1.23618 7.19231 0.653846 7.19231Z"
                    />
                  </defs>
                </svg>
              </f7-col>
              <f7-col width='10' class="column search-icon-wrapper">
                <svg style="display:none;" width="22" height="17" viewBox="0 0 22 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>camera</title>
                  <g id="Canvas" transform="translate(-442 -1255)">
                    <g id="camera">
                      <use xlink:href="#cam_path" transform="translate(442 1255)" fill="#474646" />
                    </g>
                  </g>
                  <defs>
                    <path id="cam_path" d="M 10.3594 0C 9.5957 0 8.79717 0.604297 8.47344 1.43437L 8.20781 2.125L 5.95 2.125L 5.95 1.7C 5.95 1.46426 5.76074 1.275 5.525 1.275L 2.55 1.275C 2.31426 1.275 2.125 1.46426 2.125 1.7L 2.125 2.24453C 0.874902 2.61309 0 3.76689 0 5.1L 0 14.025C 0 15.6652 1.33477 17 2.975 17L 18.275 17C 19.9152 17 21.25 15.6652 21.25 14.025L 21.25 5.1C 21.25 3.45977 19.9152 2.125 18.275 2.125L 17.2922 2.125L 17.0266 1.43437C 16.7028 0.604297 15.9043 0 15.1406 0L 10.3594 0ZM 12.75 3.825C 15.7964 3.825 18.275 6.30361 18.275 9.35C 18.275 12.3964 15.7964 14.875 12.75 14.875C 9.70361 14.875 7.225 12.3964 7.225 9.35C 7.225 6.30361 9.70361 3.825 12.75 3.825ZM 2.975 4.25C 3.44482 4.25 3.825 4.63018 3.825 5.1C 3.825 5.56982 3.44482 5.95 2.975 5.95C 2.50518 5.95 2.125 5.56982 2.125 5.1C 2.125 4.63018 2.50518 4.25 2.975 4.25ZM 12.75 4.675C 10.1718 4.675 8.075 6.77178 8.075 9.35C 8.075 11.9282 10.1718 14.025 12.75 14.025C 15.3282 14.025 17.425 11.9282 17.425 9.35C 17.425 6.77178 15.3282 4.675 12.75 4.675Z"
                    />
                  </defs>
                </svg>
              </f7-col>
              <f7-col width='10' class="column search-icon-wrapper">
                <f7-link href="#" @click="scanProductBarcode">
                  <svg width="25" height="17" viewBox="0 0 25 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>barcode</title>
                    <g id="Canvas" transform="translate(-482 -1340)">
                      <rect x="482" y="1340" width="25" height="17" fill="#E5E5E5" />
                      <g id="barcode">
                        <use xlink:href="#barcode_path" transform="translate(482 1340)" fill="#474646" />
                      </g>
                    </g>
                    <defs>
                      <path id="barcode_path" fill-rule="evenodd" d="M 0.669525 3.73999L 0.669525 0.679932L 4.01712 0.679932L 4.01712 0L 0.334747 0L 0 0L 0 0.339966L 0 3.73999L 0.669525 3.73999ZM 23.4332 0.680054L 20.4204 0.680054L 20.4204 0L 23.768 0L 24.1028 0L 24.1028 0.339966L 24.1028 4.07996L 23.4332 4.07996L 23.4332 0.680054ZM 1.6738 1.70007L 1.6738 15.3L 2.34332 15.3L 2.34332 1.70007L 1.6738 1.70007ZM 21.7594 1.70007L 21.7594 15.3L 22.429 15.3L 22.429 1.70007L 21.7594 1.70007ZM 3.01285 15.3L 3.01285 1.70007L 4.3519 1.70007L 4.3519 15.3L 3.01285 15.3ZM 5.02139 1.70007L 5.02139 15.3L 6.36044 15.3L 6.36044 1.70007L 5.02139 1.70007ZM 7.02997 15.3L 7.02997 1.70007L 7.69949 1.70007L 7.69949 15.3L 7.02997 15.3ZM 8.36899 1.70007L 8.36899 15.3L 10.3776 15.3L 10.3776 1.70007L 8.36899 1.70007ZM 11.0471 15.3L 11.0471 1.70007L 12.3861 1.70007L 12.3861 15.3L 11.0471 15.3ZM 13.0557 1.70007L 13.0557 15.3L 15.7337 15.3L 15.7337 1.70007L 13.0557 1.70007ZM 16.4033 15.3L 16.4033 1.70007L 19.0813 1.70007L 19.0813 15.3L 16.4033 15.3ZM 19.7509 1.70007L 19.7509 15.3L 21.0899 15.3L 21.0899 1.70007L 19.7509 1.70007ZM 23.768 13.26L 23.768 16.3201L 20.4204 16.3201L 20.4204 17L 24.1028 17L 24.4375 17L 24.4375 16.66L 24.4375 13.26L 23.768 13.26ZM 1.00427 16.3199L 4.01712 16.3199L 4.01712 17L 0.669525 17L 0.334747 17L 0.334747 16.66L 0.334747 12.92L 1.00427 12.92L 1.00427 16.3199Z"
                      />
                    </defs>
                  </svg>
                </f7-link>
              </f7-col>
            </f7-row>
          </div>
        </f7-col>
        <f7-col class="column" width='5'></f7-col>
      </f7-row>
    </div>
    <!-- ends hero search bar -->
  </div>

</template>


<script>

import $$ from 'dom7'

import { TweenMax } from 'gsap' // eslint-disable-line

// import ScrollMagic from 'scrollmagic'
import ShCartIcon from './ShShopCartIcon.vue'
import ShMasterMenu from './ShMasterMenu.vue'

export default {
  components: {
    ShCartIcon,
    ShMasterMenu
  },

  data() {
    return {
      products: true,
      storeName: '',
      storeCity: 'Please Wait...',
      timer: null,
      collapsed: false,
      heroHeightMax: -1,
      tween: null,
      offsetTop: 0
    }
  },
  computed: {
    allLocations: ({ $store }) => $store.getters.allLocations,
    selectedLocation: ({ $store }) => $store.getters.selectedLocation,
    shrinkStoreTitle: ({ storeName }) => (storeName.length > 20),
    searchPlaceholder: ({ $store }) => $store.getters.options.hideGrocery ? "" : "Bananas"
  },
  watch: {
    products: (val) => {
      const categorySearchWrapper = $$('.categorySearchWrapper');
      const catSearchText = categorySearchWrapper.children('p')[0];
      if (val){
        catSearchText.innerHTML = "PRODUCTS";
        categorySearchWrapper.css('background', '$colorThemeA');
      } else if(!this.$store.getters.options.hideGrocery) {
        catSearchText.innerHTML = "RECIPES";
        categorySearchWrapper.css('background', '$colorThemeB');
      }
    },
    selectedLocation: function() {
      this.setStoreTitle()
    }
  },
  methods: {
    redirectToSearch(event) {
      const categorySearchWrapper = $$('.categorySearchWrapper');
      const catSearchText = categorySearchWrapper.children('p')[0];
      if(this.$f7router.currentRoute.url !== '/search/products/' && catSearchText.innerHTML === 'PRODUCTS') {
        this.$f7router.navigate('/search/products/')
      } else if (this.$f7router.currentRoute.url !== '/search/recipes/' && catSearchText.innerHTML === 'RECIPES'){
        this.$f7router.navigate('/search/recipes/')
      }
    },
    redirectToLocationSelect() {
      if(this.allLocations.length > 1) {
        this.$f7.views.main.router.navigate(`/location-select/`)
      }
    },
    scanProductBarcode() {
      this.$store.dispatch('scanBarcode').then((productKey) => {
        this.$f7.views.main.router.navigate(`/product/${productKey}/`)
      }, (errorMessage) => {
        alert(errorMessage)
      })
    },
    scrollCheck() {

      if(this.heroHeightMax == -1) {
        this.heroHeightMax = $$('.hero-background').height()
        this.offsetTop = $$('.hero-background').height() - 270
      }

      const sat = this.offsetTop
      let heroHeightMin = 80 + sat
      // const w = window.screen.width
      const w = window.innerWidth
      if(w > 740) {
        heroHeightMin = 100
      }

      const heroHeightMax = this.heroHeightMax

      if(this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }

      if(this.tween) {
        if(this.tween.isActive()) {
          return
        }
      }

      if(!$$('.spacer-top')) {
        return
      }

      if(!$$('.hero-wrapper')) {
        return
      }

      if(!$$('.spacer-top').offset()) {
        return
      }

      if(!$$('.hero-wrapper').offset()) {
        return
      }

      const spacerOffset = $$('.spacer-top').offset().top
      const heroWrapperBottom = $$('.hero-wrapper').offset().top + $$('.hero-wrapper').height()
      // console.log(spacerOffset + ':' + heroWrapperBottom)

      const heroBackgroundElement = $$('.hero-background')[0]
      const heroWrapperElement = $$('.hero-wrapper')[0]

      const tweenDuration = 0.50

      const wrapperMargin = 20

      // console.log(this.collapsed)

      // collapse the hero
      if((!this.collapsed) && (spacerOffset < (heroWrapperBottom - 40))) {
        this.tween = TweenMax.to(heroBackgroundElement, tweenDuration, {
            height: heroHeightMin
        })
        TweenMax.to(heroWrapperElement, tweenDuration, {
            height: (heroHeightMin)
        })
        this.collapsed = true
        $$('.hero-background').addClass('collapsed')
        $$('.search-container').addClass('collapsed')

      }

      // expand the hero
      if((spacerOffset < (heroHeightMax/2)) && (spacerOffset >= (heroWrapperBottom - 1))) {
        this.tween = TweenMax.to(heroBackgroundElement, tweenDuration, {
            height: heroHeightMax
        })
        TweenMax.to(heroWrapperElement, tweenDuration, {
            height: (heroHeightMax + wrapperMargin)
        })
        this.collapsed = false
        $$('.hero-background').removeClass('collapsed')
        $$('.search-container').removeClass('collapsed')
      }
    },

    listenAction(e) {
      if (this.timer) return
      this.timer = setTimeout(this.scrollCheck, 50)
    },

    setStoreTitle() {
      if(this.selectedLocation) {
        this.storeName = this.selectedLocation.physical_location_name
        this.storeCity = `${this.selectedLocation.physical_location_city}, ${this.selectedLocation.physical_location_state}`
      }
    },
    openExternalLink(href) {
      if(typeof cordova !== 'undefined') {
        cordova.InAppBrowser.open(href, '_blank', 'location=yes,usewkwebview=yes');
      } else {
        window.open(href, '_system')
      }
    }

  },
  mounted() {
    this.$f7.views.main.$el.on('scroll', this.listenAction, true)
    this.scrollCheck()
    this.setStoreTitle()
  },
  beforeDestroy() {
    if(this.$f7.views.main) {
      this.$f7.views.main.$el.off('scroll', this.listenAction, true)
    }
  }
}

</script>

<style lang="scss" scoped="true"> //@import '../scss/main.scss';
// .menu-icon{
//   height: 30px;
// }

.collapsed {
  // // -webkit-transform: translate3d(0,0,0); // to keep ios from glitching
  // transform: translate3d(0, 0, 0);
}

div.wrapper {
  position: -webkit-sticky;  // required for Safari
  position: sticky;
  height: calc(290px + var(--sat));
  width: 100%;
  top: 0px;
  z-index: 501;
  margin-bottom: 0px;
  @include breakpoint(tablet) {
    height: 400px;
  }
  div.hero-background {
    position: relative;
    display: inline-block;
    // height: 270px;
    height: calc(270px + var(--sat));
    width: 100%;
    background: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0)), //background: linear-gradient(to bottom, transparent 0%, #111 100%),
    url('../images/store-assets/store-hero-back.jpg') center center no-repeat;
    background-size: cover;
    overflow-y: hidden;
    @include breakpoint(tablet) {
      height: 380px;
    }
  }
  div.topBar {
    margin-top: var(--sat);
    margin-bottom: 15px;
    @include breakpoint(tablet) {
      margin-bottom: 30px;
    }
    h3{
      font-size: 18px;
      @include breakpoint(tablet) {
        font-size: 20px;
      }
    }

    div.column {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint(tablet) {
        height: 60px;
      }
      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    div.title {
      color: white;
      display: flex;
      flex-direction: column;
      a {
        display: inline;
        color: #fff;
      }
      p {
        i {
          font-size: 1em;
        }
        margin: 0;
        @include breakpoint(tablet) {
          font-size: 1.5em;
        }
      }

    }
    div.title-small {
      h3 {
        font-size: 16px;
        @include breakpoint(tablet) {
          font-size: 20px;
        }
      }
    }
  }
  div.storeLogoWrapper {
    // margin-top: var(--sat);
    margin-bottom: 10px;
    div.storeLogo {
      display: flex;
      justify-content: center;
      div.circleLogo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 110px;
        background: white;
        border-radius: 50%;
        @include breakpoint(tablet) {
          width: 170px;
          height: 170px;
        }
        img {
          width: 80%;
        }
      }
    }
  }
  div.socialMasterWrapper {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    div.socialWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      div.socialContainer {
        display: flex;
        justify-content: center;
        div.socialCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 0 5px;
        }
      }
    }
  }
  div.hero-search-bar {
    position: relative;
    height: auto;
    bottom: 50px;
    div.search-wrapper {
      height: 50px;
      position: relative;
      @include breakpoint(tablet) {
        height: 60px;
      }
      div.search-container {
        width: 100%;
        height: 50px;
        background: white;
        border-radius: 12px;
        position: absolute;
        bottom: -25px;
        box-shadow: -1px 3px 17px 0px rgba(0, 0, 0, 0.34);
        @include breakpoint(tablet) {
          height: 60px;
          bottom: -15px;
        }
        div.search-elements-container {
          height: 100%;
          div.category-search-section {
            height: 100%;

            color: white;
            font-weight: bold;
            p {
              font-size: 9px;
              @include breakpoint(tablet) {
                font-size: 14px;
              }
            }
            .categorySearchWrapper{
              border-radius: 12px 0 0 12px;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background: $storePrimaryColor;
            }
          }
        }
        div.search-icon-wrapper {
          .iconSearchLink{
            width: 100%;
            height: 100%;
          }
          width: 10%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .fa {
            color: #474646;
          }
          .fa-search {
            color: #898989;
          }
        }
        .input-wrapper {
          .inputContainer{
            width: 100%;
            height: 100%;
          }
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          input {
            height: 100%;
            width: 100%;
            border: none;
             ::placeholder {
              color: lightgray;
            }
            font-size: 11px;
            @include breakpoint(tablet) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}


.fade-in-enter-active {
  transition: opacity .5s;
}

.fade-in-leave-active {
  //transition: opacity .5s;
  opacity: 0;
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-leave-to {
  opacity: 0;
}

// .change-store-link {
//   display: inline;
//   font-weight: $bold;
// }

.storesName{
  text-align: center;
}

</style>

