<template>
<!-- Picker Modal -->
<div class="bar-add-to-cart" id="bar-add-to-cart">
  <div class="addToCartContainer">
    <div v-if="!isEA" id='special' class="special">
      <f7-row>
        <f7-col>
          <f7-row>
            <f7-col width="80">
              <h2>Special Instructions</h2>
            </f7-col>
            <f7-col width="20" class="save-special-instructions">
              <a href="#" @click="onClickSaveSpecialInstructions"><button class="button-small button-save">Save</button></a>
            </f7-col>
          </f7-row>
          <textarea rows="10" v-bind:id="`product-input-special-instructions-${product.productKey}`" @blur="applySpecialInstructions" :placeholder="specialInstructionsPlaceholder">{{ specialInstructions }}</textarea>
        </f7-col>
      </f7-row>
    </div>
    <div class="grid-top">
      <f7-row v-if="!isEA" no-gutter
               class='secondRow'>
        <f7-col width='45'
                class='column1'>
          <div class="substitutionsWrapper text-shadow">
            <h4 id="subsH4"
                ref="subsH4">Substitution OK</h4>
            <input v-bind:checked="substitutionsok"
                   type='checkbox'
                   id='switch'></input>
            <label id="label"
                   @click="substitutionsok = !substitutionsok"
                   for="switch">Toggle</label>
          </div>
        </f7-col>
        <f7-col width='55'
                class='specialInstructions'>
          <div id="specialButtonContainer"
               @click="toggleSpecialRequestBar"
               v-bind:class="{ specialactive: hasSpecialInstructions }">
            <svg width="16"
                 height="15"
                 viewBox="0 0 21 20"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>pen</title>
                  <g id="Canvas"
                    transform="translate(-4531 -871)">
                    <g id="pen">
                      <use xlink:href="#pen_path"
                          transform="translate(4531 871)"
                          fill="#474646" />
                    </g>
                  </g>
                  <defs>
                    <path id="pen_path"
                          d="M 11.5938 0L 6.5625 5C 6.53516 4.99609 6.52734 5 6.5 5C 5.67188 5 5 5.67188 5 6.5C 5 7.32812 5.67188 8 6.5 8C 7.32812 8 8 7.32812 8 6.5C 8 6.47266 8.00391 6.46484 8 6.4375L 12.4062 2L 13 2L 15 0L 11.5938 0ZM 16.4375 0C 16.1797 0 15.9141 0.085938 15.7188 0.28125L 12.9688 3.0625L 16.9375 7.03125L 19.7188 4.28125C 20.1094 3.89062 20.1094 3.23438 19.7188 2.84375L 17.1562 0.28125C 16.9609 0.085938 16.6953 0 16.4375 0ZM 11.9062 4.125L 1 15.0312L 0 20L 4.96875 19L 15.875 8.09375L 11.9062 4.125ZM 4.40625 14.4375L 5.5625 15.5938L 4 17.1562L 2.5625 17.4375L 2.84375 16L 4.40625 14.4375Z" />
                  </defs>
            </svg>
            &nbsp&nbsp
            <h4>Special Instructions</h4>
          </div>
        </f7-col>
      </f7-row>
      <!-- counter -->
      <f7-row no-gutter
               class='firstRow'>
        <f7-col width='45'
                class='column column1'>
          <div class="quantity-wrapper">
            <div @click='qtyDecrement'
                 class="column-quantity">
              <svg width="14"
                   height="3"
                   viewBox="0 0 14 3"
                   version="1.1"
                   xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>minus</title>
                    <g id="Canvas"
                      transform="translate(-4267 -448)">
                      <g id="minus">
                        <use xlink:href="#minus_path"
                            transform="translate(4267 448)"
                            fill="#474646" />
                      </g>
                    </g>
                    <defs>
                      <path id="minus_path"
                            d="M 13.3 2.8L 0.7 2.8C 0.278907 2.8 0 2.52109 0 2.1L 0 0.7C 0 0.278907 0.278907 0 0.7 0L 13.3 0C 13.7211 0 14 0.278907 14 0.7L 14 2.1C 14 2.52109 13.7211 2.8 13.3 2.8Z" />
                    </defs>
              </svg>
            </div>
            <div class='column-quantity'>
              <h2>{{proposedProductQtyInCart}}</h2> &nbsp;
              <h2>{{productUnitOfMeasure}}</h2>
            </div>
            <div @click='qtyIncrement'
                 class="column-quantity">
              <svg width="14"
                   height="15"
                   viewBox="0 0 14 15"
                   version="1.1"
                   xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>plus</title>
                    <g id="Canvas"
                      transform="translate(-4267 -464)">
                      <g id="plus">
                        <use xlink:href="#plus_path"
                            transform="translate(4267 464)"
                            fill="#474646" />
                      </g>
                    </g>
                    <defs>
                      <path id="plus_path"
                            d="M 6.3 0C 5.87891 0 5.6 0.298828 5.6 0.75L 5.6 6L 0.7 6C 0.278907 6 0 6.29883 0 6.75L 0 8.25C 0 8.70117 0.278907 9 0.7 9L 5.6 9L 5.6 14.25C 5.6 14.7012 5.87891 15 6.3 15L 7.7 15C 8.12109 15 8.4 14.7012 8.4 14.25L 8.4 9L 13.3 9C 13.7211 9 14 8.70117 14 8.25L 14 6.75C 14 6.29883 13.7211 6 13.3 6L 8.4 6L 8.4 0.75C 8.4 0.298828 8.12109 0 7.7 0L 6.3 0Z" />
                    </defs>
              </svg>
            </div>
          </div>
        </f7-col>
        <f7-col width='55'
                class="column">
          <div @click="onClickActionButton"
               class="addButton">
            <svg width="15"
                 height="15"
                 viewBox="0 0 30 30"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>cart</title>
                  <g id="Canvas"
                    transform="translate(-322 -1187)">
                    <g id="cart">
                      <use xlink:href="#cart_path"
                          transform="translate(322 1187)"
                          fill="#FFFFFF" />
                    </g>
                  </g>
                  <defs>
                    <path id="cart_path"
                          d="M 0 0L 0 3L 3.42188 3L 7.35938 14.7188L 5.10938 17.7188L 5.0625 17.7188C 4.37109 18.6855 4.3418 19.9746 4.82812 20.9062C 5.31445 21.8379 6.33984 22.5 7.5 22.5L 27 22.5L 27 19.5L 7.5 19.5C 7.50586 19.4941 7.48242 19.4766 7.5 19.4531L 9.75 16.5L 23.8594 16.5C 24.457 16.5 25.0664 16.207 25.2188 15.6094L 29.8594 5.10938C 30.3105 4.06055 29.5488 3 28.5 3L 6.5625 3L 5.90625 1.03125L 5.57812 0L 0 0ZM 7.5 24C 5.8418 24 4.5 25.3418 4.5 27C 4.5 28.6582 5.8418 30 7.5 30C 9.1582 30 10.5 28.6582 10.5 27C 10.5 25.3418 9.1582 24 7.5 24ZM 24 24C 22.3418 24 21 25.3418 21 27C 21 28.6582 22.3418 30 24 30C 25.6582 30 27 28.6582 27 27C 27 25.3418 25.6582 24 24 24Z" />
                  </defs>
            </svg>
            &nbsp;&nbsp;&nbsp;
            <h2 class="text-shadow">{{actionButtonText}}</h2>
          </div>
        </f7-col>
      </f7-row>
    </div>
  </div>
</div>

</template>

<script>

import $$ from 'dom7'

import { TweenMax } from 'gsap' // eslint-disable-line

const ADD_TO_CART_BUTTON_STATE_ADD_TO_CART = 'ADD_TO_CART_BUTTON_STATE_ADD_TO_CART'
const ADD_TO_CART_BUTTON_STATE_REMOVE_FROM_CART = 'ADD_TO_CART_BUTTON_STATE_REMOVE_FROM_CART'
const ADD_TO_CART_BUTTON_STATE_IN_CART = 'ADD_TO_CART_BUTTON_STATE_IN_CART'
const ADD_TO_CART_BUTTON_STATE_CHANGE_QTY = 'ADD_TO_CART_BUTTON_STATE_CHANGE_QTY'

export default {
  data() {
    return {
      specialRequestActive: false,
      substitutionsok: true,
      actionButtonState: ADD_TO_CART_BUTTON_STATE_ADD_TO_CART,
      actionButtonText: '',
      proposedQtyInCart: -1,
      specialInstructions: '',
    }
  },

  props: [
    'product', 'isEA'
  ],

  computed: {
    productUnitOfMeasure: ({ proposedProductUnitOfMeasure }) => proposedProductUnitOfMeasure,
    productQtyInCart: ({ $store, product }) => {
      if (product === undefined) return undefined
      const productInCart = $store.getters.getProductInCartByProductKey(product.productKey)
      if (productInCart === undefined) return 0
      return productInCart.qtyInCart
    },
    proposedProductQtyInCart: ({ $store, proposedQtyInCart, product }) => {
      if (proposedQtyInCart !== -1) return proposedQtyInCart
      if (product === undefined) return 1

      
      const productInCart = $store.getters.getProductInCartByProductKey(product.productKey)
      
      if (productInCart !== undefined) {
        return productInCart.qtyInCart
      }
      return 1
    },
    selectedProductUnitOfMeasure: ({ product, productQtyInCart }) => {
      if (product === undefined) return undefined
      if (product.productUnit === 'each') return ''
      if (productQtyInCart !== 1) {
        return product.productUnitPlural
      }
      return product.productUnit
    },
    proposedProductUnitOfMeasure: ({ product, proposedProductQtyInCart }) => {
      if (product === undefined) return undefined
      if (product.productUnit === 'each') return ''
      if (proposedProductQtyInCart !== 1) {
        return product.productUnitPlural
      }
      return product.productUnit
    },
    hasSpecialInstructions: ( { specialInstructions } ) => {
      if(specialInstructions === null) return false
      return (specialInstructions.length > 0)
    },
    specialInstructionsPlaceholder: ({$store}) => {
      return $store.getters.options.hideGrocery ? "Enter special instructions for this product" : "For example: Pick three apples only, or pick the yellowest grapes."

    }

  },
  watch: {
    product: function(val) {
      this.proposedQtyInCart = this.proposedProductQtyInCart
      this.applyButtonState(this.actionButtonState)
      this.applySpecialInstructions()
      this.applySubstitution()
    },
    specialRequestActive: function(val) { // eslint-disable-line
      const special = this.$el.querySelector('#special')
      if (val) {
        TweenMax.to(special, 0.25, {
          y: -special.offsetHeight + 5,
          // ease: Quint.easeIn,
          ease: TweenMax.easeIn,
        })
      } else {
        TweenMax.to(special, 0.25, {
          y: 0,
          // ease: Quint.easeOut,
          ease: TweenMax.easeOut
        })
      }
    },
    substitutionsok: function(val) { // eslint-disable-line
      if( ! this.isEA) {
        const domH4 = this.$el.querySelector('#subsH4')
        const label = this.$el.querySelector('#label')
        const moveToSize = label.offsetWidth - (domH4.offsetWidth + (15 * 2))
        // console.log(this)
        if (!val) {
          TweenMax.to(domH4, 0.05, {
            css: {
              x: moveToSize,
              color: 'white',
              // ease: Quint.easeIn,
              ease: TweenMax.easeIn
            },
          })
          domH4.innerHTML = 'No Substitutions'
        } else {
          TweenMax.to(domH4, 0.05, {
            x: 0,
            color: 'white',
            // ease: Quint.easeOut,
            ease: TweenMax.easeOut
          })
          domH4.innerHTML = 'Substitution OK'
        }

        this.saveSubstitution()
      }
    },
    proposedProductQtyInCart: function(val) { // eslint-disable-line
      console.log(`proposedQtyInCart: ${val}`)
      let actionButtonState = ADD_TO_CART_BUTTON_STATE_REMOVE_FROM_CART
      if ((val === 0) && (this.productQtyInCart > 0)) {
        this.actionButtonState = ADD_TO_CART_BUTTON_STATE_REMOVE_FROM_CART
      } else if ((val === this.productQtyInCart) && (this.productQtyInCart > 0)) {
        actionButtonState = ADD_TO_CART_BUTTON_STATE_IN_CART
      } else if (this.productQtyInCart === 0) {
        actionButtonState = ADD_TO_CART_BUTTON_STATE_ADD_TO_CART
      } else if ((val !== this.productQtyInCart)) {
        actionButtonState = ADD_TO_CART_BUTTON_STATE_CHANGE_QTY
      }
      this.applyButtonState(actionButtonState)
    },
  },
  methods: {
    qtyIncrement() {
      this.qtyAdjust(this.product.quantityIncrement)
    },
    qtyDecrement() {
      this.qtyAdjust(this.product.quantityIncrement * -1)
    },
    qtyAdjust(deltaQty) {
      let min = 1
      if (this.productQtyInCart > 0) {
        min = 0
      }
      this.proposedQtyInCart = Math.max(parseFloat(this.proposedQtyInCart) + parseFloat(deltaQty), min)
    },
    toggleSpecialRequestBar() {
      console.log('toggleSpecialRequestBar: ' + this.specialRequestActive)
      this.specialRequestActive = !this.specialRequestActive
    },
    applyButtonState(actionButtonState) {
      switch (actionButtonState) {
        case ADD_TO_CART_BUTTON_STATE_ADD_TO_CART:
        default:
          this.actionButtonText = 'Add to Cart'
          break
        case ADD_TO_CART_BUTTON_STATE_IN_CART:
          this.actionButtonText = 'In cart'
          break
        case ADD_TO_CART_BUTTON_STATE_REMOVE_FROM_CART:
          this.actionButtonText = 'Remove'
          break
        case ADD_TO_CART_BUTTON_STATE_CHANGE_QTY:
          this.actionButtonText = 'Change qty'
          break
      }
    },
    applySpecialInstructions() {
      if (this.product === undefined) return undefined
      const productInCart = this.$store.getters.getProductInCartByProductKey(this.product.productKey)
      if (productInCart !== undefined) {
        this.specialInstructions = productInCart.instructions
        // console.log('specialInstructions: ' + this.specialInstructions)
      }
    },
    saveSpecialInstructions() {
      if (this.product !== undefined) {
        const v = $$(`#product-input-special-instructions-${this.product.productKey}`).val()

        // save locally
        this.specialInstructions = v

        // also update on the cart if already added
        this.$store.dispatch('setSpecialInstructionsOnProductInCart', {
            product: this.product,
            specialInstructions: v,
        })

        // console.log('saveSpecialInstructions: ' + v)
      }
    },
    applySubstitution() {
      if (this.product === undefined) return undefined
      const productInCart = this.$store.getters.getProductInCartByProductKey(this.product.productKey)
      if (productInCart !== undefined) {
        this.substitutionsok = productInCart.permitSubstitution
        console.log('permitSubstitution: ' + this.substitutionsok)
      }
    },
    saveSubstitution() {
      if (this.product === undefined) return undefined
      const productInCart = this.$store.getters.getProductInCartByProductKey(this.product.productKey)
      if (productInCart !== undefined) {
        this.$store.dispatch('setSubstitutionOnProductInCart', {
            product: this.product,
            permitSubstitution: this.substitutionsok,
        })
      }
    },
    onClickSaveSpecialInstructions() {
      this.saveSpecialInstructions()
      this.specialRequestActive = false
    },
    onClickActionButton() {
      console.log(`onClickActionButton: ${this.actionButtonState}`)

      // Strange F7 bug on nav away from the modal, so disabling this.
      // if (this.isEA && this.productQtyInCart == 0 && !this.$store.getters.userHasPlacedCDOrder) {
      //   this.$root.showChoiceDoorstepConfirmationModal(this.product, this.proposedProductQtyInCart);
      //   return;
      // }

      this.saveSpecialInstructions()

      this.$store.dispatch('setQuantityProductInCart', {
        product: this.product,
        qty: this.proposedProductQtyInCart,
        specialInstructions: this.specialInstructions,
        permitSubstitution: this.substitutionsok,
      }).then(
        () => {
          this.$root.addedItemToCartToast()
          this.closeProductPage()
        },
        () => {
          console.log('error')
        },
      )
    },
    closeProductPage() {
      // this.$nextTick(() => {
        this.$f7.views.main.router.back()
      // })
    },
  },
  created() {
  },
  mounted() {
  },
}

</script>

<style lang="scss" scoped="true">

.save-special-instructions {
  text-align: right;
  vertical-align: bottom;
  margin: -4px 0px 0px 0px;
  a {
    color: $storePrimaryColor;
  }
  button {
    top: 0px;
    font-size: 10px;
  }
}
.bar-add-to-cart {
  // --bar-native-height: 105px;
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  height: calc(var(--bar-native-height) + var(--safe-area-inset-bottom));
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 6999;
  .addToCartContainer {
    height: 100%;
    position: relative;
    box-shadow: 0px -7px 36px -2px rgba(0, 0, 0, 0.24);
    .special {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      height: auto;
      width: 100%;
      background: white;
      position: absolute;
      z-index: -1;
      box-shadow: 0px -7px 36px -2px rgba(0, 0, 0, 0.24);
      padding: 10px;
      color: $fontColor;
      textarea {
        width: 100%;
        height: auto;
        border: solid .5px #D9D9D9;
        margin: 5px 0;
        padding: 5px;
        font-size: 14px;
        color: $colorThemeB;
      }

    }
  }
  background: white;
  .grid-top {
    // height: 105px !important;
    height: 100%;
    background: white;
    .column1 {
      padding-right: 10px;
      height: 100%;
    }
    .column {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    width: 100%;
    height: 100%;
    padding:10px;
    .secondRow {
      height: 45%;
      padding-bottom: 5px;
      .specialInstructions {
        #specialButtonContainer {
          width: 100%;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        height: 100%;
        display: flex;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        h4 {
          font-family: $fontP;
          color: #474646;
          font-size: 10px;
          letter-spacing: 0.8px;
        }
        svg {
            path {
              fill: $fontColor;
            }
          }
        .specialactive {
          background: $colorThemeB;
          border-radius: 10px;
          border: 1px solid $colorThemeB;
          h4 {
            color: #fff;
          }
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
      .substitutionsWrapper {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        h4 {
          font-family: $fontP;
          position: absolute;
          z-index: 1;
          color: #fff;
          // color: $fontColor;
          top: 8px;
          left: 15px;
          transition: .3s;
          font-size: 10px;
          letter-spacing: 0.8px;
          @include breakpoint(smallCell) {
            top: 12px;
            font-size: 7px;
          }
        }
        input[type=checkbox] {
          height: 0;
          width: 0;
          visibility: hidden;
        }
        input:checked~h4 {
          left: 15px;
        }
        label {
          cursor: pointer;
          text-indent: -9999px;
          width: 100%;
          height: 32px;
          background: $colorRed;
          border-radius: 100px;
          position: relative;
          display: block;
        }
        label::after {
          content: '';
          position: absolute;
          top: 5px;
          left: 5px;
          width: 22px;
          height: 22px;
          background: white;
          border-radius: 100%;
          transition: .3s;
          z-index: 2;
        }
        input:checked+label {
          background: $colorGreen;
        }
        input:checked+label:after {
          left: calc(100% - 5px);
          transform: translateX(-100%);
        }
      }
    }
    .firstRow {
      height: 55%;
      padding-top: 5px;
      .addButton {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        background: $storePrimaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
        h2 {
          font-family: $fontP;
          font-size: 15px;
          color: white;
          // letter-spacing: 0.3px;
        }
      }
      .quantity-wrapper {
        width: 100%;
        border: solid 1px #d9d9d9;
        height: 40px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        h2 {
          font-size: 15px;
          color: $fontColor;
          letter-spacing: 1px;
        }
        .column-quantity {
          width: 33.33%;
          display: flex;
          justify-content: center;
          align-items: center;
          h2 {
            font-family: $fontP;
          }
        }
      }
    }
  }
}
</style>
