<template>
  <div class="settingsPanel">
    <!-- Exit icon -->
        <f7-row>
          <div class="backWrapper">
            <f7-link href="#" @click="closeSettings">
              <svg width="17" height="19" viewBox="0 0 19 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>back</title>
                <desc>Created using Figma</desc>
                <g id="Canvas" transform="translate(-408 -1335)">
                <g id="back">
                <use xlink:href="#backSettings" transform="translate(408 1335)" fill="#474646"/>
                </g>
                </g>
                <defs>
                <path id="backSettings" d="M 6.99247 15L 17.7253 4.04252C 18.2039 3.55422 18.199 2.77294 17.7156 2.28464L 15.7965 0.365618C 15.3034 -0.122683 14.5123 -0.122683 14.024 0.3705L 0.366226 14.1162C 0.122075 14.3603 0 14.6777 0 15C 0 15.3223 0.122075 15.6397 0.366226 15.8838L 14.024 29.6295C 14.5123 30.1227 15.3034 30.1227 15.7965 29.6344L 17.7156 27.7154C 18.199 27.2271 18.2039 26.4458 17.7253 25.9575L 6.99247 15Z"/>
                </defs>
              </svg>
            </f7-link>
          </div>
        </f7-row>

    <f7-row class="titleContainer">
      <h1>Account Settings</h1>
    </f7-row>

  </div>
</template>

<script>
import $$ from 'dom7'

export default {
  methods: {
    closeSettings() {
      let settingsPanel = $$('.settingsPanel');
      TweenMax.to(settingsPanel, 0.30, {
        right: '120%',
        display: 'none',
        opacity: '0',
        ease: Sine.easeOut
      })
    },
  }
}
</script>


<style lang="scss" scoped>
  .settingsPanel {
    background: white;
    position: absolute;
    right: 100%;
    width: 100%;
    height: 100%;
    z-index: 500000;
    .backWrapper{
      width: 100%;
      margin-bottom: 5px;
    }
    .titleContainer{
      border-bottom: .25px solid lightgrey;
      padding-bottom: 5px;
      margin-bottom: 5px;
      h1 {
      font-size: 25px;
      }
    }
  }
</style>

