<template>
  <f7-page class="page-product-browse">
    <div class="spacer"></div>
    <template v-if="!categoryFilter">
      <template v-if="initialized">
        <template v-for="categoryResults in searchResultsCategories">
          <sh-products-card
            v-bind:sourceProducts="categoryResults.products"
            v-bind:headerText="`${searchQuery} in `"
            v-bind:categoryKey="categoryResults.key"
            :isLeaf="false"
            :noLink="false"
            :loadCategoryFromApi="false"
            :searchResultMode="true"
            :searchQuery="searchQuery"
          />
        </template>
      </template>
    </template>
    <template v-else>
      <template v-if="initialized">
        <sh-products-card
          v-bind:sourceProducts="filteredProducts"
          v-bind:headerText="`${searchQuery} in `"
          v-bind:categoryKey="filteredCategory.key"
          :isLeaf="true"
          :noLink="true"
          :loadCategoryFromApi="false"
          :searchResultMode="true"
          :searchQuery="searchQuery"
          :autoLoad="true"
        />
      </template>
    </template>
    <div class="search-loading" v-show="!initialized">
      <h2>Please wait a moment while we search through {{ selectedLocation.physical_location_inventory_count.toLocaleString() }} items on our shelves...</h2>
      <div class="preloader"></div>
    </div>
    <div class="spacer-bottom" />
  </f7-page>
</template>

<script>
import ShopHeroApi from "../api/ShopHeroApi";

import ShProductsCard from "../components/ShProductsCard.vue";

export default {
  data() {
    return {
      searchResults: {},
      searchResultsCategories: [],
      filteredProducts: [],
      filteredCategory: {},
      initialized: false
    };
  },

  computed: {
    resultProducts() {
      if (this.searchResults === undefined) {
        return undefined;
      }
      return this.searchResults.products;
    },
    selectedLocation: ({ $store }) => $store.getters.selectedLocation
  },

  props: ["searchQuery", "categoryFilter"],

  components: {
    ShProductsCard
  },

  methods: {
    // this reduces the api result into a sorted collection of products
    // by category for a more useful display to end user
    compileSearchResultsCategories() {
      const self = this;

      const categoryProductMap = {};
      const categoryNameMap = {};
      const categoryWeightMap = {};
      const categoryNormalizedWeightMap = [];

      let i = 1;

      this.searchResults.products.forEach(e => {
        if (e.product_categories) {
          if (e.product_categories.length > 0) {
            const productCategoryName =
              e.product_categories[0].product_category_name;
            const productCategoryKey =
              e.product_categories[0].shophero_category_key;

            categoryNameMap[productCategoryKey] = productCategoryName;

            if (categoryProductMap[productCategoryKey]) {
              categoryProductMap[productCategoryKey].push(e);

              // categoryWeightMap[productCategoryKey] += e.product_hit_count

              // not necessary, i will always be greater than the value already initialized
              // categoryWeightMap[productCategoryKey] = Math.min(categoryWeightMap[productCategoryKey], i)
            } else {
              categoryProductMap[productCategoryKey] = [e];
              // categoryWeightMap[productCategoryKey] = e.product_hit_count
              categoryWeightMap[productCategoryKey] = i++;
            }
          }
        }
      });

      Object.keys(categoryWeightMap).forEach(k => {
        categoryNormalizedWeightMap.push([
          k,
          categoryWeightMap[k] / categoryProductMap[k].length
        ]);
      });

      // categoryNormalizedWeightMap.sort((a, b) => b[1] - a[1])
      categoryNormalizedWeightMap.sort((a, b) => a[1] - b[1]);

      self.searchResultsCategories = [];
      categoryNormalizedWeightMap.forEach(c => {
        const key = c[0];
        const weight = c[1];

        const productGroup = {
          key,
          weight,
          categoryName: categoryNameMap[key],
          products: categoryProductMap[key].map(e =>
            ShopHeroApi.convertApiProductToProductModel(e, e.product_key)
          )
        };

        self.searchResultsCategories.push(productGroup);
      });

      // console.log(self.searchResultsCategories)
    }
  },

  created() {
    // this.setNavbarTitle(this.searchQuery)
    // this.setNavbarSubtitle('Searching in Products')
    this.$root.setNavbarSearchPlaceholder("");
    this.$root.setNavbarSearchText(this.searchQuery);
  },

  mounted() {
    this.$root.setNavbarTitle(`"${this.searchQuery}" in Products`);
    this.$root.setNavbarSubtitle("");

    if (this.initialized === false) {
      ShopHeroApi.getProductSearch(
        this.$store.state.locations.selectedLocation.physical_location_id,
        this.searchQuery,
        0,
        128,
        response => {
          if (!this.categoryFilter) {
            this.searchResults = response;
            this.$root.setNavbarSubtitle(
              `Found ${response.total_result_count} Products`
            );
            this.compileSearchResultsCategories();
          } else {
            const filteredProducts = [];
            const self = this;
            self.searchResults = response;
            self.searchResults.products.forEach(e => {
              if (e.product_categories) {
                if (e.product_categories.length > 0) {
                  const productCategoryKey =
                    e.product_categories[0].shophero_category_key;
                  if (productCategoryKey == self.categoryFilter) {
                    const productCategoryName =
                      e.product_categories[0].product_category_name;
                    self.filteredCategory = {
                      key: productCategoryKey,
                      name: productCategoryName
                    };
                    filteredProducts.push(
                      ShopHeroApi.convertApiProductToProductModel(
                        e,
                        e.product_key
                      )
                    );
                  }
                }
              }
            });

            self.filteredProducts = filteredProducts;

            this.$root.setNavbarTitle(
              `"${this.searchQuery}" in ${this.filteredCategory.name}`
            );
            this.$root.setNavbarSubtitle(
              `Found ${this.filteredProducts.length} Products`
            );
          }
          this.initialized = true;
        }
      );
    }

    if (typeof AppRate !== "undefined") {
      AppRate.promptForRating(false);
    }
  }
};
</script>



<style lang="scss" scoped="true">
.spacer {
  width: 100%;
  height: var(--f7-page-spacer-top);
}

.search-loading {
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 200px;
  max-width: 300px;
  padding: 40px;
  position: relative;

  h2 {
    font-size: 2em !important;
  }

  .preloader {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 210px;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
  }
}

.spacer-bottom {
  width: 100%;
  height: 70px;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
</style>
