<template>
<f7-card v-if="showCard">
      <f7-card-header>
        {{ title }}
      </f7-card-header>
      <f7-card-content>
        <f7-swiper :init="true"
                 :params="{slidesPerView: recipesPerRow, spaceBetween: 20, slidesPerGroup: 2, preloadImages: true, observer: false, watchOverflow: true, preventClicks: true, preventClicksPropagation: true, passiveListeners: true, lazy: {loadPrevNext: true, loadOnTransitionStart: true, loadPrevNextAmount: 3}, lazyLoading: true, watchSlidesVisibility: true, loop: false}">
        <f7-swiper-slide v-for="recipe in recipes" v-bind:key="recipe.key">
          <sh-recipe-box v-bind:recipe="recipe" swiper="true"/>
        </f7-swiper-slide>
      </f7-swiper>
      </f7-card-content>
    </f7-card>
</template>

<script>

import ShRecipeBox from './ShRecipeBox.vue'

export default {

  data() {
    return {
      recipesPerRow: 3,
      showCard: false,
    }
  },

  props: [
    'title',
    'recipes',
  ],

  components: {
    ShRecipeBox,
  },

  methods: {
    determineRecipesPerRow(e) {
      // const w = window.screen.width
      const w = window.innerWidth
      if(w > 1000) {
        this.recipesPerRow = 5
      } else if(w > 740) {
        this.recipesPerRow = 4
      } else {
        this.recipesPerRow = 3
      }
      // console.log('recipesPerRow: ' + this.recipesPerRow)
    },

  },

  watch: {
    recipes(val) {
      // console.log(val)
      if(val.length > 0) {
        this.showCard = true;
      }
    }
    //   console.log(this.$children[0].$children[1])

    // }

    //   if(this.$children[0].$children[1].$children[0]) {
    //     const swiper = this.$children[0].$children[1].$children[0].swiper
    //     this.$nextTick(() => {
    //       swiper.update()
    //     })
    //   }
    // }
  },

  mounted() {

    // this.$nextTick(() => {
      // console.log(this.$children[0].$children[1])
      // if(this.$children[0].$children[1].$children[0]) {
      //   const swiper = this.$children[0].$children[1].$children[0].swiper
      //   this.$nextTick(() => {
      //     swiper.update()
      //   })
      // }
    // })

    // console.log(this.$children[0])
  },

  created() {
    this.determineRecipesPerRow()
  }



}

</script>

<style lang="scss" scoped="true">

.recipeHeader{

}

.card-header {
  font-weight: 600;
  display: flex;
  align-items: center;
  p{
    margin: 0;
  }
}
</style>
