<template>
  <f7-page>
    <div class="spacer-top"></div>

  <div class="recipe-loading"
       v-show="!recipeDetailLoaded">
      <div class="preloader"></div>
  </div>

  <template v-if="recipeDetailLoaded">
    <div class="recipe-detail-container">
      <f7-row>
        <f7-col width="50">
          <div class="recipe-image-container" @click="openExternalLink(recipeDetail.url)">
            <div class="recipe-image">
              <img v-bind:src="recipeDetail.image">
            </div>
          </div>
        </f7-col>
        <f7-col width="50">
          <div class="recipe-facts-container">
            <h2><span v-html="recipeDetail.label"></span></h2>
            <p>{{ recipeDetail.source }}</p>
            <ul>
              <li>Makes <strong>{{ recipeDetail.yield }} {{ servingLabel }}</strong></li>
              <li><strong>{{ caloriesPerServing }} calories</strong> per serving</li>
            </ul>
          </div>
        </f7-col>
      </f7-row>
    </div>

      <f7-card>
        <f7-card-header>
          {{ recipeDetail.ingredients.length }} Ingredients
        </f7-card-header>
        <f7-card-content class="ingredient-card-content">
          <f7-row v-for="ingredient in recipeDetail.ingredients" v-bind:key="ingredient.food" class="ingredient-row">
            <div class="ingredient-label">
              &bullet; {{ ingredient.text }}
            </div>
            <div class="ingredient-container">
              <sh-ingredients-card v-bind:ingredient="ingredient" />
            </div>
            <div class="spacer-row">
              &nbsp;
            </div>
          </f7-row>
        </f7-card-content>
      </f7-card>

      <f7-card>
        <f7-card-header>Preparation Instructions</f7-card-header>
        <f7-card-content class="recipe-prep-content"><p>View on <f7-link @click="openExternalLink(recipeDetail.url)"><strong>{{ recipeDetail.source }}</strong></f7-link></p></f7-card-content>
      </f7-card>

      <div class="spacer-bottom" />


  </template>
</f7-page>

</template>

<script>

import ShopHeroApi from '../api/ShopHeroApi';

import ShIngredientsCard from '../components/ShIngredientsCard.vue'

export default {

  data() {
    return {
      recipeDetailLoaded: false,
      recipeDetail: null,
    }
  },

  components: {
    ShIngredientsCard,
  },

  computed: {
    caloriesPerServing() {
      return (this.recipeDetail.calories / this.recipeDetail.yield).toFixed(0)
    },
    servingLabel() {
      return (this.recipeDetail.yield == 1) ? 'serving' : 'servings'
    }
  },

  methods: {
    openExternalLink(href) {
      if(typeof cordova !== 'undefined') {
        const hrefhttps = href.replace('http://', 'https://')
        cordova.InAppBrowser.open(hrefhttps, '_blank', 'location=yes,usewkwebview=yes'); 

      } else {
        window.open(href, '_system')
      }
    }
  },

  props: [
    'recipeKey',
  ],

  mounted() {
    this.$root.setActiveToolbar('recipes')

    ShopHeroApi.getRecipe(this.$store.state.locations.selectedLocation.physical_location_id, this.recipeKey, (response) => {
        this.recipeDetail = response
        this.recipeDetailLoaded = true
        // console.log(response)

        this.$root.setNavbarTitle(this.recipeDetail.label)
        this.$root.setNavbarSubtitle(this.recipeDetail.source)
      })

      this.$root.setNavbarTitle("Loading Recipe...")
      this.$root.setNavbarSubtitle("")

      this.$store.dispatch('setSearchModeRecipes')

  }

}



</script>

<style lang="scss" scoped="true">
.spacer-top {
  width: 100%;
  height: var(--f7-page-spacer-top);
}

.recipe-loading {
  margin-top: 40px;
  text-align: center;
  height: 200px;
  width: 100%;
  position: relative;
  .preloader {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }
}

.ingredient-card-content {
  overflow-x: hidden;
}

.recipe-facts-container {
  strong {
    color: $storePrimaryColor;
  }
  @include breakpoint(tablet) {
    font-size: 16px;
    h2 {
      font-size: 24px;
    }
  }

}

.recipe-detail-container {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px; 
  ul {
    font-size: 14px;
    padding: 10px;
    li {
      margin-top: 5px;
    }
  }
}

.recipe-image-container {
  img {
    border-radius: 10px;
    max-width: 100%;
  }
}

.ingredient-label {
  font-size: 13px;
  font-weight: $bold;
  margin-bottom: 10px;
  width: 100%;
  display: block;
}

.recipe-prep-content {
  font-size: 15px;
  p {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.ingredient-container {
  max-width: 100%;
  display: block;
}

.spacer-row {
  display: block;
  height: 40px;
}

.spacer-bottom {
  height: 80px;
}

</style>
