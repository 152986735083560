

export const APP_SET_API_INITIALIZED = 'APP_SET_API_INITIALIZED'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const RECEIVE_ROOT_CATEGORIES = 'RECEIVE_ROOT_CATEGORIES'
export const RECEIVE_LOCATIONS = 'RECEIVE_LOCATIONS'
export const RECEIVE_SELECTED_LOCATION = 'RECEIVE_SELECTED_LOCATION'
export const RECEIVE_NAV_TITLE = 'RECEIVE_NAV_TITLE'
export const RECEIVE_NAV_SUBTITLE = 'RECEIVE_NAV_SUBTITLE'
export const RECEIVE_NAV_REFRESH = 'RECEIVE_NAV_REFRESH'
export const RECEIVE_NAV_STATE = 'RECEIVE_NAV_STATE'
export const RECEIVE_NAV_SEARCH_FOCUS = 'RECEIVE_NAV_SEARCH_FOCUS'
export const RECEIVE_NAV_SEARCH_BLUR = 'RECEIVE_NAV_SEARCH_BLUR'
export const RECEIVE_CART_BAR_VISIBLE = 'RECEIVE_CART_BAR_VISIBLE'
export const RECEIVE_CART_BAR_HIDDEN = 'RECEIVE_CART_BAR_HIDDEN'
export const RECEIVE_TOOLBAR_ACTIVE_ICON = 'RECEIVE_TOOLBAR_ACTIVE_ICON'
export const RECEIVE_TOOLBAR_REFRESH = 'RECEIVE_TOOLBAR_REFRESH'
export const RECEIVE_TOOLBAR_STATE = 'RECEIVE_TOOLBAR_STATE'
export const RECEIVE_NAV_SEARCH_PLACEHOLDER = 'RECEIVE_NAV_SEARCH_PLACEHOLDER'
export const RECEIVE_NAV_SEARCH_TEXT = 'RECEIVE_NAV_SEARCH_TEXT'
export const RECEIVE_CART_SET_PRODUCT_QTY = 'RECEIVE_CART_SET_PRODUCT_QTY'
export const RECEIVE_CART_SET_PRODUCT_DELTA_QTY = 'RECEIVE_CART_SET_PRODUCT_DELTA_QTY'
export const RECEIVE_CART_BAR_SELECTED_PRODUCT = 'RECEIVE_CART_BAR_SELECTED_PRODUCT'
export const RECEIVE_CART_BAR_SET_PROPOSED_PRODUCT_QTY = 'RECEIVE_CART_BAR_SET_PROPOSED_PRODUCT_QTY'
export const RECEIVE_CART_SET_PRODUCT_SPECIAL_INSTRUCTIONS = 'RECEIVE_CART_SET_PRODUCT_SPECIAL_INSTRUCTIONS'
export const RECEIVE_CART_SET_PRODUCT_SUBSTITUTION = 'RECEIVE_CART_SET_PRODUCT_SUBSTITUTION'
export const RECEIVE_CART_FROM_API = 'RECEIVE_CART_FROM_API'
export const RECEIVE_CART_SET_PRODUCT_API_ID = 'RECEIVE_CART_SET_PRODUCT_API_ID'
export const USER_SET_AUTHENTICATED = 'USER_SET_AUTHENTICATED'
export const USER_SET_NOT_AUTHENTICATED = 'USER_SET_NOT_AUTHENTICATED'
export const USER_SET_TOKEN = 'USER_SET_TOKEN'
export const USER_SET_LOYALTY_TOKEN = 'USER_SET_LOYALTY_TOKEN'
export const USER_SIGN_OUT = 'USER_SIGN_OUT'
export const RECEIVE_FAVORITES = 'RECEIVE_FAVORITES'
export const RECEIVE_NAV_SEARCH_MODE = 'RECEIVE_NAV_SEARCH_MODE'
export const CHECKOUT_MERGE_DELIVERY_INFO = 'CHECKOUT_MERGE_DELIVERY_INFO'
export const APP_SET_APP_VERSION = 'APP_SET_APP_VERSION'
export const APP_SET_APP_PACKAGE = 'APP_SET_APP_PACKAGE'
export const RESET_CART_KEY = 'RESET_CART_KEY'
export const RECEIVE_LOYALTY_MEMBERSHIP = 'RECEIVE_LOYALTY_MEMBERSHIP'
export const RECEIVE_LOYALTY_OFFERS = 'RECEIVE_LOYALTY_OFFERS'
export const RESET_LOYALTY_STATE = 'RESET_LOYALTY_STATE'
export const CLOSE_CHOICE_DOORSTEP_CONFIRMATION_MODAL = 'CLOSE_CHOICE_DOORSTEP_CONFIRMATION_MODAL'
export const OPEN_CHOICE_DOORSTEP_CONFIRMATION_MODAL = 'OPEN_CHOICE_DOORSTEP_CONFIRMATION_MODAL'

