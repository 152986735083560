<template>
  <f7-page class="checkout-payment" no-navbar no-toolbar>
    <div slot="fixed">
      <sh-checkout-bar selectedStep="payment"></sh-checkout-bar>
    </div>
    <div class="spacer-top"></div>
    <f7-card class="card-payment">
      <f7-card-header>Select a Payment Method</f7-card-header>
      <f7-card-content>
        <f7-list no-hairlines-md class="payment-method-form">
          <li
            v-for="method in paymentMethodsResponse.stored_payment_methods"
            v-bind:key="method.stored_payment_key"
          >
            <label class="item-content item-radio">
              <input
                v-bind:value="method.stored_payment_key"
                name="radio-payment-method"
                type="radio"
                v-model="selectedPaymentMethod"
              />
              <i class="icon icon-radio"></i>
              <div class="item-inner">
                <img
                  v-bind:src="getIconForCard(method.stored_payment_card_type)"
                />
                <span
                  >{{ method.stored_payment_card_type }} x{{
                    method.stored_payment_last_four
                  }}
                  (exp. {{ method.stored_payment_exp_month }}/{{
                    method.stored_payment_exp_year
                  }})</span
                >
              </div>
            </label>
          </li>
          <li
            v-if="
              checkoutInfo.serviceType == 'pickup' &&
              paymentMethodsResponse.enable_payment_on_pickup
            "
          >
            <label class="item-content item-radio">
              <input
                value="pay_at_pickup"
                name="radio-payment-method"
                type="radio"
                v-model="selectedPaymentMethod"
              />
              <i class="icon icon-radio"></i>
              <div class="item-inner">
                <img src="../images/creditcards/money.png" />
                <span>Pay In-Store Upon Pickup</span>
              </div>
            </label>
          </li>
          <li
            v-if="
              checkoutInfo.serviceType == 'delivery' &&
              paymentMethodsResponse.enable_payment_on_delivery
            "
          >
            <label class="item-content item-radio">
              <input
                value="pay_at_delivery"
                name="radio-payment-method"
                type="radio"
                v-model="selectedPaymentMethod"
              />
              <i class="icon icon-radio"></i>
              <div class="item-inner">
                <img src="../images/creditcards/money.png" />
                <span>Pay Upon Delivery</span>
              </div>
            </label>
          </li>
          <li v-if="paymentMethodsResponse.enable_payment_merchant">
            <label class="item-content item-radio">
              <input
                value="add-new"
                name="radio-payment-method"
                type="radio"
                v-model="selectedPaymentMethod"
              />
              <i class="icon icon-radio"></i>
              <div class="item-inner">
                <img src="../images/creditcards/credit.png" />
                <span>Add New Payment Method</span>
              </div>
            </label>
          </li>
        </f7-list>
        <!-- <transition name="fade-in">
          <f7-row v-if="selectedPaymentMethod !== 'add-new'">
            <f7-col width="100">
              <button
                class="button-normal button-with-icon button-continue-to-review"
                v-bind:class="{ disabled: !validSelectPaymentMethod }"
                @click="clickSubmitAddPaymentMethod"
              >
                Continue to Review Order
                <span>&gt;</span>
              </button>
            </f7-col>
          </f7-row>
        </transition> -->
      </f7-card-content>
    </f7-card>
    <transition name="slide-fade">
      <f7-card
        v-show="selectedPaymentMethod == 'add-new'"
        class="card-payment-info"
      >
        <f7-card-header>New Payment</f7-card-header>
        <f7-card-content>
          <f7-row>
            <f7-col width="100">
              <div class="card-wrapper">
                <credit-card v-model="cardDetail"></credit-card>
              </div>
            </f7-col>
          </f7-row>
          <f7-row v-if="hasCardIO" class="card-io-wrapper">
            <f7-col width="100">
              <button
                class="button-small button-with-icon"
                id="scanBtn"
                @click="scanCard"
              >
                <span> <i class="f7-icons">camera_fill</i> </span>Scan Card
              </button>
            </f7-col>
          </f7-row>
          <f7-list no-hairlines-md class="delivery-info-form-new-address">
            <f7-list-item group-title>Payment Card Details</f7-list-item>
            <f7-list-item>
              <div class="item-input-wrap">
                <input
                  id="payment-new-input-card-number"
                  name="number"
                  placeholder="Card number"
                  type="tel"
                  v-model="cardDetail.number"
                  v-card-focus
                  class="input-with-value"
                  v-on:keypress="isNumber($event)"
                />
                <span
                  class="input-clear-button"
                  v-show="cardDetail.number"
                ></span>
              </div>
            </f7-list-item>
            <f7-list-item>
              <div class="item-input-wrap">
                <input
                  id="payment-new-input-name"
                  name="name"
                  placeholder="Name on card"
                  type="text"
                  v-model="cardDetail.name"
                  v-card-focus
                  class="input-with-value"
                  @input="forceUppercase($event, cardDetail, 'name')"
                />
                <span
                  class="input-clear-button"
                  v-show="cardDetail.name"
                ></span>
              </div>
            </f7-list-item>
            <f7-list-item>
              <div class="item-input-wrap">
                <input
                  id="payment-new-input-expiry"
                  maxlength="9"
                  name="expiry"
                  placeholder="Expiration date (MM/YY)"
                  type="tel"
                  v-model="cardDetail.expiry"
                  v-card-focus
                  class="input-with-value"
                  v-on:keypress="isDate($event)"
                />
                <span
                  class="input-clear-button"
                  v-show="cardDetail.expiry"
                ></span>
              </div>
            </f7-list-item>
            <f7-list-item>
              <div class="item-input-wrap">
                <input
                  id="payment-new-input-expiry"
                  name="cvc"
                  maxlength="4"
                  placeholder="Card security code"
                  type="number"
                  v-model="cardDetail.cvc"
                  v-card-focus
                  class="input-with-value"
                  v-on:keypress="isNumber($event)"
                />
                <span class="input-clear-button" v-show="cardDetail.cvc"></span>
              </div>
            </f7-list-item>
            <f7-list-item>
              <f7-list-item-cell>
                <f7-list-item-row>
                  <f7-list-item-cell>
                    <f7-radio
                      name="payment-new-input-card-class"
                      class="payment-new-card-class-radio"
                      checked
                    ></f7-radio
                    >Credit Card
                  </f7-list-item-cell>
                  <f7-list-item-cell>
                    <f7-radio
                      name="payment-new-input-card-class"
                      class="payment-new-card-class-radio"
                    ></f7-radio
                    >Debit Card
                  </f7-list-item-cell>
                </f7-list-item-row>
              </f7-list-item-cell>
            </f7-list-item>
            <f7-list-item group-title>Billing Address</f7-list-item>
            <li>
              <div
                class="item-content item-input item-input-with-info item-input-with-value"
              >
                <!-- <div class="item-inner"> -->
                <p class="p-billing-notice">
                  Notice: We will decline your payment if the name and address
                  provided does not match your financial institution exactly.
                </p>
                <!-- </div> -->
              </div>
            </li>
            <li>
              <div
                class="item-content item-input item-input-with-info item-input-with-value"
              >
                <div class="item-inner">
                  <div class="item-input-wrap">
                    <input
                      type="text"
                      placeholder="First name"
                      id="payment-new-input-first-name"
                      class="input-with-value"
                      v-model="billingAddressFirstName"
                    />
                    <div class="item-input-error-message">
                      Please fill out this field.
                    </div>
                    <span
                      class="input-clear-button"
                      v-show="billingAddressFirstName"
                    ></span>
                  </div>
                </div>
              </div>
            </li>
            <f7-list-item>
              <div class="item-input-wrap">
                <input
                  id="payment-new-input-last-name"
                  placeholder="Last name"
                  type="text"
                  class="input-with-value"
                  v-model="billingAddressLastName"
                />
                <span
                  class="input-clear-button"
                  v-show="billingAddressLastName"
                ></span>
              </div>
            </f7-list-item>
            <f7-list-item>
              <div class="item-input-wrap">
                <input
                  id="payment-new-input-company"
                  placeholder="Company"
                  type="text"
                  class="input-with-value"
                  v-model="billingAddressCompany"
                />
                <span
                  class="input-clear-button"
                  v-show="billingAddressCompany"
                ></span>
              </div>
            </f7-list-item>
            <f7-list-item>
              <div class="item-input-wrap">
                <input
                  id="payment-new-input-address-1"
                  placeholder="Street address"
                  type="text"
                  class="input-with-value"
                  v-model="billingAddressAddress1"
                />
                <span
                  class="input-clear-button"
                  v-show="billingAddressAddress1"
                ></span>
              </div>
            </f7-list-item>
            <f7-list-item>
              <div class="item-input-wrap">
                <input
                  id="payment-new-input-address-2"
                  placeholder="Apt. # or suite"
                  type="text"
                  class="input-with-value"
                  v-model="billingAddressAddress2"
                />
                <span
                  class="input-clear-button"
                  v-show="billingAddressAddress2"
                ></span>
              </div>
            </f7-list-item>
            <f7-list-item>
              <div class="item-input-wrap">
                <input
                  id="payment-new-input-city"
                  placeholder="City"
                  type="text"
                  class="input-with-value"
                  v-model="billingAddressCity"
                />
                <span
                  class="input-clear-button"
                  v-show="billingAddressCity"
                ></span>
              </div>
            </f7-list-item>
            <f7-list-item>
              <f7-input
                input-id="payment-new-input-state"
                type="select"
                tabindex="-1"
                @input="billingAddressState = $event.target.value"
                @input:empty="billingAddressState = $event.target.value"
                :value="billingAddressState"
              >
                <option value disabled>State</option>
                <option
                  v-for="(name, abbreviation) in states"
                  v-bind:value="abbreviation"
                  v-bind:key="abbreviation"
                >
                  {{ name }}
                </option>
              </f7-input>
            </f7-list-item>
            <f7-list-item>
              <div class="item-input-wrap">
                <input
                  id="payment-new-input-zip"
                  placeholder="Zipcode"
                  type="number"
                  class="input-with-value"
                  v-on:keypress="isNumber($event)"
                  v-model="billingAddressZip"
                />
                <span
                  class="input-clear-button"
                  v-show="billingAddressZip"
                ></span>
              </div>
            </f7-list-item>
            <f7-list-item>
              <f7-input
                input-id="payment-new-input-country"
                type="select"
                tabindex="-1"
                @input="billingAddressCountry = $event.target.value"
                @input:empty="billingAddressCountry = $event.target.value"
                :value="billingAddressCountry"
              >
                <option value disabled>Country</option>
                <option
                  v-for="(name, abbreviation) in countries"
                  v-bind:value="abbreviation"
                  v-bind:key="abbreviation"
                >
                  {{ name }}
                </option>
              </f7-input>
            </f7-list-item>
            <f7-list-item>
              <div class="item-input-wrap">
                <input
                  id="payment-new-input-phone"
                  placeholder="Billing phone number"
                  type="tel"
                  class="input-with-value"
                  v-on:keypress="isPhoneNumber($event)"
                  v-model="billingAddressPhone"
                />
                <span
                  class="input-clear-button"
                  v-show="billingAddressPhone"
                ></span>
              </div>
            </f7-list-item>
            <f7-list-item>
              <f7-list-item-cell>
                <f7-link href="#" @click="toggleStoreMethod">
                  <f7-list-item-row>
                    <f7-list-item-cell
                      class="delivery-new-input-store-method-cell-left"
                    >
                      <f7-radio
                        name="delivery-new-input-store-method"
                        id="delivery-new-input-store-method"
                        :checked="billingAddressStorePaymentMethod"
                      ></f7-radio>
                    </f7-list-item-cell>
                    <f7-list-item-cell
                      class="delivery-new-input-store-method-cell-right"
                    >
                      Remember payment card for future checkouts?
                      <span
                        >Your payment card details are encrypted and stored
                        securely.</span
                      >
                    </f7-list-item-cell>
                  </f7-list-item-row>
                </f7-link>
              </f7-list-item-cell>
            </f7-list-item>
          </f7-list>

          <transition name="fade">
            <div class="card-delivery-info-error" v-show="showValidationErrors">
              <ul>
                <li v-for="error in validationErrors">{{ error }}</li>
              </ul>
            </div>
          </transition>
        </f7-card-content>
      </f7-card>
    </transition>

    <div style="margin: 30px 12px">
      <sh-checkout-button
        @onButtonClick="clickSubmitAddPaymentMethod($event)"
        :disabledConditional="
          validSelectPaymentMethod || cardDetail.number.length > 10
        "
        >Continue to Review Order
      </sh-checkout-button>
    </div>

    <f7-card class="card-error" v-if="checkoutInfo.errorMessage">
      <f7-card-content>
        <span v-html="checkoutInfo.errorMessage"></span>
      </f7-card-content>
    </f7-card>

    <f7-card class="card-error" v-if="errorMessage">
      <f7-card-content>
        <span v-html="errorMessage"></span>
      </f7-card-content>
    </f7-card>

    <div class="spacer-bottom" />
  </f7-page>
</template>

<script>
import $$ from "dom7";
import CreditCard from "vue-credit-card";
import CreditCardValidator from "card-validator";

// import CardIO from 'cordova-plugin-card-io/www/cdv-plugin-card-io.js'

import Constants from "../constants.js";

import ShopHeroApi from "../api/ShopHeroApi";

import ShCheckoutBar from "../components/ShCheckoutBar.vue";

import CreditCardIconMoney from "../images/creditcards/money.png";
import CreditCardIconCredit from "../images/creditcards/credit.png";
import CreditCardIconVisa from "../images/creditcards/visa.png";
import CreditCardIconMasterCard from "../images/creditcards/mast.png";
import CreditCardIconDiscover from "../images/creditcards/disc.png";
import CreditCardIconAmex from "../images/creditcards/amex.png";
import ShCheckoutButton from "../components/ShCheckoutButton.vue";

let defaultCardDetail = {
  number: "",
  name: "",
  expiry: "",
  cvc: "",
  class: "credit",
};

export default {
  data() {
    return {
      showValidationErrors: false,
      validationErrors: [],

      errorMessage: null,

      paymentMethodsLoaded: false,
      paymentMethodsResponse: {},

      selectedPaymentMethod: null,

      paymentMethodError: null,

      proceedButtonContent: "Continue to Review Order<span>&gt;</span>",

      states: Constants.state_map_usa,
      countries: Constants.country_map,

      hasCardIO: false,

      cardDetail: defaultCardDetail,

      billingAddressFirstName: "",
      billingAddressLastName: "",
      billingAddressCompany: "",
      billingAddressAddress1: "",
      billingAddressAddress2: "",
      billingAddressCity: "",
      billingAddressState: "",
      billingAddressCountry: "",
      billingAddressZip: "",
      billingAddressPhone: "",
      billingAddressStorePaymentMethod: true,

      submissionInProgress: false,
    };
  },

  components: {
    ShCheckoutBar,
    CreditCard,
    ShCheckoutButton,
  },

  computed: {
    selectedLocation: ({ $store }) => $store.getters.selectedLocation,
    cartKey: ({ $store }) => $store.getters.cartKey,
    checkoutInfo: ({ $store }) => $store.getters.getCheckoutInfo,
    validSelectPaymentMethod: ({ selectedPaymentMethod }) => {
      console.log(selectedPaymentMethod);
      return selectedPaymentMethod && selectedPaymentMethod !== "add-new";
    },
  },

  watch: {},

  methods: {
    populatePaymentMethods() {
      ShopHeroApi.getPaymentMethods(
        this.selectedLocation.physical_location_id,
        (response) => {
          // console.log(response)
          this.paymentMethodsResponse = response;
          this.paymentMethodsLoaded = true;

          this.billingAddressFirstName = response.stored_delivery_address
            .address_first_name
            ? response.stored_delivery_address.address_first_name
            : "";
          this.billingAddressLastName = response.stored_delivery_address
            .address_last_name
            ? response.stored_delivery_address.address_last_name
            : "";
          this.billingAddressAddress1 = response.stored_delivery_address
            .address_address1
            ? response.stored_delivery_address.address_address1
            : "";
          this.billingAddressAddress2 = response.stored_delivery_address
            .address_address2
            ? response.stored_delivery_address.address_address2
            : "";
          this.billingAddressCity = response.stored_delivery_address
            .address_city
            ? response.stored_delivery_address.address_city
            : "";
          this.billingAddressState = response.stored_delivery_address
            .address_state
            ? response.stored_delivery_address.address_state
            : "";
          this.billingAddressCountry = response.stored_delivery_address
            .address_country
            ? response.stored_delivery_address.address_country
            : "";
          this.billingAddressZip = response.stored_delivery_address.address_zip
            ? response.stored_delivery_address.address_zip
            : "";
          this.billingAddressPhone = response.stored_delivery_address
            .address_phone
            ? response.stored_delivery_address.address_phone
            : "";

          this.validateAddBillingAddress();
        }
      );
    },
    updateStateSelection(v) {
      console.log("updateState", v, v.target.value);
      this.billingAddressState = v.target.value;
    },
    stateIsSelected(name, abbreviation) {
      if (this.billingAddressState.length > 0) {
        if (this.billingAddressState == abbreviation) {
          return true;
        } else {
          return false;
        }
      }
      if (
        this.selectedLocation.physical_location_state == abbreviation ||
        this.selectedLocation.physical_location_state == name
      ) {
        return true;
      }
      return false;
    },
    getIconForCard(cardType) {
      switch (cardType) {
        case "VISA":
          return "images/visa.png";
        case "MAST":
          return "images/mast.png";
        case "DISC":
          return "images/disc.png";
        case "AMEX":
          return "images/amex.png";
        case "CASH":
          return "images/money.png";
      }
    },
    toggleStoreMethod() {
      this.billingAddressStorePaymentMethod =
        !this.billingAddressStorePaymentMethod;
    },
    validateAddBillingAddress() {
      this.validationErrors.length = 0;

      if (this.cardDetail.number.length < 10) {
        this.validationErrors.push(
          "Please double check your credit card number."
        );
      }
      if (this.cardDetail.name.length < 3) {
        this.validationErrors.push(
          "Please double check the name on your credit card."
        );
      }
      if (this.cardDetail.expiry.length < 4) {
        this.validationErrors.push(
          "Please double check the expiration date on your credit card."
        );
      }
      if (this.cardDetail.expiry.includes("/") == false) {
        this.validationErrors.push(
          "Please double check the expiration date on your credit card."
        );
      }
      if (this.cardDetail.cvc.length < 3) {
        this.validationErrors.push(
          "Please double check the card security code on your credit card."
        );
      }

      if (this.billingAddressFirstName.length === 0) {
        this.validationErrors.push(
          "Please check your billing address first name."
        );
      }
      if (this.billingAddressLastName.length === 0) {
        this.validationErrors.push(
          "Please check your billing address last name."
        );
      }
      if (this.billingAddressAddress1.length === 0) {
        this.validationErrors.push("Please check your billing address.");
      }
      if (this.billingAddressCity.length === 0) {
        this.validationErrors.push("Please check your billing address city.");
      }
      if (this.billingAddressState.length === 0) {
        this.validationErrors.push("Please select your billing address state.");
      }
      if (this.billingAddressCountry.length === 0) {
        this.validationErrors.push(
          "Please select your billing address country."
        );
      }
      if (this.billingAddressZip.length === 0) {
        this.validationErrors.push(
          "Please check your billing address zip/post code."
        );
      }
      if (this.billingAddressPhone.length === 0) {
        this.validationErrors.push("Please check your billing address phone.");
      }

      return this.validationErrors.length == 0;
    },

    clickSubmitAddPaymentMethod() {
      let paymentArgs = {
        payment_select: this.selectedPaymentMethod,
        payment_card_type: null,
        payment_card_number: null,
        payment_card_name: null,
        payment_card_expiry: null,
        payment_card_csc: null,
        payment_card_class: null,
        payment_first_name: null,
        payment_last_name: null,
        payment_company: null,
        payment_address1: null,
        payment_address2: null,
        payment_city: null,
        payment_state: null,
        payment_zip: null,
        payment_country: null,
        payment_phone: null,
        payment_card_save: null,
      };

      if (this.selectedPaymentMethod === "add-new") {
        const valid = CreditCardValidator.number(this.cardDetail.number);
        // console.log(valid)

        if (this.validationErrors.length == 0 && valid.isValid === false) {
          this.validationErrors.push(
            "Please double check your payment card number."
          );
        }

        if (
          CreditCardValidator.expirationDate(this.cardDetail.expiry).isValid ===
          false
        ) {
          this.validationErrors.push(
            "Please double check your payment card expiration date."
          );
        }

        if (valid.card && this.cardDetail.cvc.length !== valid.card.code.size) {
          this.validationErrors.push(
            "Please double check your payment card security code."
          );
        }

        this.validateAddBillingAddress();
        if (this.validationErrors.length > 0) {
          this.showValidationErrors = true;
          return;
        } else {
          this.showValidationErrors = false;
        }

        let cardType = null;
        switch (valid.card.type) {
          case "visa":
            cardType = "VISA";
            break;
          case "mastercard":
            cardType = "MAST";
            break;
          case "discover":
            cardType = "DISC";
            break;
          case "american_express":
            cardType = "AMEX";
            break;
        }

        paymentArgs = {
          payment_select: "new",
          payment_card_type: cardType,
          payment_card_number: this.cardDetail.number.replace(/ /g, ""),
          payment_card_name: this.cardDetail.name,
          payment_card_expiry: this.cardDetail.expiry,
          payment_card_csc: this.cardDetail.cvc,
          payment_card_class: this.cardDetail.class,
          payment_first_name: this.billingAddressFirstName,
          payment_last_name: this.billingAddressLastName,
          payment_company: this.billingAddressCompany,
          payment_address1: this.billingAddressAddress1,
          payment_address2: this.billingAddressAddress2,
          payment_city: this.billingAddressCity,
          payment_state: this.billingAddressState,
          payment_zip: this.billingAddressZip,
          payment_country: this.billingAddressCountry,
          payment_phone: this.billingAddressPhone,
          payment_card_save: this.billingAddressStorePaymentMethod ? 1 : 0,
        };
      } else {
        if (this.paymentMethodsResponse.stored_payment_methods) {
          for (
            let i =
              this.paymentMethodsResponse.stored_payment_methods.length - 1;
            i >= 0;
            i--
          ) {
            const method =
              this.paymentMethodsResponse.stored_payment_methods[i];
            if (method.stored_payment_key == this.selectedPaymentMethod) {
              paymentArgs.payment_card_type = method.stored_payment_card_type;
              paymentArgs.payment_card_number = method.stored_payment_last_four;
              break;
            }
          }
        }
      }

      // console.log(paymentArgs)

      const self = this;

      $$(".button-continue-to-review").html(
        // '<div class="preloader color-white"></div>'
        "Please wait..."
      );

      if (self.submissionInProgress) return;
      self.submissionInProgress = true;

      let orderInfo = { errorMessage: "" };
      self.$store
        .dispatch("checkoutMergeInfo", { orderInfo })
        .then(function () {
          self.errorMessage = null;

          ShopHeroApi.setPaymentMethod(
            self.selectedLocation.physical_location_id,
            paymentArgs,
            (response) => {
              // console.log(response)

              if (response.success == false) {
                self.errorMessage =
                  "Uh oh! An unexpected error has occurred: " +
                  response.error_message;
                $$(".button-continue-to-review").html(
                  self.proceedButtonContent
                );
                self.scrollTo(".spacer-top");
                self.submissionInProgress = false;
                return;
              }

              orderInfo.paymentMethod = self.selectedPaymentMethod;

              if (parseInt(paymentArgs.payment_card_number) > 0) {
                orderInfo.paymentCardType = paymentArgs.payment_card_type;
                orderInfo.paymentCardLastFour =
                  paymentArgs.payment_card_number.substring(-4);
              }

              console.log(orderInfo);

              self.$store
                .dispatch("checkoutMergeInfo", { orderInfo })
                .then(() => {
                  self.$store
                    .dispatch("populateCart", {
                      physicalLocationId:
                        self.selectedLocation.physical_location_id,
                    })
                    .then(() => {
                      const orderArgs = {
                        cart_key: self.cartKey,
                      };

                      ShopHeroApi.prepareOrder(
                        self.selectedLocation.physical_location_id,
                        orderArgs,
                        (response) => {
                          console.log("prepareOrder success");
                          console.log(response);

                          if (response.success == false) {
                            self.errorMessage = response.error_message
                              ? response.error_message
                              : "Uh oh! An unexpected error has occurred.";
                            $$(".button-continue-to-review").html(
                              self.proceedButtonContent
                            );
                            self.submissionInProgress = false;
                            return;
                          }
                          self.submissionInProgress = false;
                          self.$f7.views.main.router.navigate(
                            "/checkout-review/"
                          );
                        },
                        (error) => {
                          self.errorMessage = response.error_message
                            ? response.error_message
                            : "Uh oh! An unexpected error has occurred.";
                          $$(".button-continue-to-review").html(
                            self.proceedButtonContent
                          );
                          self.submissionInProgress = false;
                          return;
                        }
                      );
                    });
                });
            }
          );
        });
    },
    scrollTo(element) {
      const scrollToElement = $$(element);
      const pageContent = $$(".page-content");
      const scrollTo = pageContent.offset().top - scrollToElement.offset().top;
      pageContent.scrollTop(scrollTo, 300);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isPhoneNumber: function (evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 32 &&
        charCode !== 45 &&
        charCode !== 40 &&
        charCode !== 41
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isDate: function (evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 32 &&
        charCode !== 47
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    forceUppercase(e, o, prop) {
      const start = e.target.selectionStart;
      e.target.value = e.target.value.toUpperCase();
      this.$set(o, prop, e.target.value);
      e.target.setSelectionRange(start, start);
    },
    initScanPaymentCard() {
      if (typeof CardIO === "undefined") {
        return;
      }

      const self = this;
      const onCardIOCheck = function (canScan) {
        // alert("card.io canScan? " + canScan);
        // this.hasCardIO = canScan
        self.hasCardIO = canScan;
      };

      CardIO.canScan(onCardIOCheck);
    },
    scanCard() {
      if (!this.hasCardIO) {
        return;
      }

      // alert('scanning card')

      const self = this;

      // const cardIOResponseFields = [
      //   "cardType",
      //   "redactedCardNumber",
      //   "cardNumber",
      //   "expiryMonth",
      //   "expiryYear",
      //   "cvv",
      //   "postalCode"
      // ];

      const onCardIOComplete = function (response) {
        // alert("card.io scan complete");

        self.cardDetail.number = response.cardNumber;
        self.cardDetail.expiry =
          response.expiryMonth + " / " + response.expiryYear;
        self.cardDetail.cvc = response.cvv;
        self.cardDetail.name = response.cardholderName.toUpperCase();
      };

      const onCardIOCancel = function () {
        // alert("card.io scan cancelled");
      };

      CardIO.scan(
        {
          useCardIOLogo: true,
          hideCardIOLogo: true,
          requireExpiry: true,
          requireCVV: true,
          requireCardholderName: true,
          requirePostalCode: false,
          restrictPostalCodeToNumericOnly: true,
        },
        onCardIOComplete,
        onCardIOCancel
      );
    },
  },

  created() {},

  mounted() {
    $$("*").attr("tabindex", "-1");

    $$(".button-continue-to-review").html(this.proceedButtonContent);

    this.populatePaymentMethods();
    this.initScanPaymentCard();

    this.billingAddressState = this.selectedLocation.physical_location_state;
    this.billingAddressCountry =
      this.selectedLocation.physical_location_country;
  },
};
</script>

<style lang="scss" scoped="true">
.checkout-payment {
  background: white;
}

a {
  color: $fontColor;
}

.spacer-top {
  margin-top: 0px;
}

.spacer-bottom {
  height: calc(270px + var(--sab));
}

.card-header {
  color: $storePrimaryColor;
  font-weight: $bold;
}

.list {
  font-size: 14px !important;
}

.payment-method-form {
  padding-bottom: 10px;
  .item-row {
    img {
      width: 50px;
      height: 30px;
      margin-right: 10px;
    }
    span {
      padding-top: 5px;
    }
  }
}

.card-payment {
  button {
    margin-top: 20px;
  }
}

.card-payment-info {
  margin-bottom: 50px;
  .card-wrapper {
    margin-bottom: 30px; // .jp-card-invalid {
    //   color: inherit !important;
    // }
  }
  button {
    margin-top: 30px;
    margin-bottom: 20px;
    i {
      font-size: 12px;
    }
  }
}

.delivery-new-input-store-method-cell-left {
  width: 10% !important;
}

.delivery-new-input-store-method-cell-right {
  text-align: left;
  span {
    font-size: 0.9em;
  }
}

.item-radio {
  span {
    width: 100%;
    padding-left: 20px;
  }
}

.payment-new-card-class-radio {
  margin-right: 10px;
}

.card-io-wrapper {
  button {
    display: table;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: -10px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-in-enter-active {
  transition: opacity 0.5s;
}

.fade-in-leave-active {
  //transition: opacity .5s;
  opacity: 0;
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter {
  transform: translateY(200px);
  opacity: 0;
}

.slide-fade-leave-to {
  opacity: 0;
}

.card-error {
  background-color: $colorRed;
  color: #fff;
}

.card-delivery-info-error {
  background-color: $colorRed;
  color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 0px;
  ul {
    margin-left: 10px;
    li {
      margin-bottom: 5px;
    }
  }
}

.p-billing-notice {
  margin-top: 10px;
  margin-right: 20px;
  font-size: 0.9em;
  color: $fontColor;
}
</style>
