<template>
  <f7-toolbar tabbar labels :class="{'tab-bar': true, 'tabbar-hidden': !toolbarVisible }">
    <f7-link @click="navigate('/home/')" class="el-container">
      <svg
        width="22px"
        height="20px"
        viewBox="0 0 27 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>home</title>
        <defs />
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g class="home" fill="#000000" fill-rule="nonzero">
            <path
              d="M20.4877323,0 C19.9158121,0 19.4493519,0.468546545 19.4493519,1.04302477 L19.4493519,6.02998696 L13.9654053,0.521512386 C13.559788,0.114080834 12.8783508,0.114080834 12.4727335,0.521512386 L0.304213012,12.7770535 C-0.101404337,13.184485 -0.101404337,13.8363755 0.304213012,14.243807 C0.709830362,14.6512386 1.35881812,14.6512386 1.76443547,14.243807 L13.2190694,2.73794003 L24.7061528,14.2764016 C24.908962,14.4801179 25.1847818,14.6023468 25.4524887,14.6023468 C25.7201967,14.6023468 25.9635671,14.4801179 26.1663752,14.2764016 C26.5719926,13.86897 26.5719926,13.2170795 26.1663752,12.809648 L22.5644932,9.1916558 L22.5644932,1.04302477 C22.5644932,0.468546545 22.0980329,0 21.5261127,0 L20.4877323,0 Z M13.2190694,4.66101695 L1.79688486,16.1342894 L1.79688486,21.8709257 C1.79688486,23.5984355 3.19220854,25 4.9120261,25 L21.5261127,25 C23.2459303,25 24.641254,23.5984355 24.641254,21.8709257 L24.641254,16.1342894 L13.2190694,4.66101695 Z M11.1423086,14.5697523 L15.2958303,14.5697523 C15.8677505,14.5697523 16.3342107,15.0382988 16.3342107,15.6127771 L16.3342107,21.8709257 C16.3342107,22.4454039 15.8677505,22.9139505 15.2958303,22.9139505 L11.1423086,22.9139505 C10.5703884,22.9139505 10.1039282,22.4454039 10.1039282,21.8709257 L10.1039282,15.6127771 C10.1039282,15.0382988 10.5703884,14.5697523 11.1423086,14.5697523 Z"
              id="Shape"
            />
          </g>
        </g>
      </svg>
      <p v-bind:class="homeIconClass">HOME</p>
    </f7-link>
   <f7-link @click="navigate('/search/recipes/')" class="el-container" v-show="showRecipes">
      <img type="image/svg+xml" src="images/recipes.svg"/>
      <p v-bind:class="recipesIconClass">RECIPES</p>
    </f7-link>
    <f7-link @click="navigate('/category-browse/')" class="el-container">
      <img type="image/svg+xml" src="images/aisles.svg"/>
      <p v-bind:class="aislesIconClass">AISLES</p>
    </f7-link>
    <f7-link @click="navigate('/weekly-ads/')" class="el-container">
      <img type="image/svg+xml" src="images/ad.svg"/>
      <p v-bind:class="adIconClass">WEEKLY AD</p>
    </f7-link>
    <f7-link @click="navigate('/favorites/')" class="el-container" v-show="!couponsAvailable">
      <img type="image/svg+xml" src="images/favorites.svg">
      <p v-bind:class="favoritesIconClass">FAVORITES</p>
    </f7-link>
    <f7-link @click="navigate('/coupons/')" class="el-container" v-show="couponsAvailable">
      <img type="image/svg+xml" src="images/coupon.svg" class="svg-coupon"/>
      <p v-bind:class="couponsIconClass">COUPONS</p>
    </f7-link>
  </f7-toolbar>
</template>

<script>
import home from "../images/icons/home.svg"; // eslint-disable-line no-unused-vars
import aisles from "../images/icons/aisles.svg"; // eslint-disable-line no-unused-vars
import recipes from "../images/icons/recipes.svg"; // eslint-disable-line no-unused-vars
import ad from "../images/icons/ad.svg"; // eslint-disable-line no-unused-vars
import favorites from "../images/icons/favorites.svg"; // eslint-disable-line no-unused-vars
import coupon from "../images/icons/coupon.svg"; // eslint-disable-line no-unused-vars

export default {
  data() {
    return {
      toolbarVisible: false
    };
  },

  methods: {
    navigate(path) {
      const router = this.$f7.views.main.router;
      if (router.history.length > 1) {
        const priorPage = router.history[router.history.length - 2];
        if (priorPage == path) {
          this.$store.dispatch("refreshNavBar", { route: path });
          this.$store.dispatch("refreshToolBar", { route: path });
          return router.back();
        }
      }

      if (router.currentRoute.url !== path) {
        return router.navigate(path);
      }
    },
    onRouteChanged(newRoute, previousRoute, router) {
      if (newRoute.url.includes("/splash/")) {
        this.toolbarVisible = false;
      } else if (newRoute.url.includes("/location-select/")) {
        this.toolbarVisible = false;
      } else if (newRoute.url.includes("/product/")) {
        this.toolbarVisible = false;
      } else if (newRoute.url.includes("/auth-")) {
        this.toolbarVisible = false;
      } else if (newRoute.url.includes("/checkout-")) {
        this.toolbarVisible = false;
      } else {
        this.toolbarVisible = true;
      }
    }
  },

  computed: {
    selectedLocation: ({ $store }) => $store.getters.selectedLocation,
    couponsAvailable: ({$store}) =>  ($store.getters.selectedLocation && $store.getters.selectedLocation.physical_location_loyalty_provider == 'AppCard'),
    homeIconClass: ({ $store }) =>
      $store.getters.activeToolbarIcon === "home" ? "active" : "",
    recipesIconClass: ({ $store }) =>
      $store.getters.activeToolbarIcon === "recipes" ? "active" : "",
    aislesIconClass: ({ $store }) =>
      $store.getters.activeToolbarIcon === "aisles" ? "active" : "",
    adIconClass: ({ $store }) =>
      $store.getters.activeToolbarIcon === "weeklyad" ? "active" : "",
    favoritesIconClass: ({ $store }) =>
      $store.getters.activeToolbarIcon === "favorites" ? "active" : "",
    couponsIconClass: ({ $store }) =>
      $store.getters.activeToolbarIcon === "coupons" ? "active" : "",
    showRecipes: ({ $store }) => !$store.getters.options.hideGrocery
  },

  mounted() {
    const self = this;
    this.$f7ready($f7 => {
      $f7.router.app.on("routeChange", function(
        newRoute,
        previousRoute,
        router
      ) {
        self.onRouteChanged(newRoute, previousRoute, router);
      });
    });
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/main.scss";
.tab-bar {
  --tabbar-native-height: 60px;
  height: calc(var(--tabbar-native-height) + var(--sab));
  background: #fff;

  // height: 60px;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  -webkit-box-shadow: 0px -7px 36px -2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px -7px 36px -2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px -7px 36px -2px rgba(0, 0, 0, 0.24);

  .active {
    color: $storePrimaryColor !important;
    fill: $storePrimaryColor !important;
  }
  .el-container {
    display: flex;
    flex-direction: column;
    padding-bottom: var(--sab);
    @include breakpoint(tablet) {
      flex-direction: row;
    }
    align-items: center;
    justify-content: center;
    color: #898989;
    line-height: 100% !important;
    p {
      margin: 0 !important;
      font-size: 8px;
      font-weight: $regular;
      @include breakpoint(tablet) {
        margin: 0 0 0 10px !important;
        font-size: 10px;
      }
    }
  }
}

.home {
  fill: #474646;
}

.tabbar-hidden {
  display: none;
}

.svg-coupon {
  height: 25px;
}
</style>
