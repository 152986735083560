<template>
  <div class="wrapper">
    <div class="panel-overlay"></div>
    <div class="panel panel-right panel-cover">
      <div class="elementsWrapper">
        <f7-row class="shopping-cart-header">
          <f7-col class="column" width="80">
            <h1>Shopping Cart</h1>
          </f7-col>
          <f7-col class="column columnWrapper" width="20">
            <f7-link href="#" panel-close>
              <svg
                width="19px"
                height="19px"
                viewBox="0 0 19 19"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>exitSketch</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path
                    d="M17.7587892,15.6650392 L15.6611332,17.7587892 C15.2783203,18.1455082 14.6533203,18.1455082 14.2666022,17.7587892 L9.02441425,12.5205082 L3.78613325,17.7587892 C3.39941425,18.1455082 2.77050825,18.1455082 2.38769525,17.7587892 L0.29003925,15.6650392 C-0.09667975,15.2783203 -0.09667975,14.6533203 0.29003925,14.2666022 L5.52832025,9.02441425 L0.29003925,3.78613325 C-0.09277275,3.39550825 -0.09277275,2.76660225 0.29003925,2.38769525 L2.38769525,0.29003925 C2.77050825,-0.09667975 3.39941425,-0.09667975 3.78613325,0.29003925 L9.02441425,5.53222725 L14.2666022,0.29003925 C14.6533203,-0.09667975 15.2822272,-0.09667975 15.6611332,0.29003925 L17.7587892,2.38378925 C18.1455082,2.77050825 18.1455082,3.39941425 17.7626953,3.78613325 L12.5205082,9.02441425 L17.7587892,14.2666022 C18.1455082,14.6533203 18.1455082,15.2783203 17.7587892,15.6650392 Z"
                    id="exitSketch"
                    fill="#474646"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
            </f7-link>
          </f7-col>
        </f7-row>

        <!-- No items -->
        <div class="wrapper-category">
          <div class="spacer" v-show="cartItems.length >= 0"></div>

          <div class="cart-product-alert-checkout-disabled" v-show="cartIsUnderSubtotalMinumum">
            <p>
              <strong>The subtotal of items in your cart (${{cartProductTotal}}) does not satisfy the required order minimum (${{cartSubTotalMinimum.toFixed(2)}}).</strong>
            </p>
            <p>Please keep shopping and add items to your cart in order to proceed to check out.</p>
          </div>

          <div class="cart-product-alert-checkout-disabled" v-show="cartIsOverQuantityLimit">
            <p>
              <strong>The combined quantity of all items in your cart ({{cartItemQty}}) exceeds the limit allowed ({{cartItemQtyLimit}}) for a single order.</strong>
            </p>
            <p>Please remove items from your cart in order to proceed to check out.</p>
          </div>

          <div
            class="cart-product-alert-checkout-disabled"
            v-show="!ecommerceEnabled || checkoutDisabled"
          >
            <p>
              <strong>Mobile checkout at this location is temporarily unavailable.</strong>
            </p>
            <p>Please try again later.</p>
          </div>

          <div
            class="cart-product-alert-checkout-disabled"
            v-show="checkoutDisabledDueToUncollectedOrders"
          >
            <p>
              <strong>A hold has been placed on your account because there is an outstanding balance due on one or more prior orders.</strong>
            </p>
            <p>A new order cannot be placed until all prior balances due have been settled.</p>
          </div>

          <div
            class="cart-product-alert-checkout-disabled"
            v-show="checkoutDisabledDueToOrderVelocity"
          >
            <p>
              <strong>A hold has been temporarily placed on your account due to the number of orders you've placed recently.</strong>
            </p>
            <p>Please try again later.</p>
          </div>

          <div class="div-no-items-in-cart" v-show="cartItems.length == 0">
            <div class="noItems">
              <svg
                width="100"
                height="140"
                viewBox="0 0 240 336"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>emptyCart</title>
                <g id="Canvas" transform="translate(-6948 -1836)">
                  <g id="emptyCart">
                    <g id="icons8-shopping_bag">
                      <g id="Vector">
                        <use xlink:href="#p0" transform="translate(6948 1836)" fill="#9A2B2E" />
                      </g>
                      <g id="Vector">
                        <use
                          xlink:href="#p1"
                          transform="translate(7097.87 1865.89)"
                          fill="#B8393C"
                        />
                      </g>
                      <g id="Vector">
                        <use
                          xlink:href="#p1"
                          transform="translate(7008.27 1865.89)"
                          fill="#B8393C"
                        />
                      </g>
                      <g id="Vector">
                        <use xlink:href="#p2" transform="translate(7023.2 1880.84)" fill="#FFFFFF" />
                      </g>
                    </g>
                    <g id="Group 9">
                      <g id="Ellipse 2">
                        <use xlink:href="#p3" transform="translate(7026 1988)" fill="#FFFFFF" />
                      </g>
                      <g id="Ellipse 2.1">
                        <use xlink:href="#p3" transform="translate(7081 1988)" fill="#FFFFFF" />
                      </g>
                    </g>
                    <g id="Rectangle">
                      <use xlink:href="#p4" transform="translate(7026 2048)" fill="#FFFFFF" />
                    </g>
                  </g>
                </g>
                <defs>
                  <path
                    id="p0"
                    d="M 232 0L 8 0L 0.365644 320.643C 0.165128 329.065 6.93733 336 15.3614 336L 224.639 336C 233.063 336 239.835 329.065 239.634 320.643L 232 0Z"
                  />
                  <path
                    id="p1"
                    d="M 14.9333 29.8947C 23.1808 29.8947 29.8667 23.2026 29.8667 14.9474C 29.8667 6.69216 23.1808 0 14.9333 0C 6.68588 0 0 6.69216 0 14.9474C 0 23.2026 6.68588 29.8947 14.9333 29.8947Z"
                  />
                  <path
                    id="p2"
                    d="M 90.6 0C 90.6 -0.552285 90.1523 -1 89.6 -1C 89.0477 -1 88.6 -0.552285 88.6 0L 90.6 0ZM 1 0C 1 -0.552285 0.552285 -1 0 -1C -0.552285 -1 -1 -0.552285 -1 0L 1 0ZM 88.6 0L 88.6 22.4211L 90.6 22.4211L 90.6 0L 88.6 0ZM 88.6 22.4211C 88.6 46.5328 68.8868 66.2632 44.8 66.2632L 44.8 68.2632C 69.9932 68.2632 90.6 47.6356 90.6 22.4211L 88.6 22.4211ZM 44.8 66.2632C 20.7132 66.2632 1 46.5328 1 22.4211L -1 22.4211C -1 47.6356 19.6068 68.2632 44.8 68.2632L 44.8 66.2632ZM 1 22.4211L 1 0L -1 0L -1 22.4211L 1 22.4211Z"
                  />
                  <path
                    id="p3"
                    d="M 30 15C 30 23.2843 23.2843 30 15 30C 6.71573 30 0 23.2843 0 15C 0 6.71573 6.71573 0 15 0C 23.2843 0 30 6.71573 30 15Z"
                  />
                  <path id="p4" d="M 0 0L 85 0L 85 9L 0 9L 0 0Z" />
                </defs>
              </svg>
              <p>Your cart is empty!</p>
            </div>
          </div>

          <!-- cart alerts -->
          <div class="cart-product-alert-price-changed" v-show="cartAlertPriceChanges.length > 0">
            <p>The following items have changed price since you added them to your cart:</p>
            <ul>
              <li v-for="cartItem in cartAlertPriceChanges" v-bind:key="cartItem.cartApiId">
                <strong>{{cartItem.product.productName}}</strong> has
                <span v-if="cartItem.pricePriceOriginal < cartItem.pricePrice">increased</span>
                <span v-else>decreased</span> from
                <strong>${{cartItem.pricePriceOriginal}}</strong> to
                <strong>${{cartItem.pricePrice}}</strong>.
              </li>
            </ul>
          </div>

          <div
            class="cart-product-alert-availability-changed"
            v-show="cartAlertAvailabilityChanges.length > 0"
          >
            <p>The following items are no longer available and have been removed from your cart:</p>
            <ul>
              <li v-for="cartItem in cartAlertAvailabilityChanges" v-bind:key="cartItem.cartApiId">
                <strong>{{cartItem.product.productName}}</strong> is out of stock or no longer available for online purchase.
              </li>
            </ul>
          </div>

          <!-- Categories -->
          <div
            v-for="rootCategory in cartItemCategories"
            class="category-group"
            v-bind:key="rootCategory.categoryName"
          >
            <div class="category-title-bar text-shadow">
              <h3>{{rootCategory.categoryName}}</h3>
            </div>
            <div class="list no-hairlines-between no-hairlines">
              <ul>
                <sh-shop-cart-item
                  v-for="cartItem in rootCategory.cartItems"
                  v-bind:cartItem="cartItem"
                  v-bind:key="cartItem.productKey"
                ></sh-shop-cart-item>
              </ul>
            </div>
          </div>
          <div class="spacer-bottom"></div>
        </div>
      </div>

      <!-- Checkout button -->
      <div class="buttonWrapper">
        <div
          class="buttonContainer"
          @click="onClickCheckout"
          v-bind:class="{ disabled: !checkoutEnabled}"
        >
          <f7-row class="checkoutRow">
            <f7-col
              width="65"
              class="button-checkout"
              v-bind:class="{ disabled: !checkoutEnabled}"
            >Checkout</f7-col>
            <f7-col width="35" class="checkoutTotalWraper">
              <div
                class="button-checkout-total"
                v-bind:class="{ disabled: !checkoutEnabled}"
              >${{cartProductTotal}}</div>
            </f7-col>
          </f7-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShShopCartItem from "./ShShopCartItem.vue";

export default {
  components: {
    ShShopCartItem
  },

  data() {
    return {
      qtyExpander: false,
      cartItemCategories: [],
      cartAlertPriceChanges: [],
      cartAlertAvailabilityChanges: []
    };
  },

  computed: {
    cartItems: ({ $store }) => {
      return $store.getters.productsInCart;
    },
    cartProductTotal: ({ $store }) =>
      $store.getters.productTotalInCart.toFixed(2),

    ecommerceEnabled: ({ $store }) =>
      $store.getters.cartConstraints.ecommerceEnabled,
    pickupEnabled: ({ $store }) => $store.getters.cartConstraints.pickupEnabled,
    deliveryEnabled: ({ $store }) =>
      $store.getters.cartConstraints.deliveryEnabled,
    checkoutDisabled: ({ $store }) =>
      $store.getters.cartConstraints.checkoutDisabled,
    checkoutDisabledDueToUncollectedOrders: ({ $store }) =>
      $store.getters.cartConstraints.checkoutDisabledDueToUncollectedOrders,
    checkoutDisabledDueToOrderVelocity: ({ $store }) =>
      $store.getters.cartConstraints.checkoutDisabledDueToOrderVelocity,
    cartIsUnderSubtotalMinumum: ({ $store }) =>
      $store.getters.cartConstraints.checkoutDisabledDueToSubtotalUnderLimit,
    cartIsOverQuantityLimit: ({ $store }) =>
      $store.getters.cartConstraints.checkoutDisabledDueToQuantityOverLimit,
    cartSubTotalMinimum: ({ $store }) =>
      $store.getters.cartConstraints.cartSubTotalMinimum,
    cartItemQtyLimit: ({ $store }) =>
      $store.getters.cartConstraints.cartItemQtyLimit,
    cartItemQty: ({ $store }) => $store.getters.cartTotals.cartItemQty,

    checkoutEnabled: ({
      cartItems,
      ecommerceEnabled,
      pickupEnabled,
      deliveryEnabled,
      checkoutDisabled,
      checkoutDisabledDueToUncollectedOrders,
      checkoutDisabledDueToOrderVelocity,
      cartIsUnderSubtotalMinumum,
      cartIsOverQuantityLimit
    }) => {
      if (!ecommerceEnabled) return false;

      if (!pickupEnabled && !deliveryEnabled) return false;

      if (checkoutDisabled) return false;

      if (cartIsUnderSubtotalMinumum) return false;

      if (cartIsOverQuantityLimit) return false;

      if (checkoutDisabledDueToUncollectedOrders) return false;

      if (checkoutDisabledDueToOrderVelocity) return false;

      if (cartItems) {
        return cartItems.length > 0;
      }
    }
  },

  watch: {
    cartItems: function(val) {
      // eslint-disable-line
      const cartItemCategoryMap = {};

      this.cartAlertPriceChanges.length = 0;
      this.cartAlertAvailabilityChanges.length = 0;

      val.forEach(cartItem => {
        if (
          cartItem.pricePriceOriginal > 0 &&
          cartItem.pricePrice != cartItem.pricePriceOriginal
        ) {
          this.cartAlertPriceChanges.push(cartItem);
        }

        if (cartItem.availableForPurchase == false) {
          this.cartAlertAvailabilityChanges.push(cartItem);
        }

        if (cartItem.availableForPurchase) {
          // sort items into root category groups
          if (cartItem.product.productCategory.length > 0) {
            const categoryKey = cartItem.product.productCategory[0];
            const category = this.$store.getters.getCategoryByKey(categoryKey);

            if (category) {
              let topLevelCategory = category;
              for (let level = 5; level >= 0; level--) {
                // eslint-disable-line
                if (
                  topLevelCategory.parent_category &&
                  topLevelCategory.parent_category.shophero_category_key !==
                    "000000"
                ) {
                  topLevelCategory = topLevelCategory.parent_category;
                } else {
                  break;
                }
              }

              if (cartItemCategoryMap[topLevelCategory.shophero_category_key]) {
                cartItemCategoryMap[
                  topLevelCategory.shophero_category_key
                ].cartItems.push(cartItem);
              } else {
                cartItemCategoryMap[topLevelCategory.shophero_category_key] = {
                  categoryName: topLevelCategory.category_name,
                  categoryKey: topLevelCategory.shophero_category_key,
                  cartItems: [cartItem]
                };
              }
            }
          }
        }
      });

      const cartItemCategoryArray = Object.values(cartItemCategoryMap);
      cartItemCategoryArray.sort(function(a, b) {
        return a.categoryName > b.categoryName
          ? 1
          : b.categoryName > a.categoryName
          ? -1
          : 0;
      }); // eslint-disable-line

      this.cartItemCategories = cartItemCategoryArray;
    }
  },

  methods: {
    onClickCheckout() {
      this.$f7.panel.close();
      if (this.$store.getters.userIsAuthenticated) {
        this.$f7router.navigate("/checkout-service/");
      } else {
        this.$f7router.navigate("/auth-sign-in/checkout-service/");
      }
    }
  },

  mounted() {
    // this.$f7.statusbar.hide()
  }
};
</script>

<style lang="scss" scoped="true">
.elementsWrapper {
  height: 100%;
}

::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}

.wrapper-category {
  overflow-y: scroll;
  overflow-x: none;
  width: 100%;
  height: 100%;
  .spacer {
    width: 100%;
    height: calc(50px + var(--sat));
  }
  .spacer-bottom {
    width: 100%;
    height: calc(70px + var(--sab));
  }
  /deep/ .div-no-items-in-cart {
    margin-top: 0px;
    // margin-top: calc(50px + var(--sat));
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    .noItems {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }
    p {
      font-weight: 700;
      font-size: 22px;
      margin-top: 20px;
      text-align: center;
    }
  }
}

.category-group {
  margin-top: 10px;
  h3 {
    font-weight: 400;
    //letter-spacing: 0.33px;
  }
  .category-title-bar {
    border-radius: 5px;
    background: $colorThemeC;
    padding: 4px 8px;
    width: 96%;
    //border: 1px solid #dce1ea;
    color: #fff;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.shopping-cart-header {
  position: fixed;
  top: 0px;
  width: 100%;
  // background: $storePrimaryColor;
  background: white;
  color: #474646;
  height: calc(var(40px) + var(--sat));
  // padding: 10px 20px 40px 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: var(--sat);
  z-index: 60000;
  border-bottom: 1px solid #eee;
  -webkit-box-shadow: 0px -7px 36px -2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px -7px 36px -2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px -7px 36px -2px rgba(0, 0, 0, 0.24);
  left: 0px;
  /deep/ .columnWrapper {
    display: flex;
    justify-content: flex-end;
  }
  /deep/ h1 {
    @include breakpoint(tablet) {
      font-size: 25px;
    }
  }
  /deep/ svg {
    width: 20px;
    height: 20px;
  }
}

.panel {
  background: white;
  box-shadow: 3px 0px 25px 0px rgba(0, 0, 0, 0.52);
  width: 90%;
  @include breakpoint(tablet) {
    width: 70%;
  }
}

.wrapper {
  color: $fontColor;
}

.buttonWrapper {
  --button-wrapper-native-padding-bottom: 10px;
  padding-bottom: calc(
    var(--button-wrapper-native-padding-bottom) + var(--sab)
  );

  background: white;
  position: fixed;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  bottom: 0px;
  width: 100%;
  -webkit-box-shadow: 0px -7px 36px -2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px -7px 36px -2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px -7px 36px -2px rgba(0, 0, 0, 0.24);
  z-index: 50000;
}

.checkoutRow {
  height: 100%;
}

.buttonContainer {
  background: $storePrimaryColor;
  border-radius: 5px;
  height: 50px;
}

.button-checkout {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 1.5px;
}

.checkoutTotalWraper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-checkout-total {
  border-radius: 5px;
  background: $storePrimaryColor;
  width: 70px;
  height: 25px;
  text-align: center;
  color: #fff;
  right: 10px;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 1.5px;
  font-weight: 500;
  border: 1px solid #fff;
}

.disabled {
  background: #474646;
  color: #fff;
}

.list {
  margin: 5px 0px 15px 0 !important;
}

.cart-product-alert-checkout-disabled {
  margin: 8px;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  color: #fff;
  background: #ed553b;

  ul {
    margin-top: 5px;
    margin-left: 15px;
  }

  li {
    margin-bottom: 5px;
  }
}

.cart-product-alert-price-changed {
  padding: 10px;
  margin: 8px;
  font-size: 12px;
  border-radius: 5px;
  color: #fff;
  background: $accentColor;

  ul {
    margin-top: 5px;
    margin-left: 15px;
  }

  li {
    margin-bottom: 5px;
  }
}

.cart-product-alert-availability-changed {
  padding: 10px;
  margin: 8px;
  font-size: 12px;
  border-radius: 5px;
  color: #fff;
  background: $colorRed;

  ul {
    margin-top: 5px;
    margin-left: 15px;
  }

  li {
    margin-bottom: 5px;
  }
}
</style>
