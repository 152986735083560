<template>
  <div>
    <f7-card>
      <f7-card-header style="padding:5px 0 15px;">
        <h4 style="margin-botton:20px;">
          Enter <span v-html="ucfirst(addressType)"></span> Address
        </h4>
        <div class="float-right">
          <a class="link popup-close" :class="isValid ? 'text-black' : ''"
            ><i class="f7-icons">xmark</i></a
          >
        </div>
      </f7-card-header>

      <f7-card-content>
        <f7-list no-hairlines-md class="delivery-info-form-new-address">
          <f7-list-item>
            <f7-input
              type="text"
              placeholder="First Name"
              info="First Name of person receiving order"
              clear-button
              required
              validate
              @input="firstName = $event.target.value"
              @input:empty="firstName = $event.target.value"
              :value="firstName"
            ></f7-input>
          </f7-list-item>

          <f7-list-item>
            <f7-input
              type="text"
              placeholder="Last Name"
              info="Last Name of person receiving order"
              clear-button
              required
              validate
              @input="lastName = $event.target.value"
              @input:empty="lastName = $event.target.value"
              :value="lastName"
            ></f7-input>
          </f7-list-item>

          <f7-list-item>
            <f7-input
              type="tel"
              placeholder="Phone #"
              info="Phone # of person receiving order"
              clear-button
              required
              validate
              pattern="[0-9\-\.\(\)\+ ]*"
              error-message="Only phone numbers please!"
              @input="phone = $event.target.value"
              @input:empty="phone = $event.target.value"
              :value="phone"
            ></f7-input>
          </f7-list-item>

          <f7-list-item>
            <f7-input
              type="text"
              placeholder="Company"
              info
              clear-button
              @input="company = $event.target.value"
              @input:empty="company = $event.target.value"
              :value="company"
            ></f7-input>
          </f7-list-item>

          <f7-list-item>
            <f7-input
              type="text"
              placeholder="Address"
              info
              clear-button
              required
              validate
              @input="address1 = $event.target.value"
              @input:empty="address1 = $event.target.value"
              :value="address1"
            ></f7-input>
          </f7-list-item>

          <f7-list-item>
            <f7-input
              type="text"
              placeholder="Apt. # or suite"
              info
              clear-button
              @input="address2 = $event.target.value"
              @input:empty="address2 = $event.target.value"
              :event="address2"
            ></f7-input>
          </f7-list-item>

          <f7-list-item>
            <f7-input
              type="text"
              placeholder="City"
              info
              clear-button
              required
              validate
              @input="city = $event.target.value"
              @input:empty="city = $event.target.value"
              :value="city"
            ></f7-input>
          </f7-list-item>

          <f7-list-item>
            <f7-input
              type="select"
              required
              validate
              @input="state = $event.target.value"
              @input:empty="state = $event.target.value"
              :value="state"
            >
              <option value disabled>State</option>
              <option
                v-for="(name, abbreviation) in states"
                :value="abbreviation"
                :key="abbreviation"
                >{{ name }}</option
              >
            </f7-input>
          </f7-list-item>

          <f7-list-item>
            <f7-input
              type="text"
              placeholder="Zip/Postal Code"
              info
              clear-button
              required
              validate
              pattern="[0-9\-]*"
              error-message="Only digits please!"
              @input="zipcode = $event.target.value"
              @input:empty="zipcode = $event.target.value"
              :value="zipcode"
            ></f7-input>
          </f7-list-item>

          <f7-list-item>
            <f7-input
              type="textarea"
              placeholder="Special delivery instructions: ie. side door, gate code, etc."
              @input="notes = $event.target.value"
              @input:empty="notes = $event.target.value"
              :value="notes"
            ></f7-input>
          </f7-list-item>
        </f7-list>
      </f7-card-content>
    </f7-card>

    <transition name="fade">
      <div
        class="alert-error"
        v-show="error"
        v-html="error"
        style="margin:10px;"
      ></div>
    </transition>

    <div style="padding:10px">
      <button
        class="button-normal button-with-icon"
        :class="{ disabled: !isValid }"
        @click="saveAddress"
      >
        Save Address
      </button>
      <!-- <button
                class="button-normal popup-close"
                style="margin-top:5px;background-color:silver;"
                @click="cancel()">Cancel</button> -->
    </div>
  </div>
</template>

<script>
import ShopHeroApi from "../api/ShopHeroApi";

export default {
  props: ["addressType", "states", "storeLocation"],
  data() {
    return {
      firstName: null,
      lastName: null,
      phone: null,
      company: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zipcode: null,
      notes: null,

      isValid: false,

      error: null,
    };
  },
  components: {
    //
  },
  watch: {
    firstName: function(v) {
      this.validateAddress();
    },
    lastName: function(v) {
      this.validateAddress();
    },
    address1: function(v) {
      this.validateAddress();
    },
    city: function(v) {
      this.validateAddress();
    },
    state: function(v) {
      this.validateAddress();
    },
    zipcode: function(v) {
      this.validateAddress();
    },
  },
  methods: {
    validateAddress: function() {
      this.isValid = false;
      if (
        this.firstName &&
        this.lastName &&
        this.phone &&
        this.address1 &&
        this.city &&
        this.state &&
        this.zipcode
      ) {
        if (this.zipcode.length >= 5) {
          this.isValid = true;
        }
      }
    },
    saveAddress: function() {
      const params = {
        first_name: this.firstName,
        last_name: this.lastName,
        company: this.company,
        address: this.address1,
        apt_suite: this.address2,
        city: this.city,
        state: this.state,
        zipcode: this.zipcode,
        country: this.storeLocation.physical_location_country,
        phone: this.phone,
        notes: this.notes,
        address_type: this.addressType
      };

      switch (this.addressType.toUpperCase()) {
        case "DELIVERY":
          this.storeDeliveryAddress(params);
          break;
        case "SHIPMENT":
        default:
          this.storeShipmentAddress(params);
          break;
      }
    },
    storeDeliveryAddress(params) {
      this.error = null;
      ShopHeroApi.storeDeliveryAddress(params, (response) => {
        console.log(response);
        if (!response.success) {
          this.error = response.error_message;
          return;
        }
        let data = {
          addressType: this.addressType,
          storedAddress: response.stored_address,
        };
        this.$emit("newAddressStored", data);
      });
    },
    storeShipmentAddress(params) {
      // Replace with EA Endpoint
      ShopHeroApi.storeDeliveryAddress(params, (response) => {
        console.log(response);
        if (!response.success) {
          this.error = response.error_message;
          return;
        }
        let data = {
          addressType: this.addressType,
          storedAddress: response.stored_address,
        };
        this.$emit("newAddressStored", data);
      });
    },
    cancel: function() {
      const event = { addressType: this.addressType };
      // console.log(event);
      this.$emit("cancel", event);
    },

    ucfirst: function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  mounted() {
    this.state = this.storeLocation
      ? this.storeLocation.physical_location_state
      : null;
  },
};
</script>

<style lang="scss" scoped="false">
.input {
  width: 100%;
}
.text-black {
  color: black;
}
.alert-error {
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px;
  margin-top: 0px;
  margin-bottom: 20px;
  background-color: #d02a2e;
  color: white;
}
</style>
