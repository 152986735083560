<template>
  <div class="popup popup-pickup">
    <div class="page-content">
      <f7-card v-show="serviceType == 'pickup'">
        <f7-card-header class="cardHeader">
          <h4>Your PICKUP Information</h4>
          <a class="popup-close">
            <i class="f7-icons">xmark</i>
          </a>
        </f7-card-header>

        <f7-card-content>
          <f7-list no-hairlines-md>
            <f7-list-item>
              <f7-input
                type="text"
                name="first_name"
                input-id="pickup-info-input-first-name"
                placeholder="First name"
                info="First name of person picking up order"
                clear-button
                required
                validate
                @input="$emit('validatePickupInfo')"
                @input:empty="$emit('validatePickupInfo')"
              ></f7-input>
            </f7-list-item>
            <f7-list-item>
              <f7-input
                type="text"
                defaultValue="+1"
                name="last_name"
                input-id="pickup-info-input-last-name"
                placeholder="Last name"
                info="Last name of person picking up order"
                clear-button
                required
                validate
                @input="$emit('validatePickupInfo')"
                @input:empty="$emit('validatePickupInfo')"
              ></f7-input>
            </f7-list-item>

            <f7-list-item>
              <f7-input
                class="inputCode"
                input-id="pickup-info-input-country-code"
                type="text"
                info="Code"
                pattern="\+[0-9]{1,4}"
                error-message="Error! (example: +###)"
                validate
                required
                placeholder="Country code"
                @input="$emit('validatePickupInfo')"
                @input:empty="$emit('validatePickupInfo')"
              >
              </f7-input>
              <f7-input
                class="inputPhone"
                input-id="pickup-info-input-phone-number"
                type="tel"
                placeholder="Mobile Phone #"
                info="We'll text you when your order is ready"
                clear-button
                required
                validate
                :pattern='phoneCountryCode=="+1"?"[0-9]{10}":"[0-9]{6,15}"'
                error-message="Only phone numbers please!"
                @input="$emit('validatePickupInfo')"
                @input:empty="$emit('validatePickupInfo')"
              ></f7-input>
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <f7-button
        :disabled="!pickupInfo"
        large
        class="saveButton popup-close"
      >
        Save Information
      </f7-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["serviceType", "pickupInfo","phoneCountryCode"],
};
</script>

<style lang="scss" scoped="true">
.cardHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
}

.saveButton {
  background: $storePrimaryColor;
  margin: 32px 12px;
  color: white;
}
.inputCode {
  margin-right: 10px;
  max-width: 15%;
  border-bottom: 1px    solid #000;
 
}
.inputPhone{
  width: 100%;
  border-bottom: 1px    solid #000;
}
</style>
