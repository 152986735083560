<template>
<f7-page class="no-padding">
  <div class="search-container">
    <div class="search-header">
      <h2>Popular Recipe Searches</h2>
    </div>
    <div class="popular-searches-container">
      <div class="popular-searches-button" v-for="term in popularSearchTerms">
        <f7-link v-bind:href="`/search/recipes-query/${term}/`">
        {{ term }}
        </f7-link>
      </div>
    </div>
    <div class="search-footer">
      <img src="../images/store-assets/store-logo.png">
    </div>
  </div>

</f7-page>

</template>

<script>

export default {

  data() {
    return {
      popularSearchTerms:
      ['mango', 'wrap', 'salad', 'chicken', 'cheese', 'macaroni', 'soup', 'sandwich', 'taco', 'curry',
        'indian', 'pork', 'beef', 'lasagna', 'bake', 'couscous', 'rice', 'salmon', 'fruit', 'cookies', 'teriyaki', 'greek',
        'smoothie', 'bento', 'pizza', 'omlette', 'masala', 'stir fried' , 'lemon', 'potato', 'vegetarian'],
    }
  },

  props: [
  ],

  computed: {
  },

  components: {
  },

  methods: {
    shufflePopularSearchTerms() {
      this.popularSearchTerms = this.shuffle(this.popularSearchTerms)
    },
    shuffle(array) {
      let currentIndex = array.length, temporaryValue, randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },

  },

  created() {
    this.$root.setNavbarTitle('Search Recipes')
    this.$root.setNavbarSubtitle('')
    this.$root.setNavbarSearchPlaceholder(this.$store.getters.options.hideGrocery ? "" : "Fruit Salad")
    this.$root.setNavbarSearchText('')

    this.$store.dispatch('setSearchModeRecipes')

    this.shufflePopularSearchTerms()

  },

  mounted() {
    this.$root.setActiveToolbar('recipes')

    this.$nextTick(() => {
     this.$store.dispatch('setSearchFocus', { focused: true })
    })

  },
}

</script>

<style lang="scss" scoped="true">

.no-padding {
  /deep/ .page-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.search-container {
  height: 100%;
  padding-top: var(--f7-page-spacer-top);
  margin-left: 0px;
  margin-right: 0px;
  //padding-left: 20px;
  //padding-right: 20px;
  padding-bottom: 50px;
  background-image: url(../images/search/search-background2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% -50px;

  //background-size: contain;
  //background-position: 50% 85%;
}

.search-header {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.search-footer {
  bottom: 120px;
  width: 100%;
  text-align: center;
  position: absolute;
  img {
    margin-top: 10px;
    width: 150px;
    bottom: 0px;
    left:50%;
    transform:translateX(-50%);
    position: absolute;
  }
}

.popular-searches-container {
  width: 100%;
  padding-left: 20px;
  padding-right: 10px;
  display: block;
}

.popular-searches-button {
  background-color: $accentColor;
  border-radius: 10px;
  float: left;
  display: flex;
  width: auto;
  margin-right: 8px;
  padding: 5px 10px;
  margin-bottom: 10px;
  a {
    color: #fff;
    font-weight: bold;
  }
  @include breakpoint(tablet) {
    font-size: 18px;
  }
}

</style>
