<template>
<div class="quick-slider"
     v-show="childCategoryCount > 1">
  <f7-swiper :init="true"
             :params="{slidesPerView: 'auto', spaceBetween: 10, slidesPerGroup: 1, observer: true, loop: false}">
    <f7-swiper-slide v-for="child in childCategories" class='swiper-child' v-bind:key="child.shophero_category_key">
      <f7-link v-bind:href="'/product-browse/' + child.shophero_category_key + '/'">
          <p class="text-shadow">{{child.category_name}}</p>
      </f7-link>
    </f7-swiper-slide>
  </f7-swiper>
</div>

</template>

<script>
export default {

  data() {
    return {
      childCategories: {},
      filterZeroProductCategories: true,
    }
  },

  props: [
    'categoryKey',
  ],

  computed: {
    childCategoryCount: ({ childCategories }) => Object.size(childCategories),
  },

  methods: {
  },

  created() {
    if(this.filterZeroProductCategories) {
      const childCategories = this.$store.getters.getCategoryByKey(this.categoryKey).child_categories

      const childCategoriesFiltered = {}
      for(let child in childCategories) {
        if(childCategories[child].child_product_count > 0)
          childCategoriesFiltered[child] = childCategories[child]
      }

      this.childCategories = childCategoriesFiltered

    } else {
      this.childCategories = this.$store.getters.getCategoryByKey(this.categoryKey).child_categories
    }
  },
}

</script>

<style lang="scss" scoped="true">
//@import '../scss/main.scss';
.quick-slider {
  height: 60px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow:0px 1px 2px rgba(0, 0, 0, 0.3);
  .swiper-child {
    width: auto;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $storePrimaryColor;
    border-radius: 12px;
    margin: 5px;
    padding: 0 15px;
    p{
      color: white;
      font-weight: $bold;
    }
  }
}
</style>
