export default class {
  constructor() {
    this.productKey = ''
    this.productName = ''
    this.productImageUrl150 = ''
    this.productImageUrl215 = ''
    this.productImageUrl500 = ''
    this.productImageUrlFull = ''
    this.productCategory = []

    this.productAgeRestricted = false

    this.priceRank = 0
    this.pricePrice = 0
    this.productUnit = 'each'
    this.productUnitPlural = 'each'
    this.productUnitText = 'each'
    this.productUnitNote = ''

    this.quantityIncrement = 1

    this.basePriceRank = 0
    this.basePricePrice = 0
    this.baseProductUnit = 'each'
    this.baseProductUnitPlural = 'each'
    this.baseProductUnitText = 'each'
    this.baseProductUnitNote = ''

    this.productLabelUnits = []

    this.isEndlessAisle = false;
    this.endlessAisle = {
      casePrice: null,
      caseQty: null,
      listingId: 0,
      unitPrice() {
        return (this.casePrice && this.caseQty) ? this.casePrice / this.caseQty : null;
      }
    };

    // this.qtyInCart = 0
    // this.instructions = ''
    // this.addedTimeUTC = Date.UTC()
    // this.updatedTimeUTC = Date.UTC()

    this.productUnitLabel = function() {
      let resultStrings = []
      let lastUnit = null

      const unitTexts = []

      this.productLabelUnits.forEach(function(v) {
        const unit = v.unit
        unitTexts.push(`${v.qty} ${v.unit.replace('.', '')}`)
        lastUnit = {
          qty: v.qty,
          unit: v.unit,
        }
      });
      if(unitTexts.length > 0) {
        resultStrings.push(unitTexts.join(', '))
      }

      if((lastUnit) && (this.pricePrice > 0)) {
        if(lastUnit.qty > 0) {
          lastUnit.unit = lastUnit.unit.replace('ct', 'ea')
          const pricePerUnit = parseFloat(this.pricePrice) / parseFloat(lastUnit.qty)
          if(pricePerUnit > 1) {
              const pricePerUnitFormatted = parseFloat(Math.round(pricePerUnit * 100) / 100).toFixed(2)
              resultStrings.push('$' + pricePerUnitFormatted + '/' + lastUnit.unit)
          } else if(pricePerUnit > 0)  {
              const pricePerUnitFormatted = parseFloat(Math.round(pricePerUnit * 100)).toFixed(1)
              resultStrings.push(pricePerUnitFormatted.replace('.0', '') + '¢/' + lastUnit.unit)
          }
        }
      }

      return resultStrings.join(' &bullet; ')

    }
  }
}


