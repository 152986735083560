<template>
  <f7-popup id="cd-confirm-modal" class="demo-popup" :opened="modalOpen" @popup:closed="closeCDConfirmationModal">
    <f7-page>

      <f7-block class="close-container">
          <f7-link id="cd-modal-close-btn" popup-close>
            <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>exitSketch</title>
              <desc>Created with Sketch.</desc>
              <defs></defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M17.7587892,15.6650392 L15.6611332,17.7587892 C15.2783203,18.1455082 14.6533203,18.1455082 14.2666022,17.7587892 L9.02441425,12.5205082 L3.78613325,17.7587892 C3.39941425,18.1455082 2.77050825,18.1455082 2.38769525,17.7587892 L0.29003925,15.6650392 C-0.09667975,15.2783203 -0.09667975,14.6533203 0.29003925,14.2666022 L5.52832025,9.02441425 L0.29003925,3.78613325 C-0.09277275,3.39550825 -0.09277275,2.76660225 0.29003925,2.38769525 L2.38769525,0.29003925 C2.77050825,-0.09667975 3.39941425,-0.09667975 3.78613325,0.29003925 L9.02441425,5.53222725 L14.2666022,0.29003925 C14.6533203,-0.09667975 15.2822272,-0.09667975 15.6611332,0.29003925 L17.7587892,2.38378925 C18.1455082,2.77050825 18.1455082,3.39941425 17.7626953,3.78613325 L12.5205082,9.02441425 L17.7587892,14.2666022 C18.1455082,14.6533203 18.1455082,15.2783203 17.7587892,15.6650392 Z" id="exitSketch" fill="#000000" fill-rule="nonzero"></path>
              </g>
          </svg>
        </f7-link>
      </f7-block>

      <f7-block class="cd-modal-container">
       
        <div class="cd-modal-product-card">
           <div v-if="caseQuantity" class="case-count">
            <case-count :count="caseQuantity" />
          </div>

          <div class="cd-modal-product-image-container">
            <img v-bind:src="imageUrl" />
          </div>
      
          <div class="cd-modal-product-title">
            <h3>{{ productName }}</h3>
          </div>
      
          <div class="cd-modal-case-price">
            {{ caseQuantity }} per case (${{ (price / caseQuantity).toFixed(2) }} each)
          </div>
      
          <div class="cd-modal-product-price">
            <span class="cd-modal-product-price">${{ price }}</span>
            <span>{{ productUnit === 'case' ? 'per case' : 'each' }}</span>
          </div>
        </div>

        <div class="fedex-button">
          <fed-ex-button />
        </div>


        <div class="cd-modal-explanation">
          <h1>Heads Up!</h1>
          <div class="cd-modal-explanation-text-container">
            <p>You're adding a full case of this product to your cart - not just a single item.</p>
            <p>The full case will be shipped directly to an address you provide in approximately 4 to 7 days.</p>
          </div>
        </div>


        <div class="cd-modal-button-container">
          <div>
            <button class="cd-modal-add-button" @click="addChoiceDoorstepItemToCart">
              <svg width="20" height="20" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>cart</title>
                <desc>Created using Figma</desc>
                <g id="Canvas" transform="translate(-430 -1195)">
                <g id="cart">
                <use xlink:href="#path0_fill" transform="translate(430 1195)" fill="#FFFFFF"/>
                </g>
                </g>
                <defs>
                <path id="path0_fill" d="M 0 0L 0 2.5L 2.85156 2.5L 6.13281 12.2656L 4.25781 14.7656L 4.21875 14.7656C 3.64258 15.5713 3.61816 16.6455 4.02344 17.4219C 4.42871 18.1982 5.2832 18.75 6.25 18.75L 22.5 18.75L 22.5 16.25L 6.25 16.25C 6.25488 16.2451 6.23535 16.2305 6.25 16.2109L 8.125 13.75L 19.8828 13.75C 20.3809 13.75 20.8887 13.5059 21.0156 13.0078L 24.8828 4.25781C 25.2588 3.38379 24.624 2.5 23.75 2.5L 5.46875 2.5L 4.92188 0.859375L 4.64844 0L 0 0ZM 6.25 20C 4.86816 20 3.75 21.1182 3.75 22.5C 3.75 23.8818 4.86816 25 6.25 25C 7.63184 25 8.75 23.8818 8.75 22.5C 8.75 21.1182 7.63184 20 6.25 20ZM 20 20C 18.6182 20 17.5 21.1182 17.5 22.5C 17.5 23.8818 18.6182 25 20 25C 21.3818 25 22.5 23.8818 22.5 22.5C 22.5 21.1182 21.3818 20 20 20Z"/>
                </defs>
              </svg>
              Add to Cart</button>
          </div>
          <div>
            <button class="cd-modal-cancel-button" @click="closeCDConfirmationModal">Don't add to cart</button>
          </div>
        </div>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import FedExButton from "../components/FedExButton.vue";
import CaseCount from "../components/CaseCount.vue";

export default {
   components: {
    CaseCount,
    FedExButton,
  },
  computed: {
    modalOpen: ({ $store }) => {
      return $store.getters.choiceDoorstepConfirmationModalIsOpen;
    },
    productName: ({ $store }) => {
      return $store.getters.choiceDoorstepProductConfirmationName;
    },
    imageUrl: ({ $store }) => {
      return $store.getters.choiceDoorstepProductConfirmationImageUrl;
    },
    caseQuantity: ({ $store }) => {
      return $store.getters.choiceDoorstepProductConfirmationCaseQuantity;
    },
    price: ({ $store }) => {
      return $store.getters.choiceDoorstepProductConfirmationPrice;
    },
    productUnit: ({ $store }) => {
      return $store.getters.choiceDoorstepProductConfirmationProductUnit;
    },
    product: ({ $store }) => {
      return $store.getters.choiceDoorstepProductConfirmation;
    },
    proposedQuantity: ({ $store }) => {
      return $store.getters.choiceDoorstepConfirmationProposedQuantity;
    },
  },

  data: function () {
    return {
      saving: false,
    };
  },

  methods: {
    addChoiceDoorstepItemToCart() {
      this.$store.dispatch('setQuantityProductInCart', {
        product: this.product,
        qty: this.proposedQuantity,
        specialInstructions: '',
        permitSubstitution: false,
      }).then(
        () => {
          this.closeCDConfirmationModal();
          // this.$f7.views.main.router.back();
          this.$root.addedItemToCartToast();
        },
        () => {
          console.log('Could not add/increment Choice Doorstep product');
        },
      )
    },

    closeCDConfirmationModal() {
      // this.$f7.popup.close(); //'#cd-confirm-modal'
      this.$store.dispatch('closeCDModal');
    }
  },
};
</script>

<style lang="scss" scoped>

  .page {
    background:#fff
  }

  .close-container {
    width: 100%; 
    display: flex;
    align-items: center;
    justify-content: flex-end;

    #cd-modal-close-btn {
      padding: 1rem;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: #000;
      }
    } 
  }

  

  .cd-modal-container {
    background: #fff;
    padding: 2rem;
    font-size: 125%;
    max-width: 24rem;
    margin: 0 auto;
    font-family: $fontStack;

  }

  .case-count {
    position: absolute;
    right: -2.75rem;
    top: 0;
    transform: translate(-50%, -50%);
    width: 2.75rem;
    height: 2.75rem;
    font-size: 0.8rem;
  }

  .case-count::v-deep .counterLabel {
    margin-top: 0.5rem;
    font-size: 0.6rem;
  }

  // Product Card
  .cd-modal-product-card {
    position: relative;
    border: 1px solid #eee;
    border-radius: .5rem;
    padding: 1rem 1rem 3rem 1rem;
    text-align: center;
    font-size: 16px;

    .cd-modal-product-image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;

      img {
        max-width: 150px;
      }
    }

     .cd-modal-product-title {
       font-weight: 800;
       text-align: center;

       h3 {
         font-size: 1rem;
       }
    }

    .cd-modal-case-price {
      margin-top: 0.5rem;
      font-size: .75rem;
    }

    .cd-modal-product-price {
      font-weight: bold;
      font-size: 1rem;
      margin-top: 0.5rem;
    }
  }


  // FedEx button
  .fedex-button {
    margin: auto;
    text-align: center;
    margin-top: -1.25rem;
    width: 200px;
  }

  .fedex-button::v-deep .label {
    font-size: 0.75rem;
  }

  .fedex-button::v-deep .logoContainer {
    width: 1.5rem;
    height: 1.5rem;
  }


  // Heads up section
  .cd-modal-explanation {
    h1 {
      font-weight: bold;
      text-align: center;
      margin-top: 2rem;
    }

    .cd-modal-explanation-text-container {
      margin-top: .5rem;
      text-align: center;
      font-weight: 500;

      p {
        font-size: .75rem;
      }
      p:first-child {
        margin-bottom: .5rem;
      }
    }
  }

  .cd-modal-button-container {
    margin-top: 2rem;

    .cd-modal-add-button, .cd-modal-cancel-button {
      width: 100%;
      height: 40px;
      font-size: 1rem;
      border-radius: .25rem;
      border: none;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $fontStack;
    }

    .cd-modal-add-button {
      margin-top: 15px;
      background: $storePrimaryColor;
      color: white;
      font-weight: bold;

      svg {
        margin-right: .5rem;
      }
    }

    .cd-modal-cancel-button {
      margin-top: 1rem;
      background: #fff;
      color: $storePrimaryColor;
      border: 1px solid #eee;
      font-weight: bold;
    }
  }
</style>
