<template>
  <f7-page class="no-padding">
    <div v-if="showPopularSearch" class="search-container">
      <div class="search-header">
        <h2>Popular Searches</h2>
      </div>
      <div class="popular-searches-container">
        <div class="popular-searches-button" v-for="term in popularSearchTerms">
          <f7-link v-bind:href="`/search/products-query/${term}/`">{{ term }}</f7-link>
        </div>
      </div>
      <div class="search-footer">
        <img src="../images/store-assets/store-logo.png" />
      </div>
    </div>
  </f7-page>
</template>

<script>
import $$ from "dom7";

export default {
  data() {
    return {
      popularSearchTerms: [
        "milk",
        "eggs",
        "chicken",
        "cheese",
        "bread",
        "onion",
        "toilet paper",
        "yogurt",
        "carrots",
        "potatoes",
        "butter",
        "bananas",
        "chips",
        "garlic",
        "corn",
        "salsa",
        "orange juice",
        "apples",
        "sugar",
        "tomatoes",
        "spinach"
      ]
    };
  },

  props: [],

  computed: {
    showPopularSearch() { return !this.$store.getters.options.hidePopularSearch; }
  },

  components: {},

  methods: {},

  created() {
    this.$root.setNavbarTitle("Search Products");
    this.$root.setNavbarSubtitle("");
    this.$root.setNavbarSearchPlaceholder(this.$store.getters.options.hideGrocery ? "" : "Bananas");
    this.$root.setNavbarSearchText("");
  },

  mounted() {
    this.$nextTick(() => {
      // console.log('setSearchFocus!')
      //  this.$store.dispatch('setSearchFocus', { focused: true })
      setTimeout(() => {
        $$(".inputSearch").focus();
        console.log("Input focused");
      }, 500);
    });

    this.$store.dispatch("setSearchModeProducts");
  }
};
</script>

<style lang="scss" scoped="true">
.no-padding {
  /deep/ .page-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.search-container {
  height: 100%;
  padding-top: var(--f7-page-spacer-top);
  margin-left: 0px;
  margin-right: 0px;
  //padding-left: 20px;
  //padding-right: 20px;
  padding-bottom: 50px;
  background-image: url(../images/search/search-background2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% -50px;

  //background-size: contain;
  //background-position: 50% 85%;
}

.search-header {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.search-footer {
  bottom: 120px;
  width: 100%;
  text-align: center;
  position: absolute;
  img {
    margin-top: 10px;
    width: 150px;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}

.popular-searches-container {
  width: 100%;
  padding-left: 20px;
  padding-right: 10px;
  display: block;
}

.popular-searches-button {
  background-color: $storePrimaryColor;
  border-radius: 10px;
  float: left;
  display: flex;
  width: auto;
  margin-right: 8px;
  padding: 5px 10px;
  margin-bottom: 10px;
  a {
    color: #fff;
    font-weight: bold;
  }
  @include breakpoint(tablet) {
    font-size: 18px;
  }
}
</style>
