<template>
<f7-page>

  <div class="spacer"></div>

  <div class="info-loading" v-show="!infoLoaded">
    <div class="preloader"></div>
  </div>

  <template v-if="infoLoaded">
    <transition name="fade-in">
    <f7-card>
      <!-- <f7-card-header>Visit Us!</f7-card-header> -->
      <f7-card-content>

        <f7-row>
          <f7-col width="100">
            <div id="map-canvas"></div>
          </f7-col>
        </f7-row>

        <template v-if="selectedLocation.physical_location_logo_url">
          <f7-row>
            <f7-col width="100">
              <img class="img-logo" src="../images/store-assets/store-logo.png" alt="logo" v-show="allLocations.length == 1">
              <img class="img-logo" v-bind:src="selectedLocation.physical_location_logo_url" alt="logo" v-show="allLocations.length > 1">
              <!-- <img class="img-logo" v-bind:src="selectedLocation.physical_location_logo_url"> -->
            </f7-col>
            <f7-col width="100">
              <div class="address">
                {{selectedLocation.physical_location_name}} <br>
                {{selectedLocation.physical_location_street_address}} <br>
                {{selectedLocation.physical_location_city}},
                {{selectedLocation.physical_location_state}}
                {{selectedLocation.physical_location_zip}}
              </div>
            </f7-col>
          </f7-row>
        </template>


      <!-- <f7-row>
        <f7-col width="100">
          {{selectedLocation.physical_location_name}}
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="100">
          {{selectedLocation.physical_location_street_address}}
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="100">
          {{selectedLocation.physical_location_city}}, {{selectedLocation.physical_location_state}} {{selectedLocation.physical_location_zip}}
        </f7-col>
      </f7-row> -->

      <template v-if="hasLaunchNavigator">
        <f7-row>
          <f7-col width="100">
            &nbsp;
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="100">
            <button class="button-normal" @click="navigateToLocation">Navigate to<br/>{{selectedLocation.physical_location_name}}</button>
          </f7-col>
        </f7-row>
      </template>

      </f7-card-content>
    </f7-card>
    </transition>

    <transition name="fade-in">
    <f7-card v-if="info.hours">
        <f7-card-header>Hours of Operation</f7-card-header>
        <f7-card-content>
          <f7-row v-for="hour in info.hours" v-bind:key="hour.id">
            <f7-col width="50">{{hour.description}}</f7-col>
            <f7-col width="50"  class="col-right">{{hour.hours}}</f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
    </transition>

    <transition name="fade-in">
    <f7-card>
        <f7-card-header>Contact Us</f7-card-header>
        <f7-card-content>
          <f7-row v-for="number in info.phone_numbers" v-bind:key="number.id">
            <f7-col width="50">{{number.description}}</f7-col>
            <f7-col width="50" class="col-right"><a v-bind:href="`tel://${number.phone_number}`">{{number.phone_number}}</a></f7-col>
          </f7-row>
          <f7-row v-if="info.support.customer_care && !info.support.customer_care_delivery">
            <f7-col width="50">Online/Mobile Shopping</f7-col>
            <f7-col width="50" class="col-right"><a v-bind:href="`tel://${info.support.customer_care_phone}`">{{info.support.customer_care_phone}}</a></f7-col>
          </f7-row>
          <f7-row v-if="info.support.customer_care && info.support.customer_care_delivery">
            <f7-col width="50">Mobile Shopping &amp; Delivery</f7-col>
            <f7-col width="50" class="col-right"><a v-bind:href="`tel://${info.support.customer_care_phone}`">{{info.support.customer_care_phone}}</a></f7-col>
          </f7-row>
          <f7-row v-if="info.support.customer_care && !info.support.customer_care_order_by_phone">
            <f7-col width="50">Place Order By Phone</f7-col>
            <f7-col width="50" class="col-right"><a v-bind:href="`tel://${info.support.customer_care_phone}`">{{info.support.customer_care_phone}}</a></f7-col>
          </f7-row>
          <template v-if="info.support.customer_care">
            <f7-row>
              <f7-col width="100">&nbsp;</f7-col>
            </f7-row>
            <f7-row >
              <f7-col width="30">Email</f7-col>
              <f7-col width="70" class="col-right"><a v-bind:href="`mailto:${info.support.customer_care_email}`">{{info.support.customer_care_email}}</a></f7-col>
            </f7-row>
          </template>
           <f7-row v-if="selectedLocation.physical_location_website_link">
              <f7-col width="30">Online</f7-col>
              <f7-col width="70" class="col-right"><a @click="openExternalLink(selectedLocation.physical_location_website_link)">{{selectedLocation.physical_location_website_link.replace('https://', '')}}</a></f7-col>
            </f7-row>
       </f7-card-content>
      </f7-card>

    </transition>

   
    <transition name="fade-in">
      <f7-card v-show="cancelAccountMessage">
        <div class="error-banner-wrapper" >
          <p>{{ cancelAccountMessage }}</p>
        </div>
      </f7-card>
    </transition>

    <f7-card v-if="userIsAuthenticated">
      <f7-card-header>Delete Account</f7-card-header>
      <f7-card-content>
      
        You may delete your account, but this action is permanent. You must NOT have any outstanding orders or payments.
        You must type the key phrase "<b>{{ cancelVerificationText }}</b>" in the input below. <br><br><b>Your account will 
        not be accessible to you immediately upon deletion and can not be recovered.</b><br><br>

        <f7-input
          type="text"
          class="input-cancel-account"
          placeholder="Type the key phrase"
          @input="canceVerificationInput = $event.target.value"
          :value="canceVerificationInput"
        ></f7-input>
       
        <button id="cancel-account-button" class="button button-cancel-account" @click="onClickCancelAccount">Submit to delete</button>
            
      </f7-card-content>
    </f7-card>

    

  </template>

  <div class="spacer-bottom"></div>

</f7-page>
</template>

<script>


import $$ from 'dom7'

import ShopHeroApi from '../api/ShopHeroApi';

export default {

  data() {
    return {
      infoLoaded: false,
      info: {},
      cancelVerificationText: "I AGREE TO DELETE",
      canceVerificationInput: null,
      cancelAccountMessage: null,
    }
  },

  computed: {
    allLocations: ({ $store }) => $store.getters.allLocations,
    selectedLocation: ({ $store }) => $store.getters.selectedLocation,
    hasLaunchNavigator: ({ launchnavigator }) => (typeof launchnavigator !== 'undefined'),
    userIsAuthenticated: ({ $store }) => $store.getters.userIsAuthenticated,
  },

  methods: {
    navigateToLocation() {
      launchnavigator.navigate(this.selectedLocation.physical_location_formatted)
    },
    openExternalLink(href) {
      if(typeof cordova !== 'undefined') {
        cordova.InAppBrowser.open(href, '_blank', 'location=yes,usewkwebview=yes');
      } else {
        window.open(href, '_system')
      }
    },

    onClickCancelAccount() {
      const self = this
      if(self.canceVerificationInput == self.cancelVerificationText) {
        self.$f7.dialog.confirm('Click "OK" to delete your user account permanently. This action can not be undone. Your account can not be recovered.', 'CONFIRM ACCOUNT DELETION', function () {
      
          ShopHeroApi.cancelUser(
            response => {
              if (response.success === true) {
                self.cancelAccountMessage = "Account deleted - you have been logged out."
                self.$store.dispatch("userSignedOut");
              }
              else {
                self.cancelAccountMessage = response.cancel_account_error_message
              }
            },
            error => {
            self.$f7.dialog.alert("Unknown error - please try again", "System Error")
            }
          );
        });
      } else {  
        self.$f7.dialog.alert('You must type the key phrase exactly as it appears to confirm that you want to delete your account.', 'Key Phrase Error');
      }
    }

  },

  mounted() {
    this.$root.setActiveToolbar('none')

    this.$root.setNavbarTitle(this.selectedLocation.physical_location_name)
    this.$root.setNavbarSubtitle("Hours and Location")

    const mapCanvas = $$('#map-canvas')[0]

    const onMapInit = function(map) {
      map.addMarker({
        'position': this.selectedLocation.physical_location_formatted,
        'title': this.selectedLocation.physical_location_name,
     });
    }

    if(typeof plugin !== 'undefined') {
      if(typeof plugin.google !== 'undefined') {
        const map = plugin.google.maps.Map.getMap(mapCanvas,
          {
            'center': this.selectedLocation.physical_location_formatted,
          }
        );

        map.one(plugin.google.maps.event.MAP_READY, onMapInit);

         // const mapDiv = document.getElementById('map-canvas');
         //  const map = new google.maps.Map(mapDiv, {
         //      center: {lat:  40.274997, lng: -111.652189 } ,
         //      zoom: 8
         //  });

       }
     }

     const self = this
    ShopHeroApi.getStoreInfo(this.selectedLocation.physical_location_id, (response) => {
      // console.log(response)
          self.info = response
          self.infoLoaded = true
   })


  },

}

</script>

<style lang="scss" scoped="true">

a {
  color: $storePrimaryColor !important;
}

.spacer {
  width: 100%;
  height: var(--f7-page-spacer-top);
}

.spacer-bottom {
  width: 100%;
  height: 80px;
}

.col-right {
  text-align: right;
}

.info-loading {
  margin-top: 40px;
  text-align: center;
  height: 200px;
  width: 100%;
  position: relative;
  .preloader {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }
}

.img-logo {
  margin-top: 0px;
  margin-bottom: 10px;
  max-width: 180px;
}


.fade-in-enter-active {
  transition: opacity .5s;
}

.fade-in-leave-active {
  //transition: opacity .5s;
  opacity: 0;
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-leave-to {
  opacity: 0;
}

.input-cancel-account {
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 10px 5px;
  margin: 10px 5px;
}

.button-cancel-account {
  border-radius: 5px;
  background: $colorThemeA;
  color: white;
  font-weight: $bold;
  height: 50px;
  margin: 5px 0;
}

</style>
