<template>
  <f7-page login-screen no-navbar no-toolbar no-padding data-name="link-account">
    <div class="loyalty-container">
      <f7-link back class="link-back">
        <svg
          width="15"
          height="25"
          viewBox="0 0 19 30"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>back</title>
          <g id="Canvas" transform="translate(-408 -1335)">
            <g id="back">
              <use xlink:href="#back_path" transform="translate(408 1335)" fill="#000000" />
            </g>
          </g>
          <defs>
            <path
              id="back_path"
              d="M 6.99247 15L 17.7253 4.04252C 18.2039 3.55422 18.199 2.77294 17.7156 2.28464L 15.7965 0.365618C 15.3034 -0.122683 14.5123 -0.122683 14.024 0.3705L 0.366226 14.1162C 0.122075 14.3603 0 14.6777 0 15C 0 15.3223 0.122075 15.6397 0.366226 15.8838L 14.024 29.6295C 14.5123 30.1227 15.3034 30.1227 15.7965 29.6344L 17.7156 27.7154C 18.199 27.2271 18.2039 26.4458 17.7253 25.9575L 6.99247 15Z"
            />
          </defs>
        </svg>
      </f7-link>

      <div class="content-wrapper-link-account">
        <div class="logo-wrapper">
          <img src="../images/store-assets/store-logo.png" />
        </div>

        <template v-if="userAccountIsLinked">
          <div class="welcome-wrapper">
            <span class="welcome-top">Welcome back!</span>
            <br />
            Your account is already linked to your {{loyaltyProgramName}} profile.
            <br />
            <br />
            <f7-link href="/">
              <button class="button-small">Return Home</button>
            </f7-link>
          </div>
        </template>
        <template v-else>
          <template v-if="displayMode == 'link-account'">
            <div class="welcome-wrapper">
              <span class="welcome-top">Register for {{loyaltyProgramName}}</span>
              <p>In order to register for {{loyaltyProgramName}} or to link your existing {{loyaltyProgramName}} account with your online account, we need to verify your phone number.</p>
              <p>You will receive an automated text message or phone call with a verification code that you must provide on the next screen in order to successfully link your account.</p>
            </div>

            <transition name="fade-in">
              <div class="error-banner-wrapper" v-show="authErrorMessage">
                <p>{{ authErrorMessage }}</p>
              </div>
            </transition>
            <f7-list
              form
              no-hairlines
              no-hairlines-between
              class="form-style"
              id="form-link-account"
              @submit="onClickSendAccountPin"
            >
              <f7-list-item @click="focusInput('#input-link-phone-number')">
                <f7-input
                  type="tel"
                  placeholder="Enter your phone number"
                  input-id="input-link-phone-number"
                  autocomplete="off"
                  tabindex="1"
                  :value="inputPhoneNumber"
                  @input="inputPhoneNumber = $event.target.value"
                ></f7-input>
              </f7-list-item>
              <f7-list-item>
                <f7-input
                  type="select"
                  input-id="input-link-verify-method"
                  autocomplete="off"
                  tabindex="2"
                  :value="inputVerifyMethod"
                  @input="inputVerifyMethod = $event.target.value"
                >
                  <option value="text">Receive Text Message</option>
                  <option value="voice">Receive Automated Voice Message</option>
                </f7-input>
              </f7-list-item>
            </f7-list>

            <div class="button-wrapper">
              <div class="sign-in-button-wrapper">
                <button
                  @click="onClickSendAccountPin"
                  id="sign-in-button"
                  class="button-normal button-with-icon light-color active-gradient dark-gradient-hover sign-in-button"
                  tabindex="3"
                  type="submit"
                >
                  <template v-if="!actionWait">Verify Phone Number</template>
                  <template v-if="actionWait">
                    <img src="../images/ajax-loader.gif" class="button-ajax-loader" />
                  </template>
                  <span>&gt;</span>
                </button>
              </div>
            </div>
          </template>
          <template v-if="displayMode == 'pin-entry'">
            <div class="welcome-wrapper">
              <span class="welcome-top">Register for {{loyaltyProgramName}}</span>
              <p>In order to register for {{loyaltyProgramName}} or to link your existing {{loyaltyProgramName}} account with your online account, we need to verify your phone number.</p>
              <p>Please enter the PIN code you've received in order to complete linking your account.</p>
            </div>

            <transition name="fade-in">
              <div class="success-banner-wrapper" v-show="authSuccessMessage">
                <p>{{ authSuccessMessage }}</p>
              </div>
            </transition>

            <transition name="fade-in">
              <div class="error-banner-wrapper" v-show="authErrorMessage">
                <p>{{ authErrorMessage }}</p>
              </div>
            </transition>
            <f7-list
              form
              no-hairlines
              no-hairlines-between
              class="form-style"
              id="form-register-pin"
              @submit="onClickRegisterAccountPin"
            >
              <f7-list-item @click="focusInput('#input-link-pin-entry')">
                <f7-input
                  type="number"
                  placeholder="Enter your verification PIN"
                  input-id="input-link-pin-entry"
                  autocomplete="off"
                  tabindex="1"
                  :value="inputVerifyPin"
                  @input="inputVerifyPin = $event.target.value"
                ></f7-input>
              </f7-list-item>
            </f7-list>

            <div class="button-wrapper">
              <div class="sign-in-button-wrapper">
                <button
                  @click="onClickRegisterAccountPin"
                  id="sign-in-button"
                  class="button-normal button-with-icon light-color active-gradient dark-gradient-hover sign-in-button"
                  tabindex="3"
                  type="submit"
                >
                  <template v-if="!actionWait">Link Account</template>
                  <template v-if="actionWait">
                    <img src="../images/ajax-loader.gif" class="button-ajax-loader" />
                  </template>
                  <span>&gt;</span>
                </button>
              </div>
            </div>
          </template>

          <template v-if="displayMode == 'success'">
            <div class="welcome-wrapper">
              <span class="welcome-top">Register for {{loyaltyProgramName}}</span>
            </div>

            <br />
            <br />
            <br />

            <transition name="fade-in">
              <div class="success-banner-wrapper" v-show="authSuccessMessage">
                <p>{{ authSuccessMessage }}</p>
              </div>
            </transition>

            <br />
            <br />
            <br />

            <div class="button-wrapper">
              <div class="sign-in-button-wrapper">
                <button
                  @click="redirectOnSuccess"
                  id="sign-in-button"
                  class="button-normal button-with-icon light-color active-gradient dark-gradient-hover sign-in-button"
                  tabindex="3"
                  type="submit"
                >
                  <template v-if="!actionWait">Continue</template>
                  <template v-if="actionWait">
                    <img src="../images/ajax-loader.gif" class="button-ajax-loader" />
                  </template>
                  <span>&gt;</span>
                </button>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div class="spacer-bottom"></div>
    </div>
  </f7-page>
</template>

<script>
import $$ from "dom7";

import { parsePhoneNumberFromString } from "libphonenumber-js";

import ShopHeroApi from "../api/ShopHeroApi";

export default {
  data() {
    return {
      displayMode: "link-account",
      verifiedPhone: null,
      actionWait: false,
      authSuccessMessage: null,
      authErrorMessage: null,

      inputPhoneNumber: null,
      inputVerifyMethod: "text",
      inputVerifyPin: null
    };
  },

  props: ["nextPage"],

  computed: {
    selectedLocation: ({ $store }) => $store.getters.selectedLocation,
    loyaltyProgramName: ({ selectedLocation }) =>
      selectedLocation.physical_location_loyalty_program_name,
    userIsAuthenticated: ({ $store }) => $store.getters.userIsAuthenticated,
    userAccountIsLinked: ({ $store }) => false
  },

  methods: {
    focusInput(element) {
      this.$nextTick(() => {
        const field = $$(element);
        field.focus();
      });
    },

    onFormLinkAccountKeyUp(e) {
      if (e.keyCode == 13) this.onClickSendAccountPin();
    },

    onFormRegisterPinKeyUp(e) {
      if (e.keyCode == 13) this.onClickRegisterAccountPin();
    },

    onClickSendAccountPin(e) {
      if (e) e.preventDefault();

      let phone = this.inputPhoneNumber;
      if (phone) phone = phone.replace(/\s/g, "");

      let method = this.inputVerifyMethod;

      let phoneIsValid = false;

      const phoneNumber = parsePhoneNumberFromString(phone, "US");
      if (phoneNumber) {
        if (phoneNumber.isValid() && phoneNumber.countryCallingCode == "1") {
          phoneIsValid = true;
          phone = phoneNumber.number;
        }
      }

      if (phoneIsValid == false) {
        this.authErrorMessage =
          "The phone number entered does not appear to be valid.";
        return;
      }

      this.actionWait = true;

      this.authErrorMessage = null;

      const self = this;

      ShopHeroApi.doLoyaltyAppCardRequestPin(
        self.selectedLocation.physical_location_id,
        phone,
        method,
        response => {
          console.log("callBackSuccess");
          console.log(response);

          self.actionWait = false;

          if (response.success !== true) {
            self.authErrorMessage = "An unexpected error has occurred.";
            return;
          }

          self.verifiedPhone = phone;
          self.authSuccessMessage =
            "You should receive your verification PIN code shortly.";
          self.displayMode = "pin-entry";
          self.focusInput("#input-link-pin-entry");
        },
        response => {
          this.actionWait = false;

          console.log("callBackError");
          console.log(response);

          this.authErrorMessage = "An unexpected error has occurred.";
          return;
        }
      );
    },

    onClickRegisterAccountPin(e) {
      if (e) e.preventDefault();

      let pin = this.inputVerifyPin;

      this.authSuccessMessage = null;

      if (!this.verifiedPhone) {
        this.authErrorMessage =
          "The phone number entered does not appear to be valid.";
        return;
      }

      this.actionWait = true;

      this.authErrorMessage = null;

      const self = this;

      ShopHeroApi.doLoyaltyAppCardRegisterPin(
        self.selectedLocation.physical_location_id,
        this.verifiedPhone,
        pin,
        response => {
          console.log("callBackSuccess");
          console.log(response);

          self.actionWait = false;

          if (response.success === false) {
            self.authErrorMessage = "An unexpected error has occurred.";
            return;
          }
          self.$store.dispatch("setUserLoyaltyToken", {
            userLoyaltyToken: true
          });

          self.$store.dispatch("resetLoyaltyState").then(success => {
            self.$store
              .dispatch("populateLoyaltyMembership", {
                physicalLocationId: self.selectedLocation.physical_location_id
              })
              .then(() => {
                this.$store
                  .dispatch("populateLoyaltyOffers", {
                    physicalLocationId:
                      self.selectedLocation.physical_location_id
                  })
                  .then(() => {
                    console.log("loyaltyRePopulated");
                  });
              });
          });

          self.authSuccessMessage = `Your ${self.loyaltyProgramName} account has been successfully registered and/or linked to your online account!`;
          self.displayMode = "success";
        },
        response => {
          this.actionWait = false;

          console.log("callBackError");
          console.log(response);

          this.authErrorMessage = "An unexpected error has occurred.";
          return;
        }
      );
    },

    redirectOnSuccess() {
      if (this.nextPage) {
        if (this.nextPage == "back") {
          if (this.$f7.views.main.router.history.length > 1) {
            this.$f7.views.main.router.back();
          } else {
            this.$f7.views.main.router.navigate(`/home/`);
          }
        } else {
          this.$f7.views.main.router.navigate(`/${this.nextPage}/`);
        }
      }
    }
  },

  mounted() {
    if (this.userIsAuthenticated == false) {
      this.$f7.views.main.router.navigate(
        `/auth-sign-in/loyalty-app-card-link-account/`
      );
    }

    $$("#form-link-account")
      .find("input")
      .keyup(this.onFormLinkAccountKeyUp);

    $$("#form-register-pin")
      .find("input")
      .keyup(this.onFormRegisterPinKeyUp);

    if (this.displayMode == "link-account") {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.focusInput("#input-link-phone-number");
        });
      });
    }
  }
};
</script>

<style lang="scss">
.no-padding {
  /deep/ .page-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.loyalty-container {
  height: 100%;
  padding-top: 50px;
  margin-left: 0px;
  margin-right: 0px;
  //padding-left: 20px;
  //padding-right: 20px;
  padding-bottom: 50px;
  background-image: url(../images/search/search-background2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% -50px;

  //background-size: contain;
  //background-position: 50% 85%;
}

.content-wrapper-link-account {
  //   margin-top: calc(160px + var(--sat));
  //  margin-bottom: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.login-screen-content {
  // position: relative !important;
  height: 100% !important;
}

.link-back {
  top: calc(20px + var(--sat));
  left: 20px;
  position: absolute;
  margin: 0px;
}

.logo-wrapper {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 20px;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 100px;
  }
}

.welcome-wrapper {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  .welcome-top {
    font-weight: 600;
    font-size: 16px;
  }
}

.error-banner-wrapper {
  width: 100%;
  p {
    font-weight: $bold;
    font-size: 12px;
    color: #fff;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-radius: 5px;
    background: darkred;
    text-align: center;
  }
}

.success-banner-wrapper {
  width: 100%;
  p {
    font-weight: $bold;
    font-size: 12px;
    color: #fff;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-radius: 5px;
    background: $accentColor;
    text-align: center;
  }
}

.form-style {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  /deep/ ul {
    background-color: transparent !important;
  }
  .input {
    width: 100%;

    input {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #eee;
      margin-top: 2px;
      margin-bottom: 10px;
      padding-left: 10px;
    }
    input:focus-within {
      border: 2px solid $colorThemeB !important;
    }

    select {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #eee;
      margin-top: 2px;
      margin-bottom: 10px;
      padding-left: 10px;
    }
    select:focus-within {
      border: 2px solid $colorThemeB !important;
    }
  }
}

.button-wrapper {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.text-create-account {
  font-size: 1.3em !important;
  margin-top: 20px;
  text-align: center;
  a {
    font-weight: $bold;
  }
}

.text-forgot-password {
  font-size: 1.3em !important;
  margin-top: 10px;
  text-align: center;
  a {
    font-weight: $bold;
  }
}

.sign-in-button {
  height: 40px;
  span {
    top: 4px !important;
    // line-height: 34px !important;
    right: 6px !important;
    height: 36px !important;
  }
}

.button-ajax-loader {
  width: 20px;
  height: 20px;
  margin-top: 3px;
}

// .sign-in-button:focus {
//   border: 2px solid $colorThemeB !important;
//   span {
//     line-height: 30px;
//   }
// }

.sign-in-button-wrapper {
  display: block;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
}

.facebook-button-wrapper {
  display: block;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
}

.google-button-wrapper {
  display: block;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
}

.sign-in-or-bar {
  margin-top: 15px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  hr {
    margin-top: 6px;
  }
}

.fade-in-enter-active {
  transition: opacity 0.5s;
}

.fade-in-leave-active {
  //transition: opacity .5s;
  opacity: 0;
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-leave-to {
  opacity: 0;
}

.link {
  color: $storePrimaryColor;
}

.spacer-bottom {
  height: 150px;
}
</style>
