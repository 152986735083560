<template>
  <f7-navbar no-shadow hidden hide-on-page-scroll class="sh-navbar">
    <f7-row class="navbar-top">
      <f7-col width="20" class="column left">
        <f7-link @click="routeBack" class="link-route-back">
          <svg
            width="15"
            height="25"
            viewBox="0 0 19 30"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>back</title>
            <g id="Canvas" transform="translate(-408 -1335)">
              <g id="back">
                <use xlink:href="#back_path" transform="translate(408 1335)" fill="#FFFFFF" />
              </g>
            </g>
            <defs>
              <path
                id="back_path"
                d="M 6.99247 15L 17.7253 4.04252C 18.2039 3.55422 18.199 2.77294 17.7156 2.28464L 15.7965 0.365618C 15.3034 -0.122683 14.5123 -0.122683 14.024 0.3705L 0.366226 14.1162C 0.122075 14.3603 0 14.6777 0 15C 0 15.3223 0.122075 15.6397 0.366226 15.8838L 14.024 29.6295C 14.5123 30.1227 15.3034 30.1227 15.7965 29.6344L 17.7156 27.7154C 18.199 27.2271 18.2039 26.4458 17.7253 25.9575L 6.99247 15Z"
              />
            </defs>
          </svg>
        </f7-link>
      </f7-col>
      <f7-col width="60" class="column center">
        <transition name="fade" mode="out-in">
          <h3 :key="title">
            <span v-html="title"></span>
          </h3>
        </transition>
        <transition name="fade" mode="out-in">
          <p :key="subtitle">
            <span v-html="subtitle"></span>
          </p>
        </transition>
      </f7-col>
      <f7-col width="20" class="column right" mode="out-in">
        <sh-cart-icon></sh-cart-icon>
      </f7-col>
    </f7-row>
    <!-- hero-search bar -->
    <div class="hero-search-bar">
      <f7-row no-gutter>
        <f7-col width="100" class="column search-wrapper">
          <div class="search-container">
            <f7-row class="search-elements-container" no-gutter>
              <template v-if="searchMode == 'PRODUCTS'">
                <f7-col
                  width="30"
                  class="column category-search-section category-search-section-products"
                >
                  <div class="categorySearchWrapper" @click="toRecipes">PRODUCTS&nbsp;</div>
                </f7-col>
              </template>
              <template v-if="searchMode == 'RECIPES'">
                <f7-col
                  width="30"
                  class="column category-search-section category-search-section-recipes"
                >
                  <div class="categorySearchWrapper" @click="toProducts">RECIPES&nbsp;</div>
                </f7-col>
              </template>
              <f7-col width="10" class="column search-icon-wrapper">
                <div class="search-icon-clickable" @click="onSearchFocus">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>search</title>
                    <g id="Canvas" transform="translate(-469 -1284)">
                      <g id="search">
                        <use
                          xlink:href="#search_path"
                          transform="translate(469 1284)"
                          fill="#D9D9D9"
                        />
                      </g>
                    </g>
                    <defs>
                      <path
                        id="search_path"
                        d="M 9.39381 10.531L 11.1508 12.288C 10.8824 12.7999 10.8548 13.309 11.137 13.5913L 14.3439 16.7982C 14.7479 17.2021 15.6278 16.9808 16.3029 16.3029C 16.9808 15.625 17.2021 14.7479 16.7982 14.3439L 13.594 11.137C 13.309 10.8548 12.7999 10.8824 12.288 11.1481L 10.531 9.39381L 9.39381 10.531ZM 6.02089 0C 2.69501 0 0 2.69501 0 6.02089C 0 9.34677 2.69501 12.0418 6.02089 12.0418C 9.34677 12.0418 12.0418 9.34677 12.0418 6.02089C 12.0418 2.69501 9.34677 0 6.02089 0ZM 6.02089 10.6251C 3.47806 10.6251 1.41668 8.56372 1.41668 6.02089C 1.41668 3.47806 3.47806 1.41668 6.02089 1.41668C 8.56372 1.41668 10.6251 3.47806 10.6251 6.02089C 10.6251 8.56372 8.56372 10.6251 6.02089 10.6251Z"
                      />
                    </defs>
                  </svg>
                </div>
              </f7-col>
              <f7-col width="50" class="column input-wrapper">
                <div class="search-input-clickable" @click="onSearchFocus">
                  <form @submit="onSearchSubmit">
                    <input
                      class="inputSearch"
                      ref="search"
                      type="text"
                      name
                      id="input-search"
                      v-bind:placeholder="searchPlaceholder"
                      v-bind:value="searchText"
                      v-focus="focused"
                      @click="onSearchFocus"
                      @blur="onSearchBlur"
                      @keydown="onSearchKeyDown"
                    />
                  </form>
                </div>
              </f7-col>
              <!--
            <f7-col width='10'
                    class="column search-icon-wrapper">
              <svg style="display:none;" width="12"
                   height="17"
                   viewBox="0 0 12 17"
                   version="1.1"
                   xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink"
                   @click="captureSearchAudio">
                <title>mic</title>
                <g id="Canvas"
                   transform="translate(-420 -1255)">
                  <g id="mic">
                    <use xlink:href="#mic_path"
                         transform="translate(420 1255)"
                         fill="#474646" />
                  </g>
                </g>
                <defs>
                  <path id="mic_path"
                        d="M 5.88462 0C 4.07888 0 2.61538 1.46349 2.61538 3.26923L 2.61538 8.5C 2.61538 10.3057 4.07888 11.7692 5.88462 11.7692C 5.997 11.7692 6.10171 11.759 6.21154 11.7488C 7.19997 11.6492 8.07347 11.1001 8.60216 10.3185C 8.95463 9.79748 9.15385 9.17683 9.15385 8.5L 9.15385 3.26923C 9.15385 1.46349 7.69035 0 5.88462 0ZM 5.88462 1.30769C 6.73513 1.30769 7.45027 1.85938 7.72356 2.61538L 7.19231 2.61538C 6.83218 2.61538 6.53846 2.9091 6.53846 3.26923C 6.53846 3.90009 7.19231 3.92308 7.19231 3.92308L 7.84615 3.92308L 7.84615 4.57692L 7.19231 4.57692C 6.83218 4.57692 6.53846 4.87064 6.53846 5.23077C 6.53846 5.86163 7.19231 5.88462 7.19231 5.88462L 7.84615 5.88462L 7.84615 6.53846L 3.92308 6.53846L 3.92308 5.88462L 4.57692 5.88462C 4.57692 5.88462 5.23077 5.86163 5.23077 5.23077C 5.23077 4.87064 4.93705 4.57692 4.57692 4.57692L 3.92308 4.57692L 3.92308 3.92308L 4.57692 3.92308C 4.57692 3.92308 5.23077 3.90009 5.23077 3.26923C 5.23077 2.9091 4.93705 2.61538 4.57692 2.61538L 4.04567 2.61538C 4.31896 1.85938 5.0341 1.30769 5.88462 1.30769ZM 0.653846 7.19231C 0.0715144 7.19231 0 7.84615 0 7.84615L 0 8.5C 0 11.2942 1.95898 13.6235 4.57692 14.2212L 4.57692 15.6923L 2.61538 15.6923C 1.98708 15.6923 1.96154 16.3462 1.96154 16.3462L 1.96154 17L 9.80769 17L 9.80769 16.3462C 9.80769 15.986 9.51397 15.6923 9.15385 15.6923L 7.19231 15.6923L 7.19231 14.2212C 9.81025 13.6235 11.7692 11.2942 11.7692 8.5L 11.7692 7.84615C 11.7692 7.84615 11.7079 7.19231 11.1154 7.19231C 10.5228 7.19231 10.4615 7.84615 10.4615 7.84615L 10.4615 8.5C 10.4615 11.0234 8.40805 13.0769 5.88462 13.0769C 3.36118 13.0769 1.30769 11.0234 1.30769 8.5L 1.30769 7.84615C 1.30769 7.84615 1.23618 7.19231 0.653846 7.19231Z" />
                </defs>
              </svg>
            </f7-col>
            <f7-col width='10'
                    class="column search-icon-wrapper">
                <svg
                     style="display:none;"
                     width="22"
                     height="17"
                     viewBox="0 0 22 17"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>camera</title>
                  <g id="Canvas"
                     transform="translate(-442 -1255)">
                    <g id="camera">
                      <use xlink:href="#cam_path"
                           transform="translate(442 1255)"
                           fill="#474646" />
                    </g>
                  </g>
                  <defs>
                    <path id="cam_path"
                          d="M 10.3594 0C 9.5957 0 8.79717 0.604297 8.47344 1.43437L 8.20781 2.125L 5.95 2.125L 5.95 1.7C 5.95 1.46426 5.76074 1.275 5.525 1.275L 2.55 1.275C 2.31426 1.275 2.125 1.46426 2.125 1.7L 2.125 2.24453C 0.874902 2.61309 0 3.76689 0 5.1L 0 14.025C 0 15.6652 1.33477 17 2.975 17L 18.275 17C 19.9152 17 21.25 15.6652 21.25 14.025L 21.25 5.1C 21.25 3.45977 19.9152 2.125 18.275 2.125L 17.2922 2.125L 17.0266 1.43437C 16.7028 0.604297 15.9043 0 15.1406 0L 10.3594 0ZM 12.75 3.825C 15.7964 3.825 18.275 6.30361 18.275 9.35C 18.275 12.3964 15.7964 14.875 12.75 14.875C 9.70361 14.875 7.225 12.3964 7.225 9.35C 7.225 6.30361 9.70361 3.825 12.75 3.825ZM 2.975 4.25C 3.44482 4.25 3.825 4.63018 3.825 5.1C 3.825 5.56982 3.44482 5.95 2.975 5.95C 2.50518 5.95 2.125 5.56982 2.125 5.1C 2.125 4.63018 2.50518 4.25 2.975 4.25ZM 12.75 4.675C 10.1718 4.675 8.075 6.77178 8.075 9.35C 8.075 11.9282 10.1718 14.025 12.75 14.025C 15.3282 14.025 17.425 11.9282 17.425 9.35C 17.425 6.77178 15.3282 4.675 12.75 4.675Z" />
                  </defs>
                </svg>
            </f7-col>
              -->
              <f7-col width="10" class="column search-icon-wrapper">
                <f7-link href="#" @click="scanProductBarcode">
                  <svg
                    width="25"
                    height="17"
                    viewBox="0 0 25 17"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>barcode</title>
                    <g id="Canvas" transform="translate(-482 -1340)">
                      <rect x="482" y="1340" width="25" height="17" fill="#E5E5E5" />
                      <g id="barcode">
                        <use
                          xlink:href="#barcode_path"
                          transform="translate(482 1340)"
                          fill="#474646"
                        />
                      </g>
                    </g>
                    <defs>
                      <path
                        id="barcode_path"
                        fill-rule="evenodd"
                        d="M 0.669525 3.73999L 0.669525 0.679932L 4.01712 0.679932L 4.01712 0L 0.334747 0L 0 0L 0 0.339966L 0 3.73999L 0.669525 3.73999ZM 23.4332 0.680054L 20.4204 0.680054L 20.4204 0L 23.768 0L 24.1028 0L 24.1028 0.339966L 24.1028 4.07996L 23.4332 4.07996L 23.4332 0.680054ZM 1.6738 1.70007L 1.6738 15.3L 2.34332 15.3L 2.34332 1.70007L 1.6738 1.70007ZM 21.7594 1.70007L 21.7594 15.3L 22.429 15.3L 22.429 1.70007L 21.7594 1.70007ZM 3.01285 15.3L 3.01285 1.70007L 4.3519 1.70007L 4.3519 15.3L 3.01285 15.3ZM 5.02139 1.70007L 5.02139 15.3L 6.36044 15.3L 6.36044 1.70007L 5.02139 1.70007ZM 7.02997 15.3L 7.02997 1.70007L 7.69949 1.70007L 7.69949 15.3L 7.02997 15.3ZM 8.36899 1.70007L 8.36899 15.3L 10.3776 15.3L 10.3776 1.70007L 8.36899 1.70007ZM 11.0471 15.3L 11.0471 1.70007L 12.3861 1.70007L 12.3861 15.3L 11.0471 15.3ZM 13.0557 1.70007L 13.0557 15.3L 15.7337 15.3L 15.7337 1.70007L 13.0557 1.70007ZM 16.4033 15.3L 16.4033 1.70007L 19.0813 1.70007L 19.0813 15.3L 16.4033 15.3ZM 19.7509 1.70007L 19.7509 15.3L 21.0899 15.3L 21.0899 1.70007L 19.7509 1.70007ZM 23.768 13.26L 23.768 16.3201L 20.4204 16.3201L 20.4204 17L 24.1028 17L 24.4375 17L 24.4375 16.66L 24.4375 13.26L 23.768 13.26ZM 1.00427 16.3199L 4.01712 16.3199L 4.01712 17L 0.669525 17L 0.334747 17L 0.334747 16.66L 0.334747 12.92L 1.00427 12.92L 1.00427 16.3199Z"
                      />
                    </defs>
                  </svg>
                </f7-link>
              </f7-col>
            </f7-row>
          </div>
        </f7-col>
      </f7-row>
    </div>
    <!-- ends hero search bar -->
  </f7-navbar>
</template>

<script>
import $$ from "dom7";
import ShCartIcon from "./ShShopCartIcon.vue";
import { mixin as focusMixin } from "vue-focus";
import ShopHeroApi from "../api/ShopHeroApi";

export default {
  components: {
    ShCartIcon
  },
  data() {
    return {};
  },
  mixins: [focusMixin],
  computed: {
    title: ({ $store }) => $store.getters.title,
    subtitle: ({ $store }) => $store.getters.subtitle,
    focused: ({ $store }) => $store.getters.searchFocusState,
    searchPlaceholder: ({ $store }) => $store.getters.searchInputPlaceholder,
    searchText: ({ $store }) => $store.getters.searchInputText,
    searchMode: ({ $store }) => $store.getters.searchMode
  },
  methods: {
    onRouteChanged(newRoute, previousRoute, router) {
      // console.log("shNavBar-fonRouteChanged: " + newRoute.url, newRoute);
      if (newRoute.url == "/home/") {
        this.$f7.navbar.hide(".navbar");
      } else if (newRoute.url == "/splash/") {
        this.$f7.navbar.hide(".navbar");
      } else if (newRoute.url == "/location-select/") {
        this.$f7.navbar.hide(".navbar");
      } else if (newRoute.url.includes("/auth-")) {
        this.$f7.navbar.hide(".navbar");
      } else if (newRoute.url.includes("/checkout-")) {
        this.$f7.navbar.hide(".navbar");
      } else if (newRoute.url.includes("/loyalty-app-card-link-account/")) {
        this.$f7.navbar.hide(".navbar");
      } else {
        this.$f7.navbar.show(".navbar");
      }
    },
    toRecipes() {
      if(this.$store.getters.options.hideGrocery) return;
      this.$f7.views.main.router.navigate("/search/recipes/");
      console.log("Ready");
    },
    toProducts() {
      this.$f7.views.main.router.navigate("/search/products/");
      console.log("Ready");
    },
    onSearchFocus() {
      this.$store.dispatch("setSearchFocus", {
        focused: true
      });
      this.redirectToSearch();
    },
    onSearchBlur() {
      this.$store.dispatch("setSearchFocus", {
        focused: false
      });
    },
    onSearchKeyDown(event) {
      if (event.key === "Enter") {
        //   this.redirectToSearch(event.target.value)
      }
    },
    onSearchSubmit(event) {
      event.preventDefault();
      const query = event.target.querySelector("#input-search").value;
      this.redirectToSearch(query);
    },
    redirectToSearch(query) {
      let path = "/home/";

      if (this.searchMode == "PRODUCTS") {
        path = "/search/products/";
        if (query) {
          path = `/search/products-query/${query}/`;
        }
      } else {
        path = "/search/recipes/";
        if (query) {
          path = `/search/recipes-query/${query}/`;
        }
      }

      const router = this.$f7.views.main.router;
      console.log(
        "redirectToSearch: " + router.currentRoute.url + " => " + path
      );

      if (router.currentRoute.url !== path) {
        router.navigate(path);
      }
    },
    routeBack() {
      // don't allow back button to go back to review (we just checked out!) - order-placed is for orders with Choice Doorstep items and ins-tore items
      if (this.$f7router.previousRoute.url.includes("/checkout-review/") || this.$f7router.currentRoute.url.includes("/order-placed/")) {
        this.$f7router.navigate("/home/");
        return;
      }

      this.$f7router.back();
    },
    scanProductBarcode() {
      this.$store.dispatch("scanBarcode").then(
        productKey => {
          this.$f7.views.main.router.navigate(`/product/${productKey}/`);
        },
        errorMessage => {
          alert(errorMessage);
        }
      );
    },
    captureSearchAudio() {
      if (navigator.device.capture) {
        const captureSuccess = function(mediaFiles) {
          for (let i = 0, len = mediaFiles.length; i < len; i += 1) {
            const path = mediaFiles[i].fullPath;
            // do something interesting with the file
            navigator.notification.alert(path);
          }
        };

        const captureError = function(error) {
          navigator.notification.alert(
            `Error code: ${error.code}`,
            null,
            "Capture Error"
          );
        };

        navigator.device.capture.captureAudio(captureSuccess, captureError, {
          limit: 1,
          duration: 5
        });
      } else {
        console.log("no capture device available");
      }
    }
  },

  mounted() {
    // console.log("ShNavBar Mounted");
    const self = this;
    this.$f7ready($f7 => {
      $f7.router.app.on("routeChange", function(
        newRoute,
        previousRoute,
        router
      ) {
        self.onRouteChanged(newRoute, previousRoute, router);
      });
    });
  }
};
</script>

<style lang="scss" scoped="">
.navbar {
  font-size: 1em;
  -webkit-transform: translate3d(0, 0, 0); // to keep ios from glitching
  transform: translate3d(0, 0, 0);
}

.navbar-hidden {
  -webkit-transform: translate3d(0, -200%, 0);
  transform: translate3d(0, -200%, 0);
}

.toolbar {
  -webkit-transform: translate3d(0, 0, 0); // to keep ios from glitching
  transform: translate3d(0, 0, 0);
}

.toolbar-hidden {
  -webkit-transform: translate3d(0, -200%, 0);
  transform: translate3d(0, -200%, 0);
}

.navbar::after {
  background: transparent;
}

.sh-navbar {
  height: calc(110px + var(--sat));
  background: transparent;
  .navbar-top {
    -webkit-box-shadow: -1px 2px 32px -2px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: -1px 2px 32px -2px rgba(0, 0, 0, 0.8);
    box-shadow: -1px 2px 32px -2px rgba(0, 0, 0, 0.8);
    width: 100%;
    height: calc(60px + var(--sat));
    padding-top: var(--sat);
    background: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0)),
      url("../images/store-assets/store-hero-back.jpg") center center no-repeat;
    background-size: cover;
    letter-spacing: 0;
    .left,
    .right {
      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      margin: 0;
    }
    .column {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .center {
      margin-top: 2px;
      line-height: 1.5;
      color: white;
      display: flex;
      flex-direction: column;
      h3 {
        font-size: 16px;
        line-height: 120%;
      }
      p {
        font-size: 1em;
        // margin: 0px;
        //max-width: 200px;
        word-wrap: break-word;
        text-overflow: ellipsis;
      }
      span {
        display: block;
        text-align: center;
        @include breakpoint(tablet) {
          font-size: 16px;
        }
      }
    }
  }
  div.hero-search-bar {
    width: 100%;
    height: auto;
    div.search-wrapper {
      height: 50px;
      position: relative;
      div.search-container {
        width: 100%;
        height: 50px;
        background: white;
        -webkit-border-bottom-right-radius: 12px;
        -webkit-border-bottom-left-radius: 12px;
        -moz-border-radius-bottomright: 12px;
        -moz-border-radius-bottomleft: 12px;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        box-shadow: -1px 3px 17px 0px rgba(0, 0, 0, 0.34);
        div.search-elements-container {
          height: 100%;
          div.category-search-section {
            div.categorySearchWrapper {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            height: 100%;
            border-radius: 0 0 0 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-weight: bold;
            p {
              font-size: 9px;
              @include breakpoint(tablet) {
                font-size: 14px;
              }
            }
          }
          div.category-search-section-products {
            background: $storePrimaryColor;
          }
          div.category-search-section-recipes {
            background: $accentColor;
          }
          div.search-icon-clickable {
            width: 100%;
            height: 100%;
            padding-top: 18px;
            padding-left: 10px;
          }
          div.search-input-clickable {
            width: 100%;
            height: 100%;
            padding-top: 20px;
            @include breakpoint(tablet) {
              padding-top: 16px;
            }
          }
        }
        div.search-icon-wrapper {
          width: 10%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .fa {
            color: #474646;
          }
          .fa-search {
            color: #898989;
          }
        }
        .input-wrapper {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          input {
            width: 100%;
            border: none;
            ::placeholder {
              color: lightgray;
            }
            font-size: 11px;
            @include breakpoint(tablet) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
