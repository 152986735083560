<template>
  <f7-page>
    <div class="spacer"></div>

    <div class="order-loading" v-show="!orderLoaded">
      <div class="preloader"></div>
    </div>

    <template v-if="orderLoaded">
      <f7-card v-if="freshOrder" class="card-fresh-order">
        <f7-card-content>
          <h3>Great news!</h3>
          <br />
          Your order has been placed!
        </f7-card-content>
      </f7-card>
    </template>

      <div class="alert" v-if=" orderLoaded && itemsAddedCount>0">
        <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
       Success! {{itemsAddedCount}} Items have been added to the order #{{orderKey.toUpperCase()}}
      </div>

    <f7-card v-if="orderLoaded && !isEa">
      <!-- <f7-card-content> -->

      <div class="timeline timeline-order">
        <div class="timeline-item" v-if="orderDetail.created_at != null">
          <div class="timeline-item-date">
            <strong
              ><small>{{
                formatDate(orderDetail.created_at, "MMM Do")
              }}</small></strong
            ><br /><small>{{
              formatDate(orderDetail.created_at, "h:mm a")
            }}</small>
          </div>
          <div class="timeline-item-divider"></div>
          <div class="timeline-item-content">
            <div class="timeline-item-inner timeline-item-inner-order">
              <div class="timeline-item-subtitle">Order Placed</div>
            </div>
          </div>
        </div>

        <div
          class="timeline-item"
          v-if="orderActivity.first_item_picked_at != null"
        >
          <div class="timeline-item-date">
            <strong
              ><small>{{
                formatDate(orderActivity.first_item_picked_at, "MMM Do")
              }}</small></strong
            ><br /><small>{{
              formatDate(orderActivity.first_item_picked_at, "h:mm a")
            }}</small>
          </div>
          <div class="timeline-item-divider"></div>
          <div class="timeline-item-content">
            <div class="timeline-item-inner timeline-item-inner-order">
              <div class="timeline-item-subtitle">Order Picking Started</div>
            </div>
          </div>
        </div>

        <div class="timeline-item" v-if="orderActivity.checked_out_at != null">
          <div class="timeline-item-date">
            <strong
              ><small>{{
                formatDate(orderActivity.checked_out_at, "MMM Do")
              }}</small></strong
            ><br /><small>{{
              formatDate(orderActivity.checked_out_at, "h:mm a")
            }}</small>
          </div>
          <div class="timeline-item-divider"></div>
          <div class="timeline-item-content">
            <div class="timeline-item-inner timeline-item-inner-order">
              <div
                class="timeline-item-subtitle"
                v-if="orderDetail.order_type == 'delivery'"
              >
                Order Ready for Delivery
              </div>
              <div
                class="timeline-item-subtitle"
                v-if="orderDetail.order_type == 'pickup'"
              >
                Order Ready for Pick Up
              </div>
            </div>
          </div>
        </div>

        <div
          class="timeline-item"
          v-if="orderDetail.order_fulfilled_at != null"
        >
          <div class="timeline-item-date">
            <strong
              ><small>{{
                formatDate(orderDetail.order_fulfilled_at, "MMM Do")
              }}</small></strong
            ><br /><small>{{
              formatDate(orderDetail.order_fulfilled_at, "h:mm a")
            }}</small>
          </div>
          <div class="timeline-item-divider"></div>
          <div class="timeline-item-content">
            <div
              class="timeline-item-inner timeline-item-inner-order timeline-item-inner-order-complete"
            >
              <div
                class="timeline-item-title"
                v-if="orderDetail.order_type == 'delivery'"
              >
                Order Delivered
              </div>
              <div
                class="timeline-item-title"
                v-if="orderDetail.order_type == 'pickup'"
              >
                Order Picked Up
              </div>
            </div>
          </div>
        </div>
        <div
          class="timeline-item"
          v-if="
            orderDetail.order_fulfilled_at == null &&
            orderDetail.order_state !== 'canceled'
          "
        >
          <div
            class="timeline-item-date"
            v-if="orderDetail.order_type == 'delivery'"
          >
            <strong
              ><small>{{
                formatDate(
                  orderDetail.order_scheduled_delivery_time_start,
                  "MMM Do"
                )
              }}</small></strong
            ><br />
            <small>{{
              formatDate(
                orderDetail.order_scheduled_delivery_time_start,
                "h:mm a"
              )
            }}</small
            ><br />
            <small>{{
              formatDate(
                orderDetail.order_scheduled_delivery_time_end,
                "h:mm a"
              )
            }}</small>
          </div>
          <div
            class="timeline-item-date"
            v-if="orderDetail.order_type == 'pickup'"
          >
            <strong
              ><small>{{
                formatDate(
                  orderDetail.order_scheduled_delivery_time_start,
                  "MMM Do"
                )
              }}</small></strong
            ><br />
            <small>{{
              formatDate(
                orderDetail.order_scheduled_delivery_time_end,
                "h:mm a"
              )
            }}</small>
          </div>
          <div class="timeline-item-divider"></div>
          <div class="timeline-item-content">
            <div class="timeline-item-inner timeline-item-inner-order">
              <div
                class="timeline-item-subtitle timeline-item-subtitle-disabled"
                v-if="orderDetail.order_type == 'delivery'"
              >
                Delivery (Scheduled)
              </div>
              <div
                class="timeline-item-subtitle timeline-item-subtitle-disabled"
                v-if="orderDetail.order_type == 'pickup'"
              >
                Ready for Pick Up (Scheduled)
              </div>
            </div>
          </div>
        </div>

        <div
          class="timeline-item"
          v-if="orderDetail.order_state === 'canceled'"
        >
          <div class="timeline-item-date">
            <strong
              ><small>{{
                formatDate(orderActivity.updated_at, "MMM Do")
              }}</small></strong
            ><br /><small>{{
              formatDate(orderActivity.updated_at, "h:mm a")
            }}</small>
          </div>
          <div class="timeline-item-divider"></div>
          <div class="timeline-item-content">
            <div
              class="timeline-item-inner timeline-item-inner-order timeline-item-inner-order-canceled"
            >
              <div class="timeline-item-subtitle">Order Canceled</div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="container-cancel-button"
        v-if="
          orderLoaded &&
          orderActivity.first_item_picked_at == null &&
          orderDetail.order_fulfilled_at == null &&
          orderDetail.order_state !== 'canceled'
        "
      >
        <div class="container-cancel-button-inner">
          <f7-link
            href="#"
            data-popover=".popup-confirm-cancel"
            class="popover-open"
            ><button class="button button-small button-cancel-order">
              Cancel Order
            </button></f7-link
          >
        </div>
      </div>
      <!-- </f7-card-content> -->
    </f7-card>

    <f7-card v-if="orderLoaded && isEa">
      <f7-card-content class="cd-status-card">
        <f7-row>
          <f7-col width="25" class="checkout-flex-col">
            <div class="cd-icon">
              <cd-icon />
            </div>
          </f7-col>
          <f7-col width="75" class="checkout-flex-col">
            <div class="cd-header">Choice Doorstep</div>
          </f7-col>
        </f7-row>

        <f7-row>
          <f7-col width="100">
            <strong>Order Status:</strong> <br />
            <div class="cd-status-button">
              <button class="button button-normal button-cd-status">
                {{ eaOrderDetail.order_status.name }}
              </button>
            </div>
          </f7-col>
        </f7-row>

        <f7-row>
          <f7-col width="100">
            <div>
              <strong
                >Arriving between {{ eaDeliveryStartDate }} &
                {{ eaDeliveryEndDate }}</strong
              >
            </div>
          </f7-col>
        </f7-row>

        <f7-row v-if="eaOrderDetail.vendor_orders">
          <f7-col width="100">
            <sh-ea-tracking :eaVendorOrders="eaOrderDetail.vendor_orders" />
          </f7-col>
        </f7-row>

        <f7-row>
          <f7-col width="100">
            <div><strong>Shipping Address</strong></div>
            <div>
              {{ eaOrderDetail.shipping_addressee }}
              <br />{{ eaOrderDetail.shipping_address_line_1 }}
              <span v-if="eaOrderDetail.shipping_address_line_2">{{
                eaOrderDetail.shipping_address_line_2
              }}</span>
              <br />{{ eaOrderDetail.shipping_city }},
              {{ eaOrderDetail.region.region_code }}
              {{ eaOrderDetail.shipping_postal_code }}
            </div>
          </f7-col>
        </f7-row>
      </f7-card-content>
    </f7-card>

    <f7-card
      v-if="
        orderDetail.order_type == 'delivery' && orderAddress.address_address1
      "
    >
      <f7-card-header> Delivery Address </f7-card-header>
      <f7-card-content>
        <div class="address-row">
          {{ orderAddress.address_first_name }}
          {{ orderAddress.address_last_name }}
        </div>
        <div class="address-row">
          {{ orderAddress.address_address1 }}
        </div>
        <div class="address-row" v-show="orderAddress.address_address2">
          {{ orderAddress.address_address2 }}
        </div>
        <div class="address-row">
          {{ orderAddress.address_city }}, {{ orderAddress.address_state }}
          {{ orderAddress.address_zip }}
        </div>
        <div class="address-row">
          {{ formatPhoneNumber(orderAddress.address_phone) }}
        </div>
      </f7-card-content>
    </f7-card>

    <f7-card v-if="orderLoaded && orderDetail.order_state !== 'canceled'">
      <f7-card-header v-if="orderDetail.order_checkout_total_verified"> Order Receipt</f7-card-header>
      <f7-card-header v-else> Order Summary (Estimated)</f7-card-header>
      <f7-card-content class="order-totals-card">
        <div>
          <f7-list v-for="(receiptline, receiptlabel) in orderDetail.display_amounts"
            v-bind:key="receiptlabel" >
            <f7-list-item
              v-show="receiptline.amount > 0"
              :title="`${receiptlabel}`"
              :style="receiptline.is_total_amount ? 'font-weight:bold;font-size:22px;' : 'font-weight:normal;'"
            >
              {{ receiptline.is_discount_amount ? '(' + `$${receiptline.amount}` + ')' : `$${receiptline.amount}` }}
            </f7-list-item>

            <f7-list-item
              v-for="(specific_receipt_line_amount, specific_receipt_line_label) in receiptline.items"
              v-show="specific_receipt_line_amount > 0"
              v-bind:key="specific_receipt_line_label"
              :title="`${specific_receipt_line_label}`"
              style="font-size: 12px; padding-left: 30px;"
            >
              {{ receiptline.is_discount_amount ? '(' + `$${specific_receipt_line_amount}` + ')' : `$${specific_receipt_line_amount}` }}
            
            </f7-list-item>
          </f7-list>   
        </div>
      </f7-card-content>
    </f7-card>

    <f7-card v-if="orderLoaded && !isEa">
      <f7-card-header> Order Items </f7-card-header>
      <f7-card-content>
        <div class="list media-list">
          <ul>
            <template v-for="category in orderItemCategories">
              <li class="list-group-title">{{ category.categoryName }}</li>
              <li v-for="(cartItem, index) in category.cartItems" :key="index">
                <a
                  v-bind:href="`/product/${cartItem.product.product_key}/`"
                  class="item-link item-content"
                >
                  <div class="item-media">
                    <img
                      v-bind:src="cartItem.product.product_image_url_150"
                      width="44"
                    />
                  </div>
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title item-title-product">
                        {{ cartItem.product.product_name }}
                      </div>
                    </div>
                    <div class="item-subtitle">
                      ${{ cartItem.order_item_price }}
                      {{ cartItem.order_item_price_unit.product_unit_text }}
                    </div>
                    <div
                      class="item-text"
                      v-show="cartItem.order_item_substitution_permitted == 0"
                    >
                      No Substitution
                    </div>
                    <div class="item-text">
                      {{ cartItem.order_item_special_request }}
                    </div>
                  </div>
                </a>
              </li>
            </template>
          </ul>
        </div>
      </f7-card-content>
    </f7-card>

    <f7-row class="ea-items-container">
      <f7-col width="100">
        <sh-product-card-ea
          v-for="oneEaProduct in eaProdcts"
          v-bind:product="oneEaProduct"
          :key="oneEaProduct.productKey"
        />
      </f7-col>
    </f7-row>

    <div class="spacer-bottom"></div>

    <div class="popover popup-confirm-cancel">
      <div class="popover-inner">
        <div class="popover-block">
          <div class="popover-header">
            Are you sure you want to cancel this order?
          </div>

          <div class="popover-content">
            Orders that have already begun preparation in the store will be
            unable to be canceled.
          </div>

          <div class="popover-footer">
            <a class="link popover-close"
              ><button class="button-small button-cancel">Oops</button></a
            >
            <div class="spacer-button"></div>
            <a class="link popover-close" @click="cancelOrder"
              ><button class="button-small button-really-cancel">
                Cancel My Order
              </button></a
            >
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import moment from "moment";

import $$ from "dom7";

import ShopHeroApi from "../api/ShopHeroApi";
import ShEaTracking from "../components/ShEaTracking.vue";
import ShProductCardEa from "../components/ShProductCardEa.vue";

import CdIcon from "../components/icons/CdIcon.vue";

export default {
  data() {
    return {
      orderDetail: {
        order_type: null,
        created_at: null,
        order_fulfilled_at: null,
      },
      orderActivity: {
        first_item_picked_at: null,
        checked_out_at: null,
      },
      orderAddress: {
        address_first_name: null,
      },
      orderItems: [],
      orderLoaded: false,

      orderItemCategories: [],

      isEa: false,
      eaOrderDetail: {},
      eaDeliveryStartDate: null,
      eaDeliveryEndDate: null,
      eaProdcts: [],
    };
  },

  components: {
    ShEaTracking,
    ShProductCardEa,
    CdIcon,
  },

  props: ["orderKey","itemsAddedCount"],

  computed: {
    userIsAuthenticated: ({ $store }) => $store.getters.userIsAuthenticated,
    freshOrder: ({ orderDetail }) => {
      const m = new moment(orderDetail.created_at);
      const n = new moment().subtract(5, "minutes");
      return m.isAfter(n);
    },
  },

  methods: {
    formatDate(timeString, format) {
      const m = new moment(timeString);
      return m.format(format);
    },
    formatPhoneNumber(s) {
      var s2 = ("" + s).replace(/\D/g, "");
      var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
      return !m ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
    },
    cancelOrder() {
      const buttonContent = $$(".button-cancel-order").html();
      $$(".button-cancel-order").html("Please wait...");

      ShopHeroApi.cancelOrder(this.orderKey, (response) => {
        console.log(response);
        $$(".button-cancel-order").html(buttonContent);
        this.populateOrder();
      });
    },
    populateOrder() {
      ShopHeroApi.getOrderDetail(this.orderKey, (response) => {
        // console.log(response)
        this.orderDetail = response.order;
        this.orderActivity = response.order_activity;
        this.orderItems = response.order_items;
        this.orderAddress = response.delivery_address;
        this.orderLoaded = true;
        
        // it's an endless aisle order
        if (response.order.endless_aisle_customer_order_id) {
          this.isEa = true;
          this.eaOrderDetail = response.endless_aisle_order_data;
          this.eaOrderDetail.order_items.forEach((eaCartItem) => {
            // TODO - handle this better - seems like we could handle these mapping more eloquently
            // there is a product model that EA uses for a product that isn't ordered yet
            // but this is an item that has been ordered - the image and product name are pretty deep
            // as far as I can tell this is the only place we display this, and it is only those 5 attributes

            let isCase = eaCartItem.availability_listing.quantity_per_case == 1 ? false : true;
            
            this.eaProdcts.push({
              productName: eaCartItem.availability_listing.product.descriptive_name,
              productKey: eaCartItem.availability_listing.product_id,
              isEndlessAisle: true,
              productImageUrl150: eaCartItem.availability_listing.product.images[0].path,
              productQty: eaCartItem.quantity,
              productUnit: isCase ? 'case' : 'each',
              productUnitPlural: isCase ? 'cases' : 'units',
              pricePrice: eaCartItem.per_unit_retail_price_paid,
            });
          });
        }

        
        if (this.freshOrder && this.$f7.views.main.router.previousRoute.url.includes("/checkout-review/")) {
          this.$f7.views.main.router.clearPreviousHistory();
          // set history back to only homepage
          this.$f7.views.main.history = ["/"]; // url of the home pagec
          this.$f7.views.main.router.history = ["/"];
        }
        
        const cartItemCategoryMap = {};

        this.orderItems.forEach((cartItem) => {
          // sort items into root category groups
          if (cartItem.product.product_categories.length > 0) {
            const categoryKey =
              cartItem.product.product_categories[0].shophero_category_key;
            const category = this.$store.getters.getCategoryByKey(categoryKey);

            if (category) {
              let topLevelCategory = category;
              for (let level = 5; level >= 0; level--) {
                // eslint-disable-line
                if (
                  topLevelCategory.parent_category &&
                  topLevelCategory.parent_category.shophero_category_key !==
                    "000000"
                ) {
                  topLevelCategory = topLevelCategory.parent_category;
                } else {
                  break;
                }
              }

              if (cartItemCategoryMap[topLevelCategory.shophero_category_key]) {
                cartItemCategoryMap[
                  topLevelCategory.shophero_category_key
                ].cartItems.push(cartItem);
              } else {
                cartItemCategoryMap[topLevelCategory.shophero_category_key] = {
                  categoryName: topLevelCategory.category_name,
                  categoryKey: topLevelCategory.shophero_category_key,
                  cartItems: [cartItem],
                };
              }
            }
          }
        });

        const cartItemCategoryArray = Object.values(cartItemCategoryMap);
        cartItemCategoryArray.sort(function (a, b) {
          return a.categoryName > b.categoryName
            ? 1
            : b.categoryName > a.categoryName
            ? -1
            : 0;
        }); // eslint-disable-line

        this.orderItemCategories = cartItemCategoryArray;

        // console.log(cartItemCategoryMap)
      });
    },
    populateEaEstimatedDeliveryDates() {
      ShopHeroApi.getEaEstimatedDeliveryDates((response) => {
        this.eaDeliveryStartDate = moment(response.start).format(
          "dddd, MMMM Do"
        );
        this.eaDeliveryEndDate = moment(response.end).format("dddd, MMMM Do");
      });
    },
  },

  mounted() {
    if (this.userIsAuthenticated) {
      this.populateOrder();
      this.populateEaEstimatedDeliveryDates();
    }

    this.$root.setActiveToolbar("none");

    this.$root.setNavbarTitle(`Order #${this.orderKey.toUpperCase()}`);
    this.$root.setNavbarSubtitle("");
  },
};
</script>

<style lang="scss" scoped="true">
.spacer {
  width: 100%;
  height: var(--f7-page-spacer-top);
}

.spacer-bottom {
  width: 100%;
  height: 70px;
}

.order-loading {
  margin-top: 40px;
  text-align: center;
  height: 200px;
  width: 100%;
  position: relative;
  .preloader {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }
}

.card-fresh-order {
  background-color: #2ca3e5; //$colorGreen;
  color: #fff;
  text-align: center;
}

.timeline-order {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.timeline-item-inner-order {
  background-color: rgb(239, 239, 244);
}

.timeline-item-inner-order-complete {
  background-color: #ddfcd9;
}

.timeline-item-inner-order-canceled {
  background-color: #ff9999;
}

.timeline-item-subtitle-disabled {
  color: #999;
}

.timeline-item {
  min-height: 45px;
}

.timeline-item-title {
  padding: 5px 10px;
}

.timeline-item-subtitle {
  padding: 5px 10px;
}

.timeline-item-divider {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.card-header {
  font-size: 1.4em !important;
  font-weight: bold;
  margin-bottom: 10px;
}

.border-thin-bottom {
  border-bottom: 0.25px solid #ccc;
}

.row-total {
  font-weight: $bold;
}

.row-divider {
  height: 1px;
  margin-top: 3px;
  margin-bottom: 5px;
}

.container-cancel-button {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  // padding-bottom: 50px;
  padding: 0px;
}

.container-cancel-button-inner {
  width: 50%;
  display: table;
  margin: 0 auto;
}

.button-cancel-order {
  background-color: $colorRed;
  width: 300px;
  height: 40px;
  padding: 0px;
  margin-bottom: 20px;
}

.item-title-product {
  font-size: 0.66em;
}

.popover-block {
  font-size: 1.2em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.popover-header {
  width: 100%;
  border-bottom: 0.5px solid #ccc;
  text-align: center;
  font-weight: $bold;
  top: 0px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.popover-content {
  padding-left: 20px;
  padding-right: 20px;
}

.popover-footer {
  width: 100%;
  border-top: 0.5px solid #ccc;
  text-align: center;
  font-weight: $bold;
  padding-top: 10px;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.popover-input {
  width: 100%;
  height: 30px;
}

.popover-textarea {
  width: 100%;
  height: 100px;
}

.button-cancel {
  background-color: #ccc;
}

.button-really-cancel {
  background-color: $colorRed;
  color: #fff;
}

.spacer-button {
  display: inline-block;
  width: 10px;
}

.checkout-flex-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px 0 5px;
  margin: auto;
}

.cd-icon {
  min-width: 55px;
  height: auto;
  font-size: 24px;
}

.cd-header {
  font-size: 24px;
  font-weight: bold;
}

.cd-status-button {
  vertical-align: middle;
  width: 50%;
  padding-top: 10px;
}

.button-cd-status {
  padding: 0px;
  height: 35px;
}
.cd-status-card {
  .row {
    margin-bottom: 20px;
  }
}

.order-totals-card {
  .row {
    margin-bottom: 10px;
  }
}
/* The alert message box */
.alert {
  padding: 20px;
  background-color: $storePrimaryColor; /* Red */
  color: white;
  font-size: 1.2em;
  margin:1em 1em 1em 1em;
  border-radius: 5px;
}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 13px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}

</style>
