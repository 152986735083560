
// this is a wrapper in case we want to use
// another method for local storage in the
// future

const myStorage = window.localStorage

export default {
  setItem(key, value) {
    return myStorage.setItem(key, value)
  },

  getItem(key) {
    return myStorage.getItem(key)
  },

  removeItem(key) {
    return myStorage.removeItem(key)
  },
}
