/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: "warn" */

import * as types from '../mutation-types' // eslint-disable-line no-unused-vars

import ShopHeroApi from '../../api/ShopHeroApi' // eslint-disable-line no-unused-vars
import LocalStorage from '../../local-storage/LocalStorageInterface' // eslint-disable-line no-unused-vars

// initial state
const state = {
  orderInfo: {},
}

// getters
const getters = {
  getCheckoutInfo: state => state.orderInfo,
}

// actions
const actions = {

  checkoutMergeInfo({ commit }, { orderInfo }) {
    commit(types.CHECKOUT_MERGE_DELIVERY_INFO, { orderInfo })
  },
}

// mutations
const mutations = {
  [types.CHECKOUT_MERGE_DELIVERY_INFO](state, { orderInfo }) {
    return new Promise((resolve, reject) => {
      for(let propertyName in orderInfo) {
        console.log('CHECKOUT_MERGE_DELIVERY_INFO ' + propertyName)
        state.orderInfo[propertyName] = orderInfo[propertyName]
      }

      resolve()
    })
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}
