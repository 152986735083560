/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: "warn" */

import * as types from '../mutation-types' // eslint-disable-line no-unused-vars

import ShopHeroApi from '../../api/ShopHeroApi' // eslint-disable-line no-unused-vars

import LocalStorage from '../../local-storage/LocalStorageInterface' // eslint-disable-line no-unused-vars


import ProductInCart from '../../models/ProductInCart'


const productInCartByKey = function (productsInCart, productKey) {
  for (let i = 0; i < productsInCart.length; i += 1) {
    if (productsInCart[i].productKey == productKey) {
      return productsInCart[i]
    }
  }
  return undefined
}


// initial state
const state = {
  cartKey: 'new',
  physicalLocationId: 0,
  requestSequenceCounter: 0,
  productsInCart: [],
  eaCartCheckoutTotals: {},

  cartConstraints: {
    ecommerceEnabled: false,
    pickupEnabled: false,
    deliveryEnabled: false,
    ageRestrictedEnabled: false,

    checkoutDisabled: false,

    cartItemQtyLimit: 200,
    cartSubTotalMinimum: 0,
    checkoutDisabledDueToUncollectedOrders: false,
    checkoutDisabledDueToOrderVelocity: false,
    checkoutDisabledDueToQuantityOverLimit: false,
    checkoutDisabledDueToSubtotalUnderLimit: false

  },

  cartLoyalty: {
    loyaltyCollectAtCheckout: false,
    loyaltyAccountIdentifier: null,
    loyaltyAccountName: null,
    loyaltyAccountNumber: null,
  },

  cartTotals: {

    cartItemQty: 0,

    subtotal: 0,

    taxTotal: 0,

    taxDesc1: 0,
    taxRate1: 0,
    taxTotal1: 0,
    taxDesc2: 0,
    taxRate2: 0,
    taxTotal2: 0,
    taxDesc3: 0,
    taxRate3: 0,
    taxTotal3: 0,

    costPlusFee: 0,
    costPlusRate: 0,
    convenienceFee: 0,
    deliveryFee: 0,

    totalDiscount: 0,

    total: 0,

    totalAuth: 0,

  },

  cartMessaging: {
    schedulingSpecialAlert: {
      active: false,
      title: null,
      content: null
    }
  },

  choiceDoorstepProductConfirmation: null,
  choiceDoorstepConfirmationModalIsOpen: false,
  choiceDoorstepConfirmationProposedQuantity: 0,

  voucherResult: {},
}

// getters
const getters = {
  productsInCart: state => state.productsInCart,
  eaProductsInCartCount: (state) => {
    let ea_prod_count = 0;
    state.productsInCart.forEach((e) => {
      if(e.isEndlessAisle) {
        ea_prod_count++;
      }
    });
    return ea_prod_count;
  },

  inStoreProductsInCartCount: (state) => {
    let in_store_prod_count = 0;
    state.productsInCart.forEach((e) => {
      if( ! e.isEndlessAisle) {
        in_store_prod_count++;
      }
    });
    return in_store_prod_count;
  },

  cartHasEaProducts: (state) => {
    for(let p of state.productsInCart){
      if(p.isEndlessAisle) {
        return true;
      }
    };
    return false;
  },
  cartHasInStoreProducts: (state) => {
    for(let p of state.productsInCart){
      if( ! p.isEndlessAisle) {
        return true;
      }
    };
    return false;
  },
  eaCartCheckoutTotals: state => state.eaCartCheckoutTotals,
  productQtyInCart: state =>  { 
    return state.productsInCart.length
  },
  productTotalInCart: (state) => {
    let totalPriceInCart = 0
    state.productsInCart.forEach((e) => {
      totalPriceInCart += e.qtyInCart * e.pricePrice
    })

    return totalPriceInCart
  },
  getProductInCartByProductKey: state => productKey => {
    let foundProduct = productInCartByKey(state.productsInCart, productKey);
    return foundProduct;
  },
  cartKey: state => state.cartKey,
  cartConstraints: state => state.cartConstraints,
  cartLoyalty: state => state.cartLoyalty,
  cartTotals: state => state.cartTotals,
  cartMessaging: state => state.cartMessaging,
  cartVoucherResult: state => state.voucherResult,
  requestSequenceCounter: state => state.requestSequenceCounter,

  // Choice Doorstep Confirmation Modal
  choiceDoorstepConfirmationModalIsOpen: (state) => {
    return state.choiceDoorstepConfirmationModalIsOpen;
  },

  choiceDoorstepProductConfirmation: (state) => {
    if (state.choiceDoorstepProductConfirmation) {
      return state.choiceDoorstepProductConfirmation;
    } else {
      return null;
    }
  },

  choiceDoorstepProductConfirmationName: (state) => {
    if (state.choiceDoorstepProductConfirmation) {
      return state.choiceDoorstepProductConfirmation.productName;
    } else {
      return null;
    }
  },

  choiceDoorstepProductConfirmationImageUrl: (state) => {
    if (state.choiceDoorstepProductConfirmation) {
      if (state.choiceDoorstepProductConfirmation.productImageUrl215) {
        return state.choiceDoorstepProductConfirmation.productImageUrl215;
      } else {
        return state.choiceDoorstepProductConfirmation.productImageUrl150;
      }
    } else {
      return null;
    }
  },

  choiceDoorstepProductConfirmationCaseQuantity: (state) => {
    if (state.choiceDoorstepProductConfirmation) {
      return state.choiceDoorstepProductConfirmation.endlessAisle.caseQty;
    } else {
      return null;
    }
  },

  choiceDoorstepProductConfirmationPrice: (state) => {
    if (state.choiceDoorstepProductConfirmation) {
      return state.choiceDoorstepProductConfirmation.pricePrice;
    } else {
      return null;
    }
  },

  choiceDoorstepProductConfirmationProductUnit: (state) => {
    if (state.choiceDoorstepProductConfirmation) {
      return state.choiceDoorstepProductConfirmation.productUnit;
    } else {
      return null;
    }
  },

  choiceDoorstepConfirmationProposedQuantity: (state) => {
    return state.choiceDoorstepConfirmationProposedQuantity;
  },
}

// actions
const actions = {
  populateCart({ commit }, { physicalLocationId, requestSequenceNumber }) {
    return new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars

      let cartKey = LocalStorage.getItem('cart:cartKey')
      if (!cartKey) cartKey = 'new'

      ShopHeroApi.getCart(physicalLocationId, cartKey, (response) => {
        commit(types.RECEIVE_CART_FROM_API, { response, physicalLocationId, requestSequenceNumber })
        resolve()
      })
    })
  },
  setQuantityProductInCart({ commit }, { product, qty, specialInstructions, permitSubstitution }) {
    commit(types.RECEIVE_CART_SET_PRODUCT_QTY, { product, qty, specialInstructions, permitSubstitution })
  },
  
  incrementProductInCart({ commit }, { product }) {
    commit(types.RECEIVE_CART_SET_PRODUCT_DELTA_QTY, { product, deltaQty: product.quantityIncrement })
  },

  decrementProductInCart({ commit }, { product }) {
    commit(types.RECEIVE_CART_SET_PRODUCT_DELTA_QTY, { product, deltaQty: product.quantityIncrement * -1 })
  },
  setApiIdProductInCart({ commit }, { product, cartItemId }) {
    commit(types.RECEIVE_CART_SET_PRODUCT_API_ID, { product, cartItemId })
  },
  setSpecialInstructionsOnProductInCart({ commit }, { product, specialInstructions }) {
    commit(types.RECEIVE_CART_SET_PRODUCT_SPECIAL_INSTRUCTIONS, { product, specialInstructions })
  },
  setSubstitutionOnProductInCart({ commit }, { product, permitSubstitution }) {
    commit(types.RECEIVE_CART_SET_PRODUCT_SUBSTITUTION, { product, permitSubstitution })
  },
  resetCartKey({ commit }) {
    return new Promise((resolve, reject) => {
      commit(types.RESET_CART_KEY)
      resolve()
    })
  },
  closeCDModal({commit}) {
    commit(types.CLOSE_CHOICE_DOORSTEP_CONFIRMATION_MODAL);
  }
}

// mutations
const mutations = {
  [types.CLOSE_CHOICE_DOORSTEP_CONFIRMATION_MODAL](state) {
    state.choiceDoorstepConfirmationModalIsOpen = false;
  },
  [types.OPEN_CHOICE_DOORSTEP_CONFIRMATION_MODAL](state, {product, proposedQuantity}) {
    state.choiceDoorstepProductConfirmation = product;
    state.choiceDoorstepConfirmationModalIsOpen = true;
    state.choiceDoorstepConfirmationProposedQuantity = proposedQuantity;
  },

  [types.RECEIVE_CART_SET_PRODUCT_QTY](state, { product, qty, specialInstructions, permitSubstitution }) {
    let productInCart = productInCartByKey(state.productsInCart, product.productKey)
    if (productInCart === undefined) {
      productInCart = new ProductInCart()
      productInCart.productKey = product.productKey
      productInCart.product = product
      productInCart.pricePrice = product.pricePrice
      productInCart.priceRank = product.priceRank
      productInCart.productUnit = product.productUnit
      productInCart.productUnitPlural = product.productUnitPlural
      productInCart.productUnitText = product.productUnitText
      productInCart.productCategory = product.productCategory
      productInCart.isEndlessAisle = product.isEndlessAisle

      state.productsInCart.push(productInCart)
    } else {
      productInCart.updatedTimeUTC = Date.UTC()
    }

    let originalQtyInCart = productInCart.qtyInCart;

    productInCart.qtyInCart = Math.max(parseFloat(qty), 0)
    productInCart.instructions = specialInstructions
    productInCart.permitSubstitution = permitSubstitution

    // post update to API
    const self = this

    const substitutionArgs = {
      special_instructions: specialInstructions,
      substitution_permitted: permitSubstitution
    }

    const requestSequenceNumber = ++state.requestSequenceCounter

    if (productInCart.isEndlessAisle) {
      // Key off the qty amount here. I may also need the original quantity 
      // (productInCart.qtyInCart before change) to determine
      // which endpoint to call
     
      // Add to cart
      if (originalQtyInCart === 0) {
        ShopHeroApi.addOrIncrementEaProductQtyInCart(product.endlessAisle.listingId, product.productKey, qty, (response) => {
          if (response.cart_item_id) {
            self.dispatch('setApiIdProductInCart', { product: product, cartItemId: `ea-${response.cart_item_id}` })
          } else {
            alert('ERROR: Unable to add product to cart!')
          }
          self.dispatch('populateCart', { physicalLocationId: state.physicalLocationId, requestSequenceNumber: requestSequenceNumber })
        })
      } else if (qty > 0) {
        // Set cart quantity
        let cleanKey = productInCart.cartApiId.split('-')[1];

        ShopHeroApi.setEaProductQtyInCart(cleanKey, qty, (response) => {
          if (!response) {
            alert('ERROR: Unable to update product amount cart!')
          }
          
          self.dispatch('populateCart', { physicalLocationId: state.physicalLocationId, requestSequenceNumber: requestSequenceNumber })
        })
      } else if (qty <= 0) {
        // Remove from cart
        let cleanKey = productInCart.cartApiId.split('-')[1];

        ShopHeroApi.deleteEaProductFromCart(cleanKey, (response) => {
          if (!response) {
            alert('ERROR: Unable to remove product from cart!')
          }

          self.dispatch('populateCart', { physicalLocationId: state.physicalLocationId, requestSequenceNumber: requestSequenceNumber })
        })
      }




    } else {
      ShopHeroApi.setProductQtyInCart(state.physicalLocationId, state.cartKey, product.productKey, productInCart.priceRank, productInCart.qtyInCart, substitutionArgs, (response) => {
        // console.log(response)
        if (response.shopping_cart_item_id) {
          self.dispatch('setApiIdProductInCart', { product: product, cartItemId: response.shopping_cart_item_id })
          // productInCart.cartApiId = response.shopping_cart_item_id
        }
        if (response.success == false) {
          // TODO raise a proper, user friendly error
          alert('ERROR: Unable to add product or update quantity in cart!')
        }
        self.dispatch('populateCart', { physicalLocationId: state.physicalLocationId, requestSequenceNumber: requestSequenceNumber })
      })

    }

    if (productInCart.qtyInCart === 0) {
      // remove product completely from cart
      const index = state.productsInCart.indexOf(productInCart)
      if (index > -1) {
        state.productsInCart.splice(index, 1)
      }
      console.log(`removed [${productInCart.productKey}] from cart`)
      return
    }

    if (product.productName !== undefined) { productInCart.productName = product.productName }

    if (product.productCategory !== undefined) { 
      // force ea products into their own category right away
      if(product.isEndlessAisle) { 
        productInCart.productCategory = "eaeaea" 
        product.productCategory = ["eaeaea"]
      }
      else { productInCart.productCategory = product.productCategory; }
    }

    if (product.priceRank !== undefined) { productInCart.priceRank = product.priceRank }

    if (product.price !== undefined) { productInCart.price = product.price }

    // console.log(state.productsInCart)
  },

  [types.RECEIVE_CART_SET_PRODUCT_DELTA_QTY](state, { product, deltaQty }) {
    console.log('deltaQty: ' + deltaQty)
    let productInCart = productInCartByKey(state.productsInCart, product.productKey)
    if (productInCart === undefined) {
      productInCart = new ProductInCart()
      productInCart.productKey = product.productKey
      productInCart.product = product
      // when first adding an item to the card we want to add at least one
      productInCart.qtyInCart = Math.max(parseFloat(deltaQty), 1)
      productInCart.pricePrice = product.pricePrice
      productInCart.priceRank = product.priceRank
      productInCart.productUnit = product.productUnit
      productInCart.productUnitPlural = product.productUnitPlural
      productInCart.productUnitText = product.productUnitText
      productInCart.isEndlessAisle = product.isEndlessAisle

      state.productsInCart.push(productInCart)
    } else {
      productInCart.qtyInCart = Math.max(parseFloat(productInCart.qtyInCart) + parseFloat(deltaQty), 0)
      productInCart.updatedTimeUTC = Date.UTC()
    }

    // post update to API
    const self = this
    const substitutionArgs = {
      special_instructions: productInCart.instructions,
      substitution_permitted: productInCart.permitSubstitution
    }

    const requestSequenceNumber = ++state.requestSequenceCounter



    if(productInCart.isEndlessAisle) {
      // Determine if we are incrementing or decrementing.
      if (deltaQty > 0) {
        ShopHeroApi.addOrIncrementEaProductQtyInCart(product.endlessAisle.listingId, product.productKey, deltaQty, (response) => {
          if (response.cart_item_id) {
            self.dispatch('setApiIdProductInCart', { product: product, cartItemId: `ea-${response.cart_item_id}` })
          }
          else if (typeof response !== 'number') {
            // If the item has already been added to the cart, it will
            // increment and return the current cart item count instead
            // of the cart_item_id, so check for that here.

            // TODO raise a proper, user friendly error
            alert('ERROR: Unable to add product or update quantity in cart!')
          }
          self.dispatch('populateCart', { physicalLocationId: state.physicalLocationId, requestSequenceNumber: requestSequenceNumber })
        })
      } else {
        if (productInCart.cartApiId) {
          let cleanKey = productInCart.cartApiId.split('-')[1];

          ShopHeroApi.decrementEaProductQtyInCart(cleanKey, (response) => {
            if (typeof response !== 'number') {
              // TODO raise a proper, user friendly error
              alert('ERROR: Unable to add product or update quantity in cart!')
            }
            self.dispatch('populateCart', { physicalLocationId: state.physicalLocationId, requestSequenceNumber: requestSequenceNumber })
          })
        }
      }
    } else {

      ShopHeroApi.setProductQtyInCart(state.physicalLocationId, state.cartKey, product.productKey, productInCart.priceRank, productInCart.qtyInCart, substitutionArgs, (response) => {
        if (response.shopping_cart_item_id) {
          // console.log(response)
          self.dispatch('setApiIdProductInCart', { product: product, cartItemId: response.shopping_cart_item_id })
          // productInCart.cartApiId = response.shopping_cart_item_id
        }
        if (response.success == false) {
          // TODO raise a proper, user friendly error
          alert('ERROR: Unable to add product or update quantity in cart!')
        }
        self.dispatch('populateCart', { physicalLocationId: state.physicalLocationId, requestSequenceNumber: requestSequenceNumber })
      })
    }

    if (productInCart.qtyInCart === 0) {
      // remove product completely from cart
      const index = state.productsInCart.indexOf(productInCart)
      if (index > -1) {
        state.productsInCart.splice(index, 1)
      }
      console.log(`removed [${productInCart.productKey}] from cart`)
      return
    }

    if (product.productName !== undefined) { productInCart.productName = product.productName }

    if (product.productCategory !== undefined) { 
      // force ea products into their own category right away
      if(product.isEndlessAisle) { 
        productInCart.productCategory = "eaeaea" 
        product.productCategory = ["eaeaea"]
      } else {
        productInCart.productCategory = product.productCategory
      }
      
    }

    if (product.priceRank !== undefined) { productInCart.priceRank = product.priceRank }

    if (product.price !== undefined) { productInCart.price = product.price }


  },
  [types.RECEIVE_CART_SET_PRODUCT_SPECIAL_INSTRUCTIONS](state, { product, specialInstructions }) {
    const productInCart = productInCartByKey(state.productsInCart, product.productKey)
    if (productInCart !== undefined) {
      productInCart.instructions = specialInstructions
      // console.log(productInCart)
      if (productInCart.cartApiId) {
        ShopHeroApi.setSpecialInstructionsInCart(state.physicalLocationId, state.cartKey, productInCart.cartApiId, specialInstructions, (response) => {
          console.log('instructions')
        })
      }
    }
  },
  [types.RECEIVE_CART_SET_PRODUCT_SUBSTITUTION](state, { product, permitSubstitution }) {
    const productInCart = productInCartByKey(state.productsInCart, product.productKey)
    if (productInCart !== undefined) {
      productInCart.permitSubstitution = permitSubstitution
      // console.log(productInCart)
      if (productInCart.cartApiId) {
        ShopHeroApi.setSubstitutionInCart(state.physicalLocationId, state.cartKey, productInCart.cartApiId, permitSubstitution, (response) => {
          console.log('substitutions')
        })
      }
    }
  },
  [types.RESET_CART_KEY](state) {
    state.cartKey = 'new'
    LocalStorage.removeItem('cart:cartKey')
    state.productsInCart = []
  },
  [types.RECEIVE_CART_SET_PRODUCT_API_ID](state, { product, cartItemId }) {
    const productInCart = productInCartByKey(state.productsInCart, product.productKey)
    if (productInCart !== undefined) {
      productInCart.cartApiId = cartItemId
    }
  },
  [types.RECEIVE_CART_FROM_API](state, { response, physicalLocationId, requestSequenceNumber }) {
    state.cartKey = response.cart_key
    state.physicalLocationId = physicalLocationId

    if (requestSequenceNumber < state.requestSequenceCounter) {
      console.log('requestSequenceNumberDrop', requestSequenceNumber, state.requestSequenceCounter)
      return
    }

    LocalStorage.setItem('cart:cartKey', state.cartKey)

    // console.log(response)

    if (response.shopping_cart) {

      // map for easy merging
      const inCartProductMap = {}
      state.productsInCart.forEach(function (item) {
        inCartProductMap[item.cartApiId] = item
      })

      const matchedInCartItems = {}

      const priceChangedItemMap = {}
      response.shopping_cart.shopping_cart_item_price_changes.forEach(function (item) {
        priceChangedItemMap[item.shopping_cart_item_id] = item
      })

      const availabilityChangedItemMap = {}
      response.shopping_cart.shopping_cart_item_not_available.forEach(function (item) {
        availabilityChangedItemMap[item.shopping_cart_item_id] = item
      })

      response.shopping_cart.shopping_cart_items.forEach(function (item) {
        // console.log(item)

        // merge existing items
        let productInCart = new ProductInCart()
        if (inCartProductMap[item.shopping_cart_item_id]) {
          productInCart = inCartProductMap[item.shopping_cart_item_id]
        }

        productInCart.productKey = item.product.product_key
        productInCart.cartApiId = item.shopping_cart_item_id

        productInCart.instructions = item.special_request
        productInCart.permitSubstitution = item.substitution_permitted

        productInCart.isEndlessAisle = false;

        const product = ShopHeroApi.convertApiProductToProductModel(item.product, item.product.product_key)
        productInCart.product = product
        productInCart.qtyInCart = item.product_quantity
        productInCart.pricePrice = item.product_price
        productInCart.priceRank = item.product_price_rank

        productInCart.pricePriceOriginal = item.product_price

        if (priceChangedItemMap[item.shopping_cart_item_id]) {
          const price_change_item = priceChangedItemMap[item.shopping_cart_item_id]
          productInCart.pricePrice = price_change_item.product_price_current.product_price
          // productInCart.priceRank = price_change_item.product_price_current.product_price_rank
        }

        if (availabilityChangedItemMap[item.shopping_cart_item_id]) {
          productInCart.availableForPurchase = false
          // matchedInCartItems[item.shopping_cart_item_id] = false
        }

        if (item.product.price) {
          if (item.product.price.price_unit) {
            productInCart.productUnit = item.product.price.price_unit.product_unit
            productInCart.productUnitPlural = item.product.price.price_unit.product_unit_plural
            productInCart.productUnitText = item.product.price.price_unit.product_unit_text
          }
        }

        if (!inCartProductMap[item.shopping_cart_item_id]) {
          state.productsInCart.push(productInCart)
        }

        matchedInCartItems[item.shopping_cart_item_id] = true

      })

      // Map EA items to the local store
      response.shopping_cart.endless_aisle_cart_items.forEach(function(item) {
        // merge existing items
        let eaItemCartId = `ea-${item.id}`;

        let productInCart = new ProductInCart()
        if (inCartProductMap[eaItemCartId]) {
          productInCart = inCartProductMap[eaItemCartId]
        }

        productInCart.productKey = item.endless_aisle_product_id
        productInCart.cartApiId = eaItemCartId

        productInCart.instructions = ''
        productInCart.permitSubstitution = false

        productInCart.isEndlessAisle = true;

        const product = ShopHeroApi.convertApiEAProductToProductModel(item)
        productInCart.product = product
        productInCart.qtyInCart = item.quantity
        productInCart.pricePrice = item.unit_price
        productInCart.priceRank = 0

        productInCart.pricePriceOriginal = item.unit_price

        // TODO: Add support for price change checks on EA items
        // if (priceChangedItemMap[eaItemCartId]) {
        //   const price_change_item = priceChangedItemMap[eaItemCartId]
        //   productInCart.pricePrice = price_change_item.product_price_current.product_price
        //   // productInCart.priceRank = price_change_item.product_price_current.product_price_rank
        // }

        // TODO: Add support for availability change checks on EA items
        // if (availabilityChangedItemMap[eaItemCartId]) {
        //   productInCart.availableForPurchase = false
        //   // matchedInCartItems[eaItemCartId] = false
        // }

        // Update what the display price, etc. is right now rather than what
        // it was when the item was added to the cart.
        // TODO: Add support
        productInCart.productUnit = product.productUnit
        productInCart.productUnitPlural = product.productUnitPlural
        productInCart.productUnitText = product.productUnitText

        if (!inCartProductMap[eaItemCartId]) {
          state.productsInCart.push(productInCart)
        }

        matchedInCartItems[eaItemCartId] = true;
      })

      // remove any local items that didn't match from the api call
      state.productsInCart.forEach(function (item) {
        if (!matchedInCartItems[item.cartApiId]) {
          state.productsInCart.splice(state.productsInCart.indexOf(item), 1)
        }
      })

      // update cart constraints
      state.cartConstraints.ecommerceEnabled = response.ecommerce_available
      state.cartConstraints.pickupEnabled = response.pickup_available
      state.cartConstraints.deliveryEnabled = response.delivery_available
      state.cartConstraints.ageRestrictedEnabled = response.age_restricted_available
      state.cartConstraints.cartSubTotalMinimum = parseFloat(response.shopping_cart.shopping_cart_subtotal_minimum)
      state.cartConstraints.cartItemQtyLimit = parseInt(response.shopping_cart.shopping_cart_qty_limit)
      state.cartConstraints.checkoutDisabled = response.shopping_cart.shopping_cart_checkout_disabled
      state.cartConstraints.checkoutDisabledDueToUncollectedOrders = response.shopping_cart.shopping_cart_checkout_disabled_due_to_uncollected_orders
      state.cartConstraints.checkoutDisabledDueToOrderVelocity = response.shopping_cart.shopping_cart_checkout_disabled_due_order_velocity
      state.cartConstraints.checkoutDisabledDueToQuantityOverLimit = response.shopping_cart.shopping_cart_over_limit
      state.cartConstraints.checkoutDisabledDueToSubtotalUnderLimit = response.shopping_cart.shopping_cart_under_limit

      // update cart loyalty
      state.cartLoyalty.loyaltyCollectAtCheckout = response.loyalty.loyalty_account_collect_at_checkout
      state.cartLoyalty.loyaltyAccountIdentifier = response.loyalty.loyalty_account_identifier
      state.cartLoyalty.loyaltyAccountName = response.loyalty.loyalty_account_name
      state.cartLoyalty.loyaltyAccountNumber = response.loyalty.loyalty_account_number

      // update cart totals
      state.cartTotals.cartItemQty = parseFloat(response.shopping_cart.shopping_cart_quantity)
      
      state.cartTotals.subtotal = parseFloat(response.shopping_cart.shopping_cart_subtotal_noea) // this needs to be without the endless aisle  amount thus the _noea

      state.cartTotals.taxTotal = parseFloat(response.shopping_cart.shopping_cart_tax_total)

      state.cartTotals.taxDesc1 = response.shopping_cart.shopping_cart_tax_desc_1
      state.cartTotals.taxRate1 = parseFloat(response.shopping_cart.shopping_cart_tax_rate_1)
      state.cartTotals.taxTotal1 = parseFloat(response.shopping_cart.shopping_cart_tax_total_1_noea) // this needs to be without the endless aisle  amount thus the _noea
      state.cartTotals.taxDesc2 = response.shopping_cart.shopping_cart_tax_desc_2
      state.cartTotals.taxRate2 = parseFloat(response.shopping_cart.shopping_cart_tax_rate_2)
      state.cartTotals.taxTotal2 = parseFloat(response.shopping_cart.shopping_cart_tax_total_2)
      state.cartTotals.taxDesc3 = response.shopping_cart.shopping_cart_tax_desc_3
      state.cartTotals.taxRate3 = parseFloat(response.shopping_cart.shopping_cart_tax_rate_3)
      state.cartTotals.taxTotal3 = parseFloat(response.shopping_cart.shopping_cart_tax_total_3)

      state.cartTotals.costPlusFee = parseFloat(response.shopping_cart.shopping_cart_cost_plus_fee)
      state.cartTotals.costPlusRate = parseFloat(response.shopping_cart.shopping_cart_cost_plus_rate)
      state.cartTotals.convenienceFee = parseFloat(response.shopping_cart.shopping_cart_convenience_fee)
      state.cartTotals.deliveryFee = parseFloat(response.shopping_cart.shopping_cart_delivery_fee)

      state.cartTotals.total = parseFloat(response.shopping_cart.shopping_cart_total_cost_noea) // this needs to be without the endless aisle  amount thus the _noea

      if (response.shopping_cart.shopping_cart_scheduling_special_alert.active) {
        state.cartMessaging.schedulingSpecialAlert.active = response.shopping_cart.shopping_cart_scheduling_special_alert.active
        state.cartMessaging.schedulingSpecialAlert.title = response.shopping_cart.shopping_cart_scheduling_special_alert.title
        state.cartMessaging.schedulingSpecialAlert.content = response.shopping_cart.shopping_cart_scheduling_special_alert.content
      }

      // voucher result
      state.voucherResult = response.shopping_cart.voucher_result

      // TODO - return grandTotal from the api - don't want to calculate anything if we don't have to back here
      state.eaCartCheckoutTotals = {
        itemsTotal: response.shopping_cart.endless_aisle_items_total,
        taxTotal: response.shopping_cart.endless_aisle_items_tax_total,
        
        // EA tax rates are based on tax_1 rates for the shopping cart
        taxDesc: response.shopping_cart.shopping_cart_tax_desc_1,
        taxRate: response.shopping_cart.shopping_cart_tax_rate_1,
        
        grandTotal: parseFloat(response.shopping_cart.endless_aisle_items_total + response.shopping_cart.endless_aisle_items_tax_total),
      }
    }
  }

}

export default {
  state,
  getters,
  actions,
  mutations,
}
