<template>
  <f7-page no-navbar no-toolbar class="review-page">
    <div slot="fixed">
      <sh-checkout-bar selectedStep="review"></sh-checkout-bar>
    </div>
    <div class="spacer-top"></div>

    <f7-card class="card-error" v-if="errorMessage">
      <f7-card-content>{{ errorMessage }}</f7-card-content>
    </f7-card>

    <div class="review-content">
      <div>
        <f7-row>
          <f7-col width="100" class="sub-header">
            <h1 class="review-header">
              Review Your
              {{ selectedLocation.physical_location_name }} Order
            </h1>
          </f7-col>
        </f7-row>

        <f7-row v-if="cartHasEaProducts" divider class="divider"></f7-row>
        <f7-row v-if="cartHasEaProducts">
          <f7-col width="100">
            <sh-checkout-review-ea
              :eaCartItems="cartItems"
              :eaCartCheckoutTotals="eaCartCheckoutTotals"
              :storedShippingAddress="checkoutInfo.storedShippingAddress"
            />
          </f7-col>
        </f7-row>

        <f7-row divider class="divider"></f7-row>

        <f7-row divider class="divider"></f7-row>
        <!--
      <p>
        By placing your order, you agree to our <f7-link href="#">Terms of Service</f7-link> and <f7-link href="#">Privacy Policy</f7-link> which may change from time-to-time.
        </p>-->

        <f7-row class="cart-header" v-if="cartHasInStoreProducts">
          <div class="cd-basket-icon">
            <cd-basket-icon />
          </div>

          <h1 v-if="!cartHasEaProducts">Shopping Cart</h1>
          <h1 v-if="cartHasEaProducts">In Store Items</h1>

          <f7-chip
            v-bind:text="cartItemsChipText"
            style="chip-cart-count"
          ></f7-chip>
        </f7-row>
        
        <f7-row v-if="cartHasInStoreProducts">
          <f7-swiper
            :init="true"
            :params="{
              slidesPerView: productsPerRow,
              spaceBetween: 20,
              slidesPerGroup: 3,
              preloadImages: false,
              observer: false,
              watchOverflow: true,
              preventClicks: true,
              preventClicksPropagation: true,
              passiveListeners: true,
              lazy: {
                loadPrevNext: true,
                loadOnTransitionStart: true,
                loadPrevNextAmount: 1,
              },
              lazyLoading: true,
              watchSlidesVisibility: true,
              loop: false,
            }"
          >
            <f7-swiper-slide
              v-for="product in cartItems"
              v-bind:key="product.productKey"
              :style="cartItems.length == 1 ? 'min-width: 15em;' : '' "
            >
              <ShProductBox v-bind:product = "product" swiper="true"  />
            </f7-swiper-slide>
          </f7-swiper>
        </f7-row>

        <f7-row v-if="cartHasInStoreProducts">
          <f7-col width="100" class="sub-header"
            >My {{ serviceLabel }} Time</f7-col
          >
        </f7-row>

        <div v-if="cartHasInStoreProducts">
          <template v-if="checkoutInfo.serviceType == 'pickup'">
            <f7-row>
              <f7-col width="30" class="left-column">Pickup At:</f7-col>
              <f7-col width="50" class="right-column">{{
                selectedLocation.physical_location_name
              }}</f7-col>
              <f7-col width="20" class="left-column">&nbsp;</f7-col>
              <!-- <f7-col width="20" class="left-column"><a href="#"><button class="button button-small button-change">Change</button></a></f7-col> -->
            </f7-row>
            <f7-row>
              <f7-col width="30" class="left-column">Pickup After:</f7-col>
              <f7-col width="50" class="right-column">
                <span v-html="pickupTime"></span>
              </f7-col>
              <f7-col width="20" class="left-column">
                <a href="/checkout-schedule/">
                  <button class="button button-small button-change">
                    Change
                  </button>
                </a>
              </f7-col>
            </f7-row>
          </template>
          <template v-else>
            <f7-row>
              <f7-col width="10" class="left-column"></f7-col>
              <f7-col width="70" class="right-column">
                <span v-html="deliveryTime"></span>
              </f7-col>
              <f7-col width="20" class="left-column">
                <a href="/checkout-schedule/">
                  <button class="button button-small button-change">
                    Change
                  </button>
                </a>
              </f7-col>
            </f7-row>
          </template>
        </div>

        <f7-row divider class="divider" v-if="cartHasInStoreProducts"></f7-row>

        <template
          v-if="
            checkoutInfo.serviceType == 'delivery' && cartHasInStoreProducts
          "
        >
          <f7-row>
            <f7-col width="100" class="sub-header">My Delivery Address</f7-col>
          </f7-row>

          <f7-row>
            <f7-col width="10" class="left-column"></f7-col>
            <f7-col width="70" class="right-column">
              {{ checkoutInfo.storedDeliveryAddress.address_first_name }}
              {{ checkoutInfo.storedDeliveryAddress.address_last_name }}
              <br />
              {{ checkoutInfo.storedDeliveryAddress.address_address1 }},
              <span v-if="checkoutInfo.storedDeliveryAddress.address_address2"
                >{{
                  checkoutInfo.storedDeliveryAddress.address_address2
                }},</span
              >
              {{ checkoutInfo.storedDeliveryAddress.address_city }}
              <br />
              {{
                formatPhoneNumber(
                  checkoutInfo.storedDeliveryAddress.address_phone
                )
              }}
              <br />
            </f7-col>
            <f7-col width="20" class="left-column">
              <a href="/checkout-service/">
                <button class="button button-small button-change">
                  Change
                </button>
              </a>
            </f7-col>
          </f7-row>

          <f7-row v-if="!deliveryInstructions">
            <f7-col width="10" class="left-column"></f7-col>
            <f7-col width="70" class="left-column">
              <a
                href="#"
                data-popover=".popup-delivery-instructions"
                class="popover-open"
              >
                <button class="button button-small button-change">
                  Add Delivery Instructions
                </button>
              </a>
            </f7-col>
            <f7-col width="20" class="left-column">&nbsp;</f7-col>
          </f7-row>
          <f7-row v-else>
            <f7-col width="10" class="left-column"></f7-col>
            <f7-col width="70" class="left-column">
              <i>{{ deliveryInstructions }}</i>
            </f7-col>
            <f7-col width="20" class="left-column">
              <a
                href="#"
                data-popover=".popup-delivery-instructions"
                class="popover-open"
              >
                <button class="button button-small button-change">
                  Change
                </button>
              </a>
            </f7-col>
          </f7-row>

          <f7-row divider class="divider"></f7-row>
        </template>

        <f7-row>
          <f7-col width="100" class="sub-header">My Payment Method</f7-col>
        </f7-row>

        <f7-row>
          <f7-col width="10" class="left-column">&nbsp;</f7-col>
          <template v-if="checkoutInfo.paymentCardType">
            <f7-col width="25" class="left-column">
              <img v-bind:src="getIconForCard(checkoutInfo.paymentCardType)" />
            </f7-col>
            <f7-col width="45" class="right-column"
              >{{ checkoutInfo.paymentCardType }} x{{
                checkoutInfo.paymentCardLastFour.substring(
                  checkoutInfo.paymentCardLastFour.length - 4,
                  checkoutInfo.paymentCardLastFour.length
                )
              }}</f7-col
            >
          </template>
          <template v-if="checkoutInfo.paymentMethod == 'pay_at_pickup'">
            <f7-col width="25" class="left-column">
              <img v-bind:src="getIconForCard('CASH')" />
            </f7-col>
            <f7-col width="45" class="right-column"
              >Pay In-Store at Pickup</f7-col
            >
          </template>
          <template v-if="checkoutInfo.paymentMethod == 'pay_at_delivery'">
            <f7-col width="25" class="left-column">
              <img v-bind:src="getIconForCard('CASH')" />
            </f7-col>
            <f7-col width="45" class="right-column"
              >Payment upon Delivery</f7-col
            >
          </template>
          <f7-col width="20" class="left-column">
            <a href="/checkout-payment/">
              <button class="button button-small button-change">Change</button>
            </a>
          </f7-col>
        </f7-row>

        <f7-row divider class="divider"></f7-row>

        <f7-row v-if="enableSpecialRequests!=false">
          <f7-col width="100" class="sub-header"
            >Instructions for Your Personal Shopper</f7-col
          >
        </f7-row>

        <f7-row v-if="!specialRequests && enableSpecialRequests!=false">
          <f7-col width="10" class="left-column"></f7-col>
          <f7-col width="70" class="left-column">
            <a
              href="#"
              data-popover=".popup-special-requests"
              class="popover-open"
            >
              <button class="button button-small button-change">
                Add Special Requests
              </button>
            </a>
          </f7-col>
          <f7-col width="20" class="left-column">&nbsp;</f7-col>
        </f7-row>
        <f7-row v-else>
          <f7-col width="10" class="left-column"></f7-col>
          <f7-col width="70" class="left-column">
            <i>{{ specialRequests }}</i>
          </f7-col>
          <f7-col width="20" class="left-column">
            <a
              href="#"
              data-popover=".popup-special-requests"
              class="popover-open"
            >
              <button class="button button-small button-change">Change</button>
            </a>
          </f7-col>
        </f7-row>
        <!-- TIP  SELECTOR --> 
        <f7-row v-if="orderTipChoices && cartHasInStoreProducts">
           <f7-row>
            <f7-col style="margin-top:10px" width="100" class="sub-header">Order Tip (optional)</f7-col>
          </f7-row>

            <div class="tips">
              <article 
                :class="! finalOrderTip || finalOrderTip == 0 ? 'tip-tile tip-tile-selected' : 'tip-tile'"
              >
                <input @input="changeRadioTip" name="orderTip" type="radio" id="noTip" value="0" :checked="! finalOrderTip || finalOrderTip == 0" >
                <span class="label">No Tip   </span>
              </article>
              
              <article 
                v-for="(tip_value,i) in orderTipChoices" 
                :key="i" 
                :class="tip_value==checkoutInfo.order_tip ? 'tip-tile tip-tile-selected' : 'tip-tile'"
              >
                <input 
                  @input="changeRadioTip" 
                  name="orderTip" 
                  type="radio" 
                  :value="tip_value" 
                  :checked="tip_value==checkoutInfo.order_tip"
                  >
                <span class="label">${{parseFloat(tip_value).toFixed(2)}}</span>
              </article>
              
            </div>    
            <div class="list no-hairlines-md" style="width:100%">
            <ul>
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-input-wrap">
                    <input v-model="customOrderTip" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46' @input="changeCustomTip" type="text" placeholder="Enter a custom tip amount less than 99" id="custom-order-tip"/>
                    <span class="input-clear-button"></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </f7-row>

        <p class="tip-disclaimer" v-if="orderTipChoices && cartHasInStoreProducts">
          <span v-if=" checkoutInfo.serviceType == 'delivery'">
            <span v-if="driverTipPercent == 100">
              100% of your tip will go directly to your delivery driver.
            </span>
            <span v-else>
                100% of your tip will be split between your delivery driver and personal shopper.
            </span>
          </span>
          <span v-else>
              100% of your tip will go directly to your personal shopper.
          </span>
        </p>

          <!-- END TIP SELECTOR --> 
        <f7-row divider class="divider"></f7-row>

        <div v-if="cartHasInStoreProducts">
          <template v-if="cartLoyalty.loyaltyCollectAtCheckout">
            <f7-row>
              <f7-col
                width="100"
                class="sub-header"
                v-if="cartLoyalty.loyaltyAccountName"
                >My {{ cartLoyalty.loyaltyAccountName }}</f7-col
              >
              <f7-col width="100" class="sub-header" v-else
                >My Loyalty Card</f7-col
              >
            </f7-row>

            <f7-row v-if="!loyaltyAccountNumber">
              <f7-col width="10" class="left-column">&nbsp;</f7-col>
              <f7-col
                width="70"
                class="left-column"
                v-if="cartLoyalty.loyaltyAccountIdentifier"
              >
                <a
                  href="#"
                  data-popover=".popup-loyalty-card"
                  class="popover-open"
                >
                  <button class="button button-small button-change">
                    Add My {{ cartLoyalty.loyaltyAccountIdentifier }}
                  </button>
                </a>
              </f7-col>
              <f7-col width="70" class="left-column" v-else>
                <a
                  href="#"
                  data-popover=".popup-loyalty-card"
                  class="popover-open"
                >
                  <button class="button button-small button-change">
                    Add My Phone Number
                  </button>
                </a>
              </f7-col>
              <f7-col width="20" class="left-column">&nbsp;</f7-col>
            </f7-row>
            <f7-row v-else>
              <f7-col width="10" class="left-column">&nbsp;</f7-col>
              <f7-col width="70" class="left-column">
                <i>{{ loyaltyAccountNumber }}</i>
              </f7-col>
              <f7-col width="20" class="left-column">
                <a
                  href="#"
                  data-popover=".popup-loyalty-card"
                  class="popover-open"
                >
                  <button class="button button-small button-change">
                    Change
                  </button>
                </a>
              </f7-col>
            </f7-row>

            <f7-row divider class="divider"></f7-row>
          </template>

          <f7-row>
            <f7-col width="100" class="sub-header">My Promo Codes</f7-col>
          </f7-row>

          <template v-for="voucher in cartVoucherResult">
            <f7-row v-bind:key="voucher.voucher_code">
              <f7-col width="10" class="left-column"></f7-col>
              <f7-col width="70" class="left-column" v-if="voucher.valid">{{
                voucher.voucher_code
              }}</f7-col>
              <f7-col
                width="70"
                class="left-column strikethrough"
                v-if="!voucher.valid"
                >{{ voucher.voucher_code }}</f7-col
              >
              <f7-col width="20" class="right-column-money">
                <button
                  v-bind:id="`button-remove-promo-code-${voucher.voucher_code}`"
                  class="button button-small button-change"
                  @click="removePromoCode(voucher.voucher_code)"
                >
                  Remove
                </button>
              </f7-col>
            </f7-row>
          </template>

          <f7-row>
            <f7-col width="10" class="left-column">&nbsp;</f7-col>
            <f7-col width="70" class="left-column">
              <a
                href="#"
                data-popover=".popup-add-promo-code"
                class="popover-open"
              >
                <button
                  id="button-add-promo-code"
                  class="button button-small button-change"
                >
                  Add a Promo Code
                </button>
              </a>
            </f7-col>
            <f7-col width="20" class="left-column">&nbsp;</f7-col>
          </f7-row>

          <f7-row divider class="divider"></f7-row>

          <f7-row>
            <f7-col width="100" class="sub-header">My Order Total</f7-col>
          </f7-row>

          <f7-row>
            <f7-col width="10" class="left-column"></f7-col>
            <f7-col width="70" class="left-column"
              >Grocery Total (Estimated)</f7-col
            >
            <f7-col width="20" class="right-column-money"
              >${{ cartTotals.subtotal.toFixed(2) }}</f7-col
            >
          </f7-row>

          <f7-row v-show="cartTotals.taxRate1 > 0">
            <f7-col width="10" class="left-column"></f7-col>
            <f7-col width="70" class="left-column"
              >{{ cartTotals.taxDesc1 }} ({{ cartTotals.taxRate1.toFixed(3) }}%)
              (Estimated)</f7-col
            >
            <f7-col width="20" class="right-column-money"
              >${{ cartTotals.taxTotal1.toFixed(2) }}</f7-col
            >
          </f7-row>

          <f7-row v-show="cartTotals.taxRate2 > 0">
            <f7-col width="10" class="left-column"></f7-col>
            <f7-col width="70" class="left-column"
              >{{ cartTotals.taxDesc2 }} ({{ cartTotals.taxRate2.toFixed(3) }}%)
              (Estimated)</f7-col
            >
            <f7-col width="20" class="right-column-money"
              >${{ cartTotals.taxTotal2.toFixed(2) }}</f7-col
            >
          </f7-row>

          <f7-row v-show="cartTotals.taxRate3 > 0">
            <f7-col width="10" class="left-column"></f7-col>
            <f7-col width="70" class="left-column"
              >{{ cartTotals.taxDesc3 }} ({{ cartTotals.taxRate3.toFixed(3) }}%)
              (Estimated)</f7-col
            >
            <f7-col width="20" class="right-column-money"
              >${{ cartTotals.taxTotal3.toFixed(2) }}</f7-col
            >
          </f7-row>

          <f7-row>
            <f7-col width="10" class="left-column"></f7-col>
            <f7-col width="70" class="left-column">Convenience Fee</f7-col>
            <f7-col width="20" class="right-column-money"
              >${{ cartTotals.convenienceFee.toFixed(2) }}</f7-col
            >
          </f7-row>

          <f7-row v-if="checkoutInfo.serviceType == 'delivery'">
            <f7-col width="10" class="left-column"></f7-col>
            <f7-col width="70" class="left-column">Delivery Fee</f7-col>
            <f7-col
              width="20"
              class="right-column-money"
              v-if="cartTotals.deliveryFee > 0"
              >${{ cartTotals.deliveryFee.toFixed(2) }}</f7-col
            >
            <f7-col
              width="20"
              class="right-column-money"
              v-if="
                isNaN(cartTotals.deliveryFee) || cartTotals.deliveryFee == 0
              "
              >FREE</f7-col
            >
          </f7-row>

          <template v-for="voucher in cartVoucherResult">
            <f7-row
              v-if="voucher.valid"
              v-bind:key="`c-${voucher.voucher_code}`"
            >
              <f7-col width="10" class="left-column"></f7-col>
              <f7-col width="70" class="left-column"
                >Discount: {{ voucher.voucher_code }}</f7-col
              >
              <f7-col width="20" class="right-column-money"
                >(${{ parseFloat(voucher.discount_amount).toFixed(2) }})</f7-col
              >
            </f7-row>
          </template>

          <f7-row class="row-total">
            <f7-col width="10" class="left-column"></f7-col>
            <f7-col width="70" class="left-column">Estimated total</f7-col>
            <f7-col width="20" class="right-column-money"
              >${{ cartTotals.total.toFixed(2) }}</f7-col
            >
          </f7-row>
          <f7-row v-if="orderTipChoices && cartHasInStoreProducts">
            <f7-col width="10" class="left-column"></f7-col>
            <f7-col width="70" class="left-column">Order Tip</f7-col>
            <f7-col width="20" class="right-column-money"
              >${{(parseFloat(finalOrderTip).toFixed(2))}}</f7-col
            >
          </f7-row>
          <f7-row class="row-total" v-if="orderTipChoices && cartHasInStoreProducts">
            <f7-col width="10" class="left-column"></f7-col>
            <f7-col width="70" class="left-column">Estimated Total With Tip</f7-col>
            <f7-col width="20" class="right-column-money"
              >${{(parseFloat(finalOrderTip)+parseFloat(cartTotals.total)).toFixed(2)}}</f7-col
            >
          </f7-row>

          <f7-row divider class="divider"></f7-row>
        </div>
        <div class="container-terms">
          By placing your order, you agree to be bound by the
          <f7-link href="#" @click="openTermsLink">Terms of Service</f7-link
          >&nbsp;and
          <f7-link href="#" @click="openPrivacyLink">Privacy Policy</f7-link>.
          <!-- Your card will be temporarily authorized for ${{cartTotals.totalAuth.toFixed(2)}}. -->
          Your card may be temporarily authorized for a slightly higher amount
          to account for variability in products sold by weight. Your bank
          statement will reflect the correct final order total after order
          completion. Plastic bag fees may be added to your final total if
          required by law and will be visible on your receipt after delivery.
        </div>

        <f7-row divider class="divider"></f7-row>

        <f7-row divider class="divider"></f7-row>

        <f7-row>
          <f7-col width="100" class="row-checkout">
            <button
              id="button-submit-order"
              class="button button-small button-checkout"
              @click="submitOrder"
            >
              Place My Order
            </button>
          </f7-col>
        </f7-row>

        <p>&nbsp;</p>
      </div>
    </div>

    <div class="spacer-bottom"></div>

    <div class="popover popup-delivery-instructions">
      <div class="popover-inner">
        <div class="popover-block">
          <div class="popover-header">Order Delivery Instructions</div>

          <div class="popover-content">
            <textarea
              class="popover-textarea"
              id="input-delivery-instructions"
              placeholder="Call me when you arrive"
            ></textarea>
          </div>

          <div class="popover-footer">
            <a class="link popover-close" @click="cancelDeliveryInstructions">
              <button class="button-small button-cancel">Cancel</button>
            </a>
            <div class="spacer-button"></div>
            <a class="link popover-close" @click="saveDeliveryInstructions">
              <button class="button-small">Save</button>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="popover popup-special-requests">
      <div class="popover-inner">
        <div class="popover-block">
          <div class="popover-header">
            Special Instructions, Requests and Notes for Your Personal Shopper
          </div>

          <div class="popover-content">
            <textarea
              class="popover-textarea"
              id="input-special-requests"
              placeholder="Add 2 lbs of potato salad from the deli"
            ></textarea>
          </div>

          <div class="popover-footer">
            <a class="link popover-close" @click="cancelSpecialRequests">
              <button class="button-small button-cancel">Cancel</button>
            </a>
            <div class="spacer-button"></div>
            <a class="link popover-close" @click="saveSpecialRequests">
              <button class="button-small">Save</button>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="popover popup-loyalty-card">
      <div class="popover-inner">
        <div class="popover-block">
          <div
            class="popover-header"
            v-if="cartLoyalty.loyaltyAccountIdentifier"
          >
            Add My {{ cartLoyalty.loyaltyAccountIdentifier }}
          </div>
          <div class="popover-header" v-else>Add My Rewards Phone Number</div>

          <div class="popover-content">
            <input
              type="text"
              class="popover-input"
              id="input-loyalty-number"
            />
          </div>

          <div class="popover-footer">
            <a class="link popover-close" @click="cancelLoyaltyNumber">
              <button class="button-small button-cancel">Cancel</button>
            </a>
            <div class="spacer-button"></div>
            <a class="link popover-close" @click="saveLoyaltyNumber">
              <button class="button-small">Save</button>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="popover popup-add-promo-code">
      <div class="popover-inner">
        <div class="popover-block">
          <div class="popover-header">Add Promo Code</div>

          <div class="popover-content">
            <input type="text" class="popover-input" id="input-promo-code" />
          </div>

          <div class="popover-footer">
            <a class="link popover-close" @click="cancelPromoCode">
              <button class="button-small button-cancel">Cancel</button>
            </a>
            <div class="spacer-button"></div>
            <a class="link popover-close" @click="savePromoCode">
              <button class="button-small">Save</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import $$ from "dom7";

import moment from "moment";

import ShopHeroApi from "../api/ShopHeroApi";

import ShCheckoutBar from "../components/ShCheckoutBar.vue";

import ShProductBox from "../components/ShProductBox.vue";
import ShCheckoutReviewEa from "../components/ShCheckoutReviewEa.vue";

import CreditCardIconMoney from "../images/creditcards/money.png";
import CreditCardIconCredit from "../images/creditcards/credit.png";
import CreditCardIconVisa from "../images/creditcards/visa.png";
import CreditCardIconMasterCard from "../images/creditcards/mast.png";
import CreditCardIconDiscover from "../images/creditcards/disc.png";
import CreditCardIconAmex from "../images/creditcards/amex.png";

import CdBasketIcon from "../components/icons/CdBasketIcon.vue";

export default {
  data() {
    return {
      productsPerRow: 3,
      deliveryInstructions: null,
      loyaltyAccountNumber: null,
      specialRequests: null,
      submissionInProgress: false,
      errorMessage: null,
      //radio tip value
      orderTip: 0,
      //input custom value
      customOrderTip: '',
      //final value who send to backend
      finalOrderTip: 0,
      //order Tip config
      orderTipChoices: [],
      driverTipPercent: 100,
      enableSpecialRequests:true,
    };
  },

  components: {
    ShCheckoutBar,
    ShProductBox,
    ShCheckoutReviewEa,
    CdBasketIcon,
  },

  computed: {
    selectedLocation: ({ $store }) => $store.getters.selectedLocation,
    checkoutInfo: ({ $store }) => $store.getters.getCheckoutInfo,
    cartKey: ({ $store }) => $store.getters.cartKey,
    serviceLabel: ({ checkoutInfo }) => {
      if (checkoutInfo.serviceType === "pickup") {
        return "In-Store Pick Up";
      }
      return "Grocery Delivery";
    },
    // cartItems: ({ $store }) => { return $store.getters.productsInCart },
    cartItems: ({ $store }) => {
      const productsInCart = [];
      $store.getters.productsInCart.forEach(function (item) {
        productsInCart.push(item.product);
      });

      // console.log(productsInCart);

      return productsInCart;
    },
    eaCartCheckoutTotals: ({ $store }) => $store.getters.eaCartCheckoutTotals,
    cartHasEaProducts: ({ $store }) => $store.getters.cartHasEaProducts,
    cartHasInStoreProducts: ({ $store }) =>
      $store.getters.cartHasInStoreProducts,
    cartItemsChipText: ({ $store }) =>
      $store.getters.inStoreProductsInCartCount + " items",
    deliveryTime: ({ checkoutInfo }) => {
      if (!checkoutInfo) return "";
      let timeslotParts = checkoutInfo.timeSlot.split("_");
      // console.log(timeslotParts)
      const t1 =
        timeslotParts[0] +
        " " +
        timeslotParts[1].substring(0, 2) +
        ":" +
        timeslotParts[1].charAt(2) +
        timeslotParts[1].charAt(3);
      const t2 =
        timeslotParts[0] +
        " " +
        timeslotParts[2].substring(0, 2) +
        ":" +
        timeslotParts[2].charAt(2) +
        timeslotParts[2].charAt(3);
      const m1 = new moment(t1);
      const m2 = new moment(t2);

      if (m1.isSame(new moment(), "day")) {
        return (
          "Today<br/>" + m1.format("h:mm a") + " to " + m2.format("h:mm a")
        );
      } else if (m1.isSame(new moment().add(1, "days"), "day")) {
        return (
          "Tomorrow, " +
          m1.format(" MMMM Do") +
          "<br/>" +
          m1.format("h:mm a") +
          " to " +
          m2.format("h:mm a")
        );
      } else {
        return (
          m1.format("dddd, MMMM Do") +
          "<br/>" +
          m1.format("h:mm a") +
          " to " +
          m2.format("h:mm a")
        );
      }
    },
    pickupTime: ({ checkoutInfo }) => {
      if (!checkoutInfo) return "";
      let timeslotParts = checkoutInfo.timeSlot.split("_");
      const t1 =
        timeslotParts[0] +
        " " +
        timeslotParts[2].substring(0, 2) +
        ":" +
        timeslotParts[2].charAt(2) +
        timeslotParts[2].charAt(3);
      const m1 = new moment(t1);

      if (m1.isSame(new moment(), "day")) {
        return "Today<br/>" + m1.format("h:mm a");
      } else if (m1.isSame(new moment().add(1, "days"), "day")) {
        return (
          "Tomorrow, " + m1.format(" MMMM Do") + "<br/>" + m1.format("h:mm a")
        );
      } else {
        return m1.format("dddd, MMMM Do") + "<br/>" + m1.format("h:mm a");
      }
    },
    cartTotals: ({ $store }) => $store.getters.cartTotals,
    cartLoyalty: ({ $store }) => $store.getters.cartLoyalty,
    cartVoucherResult: ({ $store }) => $store.getters.cartVoucherResult,
    appPackage: ({ $store }) => $store.getters.appPackage,
    appVersion: ({ $store }) => $store.getters.appVersion,
  },
  watch: {
    cartItems: function (val) {
      // console.log("cartItems changed");
      const swiperParent = this.matchingDescendants(this, /swiper/, {
        first: true,
      });
      if (swiperParent) {
        if (swiperParent.swiper) {
          const swiper = swiperParent.swiper;
          // this doesn't seem to be doing what it's supposed to
          swiper.update();
        }
      }
    },
  },
  methods: {
    getIconForCard(cardType) {
      switch (cardType) {
        case "VISA":
          return "images/visa.png";
        case "MAST":
          return "images/mast.png";
        case "DISC":
          return "images/disc.png";
        case "AMEX":
          return "images/amex.png";
        case "CASH":
          return "images/money.png";
      }
    },

    saveDeliveryInstructions() {
      this.deliveryInstructions = $$("#input-delivery-instructions").val();

      const orderInfo = { deliveryInstructions: this.deliveryInstructions };
      this.$store.dispatch("checkoutMergeInfo", { orderInfo });
    },

    cancelDeliveryInstructions() {
      $$("#input-delivery-instructions").val(this.deliveryInstructions);
    },

    saveSpecialRequests() {
      this.specialRequests = $$("#input-special-requests").val();

      const orderInfo = { specialRequests: this.specialRequests };
      this.$store.dispatch("checkoutMergeInfo", { orderInfo });
    },

    cancelSpecialRequests() {
      $$("#input-special-requests").val(this.specialRequests);
    },

    saveLoyaltyNumber() {
      this.loyaltyAccountNumber = $$("#input-loyalty-number").val();

      const orderInfo = { loyaltyAccountNumber: this.loyaltyAccountNumber };
      this.$store.dispatch("checkoutMergeInfo", { orderInfo });
    },

    cancelLoyaltyNumber() {
      $$("#input-loyalty-number").val(this.loyaltyAccountNumber);
    },

    savePromoCode() {
      const self = this;

      const buttonContent = $$("#button-add-promo-code").html();
      $$("#button-add-promo-code").html(
        // '<div class="preloader color-white"></div>'
        "Please wait..."
      );

      const promoArgs = {
        promo_code: $$("#input-promo-code").val(),
      };

      ShopHeroApi.addPromoCode(
        this.selectedLocation.physical_location_id,
        this.cartKey,
        promoArgs,
        (response) => {
          // console.log(response)

          this.$store
            .dispatch("populateCart", {
              physicalLocationId: this.selectedLocation.physical_location_id,
            })
            .then(() => {
              $$("#button-add-promo-code").html(buttonContent);
            });
        }
      );
    },

    removePromoCode(promoCode) {
      const self = this;

      const buttonElement = $$("#button-remove-promo-code-" + promoCode);
      const buttonContent = buttonElement.html();
      buttonElement.html("Please wait...");

      const promoArgs = {
        promo_code: promoCode,
      };

      ShopHeroApi.removePromoCode(
        this.selectedLocation.physical_location_id,
        this.cartKey,
        promoArgs,
        (response) => {
          // console.log(response)

          this.$store
            .dispatch("populateCart", {
              physicalLocationId: this.selectedLocation.physical_location_id,
            })
            .then(() => {
              buttonElement.html(buttonContent);
            });
        }
      );
    },

    cancelPromoCode() {
      $$("#input-loyalty-number").val("");
    },

    formatPhoneNumber(s) {
      var s2 = ("" + s).replace(/\D/g, "");
      var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
      return !m ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
    },
    matchingDescendants(vm, matcher, options) {
      let descendants = vm.$children;
      let descendant;
      let returnFirst = (options || {}).first;
      let matches = [];

      for (let x = 0; x < descendants.length; x++) {
        descendant = descendants[x];

        if (matcher.test(descendant.$vnode.tag)) {
          if (returnFirst) {
            return descendant;
          } else {
            matches.push(descendant);
          }
        }

        for (let y = 0, len = descendant.$children.length; y < len; y++) {
          descendants.push(descendant.$children[y]);
        }
      }

      return matches.length === 0 ? false : matches;
    },
    openExternalLink(href) {
      if (typeof cordova !== "undefined") {
        cordova.InAppBrowser.open(
          href,
          "_blank",
          "location=yes,usewkwebview=yes"
        );
      } else {
        window.open(href, "_system");
      }
    },
    openTermsLink() {
      this.openExternalLink(this.selectedLocation.physical_location_terms_link);
    },
    openPrivacyLink() {
      this.openExternalLink(
        this.selectedLocation.physical_location_privacy_policy_link
      );
    },
    scrollTo(element) {
      const scrollToElement = $$(element);
      const pageContent = $$(".page-content");
      const scrollTo = pageContent.offset().top - scrollToElement.offset().top;
      pageContent.scrollTop(scrollTo, 300);
    },
    logAnalyticsCheckoutCompleted() {
      if (typeof facebookConnectPlugin !== "undefined") {
        facebookConnectPlugin.logPurchase(
          parseFloat(this.cartTotals),
          "USD",
          function () {
            // log success
          },
          function () {
            // log error
          }
        );
      }
    },
    submitOrder() {
      if (this.submissionInProgress) return;

      if (this.cartKey == "new") return;

      const self = this;

      const buttonElement = $$("#button-submit-order");
      const buttonContent = buttonElement.html();
      buttonElement.html("Placing your order...");

      let userAgent = navigator.userAgent;

      if (typeof device !== "undefined") {
        userAgent +=
          " [" +
          device.platform +
          ":" +
          device.manufacturer +
          ":" +
          device.model +
          ":" +
          device.version +
          "]";
      }

      let clientVersion = "dev";
      if (this.appPackage !== null)
        clientVersion = this.appPackage + ":" + this.appVersion;

      let order_tip = parseFloat(this.finalOrderTip);

      const orderArgs = {
        cart_key: this.cartKey,
        special_requests: this.specialRequests,
        loyalty_account_number: this.loyaltyAccountNumber,
        delivery_instructions: this.deliveryInstructions,
        instore_rewards_apply: true,
        client_user_agent: userAgent,
        client_version: clientVersion,
        order_tip: order_tip
      };

      this.submissionInProgress = true;
      ShopHeroApi.submitOrder(
        self.selectedLocation.physical_location_id,
        orderArgs,
        (response) => {
          this.submissionInProgress = false;

           //console.log(response)

          if (response.success) {
            //reset the defined tip when order is submitted successfully
            const orderInfo = { is_a_user_defined_order_tip: false };
            this.$store.dispatch("checkoutMergeInfo", { orderInfo });
            const toast = this.$f7.toast.create({
              icon: '<i class="f7-icons" style="margin-bottom: 5px;">paperplane</i>',
              text: "Order placed!",
              closeTimeout: 2500,
            });
            toast.open();
            if (navigator) {
              if (typeof navigator.vibrate === "function") {
                navigator.vibrate(250);
              }
            }

            this.logAnalyticsCheckoutCompleted();

            if (typeof AppRate !== "undefined") {
              AppRate.promptForRating(true);
            }

            this.$store.dispatch("resetCartKey").then(function () {
              self.$store
                .dispatch("populateCart", {
                  physicalLocationId:
                    self.selectedLocation.physical_location_id,
                })
                .then(() => {
                  if (response.ea_order_key && response.order_key) {
                    self.$f7.views.main.router.navigate(
                      `/order-placed/${response.ea_order_key}/${response.order_key}/`
                    );
                  } else if (response.ea_order_key && !response.order_key) {
                    self.$f7.views.main.router.navigate(
                      `/orders/${response.ea_order_key}/`
                    );
                  } else {
                    self.$f7.views.main.router.navigate(
                      `/orders/${response.order_key}/`
                    );
                  }

                  buttonElement.html(buttonContent);
                });
            });
          } else {

            // We save the tip and if it is defined by the user in the state, in case it redirects us back we want it to be saved
            const orderInfo = { order_tip: order_tip, is_a_user_defined_order_tip: true };
            this.$store.dispatch("checkoutMergeInfo", { orderInfo });

            if (
              response.error_code === "CHECKOUT_ERROR_TIMESLOT_NOT_AVAILABLE"
            ) {
              const orderInfo = {
                errorMessage:
                  "Uh oh! The delivery timeslot you requested is no longer available. Timeslots are only reserved for 15 minutes. Please select another timeslot.",
              };
              this.$store
                .dispatch("checkoutMergeInfo", { orderInfo })
                .then(function () {
                  self.$f7.views.main.router.navigate(`/checkout-schedule/`);
                  buttonElement.html(buttonContent);
                });
              return;
            }

            if (response.error_code === "CHECKOUT_ERROR_PAYMENT_DECLINED") {
              const orderInfo = {
                errorMessage: "Uh oh! " + response.error_message,
              };
              this.$store
                .dispatch("checkoutMergeInfo", { orderInfo })
                .then(function () {
                  self.$f7.views.main.router.navigate(`/checkout-payment/`);
                  buttonElement.html(buttonContent);
                });
              return;
            }

            if (
              response.error_code === "CHECKOUT_ERROR_CHECKOUT_DATA_MISMATCH"
            ) {
              const orderInfo = {
                errorMessage: "Uh oh! " + response.error_message,
              };
              this.$store
                .dispatch("checkoutMergeInfo", { orderInfo })
                .then(function () {
                  self.$f7.views.main.router.navigate(`/checkout-payment/`);
                  buttonElement.html(buttonContent);
                });
              return;
            }

            self.errorMessage =
              "Uh oh! An error has occurred: " + response.error_message;
            this.scrollTo(".spacer-top");
            buttonElement.html(buttonContent);
          }
        },
        (error) => {
          this.submissionInProgress = false;
          const orderInfo = {
            errorMessage:
              "Uh oh! An error communicating with the server has occurred. Please try your request again.",
          };
          this.$store
            .dispatch("checkoutMergeInfo", { orderInfo })
            .then(function () {
              self.$f7.views.main.router.navigate(`/checkout-payment/`);
              buttonElement.html(buttonContent);
            });
          return;
        }
      );
    },
    //when the radio value change then change que final tip and set empty the custom tip
    changeRadioTip(evt){
      this.customOrderTip="";
      this.finalOrderTip=evt.target.value;
      $$('.tip-tile').removeClass('tip-tile-selected');
      $$(evt.target.parentElement).addClass('tip-tile-selected');
    },
    //when the custom tip is change then uncheck the radio input
    changeCustomTip(evt){
      $$('input[name="orderTip"]').prop('checked',false);
      $$('.tip-tile').removeClass('tip-tile-selected');
    
      if(isNaN(this.customOrderTip) || this.customOrderTip==""|| this.customOrderTip<0){
        $$('input[id=noTip]').prop('checked',true);
        $$('input[id=noTip]').addClass('tip-tile-selected');
        this.finalOrderTip = 0;
      }else if(this.customOrderTip > 99){
        this.customOrderTip="";
        this.finalOrderTip=0.00;
      }
      else{
        this.finalOrderTip = parseFloat(this.customOrderTip).toFixed(2);
      }
    }
  },

  mounted() {
    //get tip config
    ShopHeroApi.getOrderTipConfig(this.selectedLocation.physical_location_id,(response)=>{
      if(response.success) {
        this.enableSpecialRequests = response.enable_special_requests;
        if(!this.checkoutInfo.orderTipChoices){
          const orderInfo = { orderTipChoices: response.order_tip_config.tip_values };
          this.$store.dispatch("checkoutMergeInfo", { orderInfo });
        }
        this.orderTipChoices = response.order_tip_config.tip_values;
        this.driverTipPercent = response.order_tip_config.driver_tip_percent;

        if (this.checkoutInfo.order_tip) {
          this.finalOrderTip = this.checkoutInfo.order_tip;
          if(this.checkoutInfo.orderTipChoices && !this.checkoutInfo.orderTipChoices.includes(this.finalOrderTip.toString())){
            this.customOrderTip = this.checkoutInfo.order_tip &&  this.checkoutInfo.order_tip > 0 ? this.checkoutInfo.order_tip : '';
          }
        }else{
          //if is the first time, and not set a tip before then set by default the first tip value
          if(this.checkoutInfo.is_a_user_defined_order_tip!=true && this.orderTipChoices.length > 0){
            this.checkoutInfo.order_tip = this.orderTipChoices[0];
            this.finalOrderTip = this.orderTipChoices[0];
          }
        }
      }
    });
    

    if (this.cartKey == "new") {
      self.$f7.views.main.router.navigate(`/`);
    }

    if (this.checkoutInfo.address_notes)
      this.deliveryInstructions = this.checkoutInfo.address_notes;

    if (this.checkoutInfo.deliveryInstructions)
      this.deliveryInstructions = this.checkoutInfo.deliveryInstructions;

    this.loyaltyAccountNumber = this.cartLoyalty.loyaltyAccountNumber;
    if (this.checkoutInfo.loyaltyAccountNumber)
      this.loyaltyAccountNumber = this.checkoutInfo.loyaltyAccountNumber;

    if (this.checkoutInfo.specialRequests)
      this.specialRequests = this.checkoutInfo.specialRequests;

    
  },
};
</script>

<style lang="scss" scoped="true">
.review-page {
  background: white !important;
}
.spacer-top {
  margin-top: 0px;
}

.spacer-bottom {
  height: calc(270px + var(--sab));
}

.review-content {
  margin: 20px 10px 48px !important;

  .review-header {
    text-align: center;
    color: $storePrimaryColor;
  }
  .row {
    margin-bottom: 10px;
  }

  .right-column {
    color: $storePrimaryColor;
    font-weight: $bold;
  }
  .right-column-money {
    // color: $storePrimaryColor;
    // font-weight: $bold;
    text-align: right;
    margin-right: 10px;
  }
  .column-header {
    // font-weight: $bold;
    text-decoration: underline;
  }
  .sub-header {
    font-size: 1.1em;
    font-weight: $bold;
    // color: $storePrimaryColor;
  }

  a {
    color: $storePrimaryColor;
  }

  p {
    font-size: 0.75em;
    margin-bottom: 10px;
  }
  .chip {
    margin: -3px auto 0 auto;
    background-color: $storePrimaryColor;
    color: #fff;
    font-size: 12px;
    min-width: 18px;
  }
  .button-change {
    font-size: 10px;
    padding: 0px;
  }

  .row-total {
    font-weight: $bold;
    font-size: 1.1em;
    border-top: 1px solid #eee;
    margin-top: 0px;
    padding-top: 4px;
  }

  .container-terms {
    border-radius: 5px;
    background-color: #eee;
    padding: 10px;
    font-size: 10px;
    color: #444;
  }

  .button-checkout {
    font-size: 16px;
    height: 40px;
  }
}

.popover-block {
  font-size: 1.2em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.popover-header {
  width: 100%;
  border-bottom: 0.5px solid #ccc;
  text-align: center;
  font-weight: $bold;
  top: 0px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.popover-content {
  padding-left: 20px;
  padding-right: 20px;
}

.popover-footer {
  width: 100%;
  border-top: 0.5px solid #ccc;
  text-align: center;
  font-weight: $bold;
  padding-top: 10px;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.popover-input {
  width: 100%;
  height: 30px;
}

.popover-textarea {
  width: 100%;
  height: 100px;
}

.button-cancel {
  background-color: #ccc;
}

.spacer-button {
  display: inline-block;
  width: 10px;
}

.strikethrough {
  text-decoration: line-through;
}

.button-small {
  width: 100%;
}

.card-error {
  background-color: $colorRed;
  color: #fff;
}

.logo-wrapper {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 80px;
  }
}

.chip {
  padding: 5px;
}

.cd-basket-icon {
  width: 35px;
  height: auto;
}

.cart-header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.75rem;
  justify-content: center;
  margin-bottom: 2rem !important;
}

.checkout-flex-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px 0 5px;
  margin: auto;
}

/* TIP SECTION */ 
.tips{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}
.tip-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5em;
  height: 3em;
  background-color: white;
  border: solid 1px #d5d4d2;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.tip-tile-selected {
  border: solid 2px $storePrimaryColor !important;
}

.tip-tile:hover {
  box-shadow: 0 0 20px -12px rgba(0, 0, 0, 0.4);
}

.tip-tile input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.tip-tile .label {
  font-size: 1.1em;
  font-weight: 10px;
}

.tip-tile input[type="radio"]:checked ~ .label {
  color: $storePrimaryColor;
}
.tip-disclaimer span{
  margin: -10px 0 50px 0;
  font-size: 1.2em;
  color: #999;
}
.inputTip{
  width: 100%;
}

/*END TIP SECTION */
</style>
