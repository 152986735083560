import { render, staticRenderFns } from "./ShShoppingCartSide.vue?vue&type=template&id=06971177&scoped=true&"
import script from "./ShShoppingCartSide.vue?vue&type=script&lang=js&"
export * from "./ShShoppingCartSide.vue?vue&type=script&lang=js&"
import style0 from "./ShShoppingCartSide.vue?vue&type=style&index=0&id=06971177&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06971177",
  null
  
)

export default component.exports