<template>
  <div class="popup popup-shipping">
    <div class="page-content">
      <sh-address-editor
        id="editor-shipping-address"
        address-type="shipping"
        :states="states"
        :store-location="selectedLocation"
        v-if="selectedShippingAddressId === 0"
        @newAddressStored="$emit('newAddressStored', $event)"
      />
    </div>
  </div>
</template>

<script>
import ShAddressEditor from "../../components/ShAddressEditor.vue";

export default {
  props: ["states", "selectedLocation", "selectedShippingAddressId"],
  components: { ShAddressEditor },
};
</script>
