/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: "warn" */

import ShopHeroApi from '../../api/ShopHeroApi' // eslint-disable-line no-unused-vars

import * as types from '../mutation-types' // eslint-disable-line no-unused-vars

// initial state
const state = {
  initialized: false,
  favoriteProductsId: [],
  favoriteProductsFull: [],
}

// getters
const getters = {
  favoriteProductsId: state => state.favoriteProductsId,
  favoriteProductsFull: state => state.favoriteProductsFull,
}


// actions
const actions = {
  populateFavorites({ commit }, { physicalLocationId }) {
    // if(state.favoriteProductsId.size()) return
    return new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
      ShopHeroApi.getFavorites(false, physicalLocationId, (response) => {
        commit(types.RECEIVE_FAVORITES, { response })
        resolve()
      })
    })
  },
  populateFavoritesFull({ commit }, { physicalLocationId }) {
    // if(state.favoriteProductsFull.size()) return
    return new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
      ShopHeroApi.getFavorites(true, physicalLocationId, (response) => {

        const products = []
        if(response.favorite_products_full) {
          response.favorite_products_full.forEach(function(p) {
            const product = ShopHeroApi.convertApiProductToProductModel(p, p.product_key)
            products.push(product)
          })
        }

        commit(types.RECEIVE_FAVORITES, {
          favorite_products_id: response.favorite_products_id,
          favorite_products_full: products
        })

        resolve()
      })
    })
  },
}

// mutations
const mutations = {
  [types.RECEIVE_FAVORITES](state, { favorite_products_id, favorite_products_full }) {
    state.favoriteProductsId = favorite_products_id
    state.favoriteProductsFull = favorite_products_full
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
