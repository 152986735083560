<template>
  <!-- v-show to hide the card if there are no products -->
  <f7-card v-observe-visibility="visibilityChanged">
    <f7-card-header class="products-card-header">
      <f7-row style="width: 100%;">
        <template v-if="showRightLink">
          <!-- show with right "view more" link -->
          <f7-col width="66" class="header-left">{{ headerTextStatic }} {{ category.category_name }}</f7-col>
          <f7-col width="33" class="header-right">
            <template v-if="customLink">
              <f7-link v-bind:href="customLink">
                <span v-html="customLinkText"></span>
              </f7-link>
            </template>
            <template v-else>
              <template v-if="!searchResultMode">
                <f7-link
                  v-bind:href="`/product-browse/${this.categoryKey}/`"
                >View {{ viewAdditionalProductsText }} more &gt;</f7-link>
              </template>
              <template v-if="searchResultMode">
                <f7-link
                  v-bind:href="`/search/products-query/${this.searchQuery}/${this.categoryKey}/`"
                >View {{ viewAdditionalProductsText }} more &gt;</f7-link>
              </template>
            </template>
          </f7-col>
        </template>
        <template v-else>
          <!-- no right link, title is full width -->
          <f7-col width="100" class="header-left">
            <p>{{ headerTextStatic }} {{ category.category_name }}</p>
          </f7-col>
          <!--    <template v-if="additionalProductCount > 0 && !isLeaf && !noLink">
        <f7-col width="34"
                class="header-right" >
                <f7-link v-bind:href="`/product-browse/${this.categoryKey}/`">
          <p>View {{ additionalProductCount }} more &gt;</p>
                </f7-link>
        </f7-col>
          </template>-->
        </template>
        <f7-col width="100" v-if="searchResultMode" class="header-sub-link">
          <f7-link
            v-if="category.child_product_count"
            v-bind:href="`/product-browse/${this.categoryKey}/`"
          >
            View all {{ category.child_product_count.toLocaleString() }} products in
            <i>{{ category.category_name }}</i> &gt;
          </f7-link>
        </f7-col>
      </f7-row>
    </f7-card-header>
    <f7-card-content>
      <template v-if="!isLeaf">
        <f7-swiper
          :init="true"
          :params="{slidesPerView: productsPerRow, spaceBetween: 20, slidesPerGroup: 2, preloadImages: false, observer: false, watchOverflow: true, preventClicks: true, preventClicksPropagation: true, passiveListeners: true, lazy: {loadPrevNext: false, loadOnTransitionStart: true, loadPrevNextAmount: 0}, lazyLoading: true, watchSlidesVisibility: true, loop: false}"
        >
          <f7-swiper-slide
            v-for="product in products"
            v-bind:key="randomInteger() +':'+ product.productKey"
          >
            <ShProductBoxEA v-if="product.isEndlessAisle" :product="product" swiper="true" />
            <ShProductBox v-else :product="product" swiper="true" />
          </f7-swiper-slide>
        </f7-swiper>
      </template>
      <template v-else>
        <div class="product-container">
          <div v-for="product in products" class="product-container-individual" :key="randomInteger() +':'+ product.productKey">
            <ShProductBoxEA v-if="product.isEndlessAisle" :product="product" />
            <ShProductBox v-else :product="product" />
          </div>
        </div>
        <div class="visbility-bottom" v-observe-visibility="visibilityChangedBottom">
          <div class="bottom-loader" v-show="products.length > 0 && apiLoading">
            <center>
              <div class="preloader"></div>
            </center>
          </div>
        </div>
      </template>
      <div v-show="products.length === 0">
        <div v-if="noProductsReturned">No items currently available in this category</div>
        <div v-else class="swiper-loading"><div class="preloader"></div></div>
      </div>
    </f7-card-content>
  </f7-card>
</template>

<script>
import { ObserveVisibility } from "vue-observe-visibility";

import ShopHeroApi from "../api/ShopHeroApi";

import ShProductBox from "./ShProductBox.vue";
import ShProductBoxEA from "./ShProductBoxEA.vue";

import Product from "../models/Product";

export default {
  /* eslint max-len: "warn" */

  data() {
    return {
      initialized: false,
      populated: false,
      apiLoading: false,
      isVisible: false,
      headerTextStatic: null,
      category: {},
      products: [],
      // api is finished and returned no items
      noProductsReturned: false,
      unfilteredProductsCount: 0,
      unfilteredSearchProductsCount: 0,
      apiLimit: 18,
      apiOffset: 0,
      productsPerRow: 3
    };
  },

  directives: {
    "observe-visibility": ObserveVisibility
  },

  props: {
    sourceProducts: Array,
    categoryKey: String,
    headerText: String,
    searchQuery: String,
    limit: Number,
    offset: Number,
    loadCategoryFromApi: Boolean,
    isLeaf: Boolean,
    noLink: Boolean,
    customLink: String,
    customLinkText: String,
    autoLoad: {
      type: Boolean,
      default: false
    },
    searchResultMode: {
      type: Boolean,
      default: false
    }
  },

  components: {
    ShProductBox, ShProductBoxEA
  },

  computed: {
    viewAdditionalProductsText() {
      return this.additionalProductCount <= 100 ? this.additionalProductCount : `100+`
    },
    // /groupTitle: ({ category }) => category.category_name,
    additionalProductCount: ({ unfilteredProductsCount }) =>
      Math.max(unfilteredProductsCount - 3, 0),
    showRightLink: ({ additionalProductCount, isLeaf, noLink, customLink }) =>
      (additionalProductCount > 0 && !isLeaf && !noLink) || customLink
  },

  methods: {
    populateCategoryFromApi: function(forceLoad) {
      // eslint-disable-line
      if (this.limit > 0) {
        this.apiLimit = this.limit;
      }
      if (this.offset > 0) {
        this.apiOffset = this.offset;
      }

      if (this.products.length === 0 || forceLoad) {
        if (this.categoryKey.length > 0) {
          this.apiLoading = true;
          ShopHeroApi.getCategoryProductsByKey(
            this.$store.state.locations.selectedLocation.physical_location_id,
            this.categoryKey,
            this.apiOffset,
            this.apiLimit,
            response => {
              this.unfilteredProductsCount = response.products_count;
              const self = this;
              response.filtered_products.forEach(function(p) {
                const product = ShopHeroApi.convertApiProductToProductModel(
                  p,
                  p.product_key
                );
                self.products.push(product);
                // console.log(product)
              });
              self.apiLoading = false;
              //check if the array of products is empty, if it is empty it will hide the card
              if(this.products.length===0){
                this.noProductsReturned=true;
              }
            }
          );
        }
      }
    },
    visibilityChanged: function(isVisible, entry) {
      // eslint-disable-line
      // console.log('visibilityChanged: ' + isVisible)
      this.isVisible = isVisible;

      if (this.limit) this.apiLimit = this.limit;

      if (
        this.categoryKey &&
        isVisible &&
        !this.autoLoad &&
        !this.apiLoading &&
        this.loadCategoryFromApi &&
        !this.populated
      ) {
        // this.populateCategoryFromApi()
        // intentionally delay loading to allow UX animations to catch up
        setTimeout(
          this.populateCategoryFromApi,
          1000 + Math.floor(Math.random() * Math.floor(200))
        );
        if (!this.isLeaf) {
          this.loadInSlideImages(this.productsPerRow);
        }
      } else {
        if (isVisible && !this.isLeaf) {
          this.loadInSlideImages(this.productsPerRow);
        }
        if (isVisible && !this.autoLoad && !this.populated) {
          this.products = this.sourceProducts.slice(0, this.apiLimit);
          this.unfilteredProductsCount = this.sourceProducts.length;
        }
      }

      if (isVisible) this.populated = true;
    },
    loadInSlideImages: function(loadPrevNextAmount) {
      try {
        if (!this.$children[0].$children[1].$children[0]) return;
      } catch (ex) {
        //noop
      }

      const swiper = this.$children[0].$children[1].$children[0].swiper; // eslint-disable-line
      if (swiper) {
        this.$nextTick(() => {
          swiper.update();

          if (!this.isVisible && !this.autoLoad) loadPrevNextAmount = 0;

          for (
            let i = 0;
            i < Math.min(this.products.length, loadPrevNextAmount);
            i += 1
          ) {
            swiper.lazy.loadInSlide(i);
          }
        });
      }
    },
    randomInteger(max = Number.MAX_SAFE_INTEGER) {
      return Math.floor(Math.random() * max);
    },
    determineProductsPerRow(e) {
      // const w = window.screen.width
      const w = window.innerWidth;
      if (w > 1000) {
        this.productsPerRow = 5;
      } else if (w > 740) {
        this.productsPerRow = 4;
      } else {
        this.productsPerRow = 3;
      }
      this.apiLimit = this.productsPerRow * 4;
    },
    linkToCategory() {},
    visibilityChangedBottom(visible) {
      // console.log(
      //   "visibilityChangedBottom",
      //   visible,
      //   this.apiOffset,
      //   this.unfilteredProductsCount
      // );
      if (
        visible &&
        this.products.length > 0 &&
        this.products.length < this.unfilteredProductsCount
      ) {
        this.apiOffset = this.products.length;
        this.populateCategoryFromApi(true);
      }
    }
  },

  watch: {
    products: function(val) {
      // eslint-disable-line
      this.loadInSlideImages(this.productsPerRow);
    },
    sourceProducts: function(val) {
      // eslint-disable-line
      if (this.isLeaf) {
        this.products = val;
        this.unfilteredProductsCount = this.products.length;
      }

      this.$root.$f7.lazy.create(".product-image");
    },
    categoryKey: function(val) {
      // eslint-disable-line
      this.category = this.$store.getters.getCategoryByKey(val);
    }
  },

  mounted() {
    this.determineProductsPerRow();

    if (this.categoryKey) {
      this.category = this.$store.getters.getCategoryByKey(this.categoryKey);
    }

    if (!this.autoLoad && this.isLeaf) {
      console.log("autoLoad should be set to true");
      this.autoLoad = true;
    }

    if (this.initialized === false) {
      if (this.loadCategoryFromApi && this.autoLoad) {
        if (this.isLeaf) {
          this.populateCategoryFromApi(false);
        } else {
          setTimeout(this.populateCategoryFromApi, 1000);
        }
      }

      if (this.sourceProducts) {
        if (this.autoLoad) {
          this.products = this.sourceProducts;
        }
        this.unfilteredProductsCount = this.products.length;
      }

      this.initialized = true;
    }

    // console.log('autoLoad ' + this.autoLoad)
  },

  created() {
    if (this.categoryKey) {
      this.category = this.$store.getters.getCategoryByKey(this.categoryKey);
    }
    this.headerTextStatic = this.headerText;
    this.determineProductsPerRow();
  }
};
</script>


<style lang="scss" scoped="true">
.products-card-header {
  /deep/ .row {
    padding-bottom: 10px !important;
  }
}

.product-container {
  //display: flex;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.product-container-individual {
  width: 33.33%;
  float: left;
  padding-left: 5px;
  padding-right: 5px;
}

.header-left {
  font-weight: $semiBold;
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
}

.header-right {
  text-align: right;
  font-size: 11px;
  @include breakpoint(tablet) {
    font-size: 13px;
  }
  a {
    color: $storePrimaryColor;
  }
}

.header-sub-link {
  padding-top: 5px;
  font-size: 11px;
  float: right;
  a {
    color: #898989;
    margin-bottom: inherit !important;
  }
  i {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }
}

.swiper-loading {
  height: 200px;
  width: 100%;
  position: relative;
  .preloader {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.card-box {
  background-color: #fff;
  border-top: 1px solid #fff;
  margin-top: 0px;
  width: 100%;
  height: auto;
  h1 {
    font-weight: normal;
    font-size: 18px;
    margin: 10px 5px 0 10px;
    @include breakpoint(tablet) {
      font-size: 20px;
    }
  }
}
.visbility-bottom {
  .bottom-loader {
    margin-top: 50px;
    height: 100px;
    width: 100%;
    .preloader {
      height: 50px;
      width: 50px;
      display: table;
      margin: 0 auto;
    }
  }
}
</style>
