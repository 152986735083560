<template>
  <div class="popup popup-delivery">
    <div class="page-content">
      <sh-address-editor
        id="editor-delivery-address"
        address-type="delivery"
        :states="states"
        :store-location="selectedLocation"
        v-if="selectedDeliveryAddressId === 0"
         @newAddressStored="$emit('newAddressStored', $event)"
      />
    </div>
  </div>
</template>

<script>
import ShAddressEditor from "../../components/ShAddressEditor.vue";

export default {
  props: ["states", "selectedLocation", "selectedDeliveryAddressId"],
  components: { ShAddressEditor },
};
</script>
