<template>
  <div class="outer">
    <svg viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.02675 6.5009C6.50203 6.5009 6.94919 6.23975 7.19105 5.8219L8.99938 2.72868L10.8049 5.8219C11.0496 6.24265 11.4967 6.5038 11.972 6.5038C12.0986 6.5038 12.2251 6.48639 12.346 6.44867L16.1989 5.3112V10.4762C16.1989 10.9028 15.9177 11.2742 15.5183 11.3758L9.4381 12.9456C9.15124 13.0181 8.85032 13.0181 8.56628 12.9456L2.48041 11.3758C2.08106 11.2713 1.79983 10.8999 1.79983 10.4762V5.3112L5.65271 6.44577C5.77364 6.48349 5.9002 6.5009 6.02675 6.5009ZM0.0477543 3.24518L1.4961 0.262226C1.58328 0.0823204 1.77171 -0.0221411 1.96576 0.00397426L8.99938 0.929619L6.42048 5.34312C6.31361 5.52593 6.09987 5.61298 5.9002 5.55494L0.334612 3.91548C0.0561905 3.83133 -0.0788021 3.51214 0.0477543 3.24518ZM16.5027 0.262226L17.951 3.24518C18.0804 3.51214 17.9426 3.83133 17.667 3.91258L12.1014 5.55204C11.9017 5.61007 11.688 5.52302 11.5811 5.34022L8.99938 0.929619L16.0358 0.00397426C16.2299 -0.0192394 16.4155 0.0823204 16.5027 0.262226Z"
      />
    </svg>
    <div class="counterLabel">{{ count }}</div>
  </div>
</template>
<script>
export default {
  props: ["count"],
};
</script>
<style lang="scss" scoped>
.outer {
  border-radius: 50%;
  box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.2);
  background-color: white;
  // The parent controls the size of the component, so we can use it everywhere with different sizes
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.counterLabel {
  text-align: center;
  color: white;
  z-index: 2;
  font-size: 0.4rem;
  margin-top: 6px;
  font-weight: 800;
}

.outer > svg {
  z-index: 1;
  position: absolute;
  width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: $colorThemeA;
}
</style>
