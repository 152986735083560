<template>
<f7-page>
  <div class="spacer"></div>

    <div class="page-title">Thanks for your order</div>
    
    <f7-card class="receipt-card">
        <f7-card-content>
            <f7-list>
                <f7-list-item
                    :link="`/orders/${eaOrderKey}/`"
                >
                    <div class="list-item-icon">
                        <cd-icon/>
                    </div>
                    <div class="list-item-text">See Choice Doorstep Order Receipt</div>
                
                </f7-list-item>
            </f7-list>
        </f7-card-content>
    </f7-card>
    
    <f7-card class="receipt-card">
        <f7-card-content>
            <f7-list>
                <f7-list-item
                    :link="`/orders/${orderKey}/`"
                >
                    <div class="list-item-icon">
                        <cd-basket-icon/>
                    </div>
                    <div class="list-item-text">See In Store Order Receipt</div>
                </f7-list-item>
            </f7-list>
        </f7-card-content>
    </f7-card>
    
    <div class="reminder-box">You can always see all of your orders in the main menu</div>
        
    <div class="home-button">
        <a href="/home/">
            <button class="button button-normal button-home" style="veritcal-align: middle;">Go back to Home Screen</button>
        </a>
    </div>

</f7-page>
</template>

<script>

import CdIcon from '../components/icons/CdIcon.vue'
import CdBasketIcon from '../components/icons/CdBasketIcon.vue'

export default {
  props: [
    'orderKey',
    'eaOrderKey'
  ],
  components: {
      CdIcon,
      CdBasketIcon
  }
}

</script>

<style lang="scss" scoped="true">
.page {
    background: white;
}

:root .page{
  --f7-list-chevron-icon-color: $storePrimaryColor;
}

.page-title {
    padding: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: $storePrimaryColor;
}
.spacer {
  width: 100%;
  height: var(--f7-page-spacer-top);
}

.list-item-icon {
    padding-top: 5px;
    width: 10%;
    margin: auto;
    min-width: 55px;
    text-align: center;
}

.list-item-text {
    width: 80%;
    margin: auto;
    font-size: 14px;
}

.receipt-card {
    background: #FFFFFF;
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 25px !important;
}

.reminder-box {
    padding: 10px 0 35px 0;
    width:100%;
    text-align: center;
    font-size: 12px;
}

.home-button {
    vertical-align: middle;
    width: 90%;
    margin: auto;
    padding-top: 20px;
}

.button-home {
    padding: 0px;
    font-weight: normal;
    min-height: 50px;
}

</style>
