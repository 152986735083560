<template>
  <f7-page>

    <div class="spacer-top"></div>

    <template v-if="userIsAuthenticated">
      <template v-if="favoritesIsPopulated && favoriteProductsFull.length > 0">
        <sh-products-card v-bind:sourceProducts="favoriteProductsFull" headerText="My Favorite Products" :isLeaf="true" :noLink="false" :loadCategoryFromApi="false"/>  </sh-products-card>
      </template>
      <template v-else>
         <f7-card>
            <f7-card-content>
            You have not saved any favorites.
            </f7-card-content>
          </f7-card>
      </template>
  </template>
  <template v-else>
    <f7-card>
      <f7-card-content>
      Please <f7-link href="/auth-sign-in/favorites/"><strong>sign in</strong></f7-link> to view your saved favorites.
      </f7-card-content>
    </f7-card>

  </template>
  </f7-page>
</template>

<script>

import ShProductsCard from '../components/ShProductsCard'

export default {

  data() {
    return {
      favoritesIsPopulated: false,
    }
  },

  props: [
  ],

  components: {
    ShProductsCard,
  },

  computed: {
    favoriteProductsFull: ({ $store }) => $store.getters.favoriteProductsFull,
    userIsAuthenticated: ({ $store }) => $store.getters.userIsAuthenticated,
  },

  methods: {

  },

  created() {
    if(this.userIsAuthenticated) {
      this.$store.dispatch('populateFavoritesFull', { physicalLocationId: this.$store.getters.selectedLocation.physical_location_id }).then(() => {
          this.favoritesIsPopulated = true
        })
    }

    this.$root.setActiveToolbar('favorites')
    this.$root.setNavbarTitle('My Favorites')
    this.$root.setNavbarSubtitle('')

  },

  mounted() {
     if(false) {
      if(typeof(AppRate) !== 'undefined') {
        alert('appRate prompt: ' + AppRate.preferences.displayAppName)
        AppRate.promptForRating(false)
      }
    }
  }

}

</script>

<style lang="scss" scoped="true">
.spacer-top {
  height: var(--f7-page-spacer-top);
}

</style>
