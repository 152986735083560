<template>
<div class="checkout-header">
  <f7-row>
    <f7-col class="column" width="25">
      <f7-link back
               class="link-back">
        <svg width="15"
             height="25"
             viewBox="0 0 19 30"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>back</title>
          <g id="Canvas"
             transform="translate(-408 -1335)">
            <g id="back">
              <use xlink:href="#back_path"
                   transform="translate(408 1335)"
                   fill="#fff" />
            </g>
          </g>
          <defs>
            <path id="back_path"
                  d="M 6.99247 15L 17.7253 4.04252C 18.2039 3.55422 18.199 2.77294 17.7156 2.28464L 15.7965 0.365618C 15.3034 -0.122683 14.5123 -0.122683 14.024 0.3705L 0.366226 14.1162C 0.122075 14.3603 0 14.6777 0 15C 0 15.3223 0.122075 15.6397 0.366226 15.8838L 14.024 29.6295C 14.5123 30.1227 15.3034 30.1227 15.7965 29.6344L 17.7156 27.7154C 18.199 27.2271 18.2039 26.4458 17.7253 25.9575L 6.99247 15Z" />
          </defs>
        </svg>
      </f7-link>
    </f7-col>
    <f7-col class="column" width="50">
      <h2>Checkout Progress</h2>
    </f7-col>
    <f7-col class="column" width="25">
      <f7-link @click="navigate('/home/')">
          <svg class="homeIcon" width="27px" height="25px" viewBox="0 0 27 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs></defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="home" fill="#ffffff" fill-rule="nonzero">
                    <path d="M20.4877323,0 C19.9158121,0 19.4493519,0.468546545 19.4493519,1.04302477 L19.4493519,6.02998696 L13.9654053,0.521512386 C13.559788,0.114080834 12.8783508,0.114080834 12.4727335,0.521512386 L0.304213012,12.7770535 C-0.101404337,13.184485 -0.101404337,13.8363755 0.304213012,14.243807 C0.709830362,14.6512386 1.35881812,14.6512386 1.76443547,14.243807 L13.2190694,2.73794003 L24.7061528,14.2764016 C24.908962,14.4801179 25.1847818,14.6023468 25.4524887,14.6023468 C25.7201967,14.6023468 25.9635671,14.4801179 26.1663752,14.2764016 C26.5719926,13.86897 26.5719926,13.2170795 26.1663752,12.809648 L22.5644932,9.1916558 L22.5644932,1.04302477 C22.5644932,0.468546545 22.0980329,0 21.5261127,0 L20.4877323,0 Z M13.2190694,4.66101695 L1.79688486,16.1342894 L1.79688486,21.8709257 C1.79688486,23.5984355 3.19220854,25 4.9120261,25 L21.5261127,25 C23.2459303,25 24.641254,23.5984355 24.641254,21.8709257 L24.641254,16.1342894 L13.2190694,4.66101695 Z M11.1423086,14.5697523 L15.2958303,14.5697523 C15.8677505,14.5697523 16.3342107,15.0382988 16.3342107,15.6127771 L16.3342107,21.8709257 C16.3342107,22.4454039 15.8677505,22.9139505 15.2958303,22.9139505 L11.1423086,22.9139505 C10.5703884,22.9139505 10.1039282,22.4454039 10.1039282,21.8709257 L10.1039282,15.6127771 C10.1039282,15.0382988 10.5703884,14.5697523 11.1423086,14.5697523 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
      </f7-link>
    </f7-col>
  </f7-row>
  <f7-row>
    <f7-col width="100">
      <div class="pbar-container">
        <ul class="pbar">
          <li v-bind:class="{ active: selectedStep == 'service' } ">Pickup /
            <br/>Delivery</li>
          <li v-bind:class="{ active: selectedStep == 'schedule' } ">Schedule<br/>Order</li>
          <li v-bind:class="{ active: selectedStep == 'payment' } ">Payment
            <br/>Method</li>
          <li v-bind:class="{ active: selectedStep == 'review' } ">Review
            <br/>Order</li>
          <li v-bind:class="{ active: selectedStep == 'complete' } ">Order
            <br/>Placed</li>
        </ul>
      </div>
    </f7-col>
  </f7-row>
</div>

</template>

<script>
export default {
  data() {
    return {
    }
  },

  props: {
    selectedStep: {
      type: String,
      default: null,
    }
  },
  computed: {
    cartKey: ({ $store }) => $store.getters.cartKey,
  },

  methods: {
    navigate(path) {
        const router = this.$f7.views.main.router

        if(router.history.length > 1) {
          const priorPage = router.history[router.history.length-2];
          if(priorPage == path) {
            this.$store.dispatch('refreshNavBar', { route: path })
            this.$store.dispatch('refreshToolBar', { route: path })
            return router.back()
          }
        }

        if(router.currentRoute.url !== path) {
          return router.navigate(path)
        }
    }
  },

  created() {
  },
}

</script>

<style lang="scss" scoped="true">
.checkout-header {
  background: $storePrimaryColor;
  color: #fff;
  height: calc(200px + var(--sat));
  padding-top: var(--sat);
  
  -webkit-box-shadow: 0px 10px 36px -2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 10px 36px -2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 10px 36px -2px rgba(0, 0, 0, 0.24);
  text-shadow: 0 1px 0 rgba(76, 70, 56, 0.7);
  h2 {
    font-size: 20px;
    text-align: center; // line-height: 60px;
  }
}

.link-back {
  display: flex;
  justify-content: center;
  align-items: center;
}


.pbar-container {
  width: 95%;
  margin: 15px auto;
}

.pbar {
  height: 100px;
  counter-reset: step;
}

.pbar li {
  list-style-type: none;
  width: 20%;
  float: left;
  line-height: 14px;
  font-size: 11px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.pbar li:before {
  color: $storePrimaryColor;
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 25px;
  border: 2px solid #fff;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
  font-size: 16px;
}

.pbar li:after {
  width: 50%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: $colorThemeC;
  top: 15px;
  left: -25%; //z-index: -1;
}

.pbar li:first-child:after {
  content: none;
}

.pbar li.active {
  color: #fff;
  font-weight: $bold;
}

.pbar li.active:before {
  background-color: $colorThemeC;
  color: #fff;
  border-color: $colorThemeC;
}

.pbar li.active+li:after {
  background-color: $colorThemeC;
}

.column{
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.homeIcon{
  g{
    fill: white;
  }
}
</style>
