/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: "warn" */

import * as types from '../mutation-types' // eslint-disable-line no-unused-vars

import ShopHeroApi from '../../api/ShopHeroApi' // eslint-disable-line no-unused-vars
import LocalStorage from '../../local-storage/LocalStorageInterface' // eslint-disable-line no-unused-vars

// initial state
const state = {
  userToken: null,
  userEmail: null,
  userFirstName: null,
  userLastName: null,
  userLoyaltyToken: null,
  userIsAuthenticated: false,
  userHasPlacedCDOrder: false
}

// getters
const getters = {
  userIsAuthenticated: state => state.userIsAuthenticated,
  userHasPlacedCDOrder: state => state.userHasPlacedCDOrder,
  userHasLoyaltyToken: state => (state.userLoyaltyToken),
  authenticatedUser: state => { return { email: state.userEmail, firstName: state.userFirstName, lastName: state.userLastName, loyaltyToken: state.userLoyaltyToken } },
}

// actions
const actions = {
  setUserToken({ commit }, { userToken }) {
    commit(types.USER_SET_TOKEN, { userToken })
  },
  setUserAuthenticated({ commit, dispatch }, { userToken, userEmail, userFirstName, userLastName, userLoyaltyToken }) {
    commit(types.USER_SET_AUTHENTICATED, { userToken, userEmail, userFirstName, userLastName, userLoyaltyToken })
    dispatch("resetLoyaltyState");
  },
  setUserNotAuthenticated({ commit }) {
    commit(types.USER_SET_NOT_AUTHENTICATED)
  },
  setUserLoyaltyToken({ commit }, { userLoyaltyToken }) {
    commit(types.USER_SET_LOYALTY_TOKEN, { userLoyaltyToken })
  },
  userSignOut({ commit, dispatch }) {
    return new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
      ShopHeroApi.deauthenticate((response) => {
        if (response.success) {
          commit(types.USER_SIGN_OUT)
          dispatch("resetLoyaltyState");
          resolve()
        } else {
          reject()
        }
      })
    })
  },
  // this is used on account deletion because the user is already "signed out" once deleted - this just makes sure we commit properly here in the store
  userSignedOut({ commit, dispatch }) {
    commit(types.USER_SIGN_OUT)
    dispatch("resetLoyaltyState");
  },

  authenticateUserViaStoredToken({ commit }) {
    console.log('authenticateUserViaStoredToken')
    const userEmail = LocalStorage.getItem('user:email')
    const userToken = LocalStorage.getItem('user:token')

    return new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
      ShopHeroApi.authenticate(userEmail, null, userToken, (response) => {
        if (response.login_success) {
          commit(types.USER_SET_AUTHENTICATED, {
            userToken: response.login_token,
            userEmail: response.login_email,
            userFirstName: response.login_first_name,
            userLastName: response.login_last_name,
            userLoyaltyToken: response.loyalty_token,
            userHasPlacedCDOrder: response.has_placed_cd_order
          })
          resolve()
        } else {
          resolve()
        }
      }, reject)
    })
  },
}

// mutations
const mutations = {
  [types.USER_SET_TOKEN](state, { userToken }) {
    state.userToken = userToken
  },
  [types.USER_SET_AUTHENTICATED](state, { userToken, userEmail, userFirstName, userLastName, userLoyaltyToken, userHasPlacedCDOrder }) {
    state.userToken = userToken
    state.userEmail = userEmail
    state.userFirstName = userFirstName
    state.userLastName = userLastName
    state.userLoyaltyToken = userLoyaltyToken
    state.userIsAuthenticated = true;
    state.userHasPlacedCDOrder = userHasPlacedCDOrder === 1 ? true : false;

    LocalStorage.setItem('user:token', userToken)
    LocalStorage.setItem('user:email', userEmail)
  },
  [types.USER_SET_NOT_AUTHENTICATED](state) {
    state.userToken = null
    state.userEmail = null
    state.userFirstName = null
    state.userLastName = null
    state.userIsAuthenticated = false
    state.userHasPlacedCDOrder = false
  },
  [types.USER_SIGN_OUT](state) {
    state.userToken = null
    state.userEmail = null
    state.userFirstName = null
    state.userLastName = null
    state.userLoyaltyToken = null
    state.userIsAuthenticated = false
    state.userHasPlacedCDOrder = false

    LocalStorage.removeItem('user:token')
    LocalStorage.removeItem('user:email')
  },
  [types.USER_SET_LOYALTY_TOKEN](state, { userLoyaltyToken }) {
    state.userLoyaltyToken = userLoyaltyToken
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
