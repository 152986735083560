<template>
  <div v-show="products.length > 0" class="ingredients-card">
    <div class="ingredients-swiper-container">
      <f7-swiper :init="true"
                 :params="{slidesPerView: productsPerRow, spaceBetween: 20, slidesPerGroup: 2, preloadImages: true, observer: false, watchOverflow: true, preventClicks: true, preventClicksPropagation: true, passiveListeners: true, lazy: {loadPrevNext: true, loadOnTransitionStart: true, loadPrevNextAmount: 1}, lazyLoading: true, watchSlidesVisibility: true, loop: false}">
        <f7-swiper-slide v-for="product in products" v-bind:key="product.productKey">
          <ShProductBox v-bind:product="product" swiper="true"/>
        </f7-swiper-slide>
      </f7-swiper>
    </div>
</div>
</template>

<script>

import ShopHeroApi from '../api/ShopHeroApi'

import ShProductBox from './ShProductBox.vue'

export default {

  data() {
    return {
      limit: 4,
      products: [],
      productsPerRow: 3,
    }
  },

  props: [
    'ingredient'
  ],

  components: {
    ShProductBox,
  },

  methods: {

    determineProductsPerRow(e) {
      // const w = window.screen.width
      const w = window.innerWidth
      if(w > 1000) {
        this.productsPerRow = 5
      } else if(w > 740) {
        this.productsPerRow = 4
      } else {
        this.productsPerRow = 3
      }
    },

    loadInSlideImages: function(loadPrevNextAmount) {
      if(!this.$children[0]) return;
      const swiper = this.$children[0].swiper // eslint-disable-line
      if (swiper) {
        this.$nextTick(() => {
          swiper.update()

          for (let i = 0; i < Math.min(this.products.length, loadPrevNextAmount); i += 1) {
            swiper.lazy.loadInSlide(i)
          }
        })
      }
    },

  },

  watch: {
    products(val) {
      this.loadInSlideImages(this.productsPerRow)
    }
  },

  created() {
    this.determineProductsPerRow()

    ShopHeroApi.getProductsByIngredient(this.$store.state.locations.selectedLocation.physical_location_id, this.ingredient.text, this.ingredient.food, this.limit, (response) => {
        // console.log(response)
        const self = this
        response.matching_products.forEach(function(e) {
            self.products.push(ShopHeroApi.convertApiProductToProductModel(e, e.product_key))
        })

      })
  },

  mounted() {
  },

}

</script>

<style lang="scss" scoped="true">
.ingredients-card {
  position: relative;
  width: 100%;
}
.ingredients-swiper-container {
  max-width: 100%;
  overflow-x: hidden;
}
</style>
