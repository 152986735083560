// store config
export default {
  appId: "com.shophero.spacemonkey.brucesfoodland",
  appleId: "1446938093",
  storeName: "Bruce's Foodland",
  storeDomain: "https://brucesfoodland.shophero.com",
  options: {},
  categoryMap: {"fresh-fruit":"ay69y8","fresh-vegetables":"4rmnhy","deli":"2qx97l","milk":"0wsbkd","eggs":"8dlqr8","cheese":"50oxml","yogurt":"kjqtyg","beef":"07xmp1","poultry":"qkwbd8","beverages":"2vfzn6","coffee":"gbefmp","bread":"9y98ub","breakfast":"fwiy6a","formula-baby-care":"cpx0z2","paper-products":"bm0d17","personal-care":"cp6srj","pet-care":"sd5gr2","produce":"4wht3m","dairy":"dwu0xd","candy-cookies-snacks":"h3odkj","bread-bakery":"u1kml7","baking-cooking-needs":"nxbtya","frozen":"639e5a","meat-seafood":"s1k3qi","canned-goods":"tweaua","wine-beer-spirits":"ilnc71"},
  menuCategories: []
}
