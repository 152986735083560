<template>
  <f7-page>
    <div class="spacer-top"></div>

    <div class="coupons-loading" v-show="!couponsLoaded">
      <div class="preloader"></div>
    </div>

    <div class="coupons-none-available" v-show="couponsLoaded && offersForDisplay.length == 0">
      <p>No offers are currently available.</p>
    </div>

    <div class="quick-slider" v-show="offersCategories.length > 1">
      <f7-swiper
        :init="true"
        :params="{slidesPerView: 'auto', spaceBetween: 10, slidesPerGroup: 1, observer: true, loop: false}"
      >
        <f7-swiper-slide
          v-for="category in offersCategories"
          class="swiper-child"
          v-bind:key="category"
          :class="{ 'swiper-child-selected': offersCategoryFilter == category}"
        >
          <f7-link @click="offersCategoryFilter = category">
            <p class="text-shadow">{{category}} ({{offersCategoriesMap[category]}})</p>
          </f7-link>
        </f7-swiper-slide>
      </f7-swiper>
    </div>

    <template v-for="offer in offersForDisplay">
      <f7-card
        :key="`a${offer.offerId}`"
        class="card-coupon"
        :id="`card-offer-${offer.offerId}`"
        v-lazy-container="{ selector: 'img', loading: 'images/ajax-loader.gif' }"
        v-show="((offersCategoryFilter == 'All') || (offersCategoryFilter == offer.offerCategoryExternal))"
      >
        <div class="container-coupon">
          <div class="container-coupon-img">
            <img :data-src="offer.offerImage.url_md" class="coupon-image" />
          </div>
          <div>
            <div class="offer-title">{{offer.offerTitle}}</div>
            <div class="offer-brand">{{offer.offerBrandExternal}}</div>
            <div class="offer-desc">{{offer.offerTitle}} {{offer.offerDescription}}</div>
          </div>
        </div>
        <transition name="fade">
          <div
            class="offer-terms"
            v-show="offer.termsVisible"
          >Terms and conditions: {{offer.offerDetails}}</div>
        </transition>
        <div class="button-clip" v-show="!offer.offerIsClipped" @click="onClickClip(offer)">
          <span v-show="!offer.isClipping">
            <i class="f7-icons">scissors</i> Clip
          </span>
          <span v-show="offer.isClipping">
            &nbsp;&nbsp;
            <f7-preloader color="white"></f7-preloader>
          </span>
        </div>
        <div class="button-clipped" v-show="offer.offerIsClipped">Clipped!</div>
        <div
          :class="{'button-shop-now': true, 'button-shop-now-active': (offer.offerProducts.length > 0)}"
          v-show="offer.offerProductIDs.length > 0"
          @click="onClickShopNow(offer)"
        >
          <span v-show="!offer.isOfferProductsLoading">
            Shop
            <i class="f7-icons">cart_fill_badge_plus</i>
          </span>
          <span v-show="offer.isOfferProductsLoading">
            &nbsp;&nbsp;
            <f7-preloader color="white"></f7-preloader>
          </span>
        </div>
        <div class="offer-expires">Expires {{formatExpirationDate(offer.offerEffectiveEnd)}}</div>

        <div class="button-terms" v-show="offer.offerDetails" @click="onClickDetails(offer)">
          <i class="f7-icons">info_circle</i>
        </div>
      </f7-card>
      <transition name="fade" :key="`b${offer.offerId}`">
        <sh-products-card
          :key="`c${offer.offerId}`"
          v-show="offer.offerProducts.length > 0"
          v-bind:sourceProducts="offer.offerProducts"
          :headerText="`${offer.offerTitle} on Select ${offer.offerBrandExternal}`"
          :isLeaf="true"
          :noLink="false"
          :loadCategoryFromApi="false"
          :autoLoad="true"
        />
      </transition>
    </template>

    <div class="spacer-bottom"></div>
  </f7-page>
</template>

<script>
import ShopHeroApi from "../api/ShopHeroApi";

import ShProductsCard from "../components/ShProductsCard.vue";

import moment from "moment";

export default {
  data() {
    return {
      couponsLoaded: false,
      offersForDisplay: [],
      offersCategoriesMap: [],
      offersCategories: [],
      offersCategoryFilter: "All"
    };
  },
  components: {
    ShProductsCard
  },
  props: [],

  computed: {
    selectedLocation: ({ $store }) => $store.getters.selectedLocation,
    userIsAuthenticated: ({ $store }) => $store.getters.userIsAuthenticated,
    userHasLoyaltyToken: ({ $store }) => $store.getters.userHasLoyaltyToken,
    availableOffers: ({ $store }) => $store.getters.loyaltyAvailableOffers,
    loyaltyProgramName: ({ selectedLocation }) =>
      selectedLocation.physical_location_loyalty_program_name
  },

  methods: {
    parseOffers(offers) {
      // reset our state
      this.couponsLoaded = false;
      this.offersForDisplay = [];
      this.offersCategories = [];

      let offersCategories = [];
      let offersCategoriesMap = [];
      offersCategoriesMap["All"] = 0;

      const self = this;

      offers.forEach(function(v) {
        // this powers the category filter at the top of the page
        if (!offersCategoriesMap[v.offerCategoryExternal])
          offersCategoriesMap[v.offerCategoryExternal] = 0;

        offersCategoriesMap[v.offerCategoryExternal]++;

        offersCategoriesMap["All"]++;

        v.termsVisible = false;
        v.isClipping = false;
        v.offerProducts = [];
        v.isOfferProductsLoading = false;

        // this is a bit of a hack because
        // we need to recreate a fresh object so that vuejs
        // can detect the new properties that we've added above
        let offer = {};
        for (const property in v) {
          offer[property] = v[property];
        }

        self.offersForDisplay.push(offer);
      });

      offersCategories = Object.keys(offersCategoriesMap);
      offersCategories = offersCategories.sort(function(a, b) {
        return offersCategoriesMap[b] - offersCategoriesMap[a];
      });

      this.offersCategories = offersCategories;
      this.offersCategoriesMap = offersCategoriesMap;

      this.couponsLoaded = true;
    },
    formatExpirationDate(time) {
      return moment.unix(time).format("MMM Do, YYYY");
    },
    randomInteger(max = Number.MAX_SAFE_INTEGER) {
      return Math.floor(Math.random() * max);
    },
    populateLoyaltyOffers() {
      // reset our state
      this.couponsLoaded = false;
      this.offersForDisplay = [];
      this.offersCategories = [];

      this.$store
        .dispatch("populateLoyaltyOffers", {
          physicalLocationId: this.selectedLocation.physical_location_id
        })
        .then(() => {
          // console.log("offersPopulated");
        });
    },
    onClickDetails(offer) {
      console.log("onClickDetails", offer.termsVisible);
      offer.termsVisible = !offer.termsVisible;
    },
    onClickClip(offer) {
      console.log("onClickClip", offer.offerId);

      if (this.userHasLoyaltyToken) {
        const self = this;

        offer.isClipping = true;

        ShopHeroApi.doLoyaltyClipCoupon(
          self.selectedLocation.physical_location_id,
          offer.offerId,
          response => {
            if (response.success) {
              offer.offerIsClipped = true;
            }
          },
          error => {
            console.log("couponError", error);
            //self.$f7.views.main.router.navigate("/uhoh/");
          }
        );
      } else {
        if (this.userIsAuthenticated) {
          this.$f7.views.main.router.navigate(
            "/loyalty-app-card-link-account/coupons/"
          );
        } else {
          this.$f7.views.main.router.navigate("/auth-sign-in/coupons/");
        }
      }
    },
    onClickShopNow(offer) {
      // hide if already open
      if (offer.offerProducts.length > 0) {
        offer.offerProducts = [];
        return;
      }

      offer.isOfferProductsLoading = true;
      ShopHeroApi.getProductsByKey(
        this.selectedLocation.physical_location_id,
        offer.offerProductIDs,
        response => {
          response.forEach(function(p) {
            offer.offerProducts.push(
              ShopHeroApi.convertApiProductToProductModel(p, p.product_key)
            );
            offer.isOfferProductsLoading = false;
          });
        },
        error => {
          console.log("fetchProductsError", error);
          offer.isOfferProductsLoading = false;
        }
      );
    }
  },
  watch: {
    availableOffers(offers) {
      this.parseOffers(offers);
    }
  },
  created() {
    if (this.availableOffers.length == 0) {
      this.populateLoyaltyOffers();
    } else {
      this.parseOffers(this.availableOffers);
    }
  },

  mounted() {
    this.$root.setActiveToolbar("coupons");
    this.$root.setNavbarTitle("Coupons and Offers");
    this.$root.setNavbarSubtitle(this.loyaltyProgramName);
    this.$f7.lazy.create(".coupon-image");
  }
};
</script>

<style lang="scss" scoped="true">
.spacer-top {
  width: 100%;
  height: var(--f7-page-spacer-top);
}

.spacer-bottom {
  width: 100%;
  height: 80px;
}

.coupons-loading {
  margin-top: 40px;
  text-align: center;
  height: 200px;
  width: 100%;
  position: relative;
  .preloader {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }
}

.card-coupon {
  position: relative;
  overflow: hidden;
}

.container-coupon {
  display: grid;
  grid-template-columns: 150px auto;
  grid-template-rows: 1fr;
  column-gap: 10px;

  .container-coupon-img {
    padding: 10px;
    img {
      max-width: 130px;
    }
  }

  .offer-title {
    padding-top: 5px;
    font-weight: bold;
    font-size: 1.4em;
    color: $storePrimaryColor;
  }

  .offer-brand {
    font-weight: bold;
    padding-top: 5px;
    font-size: 1.1em;
    color: $accentColor;
  }

  .offer-desc {
    padding-top: 5px;
    font-size: 0.92em;
  }
}

.offer-expires {
  padding-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 0.9em;
  font-style: italic;
}

.offer-terms {
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0px;

  font-size: 0.95em;
  // display: none;
}

.button-clip {
  position: absolute;
  bottom: -60px;
  right: -20px;
  border-radius: 20px;
  width: 100px;
  height: 100px;
  background-color: $storePrimaryColor;
  color: #fff;
  font-weight: bold;
  padding-top: 10px;
  padding-left: 20px;
  i {
    font-size: 15px;
    margin-right: 3px;
  }
}

.button-clipped {
  position: absolute;
  bottom: -60px;
  right: -20px;
  border-radius: 20px;
  width: 100px;
  height: 100px;
  background-color: $colorGreen;
  color: #fff;
  font-weight: bold;
  padding-top: 10px;
  padding-left: 15px;
  i {
    font-size: 15px;
    margin-right: 3px;
  }
}

.button-shop-now {
  position: absolute;
  bottom: -60px;
  left: -20px;
  border-radius: 20px;
  width: 100px;
  height: 100px;
  background-color: $storePrimaryColor;
  color: #fff;
  font-weight: bold;
  padding-top: 10px;
  padding-left: 30px;
  i {
    font-size: 15px;
    margin-left: 3px;
  }
}

.button-shop-now-active {
  background-color: $colorGreen;
}

.button-terms {
  position: absolute;
  top: 7px;
  right: 0px;
  padding: 10px;
  i {
    color: #bbb;
    font-size: 20px;
  }
}

.quick-slider {
  height: 60px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  .swiper-child {
    width: auto;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $storePrimaryColor;
    border-radius: 12px;
    margin: 5px;
    padding: 0 15px;
    p {
      color: white;
      font-weight: $bold;
    }
  }
  .swiper-child-selected {
    background-color: $colorGreen;
  }
}

.coupons-none-available {
  padding-top: 100px;
  text-align: center;
  font-size: 1.6em;
  font-weight: bold;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>