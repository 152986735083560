<template>
  <f7-page no-swipeback no-navbar no-toolbar>
    <div slot="fixed" class="header-location">
      <h2>Select a Location</h2>
    </div>
    <div class="spacer-top-location"></div>

    <f7-card
      v-for="location in availableLocations"
      v-bind:key="location.physical_location_id"
      v-bind:class="{'location-suggested-active': location.physical_location_id == suggestedPhysicalLocationId }"
    >
      <f7-card-content>
        <f7-row>
          <f7-col width="30">
            <img v-bind:src="`${location.physical_location_logo_url}`" class="logo-img" />
          </f7-col>
          <f7-col width="70">
            <h4>{{location.physical_location_name }}</h4>
            <div
              class="location-distance"
              v-show="usrLat"
            >({{distanceFromUser(location.physical_location_latitude, location.physical_location_longitude)}} miles away)</div>

            <div class="location-features">
              <p
                v-show="location.physical_location_pickup_available && location.physical_location_delivery_available"
              >
                Order now for
                <strong>curbside pickup</strong> or
                <strong>home delivery</strong>.
              </p>
              <p
                v-show="location.physical_location_pickup_available && !location.physical_location_delivery_available"
              >
                Order now for
                <strong>curbside pickup</strong>.
              </p>
              <p
                v-show="!location.physical_location_pickup_available && location.physical_location_delivery_available"
              >
                Order now for
                <strong>home delivery</strong>.
              </p>
            </div>
            <button
              class="button-normal button-location-select"
              @click="selectLocation(location.physical_location_id)"
            >
              <template v-if="!actionWait || location.physical_location_id !== selectingPhysicalLocationId">Shop Here!</template>
              <template
                v-if="actionWait && location.physical_location_id == selectingPhysicalLocationId"
              >
                <img src="../images/ajax-loader.gif" class="button-ajax-loader" />
              </template>
            </button>
          </f7-col>
        </f7-row>
      </f7-card-content>
    </f7-card>

    <div class="spacer-bottom"></div>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      actionWait: false,
      selectingPhysicalLocationId: null,
      suggestedPhysicalLocationId: null,
      usrLat: null,
      usrLng: null
      // usrLat: 32.680240,
      // usrLng: -117.078030
    };
  },
  computed: {
    availableLocations: ({ $store }) => {
      const available = [];
      $store.getters.allLocations.forEach(function(l) {
        if (true) {
          available.push(l);
        }
      });
      return available;
    }
  },
  methods: {
    selectLocation(physicalLocationId) {
      const self = this;
      self.selectingPhysicalLocationId = physicalLocationId;
      self.actionWait = true;
      self.$store
        .dispatch("setSelectedLocation", { physicalLocationId })
        .then(() => {
          self.$store.dispatch("initializeLocation").then(
            () => {
              self.$f7.views.main.router.navigate("/home/");
              // self.actionWait = false;
            },
            error => {
              self.$f7.views.main.router.navigate("/uhoh/");
              // self.actionWait = false;
            }
          );
        });
    },
    distance(lat1, lon1, lat2, lon2, unit) {
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lon1 - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    },
    distanceFromUser(lat, lng, unit) {
      if (!this.usrLat) {
        return null;
      }

      return this.distance(this.usrLat, this.usrLng, lat, lng, unit).toFixed(0);
    }
  },
  mounted() {

    this.actionWait = false
    
    if (typeof navigator !== "undefined") {
      const self = this;
      navigator.geolocation.getCurrentPosition(function(position) {
        console.log(position);

        let closestD = Number.MAX_SAFE_INTEGER;
        let closestLocationId = null;

        self.usrLat = position.coords.latitude;
        self.usrLng = position.coords.longitude;

        self.availableLocations.forEach(function(l) {
          if (l.physical_location_latitude > 0) {
            const d = self.distance(
              position.coords.latitude,
              position.coords.longitude,
              l.physical_location_latitude,
              l.physical_location_longitude
            );

            // console.log(l.physical_location_id + ": " + d)

            if (d < closestD) {
              closestD = d;
              closestLocationId = l.physical_location_id;
            }
          }
        });

        if (closestLocationId !== null) {
          self.suggestedPhysicalLocationId = closestLocationId;
        }
      });
    }

    this.$root.hideDeviceSplash();
  }
};
</script>

<style lang="scss" scoped="true">
.location-features {
  margin-top: 10px;
  font-size: 0.9em;
}

a {
  color: $fontColor;
}

h4 {
  color: $accentColor;
}

.location-distance {
  font-size: 0.9em;
  font-style: italic;
}

.page {
  background-image: url(../images/search/search-background2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% -0px;
}

.button-location-select {
  margin-top: 20px;
  height: 40px;
}

.header-location {
  text-align: center;
  padding: 20px;
  background: $accentColor;
  color: #fff;
  padding-top: var(--sat);
}

.spacer-top-location {
  margin-top: 0px;
}

.spacer-bottom {
  height: 100px;
}

.card-header {
  color: $storePrimaryColor;
  font-weight: $bold;
}

.logo-img {
  width: 100%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.location-suggested-active {
  background: #c6ebc6;
}

.spacer-bottom {
  height: calc(80px + var(--sab));
}
</style>
