
import Vue from 'vue'
import Vuex from 'vuex'

import * as actions from './actions'
// import * as getters from './getters'

import app from './modules/app'
import user from './modules/user'
import locations from './modules/locations'
import categories from './modules/categories'
import navbar from './modules/navbar'
import toolbar from './modules/toolbar'
import cart from './modules/cart'
import checkout from './modules/checkout'
import favorites from './modules/favorites'
import loyalty from './modules/loyalty'

// import ShopHeroApi from '../api/ShopHeroApi'


// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  actions,
  // getters,
  modules: {
    app,
    user,
    cart,
    checkout,
    locations,
    categories,
    navbar,
    toolbar,
    favorites,
    loyalty
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : []
})
