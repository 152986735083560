/*
 * ShopHero API Methods
 */

import axios from 'axios'
const LRU = require('lru-cache')

import Product from '../models/Product'

import store from '../store'

import storeConfig from '../config'

// const apiBasePath = 'https://www.bowmansmarket.com/mobileapp/api/v2'
// const apiBasePath = 'https://www.hilitefreshmarketdelivers.com/mobileapp/api/v2'
// const apiBasePath = 'https://demo.shophero.com/mobileapp/api/v2'
// const apiBasePath = 'https://www.springvillereams.com/mobileapp/api/v2'
// const apiBasePath = 'https://www.stewartsmarketplace.com/mobileapp/api/v2'
//  const apiBasePath = 'https://shop.test/mobileapp/api/v2'
// const apiBasePath = 'https://www.soelbergsmarket.com/mobileapp/api/v2'

const apiBasePath = storeConfig.storeDomain + '/mobileapp/api/v2'

let currentApiToken = ''

const debug = process.env.NODE_ENV !== 'production' // eslint-disable-line no-unused-vars

const cacheDebug = false

// /////
// / private functions
// /////

// capture the updated api token for use on
// future api requests
function updateApiToken(response) {
  currentApiToken = response.headers['x-api-token']

  // the non-presence of this header means our remote
  // session is not (or no longer) authenticated so
  // sign us out in the local application
  if (('x-api-authtoken' in response.headers) == false) {
    store.dispatch('setUserNotAuthenticated')
  }
}


// perform an API GET
function apiGet(apiPath, callbackSuccess, callbackError) {
  if (debug) console.log('GET: ' + apiPath)
  axios.get(`${apiBasePath}/${apiPath}`, {
    headers: { 'X-API-TOKEN': currentApiToken },
  })
    .then((response) => {
      updateApiToken(response)
      // console.log(response)
      if (typeof callbackSuccess === 'function') { callbackSuccess(response.data) }
    })
    .catch((error) => {
      console.log(error)
      if (typeof callbackError === 'function') { callbackError(error) }
    })
}

// perform an API POST
function apiPost(apiPath, postDataArgs, callbackSuccess, callbackError) {
  if (debug) console.log('POST: ' + apiPath)
  axios({
    method: 'post',
    url: `${apiBasePath}/${apiPath}`,
    data: postDataArgs,
    headers: { 'X-API-TOKEN': currentApiToken },
  })
    .then((response) => {
      updateApiToken(response)
      // console.log(response)
      if (typeof callbackSuccess === 'function') { callbackSuccess(response.data) }
    })
    .catch((error) => {
      console.log(error)
      if (typeof callbackError === 'function') { callbackError(error) }
    })
}

///////////

function CacheCategoryProducts(max = 50, maxAge = 3600000) {
  if (!CacheCategoryProducts.lruCache) {
    if (cacheDebug) console.log('CacheCategoryProducts: new cache')
    CacheCategoryProducts.lruCache = new LRU({ max: max, maxAge: maxAge })
  } else {
    if (cacheDebug) console.log('CacheCategoryProducts: cache exists')
  }
  return CacheCategoryProducts.lruCache
}

function CacheSearchProducts(max = 50, maxAge = 3600000) {
  if (!CacheSearchProducts.lruCache) {
    if (cacheDebug) console.log('CacheSearchProducts: new cache')
    CacheSearchProducts.lruCache = new LRU({ max: max, maxAge: maxAge })
  } else {
    if (cacheDebug) console.log('CacheSearchProducts: cache exists')
  }
  return CacheSearchProducts.lruCache
}

function CacheIngredientProducts(max = 100, maxAge = 3600000) {
  if (!CacheIngredientProducts.lruCache) {
    if (cacheDebug) console.log('CacheMisc: new cache')
    CacheIngredientProducts.lruCache = new LRU({ max: max, maxAge: maxAge })
  } else {
    if (cacheDebug) console.log('CacheMisc: cache exists')
  }
  return CacheIngredientProducts.lruCache
}

function CacheMisc(max = 50, maxAge = 3600000) {
  if (!CacheMisc.lruCache) {
    if (cacheDebug) console.log('CacheMisc: new cache')
    CacheMisc.lruCache = new LRU({ max: max, maxAge: maxAge })
  } else {
    if (cacheDebug) console.log('CacheMisc: cache exists')
  }
  return CacheMisc.lruCache
}

function cacheInterception(lruCache, cacheKey, callbackSuccess) {
  if (lruCache.has(cacheKey)) {
    if (cacheDebug) console.log('using cached value: ' + cacheKey)
    callbackSuccess(lruCache.get(cacheKey))
    return null
  }
  return function (data) {
    lruCache.set(cacheKey, data)
    if (cacheDebug) console.log('caching value: ' + cacheKey)
    callbackSuccess(data)
  }
}

// /////
// / api methods
// /////

export default {

  ping(callback, callbackError) {
    apiGet('ping', callback, callbackError)
  },

  getCategoryTree(physicalLocationId, callback, callbackError) {
    // callback = cacheInterception(CacheMisc(), `locations:${physicalLocationId}`, callback)
    if (callback) {
      apiGet(`location/${physicalLocationId}/category-tree`, callback, callbackError)
    }
  },

  getCategoryByKey(physicalLocationId, categoryKey, callback, callbackError) {
    console.log('getCategoryByKey: ' + categoryKey)
    apiGet(`location/${physicalLocationId}/category/${categoryKey}`, callback, callbackError)
  },

  getCategoryProductsByKey(physicalLocationId, categoryKey, offset, limit, callback, callbackError) {
    const cacheKey = `${physicalLocationId}:${categoryKey}:${offset}:${limit}`
    // callback = cacheInterception(CacheCategoryProducts(), cacheKey, callback)
    if (callback) {
      //axios.get('http://localhost:4000/products.json').then(res => callback(res.data));
      apiGet(`location/${physicalLocationId}/category/${categoryKey}/products?o=${offset}&l=${limit}&ea=true`, callback, callbackError)
    }
  },

  getEAProductById(ea_product_listing_id, callback, callbackError) {
    //axios.get('http://localhost:4000/product-detail.json').then(res => callback(res.data));
    apiGet(`products/endless-aisle/${ea_product_listing_id}`, callback, callbackError);
  },

  getProductByKey(physicalLocationId, productKey, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/product/${productKey}`, callback, callbackError)
  },

  getProductsByKey(physicalLocationId, productKeys, callback, callbackError) {
    const keystring = productKeys.join(',')
    apiGet(`location/${physicalLocationId}/products?k=${keystring}`, callback, callbackError)
  },

  getProductByUpc(physicalLocationId, productUpc, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/product/${productUpc}`, callback, callbackError)
  },

  getProductDetailByKey(physicalLocationId, productKey, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/product/${productKey}/detail`, callback, callbackError)
  },

  getProductsAlsoPurchasedByKey(physicalLocationId, productKey, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/product/${productKey}/also_purchased`, callback, callbackError)
  },

  getRelatedRecipesByKey(physicalLocationId, productKey, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/product/${productKey}/related_recipes`, callback, callbackError)
  },

  getProductSearch(physicalLocationId, searchQuery, offset, limit, callback, callbackError) {
    const query = encodeURIComponent(searchQuery)
    const cacheKey = `${physicalLocationId}:${query}:${offset}:${limit}`
    // callback = cacheInterception(CacheSearchProducts(), cacheKey, callback)
    if (callback) {
      apiGet(`location/${physicalLocationId}/products/search?q=${query}&o=${offset}&l=${limit}&ea=true`, callback, callbackError)
    }
  },

  getRecipeSearch(physicalLocationId, searchQuery, offset, limit, callback, callbackError) {
    const query = encodeURIComponent(searchQuery)
    const cacheKey = `${physicalLocationId}:${query}:${offset}:${limit}`
    // callback = cacheInterception(CacheSearchProducts(), cacheKey, callback)
    if (callback) {
      apiGet(`location/${physicalLocationId}/recipes/search?q=${query}&o=${offset}&l=${limit}`, callback, callbackError)
    }
  },

  getRecipe(physicalLocationId, recipeKey, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/recipe/${recipeKey}`, callback, callbackError)
  },

  getProductsByIngredient(physicalLocationId, ingredientText, ingredientFood, limit, callback, callbackError) {
    const text = encodeURIComponent(ingredientText)
    const food = encodeURIComponent(ingredientFood)
    const cacheKey = `${physicalLocationId}:${text}:${limit}`
    // callback = cacheInterception(CacheIngredientProducts(), cacheKey, callback)
    if (callback) {
      apiGet(`location/${physicalLocationId}/products/ingredient?ingredient=${text}&food=${food}&l=${limit}`, callback, callbackError)
    }
  },

  getPreviouslyPurchasedProductsByQuantity(physicalLocationId, offset, limit, callback, callbackError) {
    // callback = cacheInterception(CacheMisc(), `getPreviouslyPurchasedProductsByQuantity:${physicalLocationId}:${offset}:${limit}`, callback)
    if (callback) {
      apiGet(`location/${physicalLocationId}/products/previously_purchased/quantity?o=${offset}&l=${limit}`, callback, callbackError)
    }
  },

  getPreviouslyPurchasedProductsByPriceDifferential(physicalLocationId, offset, limit, callback, callbackError) {
    // callback = cacheInterception(CacheMisc(), `getPreviouslyPurchasedProductsByPriceDifferential:${physicalLocationId}:${offset}:${limit}`, callback)
    if (callback) {
      apiGet(`location/${physicalLocationId}/products/previously_purchased/price_differential?o=${offset}&l=${limit}`, callback, callbackError)
    }
  },

  getLocations(callback, callbackError) {
    callback = cacheInterception(CacheMisc(), 'locations', callback)
    if (callback) {
      apiGet('locations', callback, callbackError)
    }
  },

  getWeeklyAds(physicalLocationId, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/weekly_ads`, callback, callbackError)
  },

  getWeeklyAdImageUrl(physicalLocationId, widgetId, page) {
    return `${apiBasePath}/location/${physicalLocationId}/weekly_ads/${widgetId}/image/${page}`
  },

  getFavorites(fullProducts, physicalLocationId, callback, callbackError) {
    let path = `location/${physicalLocationId}/favorites`
    if (fullProducts) path += '?full_products=1'
    apiGet(path, callback, callbackError)
  },

  getMotd(physicalLocationId, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/motd`, callback, callbackError)
  },


  /////////
  // LOYALTY
  ////////

  getLoyaltyMembership(physicalLocationId, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/loyalty/membership`, callback, callbackError)
  },

  getLoyaltyClippableCoupons(physicalLocationId, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/loyalty/coupons`, callback, callbackError)
  },

  doLoyaltyAppCardRequestPin(physicalLocationId, phone, method, callback, callbackError) {
    const args = {
      'phone': phone,
      'method': method
    };
    apiPost(`location/${physicalLocationId}/loyalty/request_pin`, args, callback, callbackError)
  },

  doLoyaltyAppCardRegisterPin(physicalLocationId, phone, pin, callback, callbackError) {
    const args = {
      'phone': phone,
      'pin': pin
    };
    apiPost(`location/${physicalLocationId}/loyalty/register_pin`, args, callback, callbackError)
  },

  doLoyaltyClipCoupon(physicalLocationId, offerId, callback, callbackError) {
    apiPost(`location/${physicalLocationId}/loyalty/coupons/${offerId}/clip`, [], callback, callbackError)
  },

  /////////
  // CART
  ////////

  getCart(physicalLocationId, cartKey, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/cart/${cartKey}`, callback, callbackError)
  },

  setProductQtyInCart(physicalLocationId, cartKey, productKey, priceRank, qty, substitutionArgs, callback, callbackError) {
    apiPost(`location/${physicalLocationId}/cart/${cartKey}/product/${productKey}/${priceRank}/${qty}`, substitutionArgs, callback, callbackError)
  },

  addOrIncrementEaProductQtyInCart(listingId, productId, qty, callback, callbackError) {
    const args = {
      'endless_aisle_availability_listing_id': listingId,
      'endless_aisle_product_id': productId,
      'quantity': qty
    };
    
    apiPost(`endless-aisle/cart/items`, args, callback, callbackError)
  },

  decrementEaProductQtyInCart(cartItemId, callback, callbackError) {
    const args = {};
    
    apiPost(`endless-aisle/cart/item/${cartItemId}/decrement`, args, callback, callbackError)
  },

  setEaProductQtyInCart(cartItemId, quantity, callback, callbackError) {
    const args = {
      'quantity': quantity
    };

    apiPost(`endless-aisle/cart/item/${cartItemId}/update-quantity`, args, callback, callbackError)
  },

  deleteEaProductFromCart(cartItemId, callback, callbackError) {
    apiPost(`endless-aisle/cart/item/${cartItemId}/delete`, {}, callback, callbackError)
  },

  setSpecialInstructionsInCart(physicalLocationId, cartKey, cartItemId, specialInstructions, callback, callbackError) {
    apiPost(`location/${physicalLocationId}/cart/${cartKey}/item/${cartItemId}/instructions`, { special_request: specialInstructions }, callback, callbackError)
  },
  setSubstitutionInCart(physicalLocationId, cartKey, cartItemId, permitSubstitution, callback, callbackError) {
    apiPost(`location/${physicalLocationId}/cart/${cartKey}/item/${cartItemId}/substitution`, { substition: permitSubstitution }, callback, callbackError)
  },

  /////////
  // ORDERS
  ////////

  getOrderHistory(callback, callbackError) {
    apiGet(`orders`, callback, callbackError)
  },

  getOrderDetail(orderKey, callback, callbackError) {
    apiGet(`order/${orderKey}`, callback, callbackError)
  },

  cancelOrder(orderKey, callback, callbackError) {
    apiPost(`order/${orderKey}/cancel`, {}, callback, callbackError)
  },
  /////////
  // AUTH
  ////////

  authenticate(email, password, token, callback, callbackError) {
    apiPost(`authenticate`, { email: email, password: password, token: token }, callback, callbackError)
  },

  authenticateViaFacebookToken(facebookToken, callback, callbackError) {
    apiPost(`authenticate`, { fbtoken: facebookToken }, callback, callbackError)
  },

  deauthenticate(callback, callbackError) {
    apiPost(`deauthenticate`, {}, callback, callbackError)
  },

  cancelUser(callback, callbackError) {
    apiPost(`cancel_user_account`, {}, callback, callbackError)
  },

  register(email, password, firstName, lastName, callback, callbackError) {
    apiPost(`register`, { email: email, password: password, first_name: firstName, last_name: lastName }, callback, callbackError)
  },

  forgotPasswordEmail(email, callback, callbackError) {
    apiPost(`forgot_password`, { email: email }, callback, callbackError)
  },

  /////////
  // CHECKOUT
  ////////

  getDeliveryAddresses(callback, callbackError) {
    apiGet(`delivery_addresses`, callback, callbackError)
  },

  storeDeliveryAddress(addressArgs, callback, callbackError) {
    apiPost(`delivery_addresses`, addressArgs, callback, callbackError)
  },

  deliveryPreschedule(physicalLocationId, cartKey, orderType, orderArgs, callback, callbackError) {
    apiPost(`location/${physicalLocationId}/cart/${cartKey}/preschedule/${orderType}`, orderArgs, callback, callbackError)
  },

  getAvailableScheduleByDate(physicalLocationId, date, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/checkout/schedule/${date}`, callback, callbackError)
  },

  deliverySchedule(physicalLocationId, orderArgs, callback, callbackError) {
    apiPost(`location/${physicalLocationId}/checkout/schedule`, orderArgs, callback, callbackError)
  },

  getPaymentMethods(physicalLocationId, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/checkout/payment`, callback, callbackError)
  },

  setPaymentMethod(physicalLocationId, paymentArgs, callback, callbackError) {
    apiPost(`location/${physicalLocationId}/checkout/payment`, paymentArgs, callback, callbackError)
  },

  addPromoCode(physicalLocationId, cartKey, promoArgs, callback, callbackError) {
    apiPost(`location/${physicalLocationId}/cart/${cartKey}/promo/add`, promoArgs, callback, callbackError)
  },

  removePromoCode(physicalLocationId, cartKey, promoArgs, callback, callbackError) {
    apiPost(`location/${physicalLocationId}/cart/${cartKey}/promo/remove`, promoArgs, callback, callbackError)
  },
  prepareOrder(physicalLocationId, orderArgs, callback, callbackError) {
    apiPost(`location/${physicalLocationId}/checkout/prepare`, orderArgs, callback, callbackError)
  },
  submitOrder(physicalLocationId, orderArgs, callback, callbackError) {
    apiPost(`location/${physicalLocationId}/checkout/submit`, orderArgs, callback, callbackError)
  },
  getEaEstimatedDeliveryDates(callback, callbackError) {
    apiGet(`endless-aisle/delivery-dates`, callback, callbackError)
  },
  //merge order with car
  doCheckoutOrderMerge(order_key, data, callback, callbackError) {
    apiPost(`merge/${order_key}`, data, callback, callbackError);
  },
  //gel some orders that can be merged
  getMergeOrders(physicalLocationId,callback,callbackError){
    apiGet(`orders/merge/${physicalLocationId}`,callback,callbackError);
  },

  getOrderTipConfig(physicalLocationId, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/checkout/order-tip-config`, callback, callbackError)
  },


  /////////
  // MISC
  ////////

  getStoreInfo(physicalLocationId, callback, callbackError) {
    apiGet(`location/${physicalLocationId}/info`, callback, callbackError)
  },
  /////////

  correctImagePath(path) {
    if (path.substring(0, 1) === '/') {
      return storeConfig.storeDomain + path
    } else {
      return path
    }
  },

  convertApiProductToProductModel(apiProduct, productKey) {
    const productModel = new Product()

    productModel.productKey = productKey
    productModel.productName = apiProduct.product_name
    if (apiProduct.image_url_150)
      productModel.productImageUrl150 = this.correctImagePath(apiProduct.image_url_150)
    if (apiProduct.image_url_215)
      productModel.productImageUrl215 = this.correctImagePath(apiProduct.image_url_215)
    if (apiProduct.image_url_500)
      productModel.productImageUrl500 = this.correctImagePath(apiProduct.image_url_500)
    if (apiProduct.image_url_full)
      productModel.productImageUrlFull = this.correctImagePath(apiProduct.image_url_full)

    if (apiProduct.increment)
      productModel.quantityIncrement = apiProduct.increment

    if (apiProduct.age_restricted)
      productModel.productAgeRestricted = true

    if (apiProduct.product_aisle_name)
      productModel.productAisleName = apiProduct.product_aisle_name

    if (apiProduct.product_aisle_section)
      productModel.productAisleSection = apiProduct.product_aisle_section

    if (apiProduct.product_categories) {
      apiProduct.product_categories.forEach((e) => {
        productModel.productCategory.push(e.shophero_category_key)
      })
    }

    if (apiProduct.price) {
      productModel.pricePrice = apiProduct.price.product_price
      productModel.priceRank = apiProduct.price.product_price_rank
      if (apiProduct.price.price_unit) {
        productModel.productUnit = apiProduct.price.price_unit.product_unit
        productModel.productUnitPlural = apiProduct.price.price_unit.product_unit_plural
        productModel.productUnitText = apiProduct.price.price_unit.product_text
      }
    }

    if (apiProduct.price_base) {
      productModel.basePricePrice = apiProduct.price_base.product_price
      productModel.basePriceRank = apiProduct.price_base.product_price_rank
      if (apiProduct.price_base.price_unit) {
        productModel.baseProductUnit = apiProduct.price_base.price_unit.product_unit
        productModel.baseProductUnitPlural = apiProduct.price_base.price_unit.product_unit_plural
        productModel.baseProductUnitText = apiProduct.price_base.price_unit.product_text
      }
    }

    if (apiProduct.product_label_units) {
      productModel.productLabelUnits = apiProduct.product_label_units
    }

    if(apiProduct.is_endless_aisle) {
      productModel.isEndlessAisle = true;
      productModel.endlessAisle.casePrice = productModel.pricePrice;
      productModel.endlessAisle.caseQty = apiProduct.case_unit_quantity;
      productModel.endlessAisle.listingId = apiProduct.ea_product_listing_id;
    }

    return productModel
  },

  convertApiEAProductToProductModel(apiProduct) {
    const productModel = new Product()

    productModel.productKey = apiProduct.endless_aisle_product_id;
    productModel.productName = apiProduct.name

    if (apiProduct.image_url) {
      productModel.productImageUrl150 = this.correctImagePath(apiProduct.image_url)
      productModel.productImageUrl215 = this.correctImagePath(apiProduct.image_url)
      productModel.productImageUrl500 = this.correctImagePath(apiProduct.image_url)
      productModel.productImageUrlFull = this.correctImagePath(apiProduct.image_url)
    }

    if (apiProduct.increment)
      productModel.quantityIncrement = 1

    productModel.productAgeRestricted = false;

    // TODO: Add support (will need category keys)
    // if (apiProduct.product_categories) {
    //   apiProduct.product_categories.forEach((e) => {
    //     productModel.productCategory.push(e.shophero_category_key)
    //   })
    // }

    productModel.productCategory.push('eaeaea')
    productModel.productCategory.push('000000')

    if (apiProduct.unit_price) {
      productModel.pricePrice = apiProduct.unit_price
      productModel.priceRank = 0

      let isCase = apiProduct.case_unit_qty == 1 ? false : true;

      productModel.productUnit = isCase ? 'case' : 'each';
      productModel.productUnitPlural = isCase ? 'cases' : 'units';
      productModel.productUnitText = ''
    }

    // if (apiProduct.product_label_units) {
    //   productModel.productLabelUnits = apiProduct.product_label_units
    // }

    productModel.isEndlessAisle = true;
    productModel.endlessAisle.casePrice = apiProduct.unit_price;
    productModel.endlessAisle.caseQty = apiProduct.case_unit_qty;
    productModel.endlessAisle.listingId = apiProduct.endless_aisle_availability_listing_id;

    return productModel
  },
}
