<template>

  <f7-link panel-open="right">
    <div class="iconWrapper">
      <div class="icon-wrap">
        <div class="circleNotif"><p>{{number}}</p></div>
        <svg width="28" height="28" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>cart</title>
          <desc>Created using Figma</desc>
          <g id="Canvas" transform="translate(-430 -1195)">
          <g id="cart">
          <use xlink:href="#path0_fill" transform="translate(430 1195)" fill="#FFFFFF"/>
          </g>
          </g>
          <defs>
          <path id="path0_fill" d="M 0 0L 0 2.5L 2.85156 2.5L 6.13281 12.2656L 4.25781 14.7656L 4.21875 14.7656C 3.64258 15.5713 3.61816 16.6455 4.02344 17.4219C 4.42871 18.1982 5.2832 18.75 6.25 18.75L 22.5 18.75L 22.5 16.25L 6.25 16.25C 6.25488 16.2451 6.23535 16.2305 6.25 16.2109L 8.125 13.75L 19.8828 13.75C 20.3809 13.75 20.8887 13.5059 21.0156 13.0078L 24.8828 4.25781C 25.2588 3.38379 24.624 2.5 23.75 2.5L 5.46875 2.5L 4.92188 0.859375L 4.64844 0L 0 0ZM 6.25 20C 4.86816 20 3.75 21.1182 3.75 22.5C 3.75 23.8818 4.86816 25 6.25 25C 7.63184 25 8.75 23.8818 8.75 22.5C 8.75 21.1182 7.63184 20 6.25 20ZM 20 20C 18.6182 20 17.5 21.1182 17.5 22.5C 17.5 23.8818 18.6182 25 20 25C 21.3818 25 22.5 23.8818 22.5 22.5C 22.5 21.1182 21.3818 20 20 20Z"/>
          </defs>
        </svg>
      </div>
    </div>
  </f7-link>

</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    number: ({ $store }) => $store.getters.productQtyInCart,
  },
}
</script>

<style lang="scss" scoped>

  .link{
    width: 100% !important;
    height: 100% !important;
    display: block;
  }
  div.iconWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    div.icon-wrap{
      width: auto;
      position: relative;
      line-height: 0;
    }
    div.circleNotif {
      // background: $colorThemeC;
      background: #12C522;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      position: absolute;
      right: -5px;
      top: -7px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        color: white !important;
        font-weight: 700;
      }
    }
  }


</style>




