<template>
<div class="recipe-box">
  <div class="recipe-image-container" @click="navigateToRecipe">
    <img class='recipe-image lazy'
         v-bind:data-src="recipe.image" />
  </div>
  <div class="recipe-name" @click="navigateToRecipe">
      <span v-html="recipe.label.trunc(40, true)"></span>
  </div>
  <div class="recipe-servings" @click="navigateToRecipe">
        <span v-html="servingsLabel"></span>
  </div>
    <div class="recipe-source" @click="navigateToRecipe">
        <span v-html="recipe.source"></span>
  </div>
</div>



</template>

<script>

export default {

  props: [
    'recipe',
  ],

  computed: {
    servingsLabel() {
      const calories = (this.recipe.calories / this.recipe.yield)
      // const servingsText = (this.recipe.yield > 1) ? 'servings' : 'serving'
      return 'Serves ' + this.recipe.yield + ' &bull; ' + calories.toFixed(0) +  ' cal'
    }
  },

  methods: {
    navigateToRecipe() {
        const path = `/recipe/${this.recipe.key}/`;
        this.$f7.views.main.router.navigate(path)
    }
  },

  created() {
    // console.log(this.recipe)
  },

  mounted() {
    this.$root.$f7.lazy.create('.recipe-image')
  }
}
</script>

<style lang="scss">
 //Main Styles for the page
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .recipe-box {
    padding: 2%; // overflow: hidden;
    height: auto;
    position: relative;
    .recipe-image-container {
      max-height: 120px;
      max-width: 120px;
      overflow: hidden;
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 4px;
      margin-bottom: 4px;
      img.recipe-image {
        display: table;
        width: 100% !important;
        height: auto !important;
        margin-right: auto;
        margin-left: auto;
        // max-height: 111px;
        border-radius: 10px;

      }
    }
    .recipe-name {
      font-size: 11px;
      font-weight: $bold;
      line-height: 130%;
      max-height: 50px;
      overflow-y: hidden;
      letter-spacing: 0.5px;
      margin-bottom: 2px;
      @include breakpoint(tablet) {
        font-size: 14px;
      }
      a {
        color: #111;
      }
    }
    .recipe-source {
      font-size: 10px;
      font-weight: $regular;
      line-height: 130%;
      max-height: 50px;
      overflow-y: hidden;
      letter-spacing: 0.5px;
      color: #ccc;
      @include breakpoint(tablet) {
        font-size: 14px;
      }
      a {
        color: #111;
      }
    }
    .recipe-servings {
      font-size: 9px;
      font-weight: $regular;
      line-height: 130%;
      max-height: 50px;
      overflow-y: hidden;
      letter-spacing: 0.5px;
      margin-bottom: 2px;
      color: $accentColor;
      @include breakpoint(tablet) {
        font-size: 14px;
      }
      a {
        color: #111;
      }
    }
  }

</style>
