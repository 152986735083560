<template>
  <f7-row>
    <f7-col width="100">
      <f7-row>
        <div class="cd-header">
          <cd-icon />
          <h1>Choice Doorstep&trade; Items</h1>
        </div>
      </f7-row>

      <f7-row>
        <div class="checkout-flex-col">
          <div class="cd-fedex-wrapper"><FedExButton /></div>
        </div>
      </f7-row>

      <f7-row class="items-container">
        <f7-col width="100">
          <sh-product-card-ea
            v-for="oneEaProduct in eaCartItems"
            v-bind:product="oneEaProduct"
            :key="oneEaProduct.productKey"
          />
        </f7-col>
      </f7-row>

      <f7-row>
        <f7-col>
          <h1 class="cd-delivery-address-title">Shipping Address</h1>
          <div class="shipping-address-container">
            <div class="cd-delivery-address">
              {{ storedShippingAddress.address_address1 }}
              <span v-if="storedShippingAddress.address_address2">{{
                storedShippingAddress.address_address2
              }}</span>
              <br />{{ storedShippingAddress.address_city }},
              {{ storedShippingAddress.address_state }}
              {{ storedShippingAddress.address_zip }}
            </div>

            <f7-button class="edit-button" href="/checkout-service/">
              Edit Address
            </f7-button>
          </div>
        </f7-col>
      </f7-row>

      <f7-row>
        <f7-col width="100" class="checkout-flex-col">
          <div class="cd-order-total-title">Shipped Items Order Total</div>
        </f7-col>
      </f7-row>

      <f7-row>
        <f7-col width="80" class="checkout-flex-col">
          <div class="cd-order-total-items">Grocery Total</div>
        </f7-col>
        <f7-col width="20" class="checkout-flex-col">
          <div class="cd-order-total-items">
            ${{ (eaCartCheckoutTotals.itemsTotal * 1).toFixed(2) }}
          </div>
        </f7-col>
      </f7-row>

      <f7-row>
        <f7-col width="80" class="checkout-flex-col">
          <div class="cd-order-total-items">
            {{ eaCartCheckoutTotals.taxDesc }} (
            {{ eaCartCheckoutTotals.taxRate }}% )
          </div>
        </f7-col>
        <f7-col width="20" class="checkout-flex-col">
          <div class="cd-order-total-items">
            ${{ (eaCartCheckoutTotals.taxTotal * 1).toFixed(2) }}
          </div>
        </f7-col>
      </f7-row>

      <f7-row class="cd-order-grand-total-row"></f7-row>

      <f7-row>
        <f7-col width="80" class="checkout-flex-col">
          <div class="cd-order-grand-total">Total</div>
        </f7-col>
        <f7-col width="20" class="checkout-flex-col">
          <div class="cd-order-total-items">
            ${{ (eaCartCheckoutTotals.grandTotal * 1).toFixed(2) }}
          </div>
        </f7-col>
      </f7-row>

      <f7-row class="cd-info-card-row">
        <f7-col width="100" class="checkout-flex-col">
          <f7-card class="cd-info-card">
            <f7-card-content class="cd-info-card-text">
              <b>Shipped Items orders cannot be canceled.</b> I acknowledge and
              agree that orders shipped from ShopHero's partners cannot be
              canceled once placed.
            </f7-card-content>
          </f7-card>
        </f7-col>
      </f7-row>
    </f7-col>
  </f7-row>
</template>

<script>
import FedExButton from "./FedExButton.vue";
import ShProductCardEa from "./ShProductCardEa.vue";
import CdIcon from "../components/icons/CdIcon.vue";

export default {
  data() {
    return {};
  },
  props: ["eaCartItems", "eaCartCheckoutTotals", "storedShippingAddress"],

  components: {
    ShProductCardEa,
    FedExButton,
    CdIcon,
  },
};
</script>

<style lang="scss" scoped="">
.cd-icon {
  min-width: 50px;
  height: auto;
}

.cd-header {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fff;
}

.cd-fedex-wrapper {
  margin: 20px 0;
}

.cd-delivery-address-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.cd-order-total-title {
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  margin-top: 30px;
}

.cd-order-total-items {
  font-size: 14px;
  text-align: right;
  line-height: 2;
}

.cd-order-grand-total {
  font-size: 18px;
  text-align: right;
  line-height: 2;
  font-weight: bold;
}

.cd-info-card {
  background: #eee;
}

.cd-info-card-row {
  margin-top: 30px;
}

.cd-info-card-text {
  font-size: 12px;
}

.cd-order-grand-total-row {
  border-top: 1px solid silver;
  margin: 10px 0 10px 0;
}

.cd-delivery-address {
  font-size: 1rem;
}

.checkout-flex-col {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.shipping-address-container {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.75rem;
  align-items: end;
}

.edit-button {
  background: $storePrimaryColor;
  color: white;
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.items-container {
  margin-bottom: 1rem;
}
</style>
