/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: "warn" */

import * as types from '../mutation-types' // eslint-disable-line no-unused-vars

// initial state
const state = {
  title: '',
  subtitle: '',
  searchInputText: '',
  searchInputPlaceholder: 'Bananas',
  searchFocus: false,
  routeStateMap: [],
  searchMode: 'PRODUCTS',
}

// getters
const getters = {
  title: state => state.title,
  subtitle: state => state.subtitle,
  searchFocusState: state => state.searchFocus,
  searchInputText: state => state.searchInputText,
  searchInputPlaceholder: state => state.searchInputPlaceholder,
  searchMode: state => state.searchMode,
}

// actions
const actions = {
  setTitle({ commit }, { title }) {
    commit(types.RECEIVE_NAV_TITLE, { title })
  },
  setSubtitle({ commit }, { subtitle }) {
    commit(types.RECEIVE_NAV_SUBTITLE, { subtitle })
  },
  setSearchPlaceholder({ commit }, { placeholder }) {
    commit(types.RECEIVE_NAV_SEARCH_PLACEHOLDER, { placeholder })
  },
  setSearchText({ commit }, { text }) {
    commit(types.RECEIVE_NAV_SEARCH_TEXT, { text })
  },
  pushNavBarState({ commit }, { route }) {
    commit(types.RECEIVE_NAV_STATE, { route })
  },
  refreshNavBar({ commit }, { route }) {
    commit(types.RECEIVE_NAV_REFRESH, { route })
  },
  setSearchFocus({ commit }, { focused }) {
    if (focused) {
      commit(types.RECEIVE_NAV_SEARCH_FOCUS)
    } else {
      commit(types.RECEIVE_NAV_SEARCH_BLUR)
    }
  },
  setSearchModeProducts({ commit }) {
    commit(types.RECEIVE_NAV_SEARCH_MODE,  { mode: 'PRODUCTS' } )
  },
  setSearchModeRecipes({ commit }) {
    commit(types.RECEIVE_NAV_SEARCH_MODE,  { mode: 'RECIPES' } )
  },

}

// mutations
const mutations = {
  [types.RECEIVE_NAV_TITLE](state, { title }) {
    state.title = title
  },
  [types.RECEIVE_NAV_SUBTITLE](state, { subtitle }) {
    state.subtitle = subtitle
  },
  [types.RECEIVE_NAV_SEARCH_PLACEHOLDER](state, { placeholder }) {
    state.searchInputPlaceholder = placeholder
  },
  [types.RECEIVE_NAV_SEARCH_TEXT](state, { text }) {
    state.searchInputText = text
  },
  [types.RECEIVE_NAV_STATE](state, { route }) {
    state.routeStateMap[route] = {
      title: state.title,
      subtitle: state.subtitle,
      searchInputText: state.searchInputText,
      searchInputPlaceholder: state.searchInputPlaceholder,
    }
  },
  [types.RECEIVE_NAV_REFRESH](state, { route }) {
    if (state.routeStateMap[route]) {
      const map = state.routeStateMap[route]
      state.title = map.title
      state.subtitle = map.subtitle
      state.searchInputText = map.searchInputText
      state.searchInputPlaceholder = map.searchInputPlaceholder
    }
  },
  [types.RECEIVE_NAV_SEARCH_FOCUS](state) {
    state.searchFocus = true
  },
  [types.RECEIVE_NAV_SEARCH_BLUR](state) {
    state.searchFocus = false
  },
  [types.RECEIVE_NAV_SEARCH_MODE](state, { mode }) {
    state.searchMode = mode
  },
}


export default {
  state,
  getters,
  actions,
  mutations,
}
