<template>
  <f7-page no-navbar no-toolbar class="white-bg">
    <div slot="fixed">
      <sh-checkout-bar selectedStep="schedule"></sh-checkout-bar>
    </div>

    <div class="spacer-top" />

    <f7-card class="card-error" v-if="checkoutInfo.errorMessage">
      <f7-card-content>{{ checkoutInfo.errorMessage }}</f7-card-content>
    </f7-card>

    <f7-card class="card-error" v-if="errorMessage">
      <f7-card-content>{{ errorMessage }}</f7-card-content>
    </f7-card>

    <div v-if="cartHasEaProducts">
      <div class="cd-header-row">
        <cd-icon />
        <h1>Choice Doorstep&trade; Items</h1>
      </div>

      <f7-row class="white-bg">
        <f7-card class="arriving-info-card">
          <f7-card-content class="cd-information-card">
            <cd-arrival-icon />
            <div>
              <div>
                Arriving between {{ eaDeliveryStartDate }} &
                {{ eaDeliveryEndDate }}
              </div>
              <div class="cd-free-shipping">Free Shipping via Fedex</div>
            </div>
          </f7-card-content>
        </f7-card>
      </f7-row>
    </div>

    <div class="cd-header-row">
      <cd-basket-icon />
      <h1>In Store Items</h1>
    </div>

    <f7-row v-if="!cartHasInStoreProducts" class="white-bg">
      <f7-card class="arriving-info-card">
        <f7-card-content>
          <div>Your cart contains no in-store items.</div>
        </f7-card-content>
      </f7-card>
    </f7-row>

    

    <f7-row v-if="cartHasInStoreProducts">
      <f7-col width="100">
        <f7-card class="card-fee">
          <f7-card-header>Your {{ serviceLabel }}</f7-card-header>
          <f7-card-content>
            <template
              v-if="
                preScheduleLoaded && this.checkoutInfo.serviceType == 'pickup'
              "
            >
              <f7-row>
                <f7-col width="40" class="left-column">Selected Store:</f7-col>
                <f7-col width="60" class="right-column">{{
                  selectedLocation.physical_location_name
                }}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="40" class="left-column">Convenience Fee:</f7-col>
                <f7-col width="60" class="right-column"
                  >${{
                    parseFloat(preScheduleResponse.convenience_fee).toFixed(2)
                  }}</f7-col
                >
              </f7-row>
            </template>
            <template
              v-if="
                preScheduleLoaded && this.checkoutInfo.serviceType == 'delivery'
              "
            >
              <template
                v-if="preScheduleResponse.delivery_rate_rows.length > 0"
              >
                <f7-row>
                  <f7-col width="100" class="sub-header"
                    >Delivery from
                    {{ selectedLocation.physical_location_name }}</f7-col
                  >
                </f7-row>
                <f7-row
                  v-for="rate_row in preScheduleResponse.delivery_rate_rows"
                  v-bind:key="randomInteger()"
                >
                  <template
                    v-if="
                      rate_row.delivery_rate_miles_start > 0 ||
                        rate_row.delivery_rate_miles_end > 0
                    "
                  >
                    <f7-col width="5">&nbsp;</f7-col>
                    <f7-col width="50"
                      >{{ rate_row.delivery_rate_miles_start }} to
                      {{ rate_row.delivery_rate_miles_end }} miles</f7-col
                    >
                    <f7-col width="45" v-if="rate_row.delivery_rate_fee > 0"
                      >${{ rate_row.delivery_rate_fee.toFixed(2) }}</f7-col
                    >
                    <f7-col width="45" v-if="rate_row.delivery_rate_fee == 0"
                      >FREE</f7-col
                    >
                  </template>
                  <template
                    v-if="
                      rate_row.delivery_rate_minutes_start > 0 ||
                        rate_row.delivery_rate_minutes_end > 0
                    "
                  >
                    <f7-col width="5">&nbsp;</f7-col>
                    <f7-col width="50"
                      >{{ rate_row.delivery_rate_minutes_start }} to
                      {{ rate_row.delivery_rate_minutes_end }} minutes</f7-col
                    >
                    <f7-col width="45" v-if="rate_row.delivery_rate_fee > 0"
                      >${{ rate_row.delivery_rate_fee.toFixed(2) }}</f7-col
                    >
                    <f7-col width="45" v-if="rate_row.delivery_rate_fee == 0"
                      >FREE</f7-col
                    >
                  </template>
                </f7-row>
                <f7-row
                  v-if="preScheduleResponse.delivery_rate_row_miles_max > 0"
                >
                  <f7-col width="5">&nbsp;</f7-col>
                  <f7-col width="50"
                    >Over
                    {{ preScheduleResponse.delivery_rate_row_miles_max }}
                    miles</f7-col
                  >
                  <f7-col width="45">Unavailable</f7-col>
                </f7-row>
                <f7-row
                  v-if="preScheduleResponse.delivery_rate_row_minutes_max > 0"
                >
                  <f7-col width="5">&nbsp;</f7-col>
                  <f7-col width="50"
                    >Over
                    {{ preScheduleResponse.delivery_rate_row_minutes_max }}
                    minutes</f7-col
                  >
                  <f7-col width="45">Unavailable</f7-col>
                </f7-row>
                <f7-row>
                  <f7-col width="100">&nbsp;</f7-col>
                </f7-row>
              </template>

              <f7-row>
                <f7-col width="40" class="left-column">Selected Store:</f7-col>
                <f7-col width="60" class="right-column">{{
                  selectedLocation.physical_location_name
                }}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="40" class="left-column">Your Address:</f7-col>
                <f7-col width="60" class="right-column">{{
                  preScheduleResponse.address_formatted
                }}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="40" class="left-column">Travel Time:</f7-col>
                <f7-col width="60" class="right-column">
                  {{ preScheduleResponse.route_mins }} minutes <br />(by
                  Google&trade; Maps)
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="40" class="left-column">Travel Distance:</f7-col>
                <f7-col width="60" class="right-column"
                  >{{ preScheduleResponse.route_miles }} miles</f7-col
                >
              </f7-row>
              <f7-row v-if="preScheduleResponse.delivery_quotes.length > 0">
                <f7-col width="40" class="left-column">Delivered By:</f7-col>
                <f7-col
                  width="60"
                  class="right-column"
                  v-if="
                    preScheduleResponse.delivery_quotes[0]
                      .delivery_service_provider == 'POSTMATES'
                  "
                >
                  <img class="img-logo-postmates" />
                </f7-col>
                <f7-col
                  width="60"
                  class="right-column"
                  v-if="
                    preScheduleResponse.delivery_quotes[0]
                      .delivery_service_provider == 'DOORDASH'
                  "
                >
                  <img class="img-logo-doordash" />
                </f7-col>
                <f7-col
                  width="60"
                  class="right-column"
                  v-if="
                    preScheduleResponse.delivery_quotes[0]
                      .delivery_service_provider == 'BURQ'
                  "
                >
                  <img class="img-logo-burq" />
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="40" class="left-column">Delivery Fee:</f7-col>
                <f7-col
                  width="60"
                  class="right-column"
                  v-if="preScheduleResponse.delivery_fee > 0"
                >
                  ${{ parseFloat(preScheduleResponse.delivery_fee).toFixed(2) }}
                  <template
                    v-if="
                      parseFloat(
                        preScheduleResponse.voucher_delivery_discount_amount
                      ) > 0
                    "
                    >- ${{
                      parseFloat(
                        preScheduleResponse.voucher_delivery_discount_amount
                      ).toFixed(2)
                    }}
                    discount</template
                  >
                </f7-col>
                <f7-col
                  width="60"
                  class="right-column"
                  v-if="preScheduleResponse.delivery_fee == 0"
                  >FREE</f7-col
                >
              </f7-row>
              <f7-row
                v-if="parseFloat(preScheduleResponse.convenience_fee) > 0"
              >
                <f7-col width="40" class="left-column">Convenience Fee:</f7-col>
                <f7-col width="60" class="right-column">
                  ${{
                    parseFloat(preScheduleResponse.convenience_fee).toFixed(2)
                  }}
                  <template
                    v-if="
                      parseFloat(
                        preScheduleResponse.voucher_convenience_discount_amount
                      ) > 0
                    "
                    >- ${{
                      parseFloat(
                        preScheduleResponse.voucher_convenience_discount_amount
                      ).toFixed(2)
                    }}
                    discount</template
                  >
                </f7-col>
              </f7-row>
            </template>
            <template v-if="!preScheduleLoaded">
              <div class="loading">
                <div class="preloader"></div>
              </div>
            </template>
          </f7-card-content>
        </f7-card>

        <transition name="fade">
          <f7-card class="card-schedule" v-if="preScheduleLoaded">
            <f7-card-header>Schedule Your {{ serviceLabel }}</f7-card-header>
            <f7-card-content>
              <div v-if="schedulesLoaded < scheduleDayLimit">
                <div class="loading">
                  <div class="preloader"></div>
                </div>
              </div>

              <f7-row class="timeslot-error" v-if="slotErrorMessage">
                <f7-col width="100">Uh oh! {{ slotErrorMessage }}</f7-col>
              </f7-row>

              <f7-row
                class="timeslot-alert"
                v-if="
                  cartMessaging.schedulingSpecialAlert.active &&
                    schedulesLoaded > 0
                "
              >
                <f7-col width="100">
                  {{ cartMessaging.schedulingSpecialAlert.title }}
                  <br />
                  <br />
                  {{ cartMessaging.schedulingSpecialAlert.content }}
                </f7-col>
              </f7-row>

              <!-- <transition name="fade"> -->
              <f7-row>
                <div class="timeline-container">
                  <div
                    class="timeline timeline-horizontal"
                    v-if="schedulesLoaded == scheduleDayLimit"
                  >
                    <div class="timeline timeline-horizontal col-40 tablet-20">
                      <!--   <div class="timeline-month" v-for="month in availableSchedulesMonths" v-bind:key="month"> -->
                      <template v-for="month in availableSchedulesMonths">
                        <!-- <div class="timeline-month-title"><span>{{availableSchedules[month].name}}</span></div> -->
                        <div
                          class="timeline-item timeline-item-min"
                          v-for="day in availableSchedulesMonthsDays[month]"
                          v-bind:key="day"
                        >
                          <div class="timeline-item-date">
                            {{ availableSchedules[month].days[day].weekday }}
                            <br />
                            {{ availableSchedules[month].days[day].date }}
                          </div>
                          <div class="timeline-item-content">
                            <template
                              v-if="
                                availableSchedules[month].days[day].slots
                                  .length > 0
                              "
                            >
                              <template
                                v-for="slot in availableSchedules[month].days[
                                  day
                                ].slots"
                              >
                                <div
                                  v-bind:class="{
                                    'timeline-item-text': true,
                                    'time-slot-button': true,
                                    'slot-alert-reserved-background-color':
                                      slot.age_reserved,
                                    'time-slot-button-selected':
                                      selectedSlot == nameTimeslot(day, slot),
                                  }"
                                  v-bind:data-slot="nameTimeslot(day, slot)"
                                  @click="onClickTimeslot"
                                >
                                  <template
                                    v-if="checkoutInfo.serviceType == 'pickup'"
                                  >
                                    Pickup at
                                    <span>{{ slot.end_friendly }}</span>
                                    <div
                                      v-show="slot.age_reserved"
                                      class="slot-alert-reserved"
                                    >
                                      <i class="f7-icons"
                                        >exclamationmark_triangle_fill</i
                                      >
                                      Reserved for <br />elderly &amp; high risk
                                    </div>
                                  </template>
                                  <template
                                    v-if="
                                      checkoutInfo.serviceType == 'delivery'
                                    "
                                  >
                                    <span>{{ slot.start_friendly }}</span> to
                                    <span>{{ slot.end_friendly }}</span>
                                    <div
                                      v-show="slot.age_reserved"
                                      class="slot-alert-reserved"
                                    >
                                      <i class="f7-icons"
                                        >exclamationmark_triangle_fill</i
                                      >
                                      Reserved for <br />elderly &amp; high risk
                                    </div>
                                  </template>
                                </div>
                              </template>
                            </template>
                            <template v-else>
                              <div
                                class="timeline-item-time time-slot-not-available"
                              >
                                Not Available
                              </div>
                            </template>
                          </div>
                        </div>
                        <!-- </div> -->
                      </template>
                    </div>
                  </div>
                </div>
              </f7-row>

              <!-- </transition> -->
            </f7-card-content>
          </f7-card>
        </transition>
      </f7-col>
    </f7-row>

    <div v-if="!cartHasInStoreProducts" style="margin: 30px 12px">
      <sh-checkout-button
        @onButtonClick="onClickContinueToPaymentEaOnly($event)"
        :disabledConditional="true"
      >
        Continue to Payment
      </sh-checkout-button>
    </div>
    <div v-else style="margin: 30px 12px">
      <sh-checkout-button
        @onButtonClick="onClickContinueToPayment($event)"
        :disabledConditional="scheduleValid"
      >
        Continue to Payment
      </sh-checkout-button>
    </div>


    <div class="spacer-bottom" />
  </f7-page>
</template>

<script>
import moment from "moment";

import $$ from "dom7";

import ShopHeroApi from "../api/ShopHeroApi";

import ShCheckoutBar from "../components/ShCheckoutBar.vue";
import CdIcon from "../components/icons/CdIcon.vue";
import CdBasketIcon from "../components/icons/CdBasketIcon.vue";
import CdArrivalIcon from "../components/icons/CdArrivalIcon.vue";
import ShCheckoutButton from "../components/ShCheckoutButton.vue";

export default {
  data() {
    return {
      scheduleDayLimit: 1,

      preScheduleLoaded: false,

      preScheduleResponse: {},

      schedulesLoaded: 0,

      availableSchedules: {},
      availableSchedulesMonths: [],
      availableSchedulesMonthsDays: {},

      selectedSlot: null,

      slotErrorMessage: null,

      errorMessage: null,
      eaDeliveryStartDate: null,
      eaDeliveryEndDate: null,
    };
  },

  components: {
    ShCheckoutBar,
    CdIcon,
    CdBasketIcon,
    CdArrivalIcon,
    ShCheckoutButton,
  },

  computed: {
    selectedLocation: ({ $store }) => $store.getters.selectedLocation,
    checkoutInfo: ({ $store }) => $store.getters.getCheckoutInfo,
    cartKey: ({ $store }) => $store.getters.cartKey,
    cartMessaging: ({ $store }) => $store.getters.cartMessaging,
    serviceLabel: ({ checkoutInfo }) => {
      if (checkoutInfo.serviceType == "pickup") return "Grocery Pick Up";
      return "Grocery Delivery";
    },
    scheduleValid: ({ selectedSlot }) => selectedSlot !== null,
    cartHasEaProducts: ({ $store }) => $store.getters.cartHasEaProducts,
    cartHasInStoreProducts: ({ $store }) =>
      $store.getters.cartHasInStoreProducts,
  },

  props: {},

  methods: {
    applyPreSchedule() {
      const self = this;
      this.preScheduleResponse = this.checkoutInfo.deliveryPrescheduleResponse;
      const response = this.preScheduleResponse;

      console.log(response);

      if (response.success == false) {
        const orderInfo = {
          deliveryAddressValid: false,
          deliveryAddressError:
            "The selected address was unable to be used for scheduling a delivery: " +
            response.error_message,
          errorMessage: null,
        };

        this.errorMessage = orderInfo.deliveryAddressError;

        this.$store.dispatch("checkoutMergeInfo", { orderInfo }).then(() => {
          self.$f7.views.main.router.navigate("/checkout-service/");
        });

        return;
      } else {

      let delivery_rate_row_miles_max = 0;
      let delivery_rate_row_minutes_max = 0;
      if (response.delivery_rate_rows) {
        response.delivery_rate_rows.forEach(function(row) {
          delivery_rate_row_miles_max = Math.max(
            delivery_rate_row_miles_max,
            row.delivery_rate_miles_end
          );
          delivery_rate_row_minutes_max += Math.max(
            delivery_rate_row_minutes_max,
            row.delivery_rate_minutes_end
          );
        });
      }

      response.delivery_rate_row_miles_max = delivery_rate_row_miles_max;
      response.delivery_rate_row_minutes_max = delivery_rate_row_minutes_max;

      this.preScheduleResponse = response;
      this.preScheduleLoaded = true;

      const scheduleStartingDate = new moment(response.starting_date);
      const scheduleEndingDate = new moment(response.ending_date);
      this.scheduleDayLimit =
        scheduleEndingDate.diff(scheduleStartingDate, "days") + 1;

      this.populateSchedule(this.scheduleDayLimit);
      }
    },
    populateSchedule(daysCount) {
      const self = this;

      self.schedulesLoaded = 0;

      for (let i = 0; i < daysCount; i++) {
        const m = new moment().add(i, "d");

        const formattedDate = m.format("YYYY-MM-DD");

        ShopHeroApi.getAvailableScheduleByDate(
          this.selectedLocation.physical_location_id,
          formattedDate,
          (response) => {
            // console.log(response)

            if (!response.success) {
              this.errorMessage = "Uh oh! " + response.error_message;
              this.scrollTo(".spacer-top");
              return;
            }

            const month = 1; //m.format('M')
            const date = m.format("X"); //m.format('D')

            if (!self.availableSchedules[month]) {
              self.availableSchedules[month] = {
                name: m.format("MMMM"),
                days: {},
              };
              this.availableSchedulesMonths.push(month);
              this.availableSchedulesMonthsDays[month] = [];
            }

            // console.log(self.availableSchedules[month])

            if (!self.availableSchedules[month].days[date]) {
              self.availableSchedules[month].days[date] = {
                weekday: m.format("dddd"),
                date: m.format("MMMM Do"),
                slots: [],
              };

              this.availableSchedulesMonthsDays[month].push(date);
            }

            response.available_time_slots.forEach(function(slot) {
              // slot.start_friendly = new moment(slot.start_local).format("h:mma")
              // slot.end_friendly = new moment(slot.end_local).format("h:mma")
              slot.start_friendly = new moment(slot.start, "HH:mm:ss").format(
                "h:mm a"
              );
              slot.end_friendly = new moment(slot.end, "HH:mm:ss").format(
                "h:mm a"
              );
              self.availableSchedules[month].days[date].slots.push(slot);
            });

            this.availableSchedulesMonths.sort();
            this.availableSchedulesMonthsDays[month].sort();

            self.schedulesLoaded++;
          }
        );
      }
    },
    onClickContinueToPaymentEaOnly() {
      this.$f7.views.main.router.navigate("/checkout-payment/");
    },
    onClickContinueToPayment() {
      const timeslotArgs = {
        timeslot: this.selectedSlot,
      };

      const buttonContent = $$(".button-proceed-to-payment").html();
      $$(".button-proceed-to-payment").html("Please wait...");

      const self = this;

      ShopHeroApi.deliverySchedule(
        this.selectedLocation.physical_location_id,
        timeslotArgs,
        (response) => {
          // console.log(response)
          if (!response.success) {
            this.populateSchedule(this.scheduleDayLimit);

            this.slotErrorMessage =
              "The previously selected time is no longer available! Please select another.";
            if (response.error_message) {
              this.slotErrorMessage = response.error_message;
            }

            $$(".button-proceed-to-payment").html(buttonContent);

            this.scrollTo(".card-schedule");
          } else {
            const orderInfo = {
              timeSlot: this.selectedSlot,
            };

            this.$store
              .dispatch("checkoutMergeInfo", { orderInfo })
              .then(() => {
                self.$f7.views.main.router.navigate("/checkout-payment/");
                setTimeout(function() {
                  $$(".button-proceed-to-payment").html(buttonContent);
                }, 1000);
              });
          }
        }
      );
    },
    scrollTo(element) {
      const scrollToElement = $$(element);
      const pageContent = $$(".page-content");
      const scrollTo = pageContent.offset().top - scrollToElement.offset().top;
      pageContent.scrollTop(scrollTo, 300);
    },
    nameTimeslot(timestamp, slot) {
      const m = new moment.unix(timestamp);
      return (
        m.format("YYYY-MM-DD") +
        `_${slot.start.replace(":", "")}_${slot.end.replace(":", "")}_${
          slot.driver_availability_id
        }`
      );
    },
    randomInteger(max = Number.MAX_SAFE_INTEGER) {
      return Math.floor(Math.random() * max);
    },
    onClickTimeslot(e) {
      this.selectedSlot = $$(e.target)
        .closest(".time-slot-button")
        .attr("data-slot");
      // console.log(this.selectedSlot)
    },
    populateEaEstimatedDeliveryDates() {
      ShopHeroApi.getEaEstimatedDeliveryDates((response) => {
        this.eaDeliveryStartDate = moment(response.start).format(
          "dddd, MMMM Do"
        );
        this.eaDeliveryEndDate = moment(response.end).format("dddd, MMMM Do");
      });
    },
  },

  mounted() {
    this.applyPreSchedule();
    this.populateEaEstimatedDeliveryDates();
  },
};
</script>

<style lang="scss" scoped="true">
.spacer-top {
  margin-top: 0px;
}

.spacer-bottom {
  height: calc(270px + var(--sab));
}

.card-header {
  color: $storePrimaryColor;
  font-weight: $bold;
}

.list {
  font-size: 14px !important;
}

.card-fee {
  .row {
    margin-bottom: 10px;
  }
  .left-column {
    // font-weight: $bold;
  }
  .right-column {
    color: $storePrimaryColor;
    font-weight: $bold;
  }
  .column-header {
    // font-weight: $bold;
    text-decoration: underline;
  }
  .sub-header {
    // font-size: 1.1em;
    // font-weight: $bold;
    // color: $storePrimaryColor;
  }

  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.card-schedule {
  margin-bottom: 50px;
  .card-content {
    //overflow-x: auto;
    // overflow-y: scroll;
  }
  .timeline-container {
    overflow-x: auto;
  }
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.loading {
  height: 100px;
  width: 100%;
  position: relative;
  .preloader {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.time-slot-button {
  border-radius: 10px;
  min-height: 40px;
  border: 1px solid $storePrimaryColor;
  margin: 5px 0px 5px 0px;
  padding: 10px 5px 0px 5px;
  text-align: center;
  font-size: 11px;
  color: #666;
  span {
    font-size: 12px;
    font-weight: $bold;
    color: $fontColor;
  }
}

.time-slot-button-selected {
  background-color: $storePrimaryColor !important;
  color: #fff !important;
  text-shadow: 0 1px 0 rgba(76, 70, 56, 0.7);
  span {
    color: #fff !important;
  }
}

.timeline-month-title {
  font-weight: $bold;
}

.timeline-item-date {
  height: 40px;
  line-height: 1.25;
  font-weight: $bold;
  // color: $storePrimaryColor;
  // min-width: 175px !important;
}

.timeline-item-min {
  min-width: 170px !important;
}

.time-slot-not-available {
  height: 40px;
  text-align: center;
  font-size: 12px;
  color: #666;
  font-style: italic;
  margin-top: 10px !important;
}

.button-proceed-to-payment {
  .preloader {
    height: 20px;
    width: 20px;
  }
}

.timeslot-error {
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: $storePrimaryColor;
  color: #fff;
  font-weight: $bold;
  padding: 10px;
}

.timeslot-alert {
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #ff5733;
  color: #fff;
  font-weight: $bold;
  padding: 15px;
  padding-bottom: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter {
  transform: translateY(200px);
  opacity: 0;
}
.slide-fade-leave-to {
  opacity: 0;
}

.card-error {
  background-color: $colorRed;
  color: #fff;
}

.img-logo-postmates {
  content: url("../images/postmates-logo.png");
  max-width: 160px;
}

.img-logo-doordash {
  content: url("../images/doordash-logo.png");
  max-width: 160px;
}

.img-logo-burq {
  content: url("../images/burq-logo.png");
  max-width: 160px;
}

.slot-alert-reserved-background-color {
  background-color: #f0f0f0;
}

.slot-alert-reserved {
  margin-top: 10px;
  margin-bottom: 12px;
  color: #ff5733;
  i {
    font-size: 12px;
  }
}

.checkout-flex-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px 0 5px;
  margin: auto;
}

.cd-icon {
  min-width: 50px;
  height: auto;
}

.cd-header {
  h2 {
    font-size: 28px;
    font-weight: bold;
  }
  margin-left: 25px;
}

.cd-header-row {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fff;
  padding: 20px 10px 10px 10px;
}

.cd-information-card {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  align-items: center;
}

.white-bg {
  background: #fff;
}

.arriving-info-card {
  background: #ffffff;
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 25px !important;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}

.cd-free-shipping {
  font-size: 12px;
  font-weight: normal;
  line-height: 2;
  color: #999999;
}

.proceedButton {
  margin-top: 30px;
  background: $storePrimaryColor;
  color: white;
}
</style>
