<template>
  <f7-page class="no-padding">
    <div class="spacer"></div>
    <div class="category-menu-container">
      <!--     <h1>Aisles</h1> -->
      <div
        class="category-menu-wrapper"
        v-for="button in menuCategories"
        @click="loadRoute('/product-browse/' + button.categoryKey + '/')"
      >
        <div
          class="category-menu-button"
          v-bind:style="{ 'background' : 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5)), ' + 'url('+ relativeImageUrl(button.categoryImage) + ')' + ' no-repeat center center', 'background-size' : 'cover'}"
          b-bind:key="button.categoryKey"
        >
          <div class="category-menu-button-text">
            <p>{{ button.categoryDisplayName }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer-bottom"></div>
  </f7-page>
</template>

<script>
/*
// import images (for webpack)
// this is required for dynamically loaded images
// as WEBPACK doesn't detect them as the URL is constructed
// at runtime
*/

import imgCatFreshFruit from "../images/category-buttons/cat_fresh_fruit.jpg"; // eslint-disable-line no-unused-vars
import imgCatFreshVeg from "../images/category-buttons/cat_fresh_veg.jpg"; // eslint-disable-line no-unused-vars
import imgCatMilk from "../images/category-buttons/cat_milk.jpg"; // eslint-disable-line no-unused-vars
import imgCatEggs from "../images/category-buttons/cat_eggs.jpg"; // eslint-disable-line no-unused-vars
import imgCatCheese from "../images/category-buttons/cat_cheese.jpg"; // eslint-disable-line no-unused-vars
import imgCatYogurt from "../images/category-buttons/cat_yogurt.jpg"; // eslint-disable-line no-unused-vars
import imgCatBeef from "../images/category-buttons/cat_beef.jpg"; // eslint-disable-line no-unused-vars
import imgCatPoultry from "../images/category-buttons/cat_poultry.jpg"; // eslint-disable-line no-unused-vars
import imgCatBeverages from "../images/category-buttons/cat_beverages.jpg"; // eslint-disable-line no-unused-vars
import imgCatCoffee from "../images/category-buttons/cat_coffee.jpg"; // eslint-disable-line no-unused-vars
import imgCatBread from "../images/category-buttons/cat_bread.jpg"; // eslint-disable-line no-unused-vars
import imgCatBreakfast from "../images/category-buttons/cat_breakfast.jpg"; // eslint-disable-line no-unused-vars
import imgCatBaby from "../images/category-buttons/cat_baby.jpg"; // eslint-disable-line no-unused-vars
import imgCatPaper from "../images/category-buttons/cat_paper.jpg"; // eslint-disable-line no-unused-vars
import imgCatPersonalCare from "../images/category-buttons/cat_personal_care.jpg"; // eslint-disable-line no-unused-vars
import imgCatPet from "../images/category-buttons/cat_pet.jpg"; // eslint-disable-line no-unused-vars
import imgCatDeli from "../images/category-buttons/cat_deli.jpg"; // eslint-disable-line no-unused-vars
import imgCatWine from "../images/category-buttons/cat_wine.jpg"; // eslint-disable-line no-unused-vars

// ////

import storeConfig from "../config";

export default {
  data() {
    return {
      menuCategoriesDefault: [
        {
          categoryKey: storeConfig.categoryMap["fresh-fruit"],
          categoryDisplayName: "Fresh Fruit",
          categoryImage: "cat_fresh_fruit.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["fresh-vegetables"],
          categoryDisplayName: "Fresh Vegetables",
          categoryImage: "cat_fresh_veg.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["deli"],
          categoryDisplayName: "Deli",
          categoryImage: "cat_deli.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["milk"],
          categoryDisplayName: "Milk",
          categoryImage: "cat_milk.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["eggs"],
          categoryDisplayName: "Eggs",
          categoryImage: "cat_eggs.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["cheese"],
          categoryDisplayName: "Cheese",
          categoryImage: "cat_cheese.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["yogurt"],
          categoryDisplayName: "Yogurt",
          categoryImage: "cat_yogurt.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["beef"],
          categoryDisplayName: "Beef",
          categoryImage: "cat_beef.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["poultry"],
          categoryDisplayName: "Poultry",
          categoryImage: "cat_poultry.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["beverages"],
          categoryDisplayName: "Beverages",
          categoryImage: "cat_beverages.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["coffee"],
          categoryDisplayName: "Coffee",
          categoryImage: "cat_coffee.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["bread"],
          categoryDisplayName: "Bread",
          categoryImage: "cat_bread.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["breakfast"],
          categoryDisplayName: "Breakfast & Cereal",
          categoryImage: "cat_breakfast.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["formula-baby-care"],
          categoryDisplayName: "Formula & Baby Care",
          categoryImage: "cat_baby.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["paper-products"],
          categoryDisplayName: "Paper Products",
          categoryImage: "cat_paper.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["personal-care"],
          categoryDisplayName: "Personal Care & Health",
          categoryImage: "cat_personal_care.jpg"
        },
        {
          categoryKey: storeConfig.categoryMap["pet-care"],
          categoryDisplayName: "Pet Care",
          categoryImage: "cat_pet.jpg"
        },
         {
          categoryKey: storeConfig.categoryMap["wine-beer-spirits"],
          categoryDisplayName: "Wine, Beer & Spirits",
          categoryImage: "cat_wine.jpg"
         }
      ]
    };
  },

  props: [],

  components: {},

  computed: {
    menuCategories: ({ menuCategoriesDefault }) => {
      if (storeConfig.menuCategories.length > 0) {
        return storeConfig.menuCategories;
      }

      let menuCategoriesFiltered = [];
      menuCategoriesDefault.forEach(function(item) {
        if (item.categoryKey) {
          menuCategoriesFiltered.push(item);
        }
      });

      return menuCategoriesFiltered;
    }
  },

  methods: {
    loadRoute(page) {
      this.$f7.views.main.router.navigate(page);
    },
    relativeImageUrl(url) {
      if(url.includes('http')) {
        return url
      } else {
        return 'images/' + url
      }
    }
  },

  mounted() {
    this.$root.setActiveToolbar("aisles");
    this.$root.setNavbarTitle("Browse Aisles");
    this.$root.setNavbarSubtitle("");

    this.$store.dispatch("setSearchModeProducts");
  }
};
</script>

<style lang="scss" scoped="true">
.no-padding {
  /deep/ .page-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.spacer {
  width: 100%;
  height: var(--f7-page-spacer-top);
}

.spacer-bottom {
  width: 100%;
  height: calc(80px + var(--sab));
}

.category-menu-container {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 10px 0;
  h1 {
    margin: 10px 5px 5px 5px;
    width: 100%;
  }
  .category-menu-wrapper {
    width: 33.33%;
    @include breakpoint(tablet) {
      width: 25%;
    }
    padding: 5px;
    .category-menu-button {
      width: 100%;
      height: 100px;
      @include breakpoint(tablet) {
        height: 200px;
      }
      border: 1px solid #ddd;
      border-radius: 10px;
      position: relative;
    }
    .category-menu-button-text {
      position: absolute;
      width: 100%;
      bottom: 5px;
      vertical-align: bottom;
      left: 5px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      text-shadow: 1px 2px rgba(0, 0, 0, 0.25);
      p {
        margin: 0;
      }
    }
  }
}
</style>
