// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

// Import App Component
import App from '../app/app.vue';

import store from '../store'

// import ShopHeroApi from './api/ShopHeroApi'

// import storeConfig from './config.js'

import IntersectionObserver from 'intersection-observer' // eslint-disable-line no-unused-vars

import VueLazyload from 'vue-lazyload'

import $$ from "dom7"

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

Vue.use(IntersectionObserver)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 2,
  observer: true
})



// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },

  store,

  methods: {

    // this is the hardware back button on an android device

    hideDeviceSplash() {
      // alert('hideDeviceSplash')
      if (typeof navigator !== 'undefined') {
        if (typeof navigator.splashscreen !== 'undefined') {
          // if(typeof navigator.splashscreen.hide === 'function') {
          navigator.splashscreen.hide();
          // }
        }
      }
    },
    setStatusBarStyleLightText() {
      if (typeof (StatusBar) == 'object') {
        StatusBar.styleLightContent();
      }
    },
    setStatusBarStyleDarkText() {
      if (typeof (StatusBar) == 'object') {
        StatusBar.styleDefault()
      }
    },
    setNavbarTitle(title) {
      this.$store.dispatch('setTitle', { title })
    },
    setNavbarSubtitle(subtitle) {
      this.$store.dispatch('setSubtitle', { subtitle })
    },
    setNavbarSearchText(text) {
      this.$store.dispatch('setSearchText', { text })
    },
    setNavbarSearchPlaceholder(placeholder) {
      this.$store.dispatch('setSearchPlaceholder', { placeholder })
    },
    setActiveToolbar(icon) {
      this.$store.dispatch('setActiveToolbarIcon', { icon })
    },
    addedItemToCartToast() {
      const toast = this.$f7.toast.create({
        icon: '<i class="f7-icons" style="margin-bottom: 5px;">cart_badge_plus</i>',
        text: 'Added to cart!',
        closeTimeout: 1200,
      })
      toast.open()
      if (navigator) {
        if (typeof navigator.vibrate === 'function') {
          navigator.vibrate(250)
        }
      }
    },
    onRouteChanged(newRoute, previousRoute, router) {
      // console.log("shNavBar-fonRouteChanged: " + newRoute.url, newRoute);
      if (newRoute.url == "/home/") {
        this.setStatusBarStyleLightText()
      } else if (newRoute.url == "/splash/") {
        this.setStatusBarStyleDarkText()
      } else if (newRoute.url == "/location-select/") {
        this.setStatusBarStyleDarkText()
      } else if (newRoute.url.includes("/auth-")) {
        this.setStatusBarStyleDarkText()
      } else if (newRoute.url.includes("/checkout-")) {
        this.setStatusBarStyleLightText()
      } else {
        this.setStatusBarStyleLightText()
      }
    },

    showChoiceDoorstepConfirmationModal(product, proposedQuantity) {
      this.$store.commit('OPEN_CHOICE_DOORSTEP_CONFIRMATION_MODAL', {
        product: product,
        proposedQuantity: proposedQuantity
      })
    },
  },

  created() {
    /* eslint-disable */
    Object.size = function (obj) {
      let size = 0,
        key
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++
      }
      return size
    }

    String.prototype.trunc =
      function (n, useWordBoundary) {
        if (this.length <= n) { return this; }
        var subString = this.substr(0, n - 1);
        return (useWordBoundary ?
          subString.substr(0, subString.lastIndexOf(' ')) :
          subString) + "&hellip;";
      };




    /* eslint-enable */
  },

  mounted() {

    const self = this

    let onDeviceReady = function () {
      // console.log('onDeviceReadyVue', self)
      if (self.$f7.device.android) {
        let root = document.documentElement;
        root.style.setProperty('--sat', '26px');
      }
    }

    document.addEventListener('deviceready', onDeviceReady);

    let onBackKeyDown = function () {

      // console.log('onBackKeyDown', self)

      const app = self.$f7

      var leftp = app.panel.left && app.panel.left.opened
      var rightp = app.panel.right && app.panel.right.opened

      if (leftp || rightp) {

        app.panel.close()
        return false

      } else if ($$('.modal-in').length > 0) {

        app.dialog.close()
        app.popup.close()
        return false

      } else if (app.views.main.router.url == '/home/') {

        app.navigator.app.exitApp()
      } else {

        app.views.main.router.back()
      }
    }


    document.addEventListener("backbutton", onBackKeyDown, false);


    function onGlobalDeviceReady() {

      if (typeof (StatusBar) == 'object') {
        StatusBar.overlaysWebView(true);
      }
    }

    document.addEventListener('deviceready', onGlobalDeviceReady);


    self.$f7ready($f7 => {
      $f7.router.app.on("routeChange", function (
        newRoute,
        previousRoute,
        router
      ) {
        self.onRouteChanged(newRoute, previousRoute, router);
      });
    });


  }
});



