<template>
  <f7-card class="product-card" v-if="product.isEndlessAisle">
    <f7-link @click="navigateToProduct" class="product-link">
      <f7-card-content>
        <f7-row class="product-container">
          <div class="product-quantity">
            <h2>{{ productQty }}</h2>
            <div>{{ product.productUnitPlural }}</div>
          </div>

          <img
            class="product-image"
            v-bind:data-src="product.productImageUrl150"
            v-bind:src="product.productImageUrl150"
          />

          <div class="product-name">{{ product.productName }}</div>

          <div>
            <div class="product-price-per-case">
              ${{ (product.pricePrice * 1).toFixed(2) }}/{{ product.productUnit }}
            </div>
            <div class="product-price-total">
              ${{ (product.pricePrice * productQty).toFixed(2) }}
            </div>
          </div>
        </f7-row>
      </f7-card-content>
    </f7-link>
  </f7-card>
</template>

<script>
export default {
  data() {
    return {
      showEaProduct: true,
    };
  },

  props: ["product"],

  methods: {
    navigateToProduct() {
      const path = `/product/${this.product.productKey}/`;
      const router = this.$f7.views.main.router;

      // Go to regular item or EA item
      if (router.currentRoute.url !== path) {
        router.navigate(path, { props: { isEA: true } });
      }
    },
  },

  computed: {
    productQty: ({ product, $store }) => {
      // the if will typically be for an ordered item - the else for an in-cart item, this widget is used for both
      if (product.productQty) {
        return product.productQty;
      } else {
        const productInCart = $store.getters.getProductInCartByProductKey(
          product.productKey
        );
        if (productInCart) {
          if (productInCart.qtyInCart == parseInt(productInCart.qtyInCart)) {
            return parseInt(productInCart.qtyInCart);
          } else {
            return productInCart.qtyInCart;
          }
        } else {
          return 0;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped="true">
.product-card {
  background: #ffffff;
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.product-link {
  width: 100%;
  display: block;
}

.product-container {
  display: grid;
  grid-template-columns: auto 1fr 3fr auto;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.product-name {
  font-size: .75rem;
  color: #474646;
}

.product-quantity {
  h2 {
    font-size: 1rem;
    font-weight: bold;
  }
  text-align: center;
  font-size: 1rem;
  color: #474646;
}

.product-price-per-case {
  color: #898989;
  font-size: .75rem;
  text-align: right;
}

.product-price-total {
  color: #474646;
  font-size: 1rem;
  font-weight: bold;
  text-align: right;
}

img.product-image {
  width: 100%;
}
</style>
