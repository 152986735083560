<template>
<f7-page>
  <div class="spacer"></div>

    <template v-if="!userIsAuthenticated">
       <f7-card class="card-sign-in">
        <f7-card-content>
          Hi there! Please <strong><f7-link href="/auth-sign-in/orders/">sign in</f7-link></strong> to view your order history.
        </f7-card-content>
      </f7-card>
    </template>

    <template v-else>

      <div class="orders-loading" v-show="!ordersLoaded">
        <div class="preloader"></div>
      </div>

      <div class="noOrders" v-show="orderHistory.length == 0">
        <h1>OH SNAP!</h1>
        <p>It looks like you haven't ordered anything yet!</p>
      </div>

      <div class="ordersTrue" v-show="orderHistory.length > 0">
        <f7-list links-list>
        <div class="list links-list" v-show="ordersLoaded">
          <ul>
            <li 
              v-for="(order,index) in orderHistory" 
              v-bind:key="order.order_key" 
              class="order-row"
            >
              
              <div v-if="order.endless_aisle_customer_order_id">
                <div >
                  <a v-bind:href="`/orders/${order.order_key}/`">
                    <div class="list-div-width">
                      <f7-row>
                        <f7-col width="25"><strong>Shipping</strong><br/>#{{order.order_key.toUpperCase()}}</span></f7-col>
                        <f7-col width="75">
                          <strong class="color-yellow">Choice Doorstep</strong>
                          <br>Placed - {{formatDate(order.created_at, 'MMM Do YYYY @ h:mm a')}}
                        </f7-col>
                      </f7-row>
                    </div>
                  </a>
                </div>
              </div>



              <div v-if=" ! order.endless_aisle_customer_order_id">

                <div v-if="order.order_fulfilled_at">
                
                  <a v-bind:href="`/orders/${order.order_key}/`">
                  <div v-if="order.order_type == 'delivery'" class="list-div-width">
                    <f7-row>
                      <f7-col width="25"><strong>Delivery</strong><br/>#{{order.order_key.toUpperCase()}}</span></f7-col>
                      <f7-col width="75">
                        <strong class="color-green">Delivered</strong>
                        <br>{{formatDate(order.order_fulfilled_at, 'MMM Do YYYY @ h:mm a')}}   
                      </f7-col>
                      
                    </f7-row>
                    </div>
                    <div v-if="order.order_type == 'pickup'" class="list-div-width">
                      <f7-row>
                        <f7-col width="25"><strong>Pick Up</strong><br/>#{{order.order_key.toUpperCase()}}</span></f7-col>
                        <f7-col width="75">
                          <strong class="color-green">Picked Up</strong>
                          <br>{{formatDate(order.order_fulfilled_at, 'MMM Do YYYY @ h:mm a')}}
                        </f7-col>
                      </f7-row>
                    </div>
                  </a>
                </div>
                <div v-if="order.order_state == 'open'">
                  <a v-bind:href="`/orders/${order.order_key}/`">
                    <div v-if="order.order_type == 'delivery'" class="list-div-width">
                      <f7-row>
                        <f7-col width="25"><strong>Delivery</strong><br/>#{{order.order_key.toUpperCase()}}</span></f7-col>
                        <f7-col width="75">
                          <strong class="color-yellow">Scheduled</strong>
                          <br>{{formatDate(order.order_scheduled_delivery_time_start, 'MMM Do YYYY, h:mm')}} to {{formatDate(order.order_scheduled_delivery_time_end, 'h:mm a')}}
                        </f7-col>
                      </f7-row>
                    </div>
                    <div v-if="order.order_type == 'pickup'" class="list-div-width">
                      <f7-row>
                        <f7-col width="25"><strong>Pick Up</strong><br/>#{{order.order_key.toUpperCase()}}</span></f7-col>
                        <f7-col width="75">
                          <strong class="color-yellow">Scheduled</strong>
                          <br>{{formatDate(order.order_scheduled_delivery_time_end, 'MMM Do YYYY')}} after {{formatDate(order.order_scheduled_delivery_time_end, 'h:mm a')}}
                        </f7-col>
                      </f7-row>
                    </div>
                  </a>
                </div>
                <div v-if="order.order_state == 'canceled'">
                  <a v-bind:href="`/orders/${order.order_key}/`">
                    <div v-if="order.order_type == 'delivery'" class="list-div-width">
                      <f7-row>
                        <f7-col width="25"><strong>Delivery</strong><br/>#{{order.order_key.toUpperCase()}}</span></f7-col>
                        <f7-col width="75">
                          <strong class="color-red">Canceled</strong>
                          <br>{{formatDate(order.order_scheduled_delivery_time_start, 'MMM Do YYYY, h:mm')}} to {{formatDate(order.order_scheduled_delivery_time_end, 'h:mm a')}}
                        </f7-col>
                      </f7-row>
                    </div>
                    <div v-if="order.order_type == 'pickup'" class="list-div-width">
                      <f7-row>
                        <f7-col width="25"><strong>Pick Up</strong><br/>#{{order.order_key.toUpperCase()}}</span></f7-col>
                        <f7-col width="75" class="color-red">
                          <strong>Canceled</strong>
                          <br>{{formatDate(order.order_scheduled_delivery_time_end, 'MMM Do YYYY')}} after {{formatDate(order.order_scheduled_delivery_time_end, 'h:mm a')}}
                        </f7-col>
                      </f7-row>
                    </div>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </f7-list>
    </div>
  </template>


  <div class="spacer-bottom"></div>



</f7-page>
</template>

<script>

import moment from 'moment'

import $$ from 'dom7'

import ShopHeroApi from '../api/ShopHeroApi';


export default {

  data() {
    return {
      orderHistory: [],
      ordersLoaded: false,
    }
  },

  computed: {
    userIsAuthenticated: ({ $store }) => $store.getters.userIsAuthenticated,
  },

  methods: {
    formatDate(timeString, format) {
      const m = new moment(timeString)
      return m.format(format)
    }
  },

  mounted() {

    if(this.userIsAuthenticated) {
      ShopHeroApi.getOrderHistory((response) => {
          // console.log(response)
          this.orderHistory = response.order_history
          this.ordersLoaded = true
          console.log(this.orderHistory == 0)
      })
    }

    this.$root.setActiveToolbar('none')

    this.$root.setNavbarTitle("My Order History")
    this.$root.setNavbarSubtitle("")


  },

}

</script>

<style lang="scss" scoped="true">

.spacer {
  width: 100%;
  height: calc(20px + var(--f7-page-spacer-top));
}

.spacer-bottom {
  width: 100%;
  height: 80px;
}

.card-sign-in {
  margin-top: 40px;
  a {
    color: $accentColor;
  }
}

.order-row {
  font-size: 13px;
  padding: 10px 0px;
  border-bottom: 1px solid #e3e2e2;
  
  .color-green{
    color: #009933;
  }
  .color-yellow {
    color: #ff8c00;
  }
  .color-red {
    color: #ff3300;
  }
  --f7-list-item-border-color: #fff;
}

.orders-loading {
  margin-top: 40px;
  text-align: center;
  height: 200px;
  width: 100%;
  position: relative;
  .preloader {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }
}

.noOrders {
  height: 100%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  color: #8d8d8d;
}

.list-div-width {
  width: 98%;
}
</style>
