<template>
  <f7-page no-navbar no-toolbar>
    <div class="uhoh-containers">
      <div class="uhoh-logo">
        <img src="../images/store-assets/store-logo.png" alt="logo" />
      </div>
      <h1>Uh oh!</h1>
      <h2>Something's not quite right.</h2>
      <p>
        &nbsp;
        <br />
        <br />
      </p>
      <h4>Checking that your internet connection is working properly as well as closing and restarting this app may help.</h4>
    </div>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$root.hideDeviceSplash();
  }
};
</script>

<style lang="scss" scoped="true">
.page {
  background-image: url(../images/search/search-background2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% -0px;
  text-align: center;
  padding: 30px;
  padding-top: var(--f7-page-spacer-top);
}

.uhoh-logo {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 50px;
  max-width: 90%;
}

.uhoh-container {
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 200px;
  max-width: 300px;
  padding: 40px;
  position: relative;

  h4 {
    margin-top: 20px;
  }
}
</style>
