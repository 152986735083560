/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: "warn" */

import ShopHeroApi from '../../api/ShopHeroApi' // eslint-disable-line no-unused-vars

import * as types from '../mutation-types' // eslint-disable-line no-unused-vars

// initial state
const state = {
  tree: {},
  index: {},
}

// getters
const getters = {
  categoryTree: state => state.tree,
  getCategoryByKey: state => key => state.index[key],
}

function indexNodes(index, node, depth) {
  if (depth > 5) return
  if (node === undefined) return
  if (node.category_special_key) return
  if (node.child_categories === undefined) return
  Object.keys(node.child_categories).forEach((k) => {
    node.child_categories[k].parent_category = node
    index[k] = node.child_categories[k]
    if (index[k].child_categories === undefined) { index[k].child_categories = {} }
    indexNodes(index, node.child_categories[k], depth + 1)
  })
}

// actions
const actions = {
  populateRootCategoryTree({ commit }, { physicalLocationId }) {
    return new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
      ShopHeroApi.getCategoryTree(physicalLocationId, (categories) => {
        commit(types.RECEIVE_ROOT_CATEGORIES, { categories })
        resolve()
      }, (error) =>
        reject(error)
      )
      // }
    })
  },
}

// mutations
const mutations = {
  [types.RECEIVE_ROOT_CATEGORIES](state, { categories }) {
    state.tree = categories

    state.tree.child_categories['eaeaea'] = {
      category_name: 'Choice Doorstep'
    };

    const nodeIndex = {}
    indexNodes(nodeIndex, categories, 1)

    state.index = nodeIndex
  },
}


export default {
  state,
  getters,
  actions,
  mutations,
}
