<template>
  <div class="product-box" v-if=" ! product.isEndlessAisle">
    <!-- increment widget -->
    <div class="increment-wrapper">
      <sh-product-qty-increment-widget
        v-bind:product="product"
        v-bind:isEA="false"
        v-bind:id="`product-increment-widget-${product.productKey}`"
      ></sh-product-qty-increment-widget>
    </div>

    <div class="product-box-image" @click="navigateToProduct">
      <img
        class="sale-tag"
        src="../images/sale-tag.svg"
        alt="Sale tag"
        v-show="isOnSale"
      />

      <template v-if="swiper">
        <div class="product-image-container">
          <img
            class="product-image swiper-lazy"
            v-bind:data-src="productImageUrl"
          />
          <!-- <div class="swiper-lazy-preloader">
            <div class="swiper-lazy-preloader-container">
              <img src="images/photo_loading_lt_100.png" />
            </div>
          </div> -->
        </div>
      </template>
      <template v-else>
        <div
          class="product-image-container"
          v-lazy-container="{ selector: 'img' }"
        >
          <img
            class="product-image"
            v-bind:data-src="productImageUrl"
            data-loading="images/photo_loading_lt_100.png"
          />
        </div>
      </template>
      <!-- <i class="fa fa-heart icon-heart-favorite"></i> -->
    </div>
    <div class="product-price" v-if="parseFloat(this.product.pricePrice) > 0">
      <strong>${{ this.product.pricePrice }}</strong>
      <span v-if="productUnit !== undefined">
        <span v-if="productUnit !== 'each'">per</span>
        {{ productUnit }}
      </span>
    </div>
    <div class="product-price" v-else>
      <strong>
        <i>In-store Price</i>
      </strong>
    </div>
    <div class="product-name" @click="navigateToProduct">
      <span
        v-html="filteredSizeFromName(this.product.productName).trunc(30, true)"
      ></span>
    </div>
    <div class="product-per-unit">
      <span v-html="productLabelUnitPrice"></span>
    </div>

    <div v-if="this.product.productAisleName" class="product-name">
      <div> 
        <span>Aisle: {{ this.product.productAisleName }}</span> 
        <span v-if="this.product.productAisleSection"><br>Section: {{ this.product.productAisleSection }}</span> 
      </div>
    </div>
    
  </div>
</template>

<script>
import $$ from "dom7";

import ShProductQtyIncrementWidget from "../components/ShProductQtyIncrementWidget.vue";

import plus from "../images/icons/plus.svg"; // eslint-disable-line no-unused-vars
import minus from "../images/icons/minus.svg"; // eslint-disable-line no-unused-vars

import imgLoading100 from "../images/photo_loading_lt_100.png"; // eslint-disable-line no-unused-vars
import imgLoading56 from "../images/photo_loading_lt_56.png"; // eslint-disable-line no-unused-vars

import saleTag from "../images/sale-tag.svg"; // eslint-disable-line no-unused-vars

export default {
  data() {
    return {};
  },

  components: {
    ShProductQtyIncrementWidget,
  },

  props: ["product", "swiper"],

  methods: {
    filteredSizeFromName: (name) =>
      name.replace(/,\s+[0-9]+.?[0-9]*\s+[a-z]{2,}/g, ""),
    // truncatedName: filteredSizeFromName => (filteredSizeFromName.length() > 60)? filteredSizeFromName.substring(0, 60) + '...' : name,
    navigateToProduct() {
      const path = `/product/${this.product.productKey}/`;
      this.$f7.views.main.router.navigate(path);
    },
  },

  computed: {
    isOnSale: ({ product }) => product.priceRank === 3,
    productUnit() {
      if (this.product.productUnit === undefined) {
        return undefined;
      }
      return this.product.productUnit;
    },
    productImageUrl() {
      // const w = window.screen.width
      const w = window.innerWidth;
      if (w > 740) {
        if (this.product.productImageUrl215) {
          return this.product.productImageUrl215;
        } else {
          // console.log('product image downgrade')
          return this.product.productImageUrl150;
        }
      } else {
        return this.product.productImageUrl150;
      }
    },
    productLabelUnitPrice() {
      return this.product.productUnitLabel();
    },
  },

  watch: {},

  created() {},

  mounted() {
    //this.$nextTick(() => {
    // this.$root.$f7.lazy.create('.product-image')
    //})
    // $$('img.lazy').trigger('lazy')
    // console.log(this.product.productName + ": " + parseFloat(this.product.pricePrice) + ": " + this.product.pricePrice)
  },

  destroyed() {
    // this is necessary because sometimes our widget is detached as a child
    // and needs to be manually removed when this component is destroyed
    // $$(`#product-increment-widget-${this.product.productKey}`).remove()
  },
};
</script>

<style lang="scss" scoped="true">
//Main Styles for the page
*,
*::before,
*::after {
  box-sizing: border-box;
}

.product-box {
  padding: 2%; // overflow: hidden;
  height: auto;
  position: relative;
  max-width: 200px;
  .increment-wrapper {
    position: absolute;
    right: 0px;
    top: 5px;
    display: inline;
    @include breakpoint(tablet) {
      right: 10px;
    }
  }
  .product-image-container {
    max-height: 120px;
    max-width: 120px;
    overflow: hidden;
  }
  .product-box-image {
    padding: 5px;
    height: auto;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 3px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img.product-image {
      width: 100% !important;
      height: auto !important;
      // max-height: 111px;
    }
    img.sale-tag {
      position: absolute;
      left: 3px;
      top: 3px;
    }
    .swiper-lazy-preloader {
      position: absolute;
      top: 0px;
      left: -12px;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px;
      width: 100%;
      height: 100%;
      .swiper-lazy-preloader-container {
        width: 50%;
        margin: 0 auto;
        img {
          width: 100px;
          height: 100px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
    img.swiper-lazy-loaded {
      width: 70%;
      height: auto;
    }
    img.lazy-loaded {
      width: 70%;
      height: auto;
    }
  }
  .product-price {
    font-size: 14px;
    line-height: 150%;
    @include breakpoint(tablet) {
      font-size: 16px;
    }
  }
  .product-name {
    font-size: 10px;
    font-weight: $regular;
    line-height: 130%;
    max-height: 50px;
    overflow-y: hidden;
    @include breakpoint(tablet) {
      font-size: 14px;
    }
    a {
      color: #111;
    }
  }
  .product-per-unit {
    margin-top: 4px;
    font-size: 9px;
    font-weight: $regular;
    color: #ccc;
    line-height: 150%;
    @include breakpoint(tablet) {
      font-size: 12px;
    }
  }
}
svg {
  width: 9px;
  height: 9px;
}
</style>
