<template>
  <div>
    <div v-if="isEA && productModel.endlessAisle.caseQty > 1" class="case-count">
      <case-count :count="productModel.endlessAisle.caseQty" />
    </div>

    <div class="product-image-container">
      <img v-bind:src="isEA ? productImage : productImageUrl" @click="openPhotoBrowser" />
    </div>

    <div class="product-price-base-wrapper" v-if="onSale">
      <div class="product-price-base">
        <span class="product-price-base-title">ON SALE!</span>
        <br />Save
        <span class="product-price-base-bold">
          ${{
            (parseFloat(productPriceBase) - parseFloat(productPrice)).toFixed(2)
          }}
        </span>
        <span>{{ productUnitDisplay }}</span>
        <br />

        <span class="product-price-base-strikeout">
          was ${{ productPriceBase }}
          <span>{{ productUnitDisplay }}</span>
        </span>
        <br />
      </div>
    </div>

    <div class="product-title">
      <h3>{{ productModel.productName }}</h3>
    </div>

    <div class="case-price" v-if="isEA && productModel.endlessAisle.caseQty > 1">
      {{ productModel.endlessAisle.caseQty }} per case (${{
        productModel.endlessAisle.unitPrice().toFixed(2)
      }}
      each)
    </div>

    <div class="product-price">
      <template v-if="parseFloat(productPrice) > 0">
        <span class="product-price">${{ productPrice }}</span>
        <span>{{ productUnitDisplay }}</span>
      </template>
      <template v-else>
        In-store Price
      </template>
    </div>

    <div class="product-per-unit">
      <span v-html="productPricePerUnit" v-if="productPricePerUnit"></span>
    </div>

    <div v-if="productModel.productAisleName" class="product-title">
      <div> 
        <span>Aisle: {{ productModel.productAisleName }}</span> 
        <span v-if="productModel.productAisleSection">Section: {{ productModel.productAisleSection }}</span> 
      </div>
    </div>

    <div v-if="displayCDAlert" class="cd-modal-explanation-text-container">
      <p>You're adding a <strong>full case</strong> of this product to your cart - not just a single item.</p>
      <p>The <strong>full case</strong> will be shipped directly to an address you provide in approximately 4 to 7 days.</p>
    </div>

    <div v-if="isEA" class="fedex-button">
      <fed-ex-button />
    </div>

    <div
      class="product-description"
      v-if="productDescription && showProductDescription"
    >
      <h4>Product Description</h4>
      <p v-html="productDescription"></p>
    </div>
  </div>
</template>

<script>
import CaseCount from "../components/CaseCount.vue";
import FedExButton from "../components/FedExButton.vue";

export default {
  components: {
    CaseCount,
    FedExButton,
  },

  data() {
    return {};
  },

  props: ["productModel", "productImageUrl", "productDetail"],

  computed: {
    model() {
      return this.productModel || {};
    },
    // productQtyInCart: ({ $store }) => {
    //   if (this.model === undefined) return undefined
    //   const productInCart = $store.getters.getProductInCartByProductKey(this.model.productKey)
    //   if (productInCart === undefined) return 0
    //   return productInCart.qtyInCart
    // },
    displayCDAlert() {
      return this.isEA && !this.$store.getters.userHasPlacedCDOrder && this.productModel.endlessAisle.caseQty > 1;
    },
    productPrice() {
      return this.model.pricePrice;
    },
    productPriceBase() {
      return this.model.basePricePrice;
    },
    productUnit() {
      return this.model.productUnit;
    },
    productUnitDisplay() {
      return this.productUnit === "each" ? "each" : "per " + this.productUnit;
    },
    productPricePerUnit() {
      return this.model.productUnitLabel ? this.model.productUnitLabel() : null;
    },
    onSale() {
      return parseFloat(this.productPriceBase) > parseFloat(this.productPrice);
    },
    productDescription() {
      return (this.productDetail || {}).product_custom_description;
    },
    showProductDescription() {
      return this.$store.getters.options.showProductDescription;
    },
    isEA() {
      return this.model.isEndlessAisle;
    },
    productImage() {
      if (window.innerWidth > 740) {
        if (this.model.productImageUrl215) {
          return this.model.productImageUrl215;
        } else {
          return this.model.productImageUrl150;
        }
      } else {
        return this.model.productImageUrl150;
      }
    }
  },

  methods: {
    openPhotoBrowser() {
      // Full size images not here for EA yet, return.
      if (this.isEA) {
        return;
      }

      const productImages = [this.productModel.productImageUrlFull];

      const myPhotoBrowser = this.$root.$f7.photoBrowser.create({
        photos: productImages,
        theme: "light", // 'dark'
      });

      myPhotoBrowser.open();
    },
  },
};
</script>
<style lang="scss" scoped>
.product-image-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  img {
    max-width: 90%;
    max-height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.product-title {
  text-align: center;
  margin: 5px;
  h3 {
    font-size: 1.25rem;
    line-height: 120%;
    @include breakpoint(tablet) {
      font-size: 1.5rem;
    }
  }
}

.product-per-unit {
  font-size: 14px;
  font-weight: $regular;
  color: #ccc;
  line-height: 150%;
  text-align: center;
  margin-top: 10px;
}

.product-price {
  font-size: 1.25rem;
  letter-spacing: 1px;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
}

.product-price-base-wrapper {
  text-align: center;
}

.product-price-base {
  font-size: 13px;
  letter-spacing: 1px;
  text-align: center;

  border: 2px dotted darkred;
  background-color: rgba(139, 0, 0, 0.05);
  border-radius: 5px;
  min-width: 200px;
  margin: 5px;
  display: inline-block;
  padding: 10px;
  .product-price-base-strikeout {
    text-decoration: line-through;
    color: darkred;
  }
  .product-price-base-bold {
    font-weight: bold;
  }
  .product-price-base-title {
    font-weight: bold;
    color: darkred;
  }
}

.product-description {
  padding: 15px;
  text-align: justify;
  font-size: 1rem;
}
.product-description h4 {
  padding: 3% 0 2% 0;
  font-weight: 550;
}

.case-price {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1rem;
}

.fedex-button {
  margin: auto;
  text-align: center;
  width: 200px;
  margin-top: 40px;
}

.fedex-button::v-deep .label {
  font-size: 0.75rem;
  @include breakpoint(tablet) {
    font-size: 1rem;
  }
}

.fedex-button::v-deep .logoContainer {
   width: 30px;
  height: 30px;
  @include breakpoint(tablet) {
    width: 35px;
    height: 35px;
  }
}

.case-count {
  position: absolute;
  right: 10%;
  top: unset;
  margin-top: 1rem;
  width: 2rem;
  height: 2rem;
  font-size: 0.8rem;
  width: 50px;
  height: 50px;
}

.case-count::v-deep .counterLabel {
  font-size: 0.6rem;
}

.cd-modal-explanation-text-container {
  color: white;
  background: $colorThemeA;
  border-radius: .5rem;
  padding: 1rem;
  margin: 1.5rem 1rem 0 1rem;

  p {
    font-size: .75rem;
  }
  p:first-child {
    margin-bottom: .5rem;
  }
}
</style>
