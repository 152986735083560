<template>
  <f7-page no-tabbar class="product-page">
    <div slot="fixed">
      <sh-product-add-to-cart-bar v-bind:product="productModel" :isEA="isEA"></sh-product-add-to-cart-bar>
    </div>
    <div class="spacer-top" />

    <ShProductDetail :productImageUrl="productImageUrl" :productModel="productModel" :productDetail="productDetail" />

    <div class="products-also-bought" v-show="productsAlsoPurchased.length > 0">
      <sh-products-card
        v-bind:sourceProducts="productsAlsoPurchased"
        headerText="Customers Also Bought"
        :autoLoad="false"
        :noLink="true"
        :isLeaf="false"
      />
    </div>

    <div class="related-recipes" v-show="relatedRecipes.length > 0">
      <sh-recipes-card title="Suggested Recipes" v-bind:recipes="relatedRecipes" />
    </div>

    <div class="product-detail-loading" v-show="!productDetailLoaded">
      <div class="preloader" style="width: 50px; height: 50px"></div>
    </div>

    <f7-card v-if="ingredients !== undefined">
      <f7-card-header>Ingredients</f7-card-header>
      <f7-card-content>
        <template v-if="ingredients.length < 240 || showFullIngredients">{{ ingredients }}</template>
        <template v-else>
          <span v-html="ingredients.trunc(230, true)"></span>
          <f7-link @click="showFullIngredients = true">more</f7-link>
        </template>
      </f7-card-content>
    </f7-card>

    <f7-card v-if="warnings !== undefined">
      <f7-card-header>Label Warnings</f7-card-header>
      <f7-card-content style="color: darkred">{{ warnings }}</f7-card-content>
    </f7-card>

    <f7-card v-if="indications !== undefined">
      <f7-card-header>Indications</f7-card-header>
      <f7-card-content>
        <span v-html="indications" />
      </f7-card-content>
    </f7-card>

    <f7-card v-if="directions !== undefined">
      <f7-card-header>Directions</f7-card-header>
      <f7-card-content>
        <template v-if="directions.length < 240 || showFullDirections">{{ directions }}</template>
        <template v-else>
          <span v-html="directions.trunc(230, true)"></span>
          <f7-link @click="showFullDirections = true">more</f7-link>
        </template>
      </f7-card-content>
    </f7-card>

    <!--
   <f7-card v-show="manufacturer !== undefined">
    <f7-card-header>
      Manufacturer / Distributor
    </f7-card-header>
    <f7-card-content>
      {{ manufacturer }}
    </f7-card-content>
  </f7-card>
    -->

    <f7-card v-if="nutritionFacts !== undefined">
      <!--
    <f7-card-header>
      Nutrition Facts
    </f7-card-header>
      -->
      <f7-card-content>
        <f7-row no-gutter>
          <f7-col width="75" class="nutrition-facts-title">NUTRITION FACTS</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="75">Serving Size</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-right"
          >{{nutritionFacts.serving_size}} {{nutritionFacts.serving_size_uom}}</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="75">Servings per Container</f7-col>
          <f7-col width="25" class="nutrition-facts-col-right">{{nutritionFacts.number_of_servings}}</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-bold">
            <hr />
          </f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-col-left">Amount per Serving</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>
        <f7-row no-gutter class="nutrition-facts-row-bold">
          <f7-col width="75">Calories</f7-col>
          <f7-col width="25" class="nutrition-facts-col-right">{{nutritionFacts.energy}}</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="5"></f7-col>
          <f7-col width="70">Calories from Fat</f7-col>
          <f7-col width="25" class="nutrition-facts-col-right">{{nutritionFacts.calories_from_fat}}</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-bold">
            <hr />
          </f7-col>
        </f7-row>
        <f7-row class="nutrition-facts-row-bold">
          <f7-col width="65"></f7-col>
          <f7-col width="35" class="nutrition-facts-col-right">
            % Daily
            <br />Value*
          </f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>
        <f7-row no-gutter class="nutrition-facts-row-bold">
          <f7-col width="50" class="nutrition-facts-col-left">Total Fat</f7-col>
          <template v-if="nutritionFacts.total_fat_uom.length > 0">
            <f7-col
              width="25"
              class="nutrition-facts-col-mid"
            >{{nutritionFacts.total_fat}}{{nutritionFacts.total_fat_uom}}</f7-col>
            <f7-col
              width="25"
              class="nutrition-facts-col-right"
            >{{nutritionFacts.daily_pct_total_fat}}%</f7-col>
          </template>
          <template v-else>
            <f7-col width="25" class="nutrition-facts-col-mid">&nbsp;</f7-col>
            <f7-col width="25" class="nutrition-facts-col-right">&nbsp;</f7-col>
          </template>
        </f7-row>
        <f7-row no-gutter v-if="nutritionFacts.saturated_fat_uom.length > 0">
          <f7-col width="5" class="nutrition-facts-col-left"></f7-col>
          <f7-col width="45" class="nutrition-facts-col-left">Saturated Fat</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-mid"
          >{{nutritionFacts.saturated_fat}}{{nutritionFacts.saturated_fat_uom}}</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-right"
          >{{nutritionFacts.daily_pct_saturated_fat}}%</f7-col>
        </f7-row>
        <f7-row no-gutter v-if="nutritionFacts.trans_fat_uom.length > 0">
          <f7-col width="5" class="nutrition-facts-col-left"></f7-col>
          <f7-col width="45" class="nutrition-facts-col-left">Trans Fat</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-mid"
          >{{nutritionFacts.trans_fat}}{{nutritionFacts.trans_fat_uom}}</f7-col>
          <f7-col width="25" class="nutrition-facts-col-right"></f7-col>
        </f7-row>
        <f7-row no-gutter v-if="nutritionFacts.polyunsaturated_fat_uom.length > 0">
          <f7-col width="5" class="nutrition-facts-col-left"></f7-col>
          <f7-col width="45" class="nutrition-facts-col-left">Polyunsaturated Fat</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-mid"
          >{{nutritionFacts.polyunsaturated_fat}}{{nutritionFacts.polyunsaturated_fat_uom}}</f7-col>
          <f7-col width="25" class="nutrition-facts-col-right"></f7-col>
        </f7-row>
        <f7-row no-gutter v-if="nutritionFacts.monounsaturated_fat_uom.length > 0">
          <f7-col width="5" class="nutrition-facts-col-left"></f7-col>
          <f7-col width="45" class="nutrition-facts-col-left">Monounsaturated Fat</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-mid"
          >{{nutritionFacts.monounsaturated_fat}}{{nutritionFacts.monounsaturated_fat_uom}}</f7-col>
          <f7-col width="25" class="nutrition-facts-col-right"></f7-col>
        </f7-row>
        <f7-row
          no-gutter
          v-if="nutritionFacts.cholesterol_uom.length > 0"
          class="nutrition-facts-row-bold"
        >
          <f7-col width="50" class="nutrition-facts-col-left">Cholesterol</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-mid"
          >{{nutritionFacts.cholesterol}}{{nutritionFacts.cholesterol_uom}}</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-right"
          >{{nutritionFacts.daily_pct_cholesterol}}%</f7-col>
        </f7-row>
        <f7-row
          no-gutter
          v-if="nutritionFacts.sodium_uom.length > 0"
          class="nutrition-facts-row-bold"
        >
          <f7-col width="50" class="nutrition-facts-col-left">Sodium</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-mid"
          >{{nutritionFacts.sodium}}{{nutritionFacts.sodium_uom}}</f7-col>
          <f7-col width="25" class="nutrition-facts-col-right">{{nutritionFacts.daily_pct_sodium}}%</f7-col>
        </f7-row>
        <f7-row
          no-gutter
          v-if="nutritionFacts.potassium !== null && nutritionFacts.potassium.length > 0"
        >
          <f7-col width="50" class="nutrition-facts-col-left">Potassium</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-mid"
          >{{nutritionFacts.potassium}}{{nutritionFacts.potassium_uom}}</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-right"
          >{{nutritionFacts.daily_pct_potassium}}%</f7-col>
        </f7-row>
        <f7-row
          no-gutter
          v-if="nutritionFacts.carbohydrates !== null && nutritionFacts.carbohydrates.length > 0"
          class="nutrition-facts-row-bold"
        >
          <f7-col width="50" class="nutrition-facts-col-left">Total Carbohydrate</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-mid"
          >{{nutritionFacts.carbohydrates}}{{nutritionFacts.carbohydrates_uom}}</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-right"
          >{{nutritionFacts.daily_pct_carbohydrates}}%</f7-col>
        </f7-row>
        <f7-row
          no-gutter
          v-if="nutritionFacts.dietary_fiber !== null && nutritionFacts.dietary_fiber.length > 0"
        >
          <f7-col width="5" class="nutrition-facts-col-left"></f7-col>
          <f7-col width="45" class="nutrition-facts-col-left">Dietary Fiber</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-mid"
          >{{nutritionFacts.dietary_fiber}}{{nutritionFacts.dietary_fiber_uom}}</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-right"
          >{{nutritionFacts.daily_pct_dietary_fiber}}%</f7-col>
        </f7-row>
        <f7-row
          no-gutter
          v-if="nutritionFacts.insoluable_fiber !== null && nutritionFacts.insoluable_fiber.length > 0"
        >
          <f7-col width="5" class="nutrition-facts-col-left"></f7-col>
          <f7-col width="45" class="nutrition-facts-col-left">Insoluble Fiber</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-mid"
          >{{nutritionFacts.insoluable_fiber}}{{nutritionFacts.insoluable_fiber_uom}}</f7-col>
          <f7-col width="25" class="nutrition-facts-col-right"></f7-col>
        </f7-row>

        <f7-row no-gutter v-if="nutritionFacts.sugars !== null && nutritionFacts.sugars.length > 0">
          <f7-col width="5" class="nutrition-facts-col-left"></f7-col>
          <f7-col width="45" class="nutrition-facts-col-left">Sugars</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-mid"
          >{{nutritionFacts.sugars}}{{nutritionFacts.sugars_uom}}</f7-col>
          <f7-col width="25" class="nutrition-facts-col-right"></f7-col>
        </f7-row>
        <f7-row
          no-gutter
          v-if="nutritionFacts.protein !== null && nutritionFacts.protein.length > 0"
          class="nutrition-facts-row-bold"
        >
          <f7-col width="50" class="nutrition-facts-col-left">Protein</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-mid"
          >{{nutritionFacts.protein}}{{nutritionFacts.protein_uom}}</f7-col>
          <f7-col width="25" class="nutrition-facts-col-right">
            {{nutritionFacts.daily_pct_protein}}
            <span v-show="nutritionFacts.daily_pct_protein.length > 0">%</span>
          </f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-bold">
            <hr />
          </f7-col>
        </f7-row>
        <f7-row class="nutrition-facts-row-bold">
          <f7-col width="75"></f7-col>
          <f7-col width="25" class="nutrition-facts-col-right">
            % Daily
            <br />Value*
          </f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>
        <f7-row
          no-gutter
          v-if="nutritionFacts.daily_pct_vitamin_a !== null && nutritionFacts.daily_pct_vitamin_a.length > 0"
        >
          <f7-col width="75" class="nutrition-facts-col-left">Vitamin A</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-right"
          >{{nutritionFacts.daily_pct_vitamin_a}}%</f7-col>
        </f7-row>
        <f7-row
          no-gutter
          v-if="nutritionFacts.daily_pct_vitamin_c !== null && nutritionFacts.daily_pct_vitamin_c.length > 0"
        >
          <f7-col width="75" class="nutrition-facts-col-left">Vitamin C</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-right"
          >{{nutritionFacts.daily_pct_vitamin_c}}%</f7-col>
        </f7-row>
        <f7-row
          no-gutter
          v-if="nutritionFacts.daily_pct_vitamin_d !== null && nutritionFacts.daily_pct_vitamin_d.length > 0"
        >
          <f7-col width="75" class="nutrition-facts-col-left">Vitamin D</f7-col>
          <f7-col
            width="25"
            class="nutrition-facts-col-right"
          >{{nutritionFacts.daily_pct_vitamin_d}}%</f7-col>
        </f7-row>
        <f7-row
          no-gutter
          v-if="nutritionFacts.daily_pct_calcuim !== null && nutritionFacts.daily_pct_calcium.length > 0"
        >
          <f7-col width="75" class="nutrition-facts-col-left">Calcium</f7-col>
          <f7-col width="25" class="nutrition-facts-col-right">{{nutritionFacts.daily_pct_calcium}}%</f7-col>
        </f7-row>
        <f7-row
          no-gutter
          v-if="nutritionFacts.daily_pct_iron !== null && nutritionFacts.daily_pct_iron.length > 0"
        >
          <f7-col width="75" class="nutrition-facts-col-left">Iron</f7-col>
          <f7-col width="25" class="nutrition-facts-col-right">{{nutritionFacts.daily_pct_iron}}%</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>
        <f7-row style="margin-top: 10px;">
          <f7-col width="100" class="nutrition-facts-row-info">
            <p
              style="margin-top: 0px;"
            >(-) Information is currently not available for this nutrient.</p>
            <p>* Percent Daily Values are based on a 2,000 calorie diet. Your daily values may be higher or lower depending on your calorie needs**.</p>
            <p>** Percent Daily Values listed below are intended for adults and children over 4 years of age. Foods represented or purported to be for use by infants, children less than 4 years of age, pregnant women, or lactating women shall use the RDI's that are specified for the intended group provided by the FDA.</p>
          </f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>
        <f7-row class="nutrition-facts-row-info nutrition-facts-row-bold">
          <f7-col width="25">&nbsp;</f7-col>
          <f7-col width="25">Calories:</f7-col>
          <f7-col width="25">2,000</f7-col>
          <f7-col width="25">2,500</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>
        <f7-row class="nutrition-facts-row-info">
          <f7-col width="25">Total Fat</f7-col>
          <f7-col width="25">Less than</f7-col>
          <f7-col width="25">65g</f7-col>
          <f7-col width="25">80g</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>
        <f7-row class="nutrition-facts-row-info">
          <f7-col width="25">Sat Fat</f7-col>
          <f7-col width="25">Less than</f7-col>
          <f7-col width="25">20g</f7-col>
          <f7-col width="25">25g</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>
        <f7-row class="nutrition-facts-row-info">
          <f7-col width="25">Cholesterol</f7-col>
          <f7-col width="25">Less than</f7-col>
          <f7-col width="25">300mg</f7-col>
          <f7-col width="25">300mg</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>

        <f7-row class="nutrition-facts-row-info">
          <f7-col width="25">Sodium</f7-col>
          <f7-col width="25">Less than</f7-col>
          <f7-col width="25">2400mg</f7-col>
          <f7-col width="25">2400mg</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>

        <f7-row class="nutrition-facts-row-info">
          <f7-col width="50">Potassium</f7-col>
          <f7-col width="25">3500mg</f7-col>
          <f7-col width="25">3500mg</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>

        <f7-row class="nutrition-facts-row-info">
          <f7-col width="50">Total Carbohydrate</f7-col>
          <f7-col width="25">300g</f7-col>
          <f7-col width="25">375g</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>

        <f7-row class="nutrition-facts-row-info">
          <f7-col width="50">Dietary Fiber</f7-col>
          <f7-col width="25">25g</f7-col>
          <f7-col width="25">30g</f7-col>
        </f7-row>
        <f7-row no-gutter>
          <f7-col width="100" class="nutrition-facts-hr-thin">
            <hr />
          </f7-col>
        </f7-row>

        <f7-row class="nutrition-facts-row-info">
          <f7-col width="100">Calories per gram:</f7-col>
        </f7-row>
        <f7-row class="nutrition-facts-row-info">
          <f7-col width="10">&nbsp;</f7-col>
          <f7-col width="90">Fat 9 &bullet; Carbohydrate 4 &bullet; Protein 4</f7-col>
        </f7-row>
      </f7-card-content>
    </f7-card>

    <div class="disclaimer">
      <h4>DISCLAIMER</h4>
      <p>Product information accessed through this application is obtained from claims made by the product's manufacturer on its labels. Please note that, on occasion, manufacturers may alter their labels-- thus we cannot guarantee or ensure the accuracy, completeness or timeliness of any product information. We recommend that you do not rely solely on this information and that you always read the label carefully before using or consuming a product to obtain the most accurate information. If you have specific nutrition or dietary concerns or questions about a product, please consult the product's label and/or contact the manufacturer directly. We assume no liability for any inaccuracies or misstatements about product information (including any product imagery) displayed on our mobile applications or website.</p>
    </div>

    <div class="spacer-bottom" />
  </f7-page>
</template>

<script>
import ShopHeroApi from '../api/ShopHeroApi'

import photoLoading from '../images/photo_loading_lt_215.png' // eslint-disable-line no-unused-vars

import Product from '../models/Product'

import ShProductAddToCartBar from '../components/ShProductAddToCartBar.vue'
import ShProductsCard from '../components/ShProductsCard.vue'
import ShRecipesCard from '../components/ShRecipesCard.vue'
import ShProductDetail from '../components/ShProductDetail.vue';

export default {
  components: {
    ShProductAddToCartBar,
    ShProductsCard,
    ShRecipesCard,
    ShProductDetail
  },

  data() {
    return {
      product: {},
      productDetail: {},
      productModel: {},
      productsAlsoPurchased: [],
      relatedRecipes: [],
      initialized: false,
      productImageUrl: 'images/photo_loading_lt_215.png',
      productDetailLoaded: false,
      showFullIngredients: false,
      showFullDirections: false
    }
  },

  props: ['productKey', 'isEA'],

  computed: {

    ingredients({ productDetail }) {
      if (productDetail.product_data === undefined) {
        return undefined
      }
      if (productDetail.product_data.ingredients === undefined) {
        return undefined
      }
      if (productDetail.product_data.ingredients === null) {
        return undefined
      }
      if (productDetail.product_data.ingredients.length === 0) {
        return undefined
      }
      return productDetail.product_data.ingredients
    },
    indications({ productDetail }) {
      if (productDetail.product_data === undefined) {
        return undefined
      }
      if (productDetail.product_data.indications === undefined) {
        return undefined
      }
      if (productDetail.product_data.indications.length === 0) {
        return undefined
      }
      return productDetail.product_data.indications
    },
    directions({ productDetail }) {
      if (productDetail.product_data === undefined) {
        return undefined
      }
      if (productDetail.product_data.directions === undefined) {
        return undefined
      }
      if (productDetail.product_data.directions === null) {
        return undefined
      }
      if (productDetail.product_data.directions.length === 0) {
        return undefined
      }
      return productDetail.product_data.directions
    },
    manufacturer({ productDetail }) {
      if (productDetail.product_data === undefined) {
        return undefined
      }
      if (productDetail.product_data.manufacturer === undefined) {
        return undefined
      }
      if (productDetail.product_data.manufacturer === null) {
        return undefined
      }
      if (productDetail.product_data.manufacturer.length === 0) {
        return undefined
      }
      return productDetail.product_data.manufacturer
    },
    warnings({ productDetail }) {
      if (productDetail.product_warning === undefined) {
        return undefined
      }
      if (productDetail.product_warning === undefined) {
        return undefined
      }
      if (productDetail.product_warning === null) {
        return undefined
      }
      if (productDetail.product_warning.length === 0) {
        return undefined
      }
      return productDetail.product_warning
    },
    nutritionFacts({ productDetail }) {
      if (productDetail.product_nutrition_facts === undefined) {
        return undefined
      }
      if (Object.size(productDetail.product_nutrition_facts) === 0) {
        return undefined
      }
      return productDetail.product_nutrition_facts
    },
  },

  methods: {
    openPhotoBrowser() {
      const productImages = [this.productModel.productImageUrlFull]

      const myPhotoBrowser = this.$root.$f7.photoBrowser.create({
        photos: productImages,
        theme: 'light' // 'dark'
      })

      myPhotoBrowser.open()
    }
  },

  mounted() {
    // relocate addBar to page element
    // DOM manipulation is generally a no-no, but what can I do?
    const addBar = document.getElementById('bar-add-to-cart')
    const addBarParent = addBar.parentElement
    if (addBarParent.classList.contains('page-content')) {
      addBarParent.parentElement.appendChild(addBar)
    }

    const physicalLocationId = this.$store.state.locations.selectedLocation.physical_location_id;

    if(!this.isEA) {
      ShopHeroApi.getRelatedRecipesByKey(
        physicalLocationId,
        this.productKey,
        response => {
          const self = this
          self.relatedRecipes = response.related_recipes
        },
        error => {
          this.$f7.views.main.router.navigate('/uhoh/')
        }
      );
    }
  },

  created() {
    console.log(`created product.vue: ${this.productKey}`);

    if (this.initialized === false) {
      const loadResponse = response => {
          const productModel = ShopHeroApi.convertApiProductToProductModel(
            response,
            this.productKey
          )

          const imagePreload = new Image()
          if (window.screen.height > 740) {
            imagePreload.src = productModel.productImageUrlFull
          } else {
            imagePreload.src = productModel.productImageUrl500
          }
          this.productImageUrl = imagePreload.src

          // this.$root.setNavbarTitle('')
          // this.$root.setNavbarSubtitle(productModel.productName.trunc(34, true))

          this.$root.setNavbarTitle(productModel.productName.trunc(80, true))
          this.$root.setNavbarSubtitle('')

          this.$store.dispatch('setSearchModeProducts')

          this.productModel = productModel

          // console.log(this.productModel)
      };

      const physicalLocationId = this.$store.state.locations.selectedLocation.physical_location_id

      if(this.isEA) {
        ShopHeroApi.getEAProductById(this.productKey, loadResponse, error => {
          console.error(error);
          this.$f7.views.main.router.navigate('/uhoh/');
        });
        this.productDetailLoaded = true;
      } else {
        ShopHeroApi.getProductByKey(
          this.$store.state.locations.selectedLocation.physical_location_id,
          this.productKey, response => {
            loadResponse(response);
            ShopHeroApi.getProductDetailByKey(
              physicalLocationId,
              this.productKey,
              response => {
                // console.log(response)
                this.productDetail = response
                this.productDetailLoaded = true
              },
              error => {
                console.error(error);
                this.$f7.views.main.router.navigate('/uhoh/')
              }
            );
          }, error => {
            console.error(error);
            this.$f7.views.main.router.navigate('/uhoh/')
          }
        )

        ShopHeroApi.getProductsAlsoPurchasedByKey(
          physicalLocationId,
          this.productKey,
          response => {
            const self = this
            const productsAlsoPurchased = []
            response.products_also_purchased.forEach(function(v) {
              const productModel = ShopHeroApi.convertApiProductToProductModel(
                v,
                v.product_key
              )
              productsAlsoPurchased.push(productModel)
            })
            self.productsAlsoPurchased = productsAlsoPurchased
          }
        )

      }
      this.initialized = true;
    }
  }
}
</script>

<style lang="scss" scoped="true">
.spacer-top {
  height: var(--f7-page-spacer-top);
}

.spacer-bottom {
  height: 100px;
}

.product-page {
  height: 100%;
  background: #fff;
}


.card {
  margin-top: 20px;
}

.card-header {
  background-color: #fafafa;
  font-weight: $bold;
}

.card-content-inner {
  ul {
    margin-left: 10px;
  }
}

.product-detail-loading {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
}

.nutrition-facts-title {
  font-weight: $bold;
  font-size: 20px;
  margin-bottom: 8px;
}

.nutrition-facts-row-bold {
  font-weight: $bold;
}

.nutrition-facts-col-left {
  margin-top: 3px;
  //background-color: #f9f9f9;
  text-align: left;
}

.nutrition-facts-col-mid {
  margin-top: 3px;
  //background-color: #f9f9f9;
  text-align: right;
}

.nutrition-facts-col-right {
  margin-top: 3px;
  //background-color: #f0f0f0;
  text-align: right;
}

.nutrition-facts-row-info {
  font-size: 11px;
}

.nutrition-facts-hr-bold {
  hr {
    display: block;
    // margin-top: 0.5em;
    // margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: none;
    border-width: 1px;
    background-color: #000;
    width: 100%;
    height: 4px;
  }
}

.nutrition-facts-hr-thin {
  hr {
    display: block;
    margin-top: 0.1em;
    margin-bottom: 0.2em;
    margin-left: auto;
    margin-right: auto;
    border-style: none;
    border-width: 0px;
    background-color: #000;
    width: 100%;
    height: 1px;
  }
}

.disclaimer {
  margin-top: 30px;
  padding: 10px;
}

// .product-description {
//   padding : 15px;
//   text-align: justify;
//   font-size: 1rem;

// }
// .product-description h4 {
//   padding: 3% 0 2% 0 ;
//   font-weight: 550;
// }

hr {

  margin-top: 3%;
  width:100%;text-align:left;margin-left:0;
}
</style>
