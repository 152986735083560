<template>
  <f7-page no-swipeback no-navbar>
    <!-- <sh-hero-nav-bar class="heroNavBar" /> -->
    <div slot="fixed">
      <sh-hero />
    </div>

    <div class="spacer-top" />

    <div v-if="!apiInitalized" class="loading">
      <div class="preloader"></div>
    </div>

    <!-- put all API driven content in this template -->
    <template v-if="apiInitalized">
      <template v-if="!this.userIsAuthenticated">
        <f7-card class="card-sign-in">
          <f7-card-content>
            Hi there! Please
            <f7-link href="/auth-sign-in/home/">sign in</f7-link>&nbsp;for personalized shopping!
          </f7-card-content>
        </f7-card>
      </template>
      <template v-else>
        <f7-card class="card-welcome-back">
          <f7-card-content v-if="authenticatedUser.firstName">
            Welcome to {{ selectedLocation.physical_location_name }},
            <strong>{{ authenticatedUser.firstName }}</strong>!
          </f7-card-content>
          <f7-card-content v-else>Welcome to {{ selectedLocation.physical_location_name }}!</f7-card-content>
        </f7-card>
      </template>

      <template v-if="userIsAuthenticated && loyaltyAvailable">
        <f7-card class="card-loyalty">
          <f7-card-header>{{selectedLocation.physical_location_loyalty_program_name}}</f7-card-header>
          <f7-card-content>
            <div v-if="!authenticatedUser.loyaltyToken">
              <f7-link href="/loyalty-app-card-link-account/coupons/">Link</f7-link>&nbsp;your
              <f7-link
                href="/loyalty-app-card-link-account/coupons/"
              >{{selectedLocation.physical_location_loyalty_program_name}}</f7-link>&nbsp;account to view your points balances and access special savings!
            </div>
            <template v-if="authenticatedUser.loyaltyToken">
              <div v-if="parseInt(loyaltyAvailablePoints) > 0">
                You have
                <strong>{{loyaltyAvailablePoints}}</strong> reward points available!
              </div>
              <div v-else>
                <div>Your rewards membership is active. You now have access to exclusive coupons, discounts and offers!</div>
                <transition name="fade-in">
                  <div v-show="loyaltyAvailableOffers.length > 0">
                    <br />
                    Check out <f7-link href="/coupons/">{{loyaltyAvailableOffers.length}} coupons</f7-link>&nbsp;waiting for you!
                  </div>
                </transition>
              </div>
            </template>
          </f7-card-content>
        </f7-card>
      </template>

      <template v-if="showMotd">
        <!--change this -->
        <transition name="fade-in">
          <f7-card v-for="message in motd" class="card-motd" v-bind:key="message.id">
            <f7-card-content>
              <span v-html="message.content"></span>
            </f7-card-content>
          </f7-card>
        </transition>
      </template>

      <transition name="fade-in">
        <sh-products-card
          v-if="productsMightBeInterested.length > 0"
          v-bind:sourceProducts="productsMightBeInterested"
          :noLink="true"
          :headerText="`Special Savings for ${authenticatedUser.firstName}`"
        />
      </transition>

      <transition name="fade-in">
        <sh-products-card
          v-if="productsPriorPurchased.length > 0"
          v-bind:sourceProducts="productsPriorPurchased"
          :limit="48"
          :noLink="true"
          headerText="Buy It Again"
          :customLink="`/buy-it-again/`"
          :customLinkText="productsPriorPurchasedLinkText"
        />
      </transition>
      <sh-products-card
        v-for="(c,idx) in validCategories"
        :key="c"
        :categoryKey="categoryMap[c]"
        headerText="Popular in"
        :loadCategoryFromApi="true"
        :autoLoad="idx === 0"
      />
    </template>

    <div class="spacer-bottom" />
  </f7-page>
</template>

<script>
import storeConfig from "../config";

import ShopHeroApi from "../api/ShopHeroApi";

import ShHero from "../components/ShHero.vue";
import ShProductsCard from "../components/ShProductsCard.vue";

export default {
  data() {
    return {
      productsPriorPurchased: [],
      productsMightBeInterested: [],
      motd: [],
      categoryMap: storeConfig.categoryMap,
      magicCategories: ['produce', 'dairy', 'candy-cookies-snacks', 'bread-bakery', 'baking-cooking-needs', 'frozen', 'beverages', 'meat-seafood', 'canned-goods']
    };
  },

  components: {
    ShHero,
    ShProductsCard
  },

  computed: {
    userIsAuthenticated: ({ $store }) => $store.getters.userIsAuthenticated,
    authenticatedUser: ({ $store }) => $store.getters.authenticatedUser,
    loyaltyAvailable: ({ $store }) =>
      $store.getters.selectedLocation.physical_location_loyalty_provider ==
      "AppCard",
    loyaltyIsInitialized: ({ $store }) => $store.getters.loyaltyIsInitialized,
    loyaltyAvailableOffers: ({ $store }) =>
      $store.getters.loyaltyAvailableOffers,
    loyaltyAvailablePoints: ({ $store }) =>
      $store.getters.loyaltyAvailablePoints,
    allLocations: ({ $store }) => $store.getters.allLocations,
    selectedLocation: ({ $store }) => $store.getters.selectedLocation,
    apiInitalized: ({ $store }) => $store.getters.apiIsInitialized,
    productsPriorPurchasedLinkText: ({ productsPriorPurchased }) =>
      productsPriorPurchased.length < 100
        ? `View ${productsPriorPurchased.length} more &gt;`
        : "View 100+ more &gt;",
    showMotd: ({ $store }) => true,
    validCategories: function() {
      let result = this.magicCategories.filter(c => this.categoryMap[c]);
      if(result.length) return result;
      return Object.entries(this.categoryMap).map(c => c[0]).slice(0,this.magicCategories.length);
    }
  },

  methods: {
    populatePriorPurchases() {
      if (!this.apiInitalized) return false;

      if (!this.userIsAuthenticated) return false;

      const self = this;

      ShopHeroApi.getPreviouslyPurchasedProductsByQuantity(
        this.selectedLocation.physical_location_id,
        0,
        101,
        response => {
          let products = [];
          response.products_by_quantity.forEach(function(e) {
            products.push(
              ShopHeroApi.convertApiProductToProductModel(
                e.product,
                e.product.product_key
              )
            );
          });

          self.productsPriorPurchased = products;
        },
        error => {
          this.$f7.views.main.router.navigate("/uhoh/");
        }
      );

      ShopHeroApi.getPreviouslyPurchasedProductsByPriceDifferential(
        this.selectedLocation.physical_location_id,
        0,
        60,
        response => {
          let products = [];
          response.products_by_price_differential.forEach(function(e) {
            products.push(
              ShopHeroApi.convertApiProductToProductModel(
                e.product,
                e.product.product_key
              )
            );
          });

          self.productsMightBeInterested = products;
        },
        error => {
          this.$f7.views.main.router.navigate("/uhoh/");
        }
      );
    },
    populateMotd() {
      if (!this.apiInitalized) return false;

      const self = this;
      ShopHeroApi.getMotd(
        this.selectedLocation.physical_location_id,
        response => {
          // console.log(response)
          if (response.motd.home !== undefined) {
            response.motd.home.forEach(function(e) {
              self.motd.push(e);
            });
          }
        },
        error => {
          this.$f7.views.main.router.navigate("/uhoh/");
        }
      );
    },
    logAnalyticsViewedContentHome() {
      if (typeof facebookConnectPlugin !== "undefined") {
        facebookConnectPlugin.logEvent(
          "Viewed Home",
          {},
          0,
          function() {
            // log success
          },
          function() {
            // log error
          }
        );
      }
    },
    setAppVersion() {
      const self = this;

      if (typeof cordova !== "undefined") {
        cordova.getAppVersion.getVersionNumber().then(function(appVersion) {
          self.$store.dispatch("setAppVersion", { appVersion });
        });
        cordova.getAppVersion.getPackageName().then(function(appPackage) {
          self.$store.dispatch("setAppPackage", { appPackage });
        });
      }
    },
    populateLoyalty() {
      if (this.loyaltyAvailable == false) return;

      if (this.userIsAuthenticated) {
        this.$store
          .dispatch("populateLoyaltyMembership", {
            physicalLocationId: this.selectedLocation.physical_location_id
          })
          .then(() => {
            console.log("loyaltyPopulated");
          });
      }

      this.$store
        .dispatch("populateLoyaltyOffers", {
          physicalLocationId: this.selectedLocation.physical_location_id
        })
        .then(() => {
          console.log("offersPopulated");
        });
    }
  },

  watch: {
    apiInitalized: function(val) {
      // console.log('apiInitalized: ' + val)
      this.populatePriorPurchases();
      this.populateMotd();
    },
    userIsAuthenticated: function(val) {
      // console.log('userIsAuthenticated: ' + val)
      this.populatePriorPurchases();
      this.populateLoyalty();
    },
    selectedLocation: function(val) {
      if (!this.apiInitalized) return;
      console.log("selectedLocation changed");
    }
  },

  created() {},

  mounted() {
    this.$root.setStatusBarStyleLightText();

    this.$root.setActiveToolbar("home");

    this.$root.setNavbarSearchPlaceholder(this.$store.getters.options.hideGrocery ? "" : "Bananas");
    this.$root.setNavbarSearchText("");

    this.populateLoyalty();

    this.populateMotd();

    this.populatePriorPurchases();

    this.$root.hideDeviceSplash();

    this.logAnalyticsViewedContentHome();

    // console.log('produce: ' + storeConfig.categoryMap.produce)

    this.setAppVersion();
  }
};
</script>

<style lang="scss" scoped="true">
.spacer-bottom {
  width: 100%;
  // height: 70px;
  height: calc(175px + var(--sab));
}

.spacer-top {
  height: 10px;
}

.loading {
  height: 200px;
  width: 100%;
  position: relative;
  .preloader {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.card-sign-in {
  background: $colorThemeB;
  color: #fff;
  a {
    font-weight: $bold;
    color: #fff;
  }
}

.card-welcome-back {
  background: $colorThemeB;
  color: #fff;
  strong {
    font-weight: $bold;
  }
  a {
    font-weight: $bold;
    color: #fff;
    // font-size: 8px;
  }
}

.fade-in-enter-active {
  transition: opacity 0.5s;
}

.fade-in-leave-active {
  //transition: opacity .5s;
  opacity: 0;
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-leave-to {
  opacity: 0;
}

.card-motd {
  background: #2ca3e5;
  color: #fff;
}

.card-loyalty {
  background: $storePrimaryColor;
  color: #fff;
  strong {
    font-weight: $bold;
  }
  a {
    font-weight: $bold;
    color: #fff;
    // font-size: 8px;
  }

  .card-header {
    // font-size: 1.1em;
    font-weight: $bold;
  }
}
</style>
