/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: "warn" */

import ShopHeroApi from '../../api/ShopHeroApi' // eslint-disable-line no-unused-vars

import LocalStorage from '../../local-storage/LocalStorageInterface'

import * as types from '../mutation-types' // eslint-disable-line no-unused-vars

// initial state
const state = {
  all: [],
  selectedLocation: null,
}

// getters
const getters = {
  selectedLocation: state => state.selectedLocation,
  allLocations: state => state.all,
}


// actions
const actions = {
  populateLocations({ commit }) {
    return new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
      console.log('populateLocationsStart')

      ShopHeroApi.getLocations((locations) => {
        commit(types.RECEIVE_LOCATIONS, { locations })
        resolve()
      },
        (error) => {
          reject(error)
        }
      )
    })
  },
  setSelectedLocation({ commit, state }, { physicalLocationId }) {
    return new Promise((resolve, reject) => {
      let physicalLocation = null
      state.all.forEach(function (l) {
        if (l.physical_location_id == physicalLocationId) {
          physicalLocation = l
        }
      })

      if (physicalLocation !== null) {
        commit(types.RECEIVE_SELECTED_LOCATION, { physicalLocation })
        LocalStorage.setItem('location:default-id', physicalLocation.physical_location_id)
        resolve()
      }

      reject()
    })
  }
}

// mutations
const mutations = {
  [types.RECEIVE_LOCATIONS](state, { locations }) {
    state.all = locations
    if (state.selectedLocation === null) {

      const defaultLocationId = LocalStorage.getItem('location:default-id')
      if (defaultLocationId) {
        locations.forEach(function (v) {
          if (v.physical_location_id == defaultLocationId) {
            state.selectedLocation = v
            state.selectedLocation.location_preference_stored = true
          }
        })
      }

      if (state.selectedLocation === null) {
        state.selectedLocation = locations.slice(0, 1)[0] // eslint-disable-line prefer-destructuring
        state.selectedLocation.location_preference_stored = false
      }

    }
  },
  [types.RECEIVE_SELECTED_LOCATION](state, { physicalLocation }) {
    state.selectedLocation = physicalLocation // eslint-disable-line prefer-destructuring
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
