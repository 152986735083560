import Product from './Product'

export default class {
  constructor() {
    this.productKey = null
    this.cartApiId = 0

    this.product = null

    this.priceRank = 0
    this.pricePrice = 0
    this.productUnit = 'each'
    this.productUnitPlural = 'each'
    this.productUnitText = 'each'

    this.pricePriceOriginal = 0
    this.availableForPurchase = true

    this.qtyInCart = 0
    this.instructions = ''
    this.permitSubstitution = true
    this.isEndlessAisle = false

    this.addedTimeUTC = Date.UTC()
    this.updatedTimeUTC = Date.UTC()


  }
}
