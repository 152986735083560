<template>
  <f7-page login-screen no-navbar no-toolbar data-name="sign-in">
    <f7-link back class="link-back">
      <svg
        width="15"
        height="25"
        viewBox="0 0 19 30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>back</title>
        <g id="Canvas" transform="translate(-408 -1335)">
          <g id="back">
            <use xlink:href="#back_path" transform="translate(408 1335)" fill="#000000" />
          </g>
        </g>
        <defs>
          <path
            id="back_path"
            d="M 6.99247 15L 17.7253 4.04252C 18.2039 3.55422 18.199 2.77294 17.7156 2.28464L 15.7965 0.365618C 15.3034 -0.122683 14.5123 -0.122683 14.024 0.3705L 0.366226 14.1162C 0.122075 14.3603 0 14.6777 0 15C 0 15.3223 0.122075 15.6397 0.366226 15.8838L 14.024 29.6295C 14.5123 30.1227 15.3034 30.1227 15.7965 29.6344L 17.7156 27.7154C 18.199 27.2271 18.2039 26.4458 17.7253 25.9575L 6.99247 15Z"
          />
        </defs>
      </svg>
    </f7-link>

    <div class="content-wrapper-sign-in">
      <div class="logo-wrapper">
        <img src="../images/store-assets/store-logo.png" />
      </div>

      <template v-if="userIsAuthenticated">
        <div class="welcome-wrapper">
          <span class="welcome-top">Welcome back!</span>
          <br />You are already signed in!
          <br />
          <br />
          <f7-link href="/">
            <button class="button-small">Return Home</button>
          </f7-link>
        </div>
      </template>
      <template v-else>
        <template v-if="displayMode == 'sign-in'">
          <div class="welcome-wrapper">
            <span class="welcome-top">Welcome back!</span>
            <br />Please sign in with your email and password.
          </div>

          <transition name="fade-in">
            <div class="error-banner-wrapper" v-show="authErrorMessage">
              <p>{{ authErrorMessage }}</p>
            </div>
          </transition>
          <f7-list form no-hairlines no-hairlines-between class="form-style" id="form-sign-in">
            <f7-list-item @click="focusEmailInput('#input-sign-in-email')">
              <f7-input
                type="text"
                placeholder="Enter your email"
                input-id="input-sign-in-email"
                autocomplete="off"
                tabindex="1"
              ></f7-input>
            </f7-list-item>
            <f7-list-item @click="focusEmailInput('#input-sign-in-password')">
              <f7-input
                type="password"
                placeholder="Enter your password"
                input-id="input-sign-in-password"
                autocomplete="off"
                tabindex="2"
              ></f7-input>
            </f7-list-item>
          </f7-list>

          <div class="button-wrapper">
            <div class="sign-in-button-wrapper">
              <button
                @click="onClickAuthenticate"
                id="sign-in-button"
                class="button-normal button-with-icon light-color active-gradient dark-gradient-hover sign-in-button"
                tabindex="3"
                type="submit"
              >
                <template v-if="!actionWait">Sign In</template>
                <template v-if="actionWait">
                  <img src="../images/ajax-loader.gif" class="button-ajax-loader" />
                </template>
                <span>&gt;</span>
              </button>
            </div>
            <div class="text-create-account">
              Don't have an account?
              <f7-link :href="`/auth-sign-up/${nextPage}/`">Sign up</f7-link>
            </div>
            <div class="text-forgot-password">
              Forgot your password?
              <f7-link :href="`/auth-reset-password/${nextPage}/`">Reset it</f7-link>
            </div>
            <template v-if="enableThirdPartySignIn">
              <div class="sign-in-or-bar">
                <f7-row>
                  <f7-col width="25">&nbsp;</f7-col>
                  <f7-col width="20">
                    <hr />
                  </f7-col>
                  <f7-col width="10">or</f7-col>
                  <f7-col width="20">
                    <hr />
                  </f7-col>
                  <f7-col width="25">&nbsp;</f7-col>
                </f7-row>
              </div>
              <div class="facebook-button-wrapper" v-if="enableSignInWithFacebook">
                <button
                  class="button-normal button-with-icon light-color active-gradient dark-gradient-hover sign-in-button sign-in-with-facebook"
                  @click="onClickSignInWithFacebook"
                >
                  Sign In with Facebook
                  <span>&gt;</span>
                </button>
              </div>
              <div class="google-button-wrapper" v-if="enableSignInWithGoogle">
                <button
                  class="button-normal button-with-icon light-color active-gradient dark-gradient-hover sign-in-button"
                  @click="onClickSignInWithGoogle"
                >
                  Sign In with Google
                  <span>&gt;</span>
                </button>
              </div>
            </template>
          </div>
        </template>
      </template>
    </div>
    <div class="spacer-bottom"></div>
  </f7-page>
</template>

<script>
import $$ from "dom7";

import ShopHeroApi from "../api/ShopHeroApi";

export default {
  data() {
    return {
      displayMode: "sign-in",
      actionWait: false,
      authErrorMessage: null,
      enableSignInWithFacebook: false,
      enableSignInWithGoogle: false,
      enableSignInWithApple: false
    };
  },

  props: ["nextPage"],

  computed: {
    userIsAuthenticated: ({ $store }) => $store.getters.userIsAuthenticated,
    enableThirdPartySignIn: ({
      enableSignInWithFacebook,
      enableSignInWithGoogle,
      enableSignInWithApple
    }) =>
      enableSignInWithFacebook ||
      enableSignInWithGoogle ||
      enableSignInWithApple
  },

  methods: {
    focusEmailInput(element) {
      this.$nextTick(() => {
        const emailField = $$(element);
        emailField.focus();
      });
    },

    onSignUpFormKeyUp(e) {
      if (e.keyCode == 13) this.onClickAuthenticate();
    },

    onClickAuthenticate() {
      let email = $$("#input-sign-in-email").val();
      if (email) email = email.replace(/\s/g, "");

      const password = $$("#input-sign-in-password").val();

      if (email.length === 0) {
        this.authErrorMessage = "Please enter your email address.";
        return;
      }

      if (this.validateEmail(email) === false) {
        this.authErrorMessage = "Please double check your email address.";
        return;
      }

      if (password.length === 0) {
        this.authErrorMessage = "Please enter your password.";
        return;
      }

      this.actionWait = true;

      this.authErrorMessage = null;

      ShopHeroApi.authenticate(
        email,
        password,
        null,
        response => {
          // console.log('callBackSuccess')
          // console.log(response)

          this.actionWait = false;

          if (response.login_success === false) {
            this.authErrorMessage =
              "Your email or password was not recognized.";
            return;
          }

          this.$store.dispatch("setUserAuthenticated", {
            userToken: response.login_token,
            userEmail: response.login_email,
            userFirstName: response.login_first_name,
            userLastName: response.login_last_name,
            userLoyaltyToken: response.loyalty_token
          });

          this.redirectOnSuccess();
        },
        response => {
          this.actionWait = false;

          console.log("callBackError");
          console.log(response);

          this.authErrorMessage = "An unexpected error has occurred.";
          return;
        }
      );
    },

    onClickSignInWithFacebook() {
      if (typeof facebookConnectPlugin === "undefined") {
        this.authErrorMessage =
          '"Sign in with Facebook" is unavailable at this time.';
        return;
      }

      const buttonContent = $$(".sign-in-with-facebook").html();
      $$(".sign-in-with-facebook").html(
        // '<div class="preloader color-white"></div><span></span>'
        '<img src="../images/ajax-loader.gif" class="button-ajax-loader"><span></span>'
      );

      const self = this;

      const fbPermissions = ["email", "public_profile"];
      //const fbPermissions = ['email', 'public_profile', 'user_location']

      facebookConnectPlugin.login(
        fbPermissions,
        function(userData) {
          // alert("UserInfo: " + JSON.stringify(userData));

          facebookConnectPlugin.getAccessToken(
            function(token) {
              // alert("Token: " + JSON.stringify(token));

              ShopHeroApi.authenticateViaFacebookToken(
                token,
                response => {
                  // alert('authenticateViaFacebookToken callBackSuccess ' + JSON.stringify(response))
                  // console.log(response)

                  if (response.login_success === false) {
                    self.authErrorMessage = "Unable to sign in with Facebook.";
                    $$(".sign-in-with-facebook").html(buttonContent);
                    return;
                  }

                  self.$store.dispatch("setUserAuthenticated", {
                    userToken: response.login_token,
                    userEmail: response.login_email,
                    userFirstName: response.login_first_name,
                    userLastName: response.login_last_name,
                    userLoyaltyToken: response.loyalty_token
                  });

                  self.redirectOnSuccess();
                },
                response => {
                  // alert('authenticateViaFacebookToken callBackError' + JSON.stringify(response))
                  // console.log(response)

                  self.authErrorMessage = "An unexpected error has occurred.";
                  $$(".sign-in-with-facebook").html(buttonContent);
                  return;
                }
              );
            },
            function(err) {
              // alert("Could not get access token: " + err);
              self.authErrorMessage = "An unexpected error has occurred.";
              $$(".sign-in-with-facebook").html(buttonContent);
              return;
            }
          );
        },
        function(error) {
          //authenication error callback
          // alert(JSON.stringify(error))
          self.authErrorMessage = "An unexpected error has occurred.";
          $$(".sign-in-with-facebook").html(buttonContent);
        }
      );
    },

    onClickSignInWithGoogle() {
      this.authErrorMessage =
        '"Sign in with Google" is unavailable at this time.';
    },

    validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    redirectOnSuccess() {
      // console.log(this.$f7.views.main.router.history, this.$f7.views.main.router.history.splice(-1,1))
      // this.$f7.views.main.router.history.splice(-1,1)
      // console.log(this.$f7.views.main.router.history)

      // const currentRouteUrl = this.$f7.views.main.router.url

      if (this.nextPage) {
        if (this.nextPage == "back") {
          if (this.$f7.views.main.router.history.length > 1) {
            this.$f7.views.main.router.back();
          } else {
            this.$f7.views.main.router.navigate(`/home/`);
          }
        } else {
          this.$f7.views.main.router.navigate(`/${this.nextPage}/`);
        }
      }
    }
  },

  mounted() {
    $$("#form-sign-in")
      .find("input")
      .keyup(this.onSignUpFormKeyUp);

    this.$nextTick(() => {
      this.$nextTick(() => {
        this.focusEmailInput("#input-sign-in-email");
      });
    });

    if (typeof device !== "undefined") {
      if (device.platform == "iOS") {
        // everything disabled due to Sign in With Apple policy
        this.enableSignInWithFacebook = false;
        this.enableSignInWithGoogle = false;
        this.enableSignInWithApple = false;
      } else {
        this.enableSignInWithFacebook = true;
        this.enableSignInWithGoogle = false; // not yet available
        this.enableSignInWithApple = false; // not yet available
      }
    } else {
      // web emulator, not a real device
      this.enableSignInWithFacebook = false;
      this.enableSignInWithGoogle = false; // not yet available
      this.enableSignInWithApple = false; // not yet available
    }

  }
};
</script>

<style lang="scss">
.content-wrapper-sign-in {
  margin-top: calc(100px + var(--sat));
  //  margin-bottom: auto;
}
.login-screen-content {
  // position: relative !important;
  height: 100% !important;
}

.link-back {
  top: calc(20px + var(--sat));
  left: 20px;
  position: absolute;
  margin: 0px;
}

.logo-wrapper {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 100px;
  }
}

.welcome-wrapper {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  .welcome-top {
    font-weight: 600;
    font-size: 16px;
  }
}

.error-banner-wrapper {
  width: 100%;
  p {
    font-weight: $bold;
    font-size: 12px;
    color: #fff;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-radius: 5px;
    background: darkred;
    text-align: center;
  }
}

.form-style {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  .input {
    width: 100%;

    input {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #eee;
      margin-top: 2px;
      margin-bottom: 10px;
      padding-left: 10px;
    }
    input:focus-within {
      border: 2px solid $colorThemeB !important;
    }
  }
}

.button-wrapper {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.text-create-account {
  font-size: 1.3em !important;
  margin-top: 20px;
  text-align: center;
  a {
    font-weight: $bold;
  }
}

.text-forgot-password {
  font-size: 1.3em !important;
  margin-top: 10px;
  text-align: center;
  a {
    font-weight: $bold;
  }
}

.sign-in-button {
  height: 40px;
  span {
    top: 4px !important;
    // line-height: 34px !important;
    right: 6px !important;
    height: 36px !important;
  }
}

.button-ajax-loader {
  width: 20px;
  height: 20px;
  margin-top: 3px;
}

// .sign-in-button:focus {
//   border: 2px solid $colorThemeB !important;
//   span {
//     line-height: 30px;
//   }
// }

.sign-in-button-wrapper {
  display: block;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
}

.facebook-button-wrapper {
  display: block;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
}

.google-button-wrapper {
  display: block;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
}

.sign-in-or-bar {
  margin-top: 15px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  hr {
    margin-top: 6px;
  }
}

.fade-in-enter-active {
  transition: opacity 0.5s;
}

.fade-in-leave-active {
  //transition: opacity .5s;
  opacity: 0;
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-leave-to {
  opacity: 0;
}

.link {
  color: $storePrimaryColor;
}

.spacer-bottom {
  height: 150px;
}
</style>
